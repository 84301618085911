import { useTranslation } from "react-i18next";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import NoDataMessage from "components_refactor/NoDataMessage";
import { CARD_TYPE, PERSONALITY_EVALUATION } from "common/constants";
import EncouragementImage from "assets/images/potential/encouragement.svg";
import StressImage from "assets/images/potential/stress.svg";
import { useStyles } from "../../../../styles";

const EvaluationCard = (props) => {
  const { info, type, archetype, index } = props;
  const classes = useStyles();
  const { t } = useTranslation(["potential"]);

  const getBadgeTitle = () => {
    return (
      <div
        className={classes.cardIconTitleContainer}
        data-testid={"personality-test-card-evaluation"}
      >
        <img
          src={
            _.isEqual(index, PERSONALITY_EVALUATION.energizes)
              ? EncouragementImage
              : StressImage
          }
          alt={
            _.isEqual(index, PERSONALITY_EVALUATION.energizes)
              ? t("potential:chart.encouragement")
              : t("potential:chart.stress")
          }
          className={
            _.isEqual(index, PERSONALITY_EVALUATION.energizes)
              ? classes.cardImage
              : ""
          }
        />
        <Typography variant="h5" className={classes.cardTitle}>
          {_.isEqual(index, PERSONALITY_EVALUATION.energizes)
            ? t("potential:chart.encouragement")
            : t("potential:chart.stress")}
        </Typography>
      </div>
    );
  };

  return (
    <Grid
      container
      spacing={3}
      data-testid="personality-test-chart-evaluation-card"
    >
      <Grid item xs={12}>
        <div
          className={
            _.isEqual(type, CARD_TYPE.evaluation)
              ? classes.cardTitleBordered
              : ""
          }
        >
          {_.isEqual(type, CARD_TYPE.evaluation) ? (
            <Typography variant="h5" className={classes.cardTitle}>
              {`${t("potential:chart.archetype")}: ${archetype}`}
            </Typography>
          ) : (
            getBadgeTitle()
          )}
        </div>
        {_.isEmpty(info) ? (
          <NoDataMessage customStyles={classes.noDataCustom} />
        ) : (
          <Typography className={classes.cardText}>{info}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default EvaluationCard;
