import moment from "moment";

export const quadrantConfigs = [
  {
    id: 0, name: "High Performer", number: 7, fillColor: "#219653",
  },
  {
    id: 1, name: "Consistent Star", number: 8, fillColor: "#2D9CDB",
  },
  {
    id: 2, name: "Sassy Performer", number: 9, fillColor: "#2F80ED",
  },
  {
    id: 3, name: "Efficient Performer", number: 4, fillColor: "#F2994A",
  },
  {
    id: 4, name: "Key Player", number: 5, fillColor: "#27AE60",
  },
  {
    id: 5, name: "Future Star", number: 6, fillColor: "#56CCF2",
  },
  {
    id: 6, name: "Low Performer", number: 1, fillColor: "#EB5757",
  },
  {
    id: 7, name: "Inconsistent Performer", number: 2, fillColor: "#F27777",
  },
  {
    id: 8, name: "Diamond in the rough", number: 3, fillColor: "#6FCF97",
  },
];

const yLabels = [
  { label: "High", range: [71, 100] },
  { label: "Mid", range: [35, 70] },
  { label: "Low", range: [0, 34] },
];

const xLabels = [
  { label: "Low", range: [0, 34] },
  { label: "Mid", range: [35, 70] },
  { label: "High", range: [70, 100] },
];

const toSnakeCase = (str) => str.replace(/\s+/g, "_").toLowerCase();

const getQuadrant = (x, y) => {
  const quadrantX = xLabels.findIndex((label) => x >= label.range[0] && x <= label.range[1]);
  const quadrantY = yLabels.findIndex((label) => y >= label.range[0] && y <= label.range[1]);

  if (quadrantX === -1 || quadrantY === -1) {
    return null;
  }

  return (2 - quadrantY) * 3 + quadrantX + 1;
};

const getRandomGender = (name) => {
  const hash = name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return hash % 2 === 0 ? "men" : "women";
};

const getRandomImage = (name) => {
  const gender = getRandomGender(name);
  const imageIndex = Math.floor(Math.random() * 100);
  return `https://randomuser.me/api/portraits/${gender}/${imageIndex}.jpg`;
};

export const getData = (data) => {
  let invalidCount = 0;
  const maxRange = 100;

  const validData = data.filter((row) => {
    const newRow = Object.fromEntries(
      Object.entries(row).map(([key, value]) => [toSnakeCase(key), value]),
    );

    const hasRequiredFields = newRow.name
      && newRow.personal_id
      && newRow.x !== undefined && newRow.x !== null
      && newRow.y !== undefined && newRow.y !== null
      && newRow.x >= 0
      && newRow.x <= maxRange
      && newRow.y >= 0
      && newRow.y <= maxRange;

    if (!hasRequiredFields) {
      invalidCount += 1;
    }

    return hasRequiredFields;
  });

  return { validData, invalidCount };
};

const calculateQuadrantPosition = (x, y) => {
  const quadrantXIndex = xLabels.findIndex(
    (label) => x >= label.range[0] && x <= label.range[1],
  );
  const quadrantYIndex = yLabels.findIndex(
    (label) => y >= label.range[0] && y <= label.range[1],
  );

  if (quadrantXIndex === -1 || quadrantYIndex === -1) {
    return { quadrantX: null, quadrantY: null };
  }

  const xRange = xLabels[quadrantXIndex].range;
  const yRange = yLabels[quadrantYIndex].range;

  const quadrantX = (((x - xRange[0]) / (xRange[1] - xRange[0])) * 100).toFixed(2);
  const quadrantY = (((y - yRange[0]) / (yRange[1] - yRange[0])) * 100).toFixed(2);

  return { quadrantX, quadrantY };
};

export const getExcelData = (data) => data.map((row, index) => {
  const newRow = Object.fromEntries(
    Object.entries(row).map(([key, value]) => [toSnakeCase(key), value]),
  );

  const quadrant = getQuadrant(newRow.x, newRow.y);
  const { quadrantX, quadrantY } = calculateQuadrantPosition(newRow.x, newRow.y);

  return {
    ...newRow,
    quadrant,
    quadrant_x: quadrantX,
    quadrant_y: quadrantY,
    profile_img: getRandomImage(newRow.name || `User${index}`),
    full_name: newRow.name,
    id: newRow.personal_id,
  };
});

export const formatDateShort = (date, lang = "es") => {
  moment.locale(lang);

  return moment(date).utc().format("MMMYY").toLowerCase();
};
