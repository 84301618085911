import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import {
  AUTO_HIDE_DURATION,
  ALERT_ANCHOR_ORIGIN,
} from "common/constants";
import SimpleAlert from "../SimpleAlert";

const AlertAutoHide = (props) => {
  const {
    type,
    message,
    title,
    open,
    onClose,
    anchorOrigin,
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Snackbar
      open={ isOpen }
      autoHideDuration={ AUTO_HIDE_DURATION }
      onClose={ () => onClose(false) }
      anchorOrigin={ anchorOrigin }
      transitionDuration={ 0 }
      data-testid={ "alert-auto" }
    >
      <div>
        <SimpleAlert
          title={ title }
          message={ message }
          type={ type }
          onClose={ () => onClose(false) }
        />
      </div>
    </Snackbar>
  );
};

AlertAutoHide.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.object,
};

AlertAutoHide.defaultProps = {
  open: false,
  type: "info",
  message: "",
  title: "",
  anchorOrigin: ALERT_ANCHOR_ORIGIN,
  onClose: () => {},
};

export default AlertAutoHide;
