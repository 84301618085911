import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components_refactor/NoDataMessage";
import CheckboxButton from "components/CheckboxButton";
import SkeletonLoader from "components/SkeletonLoader";
import { getMonthYear } from "common/utils";
import charts from "theme/charts";
import { getDataBarNps, getDataSatisfactionBar } from "../../functions";
import { optionsBar, optionsSatisfactionBarByDivision } from "../../functions/ChartOptions";
import { StyledTitle } from "../GeneralContent/styles";
import { StyledSubtitle } from "../../styledComponents";
import { StyleMultipleCheckbox } from "./styles";

const ComparativePeriods = ({ t }) => {
  const {
    engagementEvolution,
    isLoadingEngagementEvolution,
  } = useSelector(({ engagementProcessReducer }) => engagementProcessReducer);

  const [values, setValues] = useState({
    nps: {},
    satisfaction: {},
  });

  const [activeResults, setActiveResults] = useState(null);

  const setDefaultData = useCallback((results) => {
    setActiveResults(results);
    const activeData = results.filter((item) => !item.disabled);
    const npsData = getDataBarNps(activeData, true);
    const satisfactionData = getDataSatisfactionBar(activeData, t, true);
    setValues({ nps: npsData, satisfaction: satisfactionData });
  }, [t]);

  // Update charts data when checkbox status changes
  const handleCheckboxChange = (id, checked) => {
    activeResults.forEach((item) => {
      if (item.id === id) {
        item.disabled = !checked;
      }
    });
    setDefaultData(activeResults);
  };

  useEffect(() => {
    if (engagementEvolution && !isLoadingEngagementEvolution) {
      engagementEvolution.forEach((result) => {
        result.name = getMonthYear(result.start_date, t);
      });
      setDefaultData(engagementEvolution);
    }
  }, [engagementEvolution, isLoadingEngagementEvolution, t, setDefaultData]);

  const getBarChart = (dataBar, options) => (
    dataBar?.labels?.length > 0 ? (
      <Bar data={ dataBar } options={ options } />
    ) : (
      <NoDataMessage />
    )
  );

  return isLoadingEngagementEvolution ? (
    <SkeletonLoader num={ 8 } />
  ) : (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 12 }>
        <StyledTitle>{t("comparison_of_periods")}</StyledTitle>
        {/* periods */}
        <StyleMultipleCheckbox>
          {engagementEvolution?.map((item, index) => (
            <CheckboxButton
              key={ item.id }
              id={ item.id }
              name={ item.name }
              onChange={ handleCheckboxChange }
              isChecked={ !item.disabled }
              color={ charts.colors.engagementBar[index] }
            />
          ))}
        </StyleMultipleCheckbox>
      </Grid>
      {/* nps by period */}
      <Grid item xs={ 12 } md={ 6 }>
        <StyledSubtitle variant={ "h4" }>{t("nps")}</StyledSubtitle>
        {getBarChart(values?.nps, optionsBar)}
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        {/* satisfaction by period */}
        <StyledSubtitle variant={ "h4" }>{t("satisfaction")}</StyledSubtitle>
        {getBarChart(values?.satisfaction, optionsSatisfactionBarByDivision)}
      </Grid>
    </Grid>
  );
};

ComparativePeriods.propTypes = {
  t: PropTypes.func.isRequired,
};

export default ComparativePeriods;
