import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ReactFlow, { Panel } from "reactflow";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Loader from "components/Loader";
import {
  ALIGN_ITEMS,
  OBJECT_KEYS,
  VARIANT,
  TYPES,
  CIRCULAR_PROGRESS,
  TRANSITIONS_DURATION,
} from "common/constants";
import Header from "../Header";
import NodeCard from "../NodeCard";
import { StyledButton, LoaderContainer } from "./styles";
import "reactflow/dist/style.css";

/* Define custom node types for reactflow
out of the component to avoid re-rendering */
const nodeTypes = {
  customNode: NodeCard,
};

// Component to render organization chart tab content
const OrgChart = (props) => {
  const {
    nodes, edges, onNodesChange, zoomIn, zoomOut, center,
  } = props;

  const {
    isLoadingOrgChartList: isCollaboratorLoadingList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  return (
    <>
      {/* Title and Filter */}
      <Header />
      {isCollaboratorLoadingList ? (
        <LoaderContainer>
          <Loader size={ CIRCULAR_PROGRESS.medium } />
        </LoaderContainer>
      ) : (
        <ReactFlow
          nodes={ nodes }
          edges={ edges }
          nodeTypes={ nodeTypes }
          onNodesChange={ onNodesChange }
          nodesDraggable={ false }
          defaultViewport={ center }
          minZoom={ 0.1 }
          maxZoom={ 3 }
          zoomOnScroll
          zoomOnPinch
          zoomOnDrag
        >
          <Panel position={ ALIGN_ITEMS.topRight }>
            <StyledButton
              id={ OBJECT_KEYS.zoomIn }
              variant={ VARIANT.outlined }
              type={ TYPES.text }
              disabled={ nodes.length === 0 }
              onClick={ () => zoomIn({ duration: TRANSITIONS_DURATION.zoom }) }
            >
              <AddIcon />
            </StyledButton>
            <StyledButton
              id={ OBJECT_KEYS.zoomOut }
              variant={ VARIANT.outlined }
              type={ TYPES.text }
              disabled={ nodes.length === 0 }
              onClick={ () => zoomOut({ duration: TRANSITIONS_DURATION.zoom }) }
            >
              <RemoveIcon />
            </StyledButton>
          </Panel>
        </ReactFlow>
      )}
    </>
  );
};

OrgChart.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  edges: PropTypes.arrayOf(PropTypes.object),
  onNodesChange: PropTypes.func,
  zoomIn: PropTypes.func,
  zoomOut: PropTypes.func,
  center: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    zoom: PropTypes.number,
  }).isRequired,
};

OrgChart.defaultProps = {
  nodes: [],
  edges: [],
  onNodesChange: () => {},
  zoomIn: () => {},
  zoomOut: () => {},
};

export default OrgChart;
