import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
  StyledCardActions,
  StyledCollapse,
  StyledIconButton,
} from "./styles";

const CardProfile = ({
  data,
  header,
  title,
  activeItem,
  handleAction,
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("common");

  const getValueToRender = (field) => {
    const headerItem = header.find((item) => item.id === field);
    return headerItem?.customRender
      ? headerItem.customRender(data)
      : data[headerItem?.id] || "";
  };

  useEffect(() => {
    setExpanded(activeItem !== null && data?.id === activeItem);
    // eslint-disable-next-line
  }, [activeItem]);

  return (
    <StyledCard>
      <StyledCardHeader title={ title } />
      <StyledCardContent>
        {getValueToRender("full_name")}
        {getValueToRender("state")}
      </StyledCardContent>
      <StyledCardActions>
        <StyledIconButton
          open={ expanded }
          onClick={ () => handleAction(data.id) }
          aria-expanded={ expanded }
          role={ "button" }
        >
          <ExpandMoreIcon />
        </StyledIconButton>
      </StyledCardActions>
      <StyledCollapse in={ expanded } timeout={ "auto" } unmountOnExit>
        <CardContent>
          <Typography paragraph>{`${ t("common.startingDate") }: ${ getValueToRender("starting_date")}`}</Typography>
          {getValueToRender("actions")}
        </CardContent>
      </StyledCollapse>
    </StyledCard>
  );
};

CardProfile.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    full_name: PropTypes.string,
    state: PropTypes.string,
    starting_date: PropTypes.string,
    actions: PropTypes.node,
  }).isRequired,
  header: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      customRender: PropTypes.func,
    }),
  ),
  title: PropTypes.string,
  activeItem: PropTypes.number,
  handleAction: PropTypes.func,
};

CardProfile.defaultProps = {
  header: [],
  title: "",
  activeItem: null,
  handleAction: () => {},
};

export default CardProfile;
