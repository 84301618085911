import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ProfileInfo from "components/ProfileInfo";
import DownloadExcelButton from "components/DownloadExcelButton";
import TableGrid from "components/TableGrid";
import Button from "components/Button";
import Alert from "components_refactor/Alert";
import InputTag from "components/InputTag";
import TextField from "@mui/material/TextField";
import InputCodeEditor from "components/InputCodeEditor";
import Grid from "@mui/material/Grid";
import { isEmpty, isEqual, isBlank } from "common/helpers";
import {
  MESSAGE_TYPES, toast,
} from "components/Toast/functions";
import Box from "@mui/material/Box";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import { getListSearchPaginated, getCollaboratorByEmails } from "redux/actions/collaboratorActions";
import { getCollaboratorByParts } from "views/Collaborators/functions";
import { ReactComponent as Audiences } from "assets/images/icons/audiences.svg";
import { ReactComponent as GreyAudiences } from "assets/images/icons/grey_audiences.svg";
import {
  ROWS_RANGE_PER_PAGE,
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  ALIGN_ITEMS,
  SIZE,
  OBJECT_KEYS,
  MAX_EMPLOYEES_QUERY,
 } from "common/constants";
import {
  mainDownloadExcel, getFormattedTags, mergeListsOfObjects, removeById,
} from "common/utils";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { update } from "redux/actions/audience/audienceActions";
import AlertDialog from "components/AlertDialog";
import EmployeeValidator from "../../../../../Collaborators/components/EmployeeValidator";
import useAudienceList from "./hooks/useAudienceList";
import { StyledModal, ButtonGroup, StyledEditIcon } from "./styles";
import { getAudienceDataToDownload } from "../../../../functions";

const ModalAudience = (props) => {
  const {
    t,
    isOpen,
    handleCloseModal,
    audienceSelected,
    refetchAudienceData,
  } = props;

  const {
    audienceList,
    isLoadingAudienceList,
    isFetchingAudienceList,
    refetchAudienceList,
  } = useAudienceList(audienceSelected.id, isOpen);

  const dispatch = useDispatch();
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [showInputTag, setShowInputTag] = useState(false);
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [collaboratorsBySearchList, setCollaboratorsBySearchList] = useState([]);
  const [showCodeEditor, setShowCodeEditor] = useState(false);
  const [collaboratorsByEmailList, setCollaboratorsByEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(audienceSelected.name);

  const handleDelete = useCallback(async () => {
    const updatedEmployeeIds = audienceList.filter((employee) => employee.id !== employeeToDelete)
      .map((employee) => employee.id);

    await dispatch(update(audienceSelected.id, { audience: { employee_ids: updatedEmployeeIds } }));

    refetchAudienceList();
    refetchAudienceData();
    setIsAlertOpen(false);
  }, [audienceList, employeeToDelete, refetchAudienceList, refetchAudienceData]);

  const getProfile = (employee) => (
    <ProfileInfo
      key={ employee.id }
      collaborator={ employee }
      isShortVersion
      hasStatus
    />
  );

  const renderDeleteButton = useCallback((rowData) => (
    <IconButton
      onClick={ () => {
        setEmployeeToDelete(rowData.id);
        setIsAlertOpen(true);
      } }
      disabled={ !audienceSelected.can_edit }
    >
      <DeleteIcon />
    </IconButton>
  ), [audienceSelected.can_edit]);

  const header = [
    {
      id: "full_name",
      label: t("tables:headers.name"),
      customRender: (rowData) => getProfile(rowData),
    },
    {
      id: "actions",
      label: t("tables:headers.actions"),
      customRender: renderDeleteButton,
    },
  ];

  const emptyData = () => {
    setInputTagsSelected([]);
    setCollaboratorsBySearchList([]);
    setCollaboratorsByEmailList([]);
  };

  // Handle download Excel
  const handleDownloadExcel = () => {
    const dataToDownload = getAudienceDataToDownload(audienceList, t);
    mainDownloadExcel(dataToDownload, t("planning:modal.audience"));
  };

  const handleAddCollaboratorButtonClick = () => {
    setShowCodeEditor(false);
    if (showInputTag) {
      emptyData();
    }
    setShowInputTag(!showInputTag);
  };

  const resetData = () => {
    emptyData();
    setShowInputTag(false);
  };

  const resetBulkData = () => {
    emptyData();
    setShowCodeEditor(false);
  };

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  const handleTags = (tags) => {
    setInputTagsSelected(tags);
    setCollaboratorsBySearchList(
      mergeListsOfObjects(collaboratorsBySearchList, tags),
    );
  };

  const {
    searchList: collaboratorList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
      },
    };
    dispatch(getListSearchPaginated(query));
  }, [searchFilter, dispatch]);

  const handleAdd = async () => {
    const updatedEmployeeIds = Array.from(new Set([
      ...audienceList.map((employee) => employee.id),
      ...collaboratorsBySearchList.map((collaborator) => collaborator.id),
    ]));

    await dispatch(update(audienceSelected.id, { audience: { employee_ids: updatedEmployeeIds } }));

    refetchAudienceList();
    refetchAudienceData();
    setShowInputTag(!showInputTag);
  };

  const handleBulkAdd = async () => {
    const updatedEmployeeIds = Array.from(new Set([
      ...audienceList.map((employee) => employee.id),
      ...collaboratorsByEmailList.map((collaborator) => collaborator.id),
    ]));

    await dispatch(update(audienceSelected.id, { audience: { employee_ids: updatedEmployeeIds } }));

    refetchAudienceList();
    refetchAudienceData();
    setShowCodeEditor(!showCodeEditor);
  };

  const handleBulkAddButtonClick = () => {
    setShowInputTag(false);
    if (showCodeEditor) {
      emptyData();
    }
    setShowCodeEditor(!showCodeEditor);
  };


  const getEmployees = async (emails) => {
    let allResponse = [];
    if (emails.length > MAX_EMPLOYEES_QUERY) {
      allResponse = await getCollaboratorByParts(emails);
    } else {
      allResponse = await getCollaboratorByEmails(emails);
    }
    return allResponse;
  };

  const onChangeEditor = (newValue) => {
    const emails = [...new Set(newValue.split("\n").filter((e) => !isBlank(e)))];
    if (isEmpty(emails)) {
      setCollaboratorsByEmailList([]);
    } else {
      setIsLoading(true);
      getEmployees(emails).then(async (allResponse) => {
        const orderByEmail = emails.map((email) => {
          const employee = allResponse?.find((item) => isEqual(item.email.trim(), email.trim()));
          if (employee) {
            return {
              id: employee.id,
              value: employee.full_name,
              label: employee.full_name,
              email: employee.email,
              isActive: employee.is_active,
            };
          }
          return { email, noExist: true };
        });
        setCollaboratorsByEmailList(orderByEmail);
      }).catch(() => {
        toast(
          MESSAGE_TYPES.error,
          { title: t("common:common.api_responses.error.title") },
        );
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (collaboratorsByEmailList && !isEmpty(collaboratorsByEmailList)) {
      setIsLoading(false);
    }
  }, [collaboratorsByEmailList]);

  const removeItem = (id) => {
    setCollaboratorsByEmailList(removeById(collaboratorsByEmailList, id));
  };

  const handleNameChange = async () => {
    await dispatch(update(audienceSelected.id, { audience: { name } }));
    refetchAudienceData();
  };

  const handleEditTitleClick = () => {
    if (isEditing) {
      if (name.trim() === "") {
        setIsEditing(false);
        return;
      }
      handleNameChange();
      setIsEditing(false);
    } else {
      setIsEditing(true);
    }
  };

  const handleTextFieldChange = (e) => {
    setName(e.target.value);
  };

  return (
    <StyledModal
      isOpen={ isOpen }
      onClose={ handleCloseModal }
      title={ (
        <div style={ { display: "flex", alignItems: "center", width: "100%" } }>
          {isEditing ? (
            <TextField
              value={ name }
              onChange={ handleTextFieldChange }
              onKeyDown={ (e) => (e.key === "Enter" ? handleEditTitleClick() : null) }
              variant={ VARIANT.standard }
              inputProps={ { style: { padding: "10px", height: "30px", fontSize: "20px" } } }
              fullWidth
            />
          ) : (
            <span>{`${name} (${audienceList.length})`}</span>
          )}
          <IconButton
            onClick={ handleEditTitleClick }
            sx={ { ml: 1 } }
          >
            <StyledEditIcon />
          </IconButton>
        </div>
      ) }
    >
      <StyledScrollBar maxHeight={ "600px" } minWidth={ "650px" }>
        <ButtonGroup>
          <Button
            onClick={ handleAddCollaboratorButtonClick }
            variant={ VARIANT.outlined }
            color={ COLORS_NAME.primary }
            type={ BUTTON_STYLE_TYPES.TEXT }
            typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
            icon={
              audienceSelected.can_edit ? <Audiences /> : <GreyAudiences />
            }
            iconSide={ ALIGN_ITEMS.left }
            disabled={ !audienceSelected.can_edit }
          >
            {t("audiences:add.collaborator")}
          </Button>
          <Button
            onClick={ handleBulkAddButtonClick }
            variant={ VARIANT.outlined }
            color={ COLORS_NAME.primary }
            type={ BUTTON_STYLE_TYPES.TEXT }
            typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
            icon={
              audienceSelected.can_edit ? <Audiences /> : <GreyAudiences />
            }
            iconSide={ ALIGN_ITEMS.left }
            disabled={ !audienceSelected.can_edit }
          >
            {t("audiences:add.bulk-add")}
          </Button>
        </ButtonGroup>
        {!audienceSelected.can_edit && (
          <Alert
            type={ "info" }
            title={ t("audiences:no_edit.title") }
            message={ t("audiences:no_edit.text") }
          />
        )}
        {showInputTag && (
          <Box mt={ 2 }>
            <InputTag
              id={ "employee_ids_audience_modal" }
              size={ SIZE.small }
              itemsSelected={ inputTagsSelected }
              placeholder={ t("common:common.searchCollaborators") }
              onInputTextChange={ handleInputText }
              onChange={ handleTags }
              data={ getFormattedTags(collaboratorList, OBJECT_KEYS.fullname, true) }
              withoutTags={ false }
            />
            <Box display={ ALIGN_ITEMS.flex } justifyContent={ ALIGN_ITEMS.flexEnd }>
              <Box mt={ 2 } ml={ 2 } mb={ 2 }>
                <Button
                  onClick={ resetData }
                  variant={ VARIANT.contained }
                  color={ COLORS_NAME.primary }
                  type={ BUTTON_STYLE_TYPES.CANCEL }
                  typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                  size={ SIZE.small }
                >
                  {t("common:common.cancel")}
                </Button>
              </Box>
              <Box mt={ 2 } ml={ 2 } mb={ 2 }>
                <Button
                  onClick={ () => {
                    handleAdd();
                    emptyData();
                  } }
                  variant={ VARIANT.contained }
                  color={ COLORS_NAME.primary }
                  type={ BUTTON_STYLE_TYPES.SUBMIT }
                  typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  size={ SIZE.small }
                >
                  {t("common:common.save")}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        { showCodeEditor && (
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 6 }>
              <Box mt={ 2 } flexGrow={ 1 }>
                <InputCodeEditor
                  label={ t("audiences:copyCollaborators") }
                  placeholder={ t("audiences:fields.email") }
                  onChange={ onChangeEditor }
                />
              </Box>
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <Box mt={ 2 } flexGrow={ 1 }>
                <EmployeeValidator
                  collaborators={ collaboratorsByEmailList }
                  removeItem={ removeItem }
                  isLoading={ isLoading }
                  height={ "218px" }
                />
              </Box>
            </Grid>
            <Grid item xs={ 12 } display={ ALIGN_ITEMS.flex } justifyContent={ ALIGN_ITEMS.flexEnd }>
              <Box mt={ 0 } ml={ 1 } mb={ 1.5 }>
                <Button
                  onClick={ resetBulkData }
                  variant={ VARIANT.contained }
                  color={ COLORS_NAME.primary }
                  type={ BUTTON_STYLE_TYPES.CANCEL }
                  typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                  size={ SIZE.small }
                >
                  {t("common:common.cancel")}
                </Button>
              </Box>
              <Box mt={ 0 } ml={ 1 } mb={ 1.5 }>
                <Button
                  onClick={ () => {
                    handleBulkAdd();
                    emptyData();
                  } }
                  variant={ VARIANT.contained }
                  color={ COLORS_NAME.primary }
                  type={ BUTTON_STYLE_TYPES.SUBMIT }
                  typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  size={ SIZE.small }
                >
                  {t("common:common.save")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
        <TableGrid
          rows={ audienceList }
          id={ "collaborator-table-grid" }
          header={ header }
          paginationOptions={ {
            maxPerPage: ROWS_RANGE_PER_PAGE[0],
            rowsRange: ROWS_RANGE_PER_PAGE,
          } }
          topContent={ (
            <DownloadExcelButton
              onClick={ handleDownloadExcel }
              disabled={ audienceList.length === 0 || isFetchingAudienceList }
            />
          ) }
          isLoading={ audienceList.length === 0 || isLoadingAudienceList }
          disabledSearch={ audienceList.length === 0 }
          searchBy={ "full_name" }
        />
        <AlertDialog
          isOpen={ isAlertOpen }
          onClose={ () => setIsAlertOpen(false) }
          title={ t("audiences:delete.confirmation") }
          onSubmit={ handleDelete }
        />
      </StyledScrollBar>
    </StyledModal>
  );
};

ModalAudience.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  audienceSelected: PropTypes.object,
  refetchAudienceData: PropTypes.func.isRequired,
};

ModalAudience.defaultProps = {
  audienceSelected: undefined,
};

export default ModalAudience;
