import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import noSuccessionList from "assets/images/successions/succession_empty.svg";
import { resetSuccessionCollaboratorsState, getSuccessionCollaborators as getCollaborators, getCollaborator } from "redux/actions/collaboratorActions";
import { getPositionList as getPositions } from "redux/actions/position/positionActions";
import { getList as getOrgUnitsList } from "redux/actions/common/orgUnitActions";
import { getList as getResultScales } from "redux/actions/resultScalesActions";
import {
  OBJECT_KEYS, SIZE, NINEBOX, SKELETONS_NUMBER,
} from "common/constants";
import { formatDate } from "common/utils";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import { getDataNineBoxQuadrant } from "views/MatrixNineBox/functions";
import SelectSearch from "components/SelectSearch";
import NoDataMessage from "components_refactor/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import { getCurrentTalentScoreByEmployee } from "redux/actions/talentScore/talentScoreActions";
import { getFormattedSuccession, generateEstimatedTimeByLanguage } from "./functions";
import CollaboratorInfo from "./components/CollaboratorInfo";
import { StyledInfo } from "./styles";

const Successions = (props) => {
  const { employeeId, employeeInfo } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "successions"]);
  const { language } = i18n;

  const [searchFilter, setSearchFilter] = useState(null);
  const [collaboratorsSelected, setCollaboratorsSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unitsCache, setUnitsCache] = useState({});

  const {
    successionCollaboratorList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    list: resultScaleList,
    isLoading: isLoadingScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const getChildrenData = useCallback(() => {
    const collaboratorsIds = collaboratorsSelected.map((collaborator) => collaborator.id);
    const query = {
      q: {
        active_in: [true],
        id_not_in: collaboratorsIds,
        person_full_name_cont: searchFilter,
      },
    };
    dispatch(getCollaborators(query));
  }, [searchFilter]);

  useEffect(() => {
    if (searchFilter !== null) {
      getChildrenData();
    }
    // eslint-disable-next-line
  }, [searchFilter]);

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  const { nineBoxTypeList, isLoading: isLoadingType } = useNineBoxTypes(t);

  const getNineboxResult = async (selectedEmployee) => {
    let nineboxResult = null;
    let quadrant = null;
    const currentTalentScore = await getCurrentTalentScoreByEmployee(selectedEmployee);
    if (currentTalentScore?.performance && currentTalentScore?.potential) {
      const results = getDataNineBoxQuadrant(
        currentTalentScore, resultScaleList, t, nineBoxTypeList,
      );
      if (results.length > 0) {
        quadrant = results[0].quadrantData[NINEBOX.quadrantData.quadrant];
      }
      const performanceDate = new Date(currentTalentScore.performance.score_updated_at);
      const potentialDate = new Date(currentTalentScore.potential.score_updated_at);
      const latestDate = performanceDate > potentialDate ? performanceDate : potentialDate;
      nineboxResult = {
        nineboxQuadrant: {
          quadrant,
          updatedAt: formatDate(latestDate),
        },
      };
    }
    return nineboxResult;
  };

  const getDataByEmployee = useCallback(async (selectedEmployee) => {
    setIsLoading(true);
    const employeeData = await dispatch(getCollaborator(selectedEmployee));
    const nineboxResult = await getNineboxResult(selectedEmployee);
    employeeData.results = nineboxResult;
    setCollaboratorsSelected((prevCollaborators) => [...prevCollaborators, employeeData]);
    setIsLoading(false);
  });

  const handleCollaboratorSelected = (collaborator) => {
    if (collaborator?.id) {
      dispatch(resetSuccessionCollaboratorsState());
      setSearchFilter(null);
      if (!collaboratorsSelected.some((selected) => selected.id === collaborator.id)) {
        getDataByEmployee(collaborator.id);
      }
    }
  };

  useEffect(() => {
    if (resultScaleList !== null && !isLoadingScaleList && !isLoadingType) {
      if (employeeInfo) {
        const isAlreadySelected = collaboratorsSelected.some(
          (collaborator) => collaborator.id === employeeInfo.id,
        );
        if (!isAlreadySelected) {
          setCollaboratorsSelected((prevCollaborators) => [...prevCollaborators, employeeInfo]);
        }
      } else if (employeeId && !collaboratorsSelected.some(
        (collaborator) => collaborator.id === employeeId,
      )) {
        getDataByEmployee(employeeId);
      }
    }
    // eslint-disable-next-line
  }, [resultScaleList]);

  const {
    data: positionList,
    isLoading: isLoadingPositions,
  } = useQuery("positionList", () => getPositions(), {
    staleTime: Infinity,
  });

  // const {
  //   list: unitList,
  //   loadingList: isLoadingUnits,
  // } = useSelector(({ orgUnitReducer }) => orgUnitReducer);

  useEffect(() => {
    dispatch(getOrgUnitsList());
    dispatch(getResultScales());
    // eslint-disable-next-line
  }, []);

  const estimatedTime = generateEstimatedTimeByLanguage(language);

  const handleCloseEmployeeInfo = (collaboratorId) => {
    setCollaboratorsSelected((currentSelected) => currentSelected.filter(
      (collaborator) => collaborator.id !== collaboratorId,
    ));
  };

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <SelectSearch
          id={ "employeeIds" }
          size={ SIZE.small }
          placeholder={ t("common:common.searchCollaborators") }
          onInputTextChange={ handleInputText }
          onChange={ handleCollaboratorSelected }
          data={ getFormattedSuccession(successionCollaboratorList, OBJECT_KEYS.fullname) }
          disabled={ isLoading }
        />
        <StyledInfo>{isLoading ? t("successions:alert.generate_info") : t("successions:alert.total_view", { employee_count: collaboratorsSelected.length })}</StyledInfo>
      </Grid>
      {(isLoadingPositions)
      && <SkeletonLoader num={ SKELETONS_NUMBER.SIX } />}
      {!isLoadingPositions
        && (
          <Grid item xs={ 12 }>
            {collaboratorsSelected.length === 0
              ? <NoDataMessage iconImage={ noSuccessionList } message={ t("successions:actions.search") } /> : collaboratorsSelected.map((collaborator) => (
                <CollaboratorInfo
                  key={ collaborator.id }
                  collaborator={ collaborator }
                  t={ t }
                  positions={ positionList }
                  estimatedTime={ estimatedTime }
                  handleCloseEmployeeInfo={ handleCloseEmployeeInfo }
                  isLoading={ isLoading }
                  unitsCache={ unitsCache }
                  setUnitsCache={ setUnitsCache }
                />
              ))}
          </Grid>
        )}
    </Grid>
  );
};

Successions.propTypes = {
  employeeId: PropTypes.number,
  employeeInfo: PropTypes.object,
};

Successions.defaultProps = {
  employeeId: null,
  employeeInfo: null,
};

export default Successions;
