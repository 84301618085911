import styled from "styled-components";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import palette from "theme/palette";

export const StyledMatrixContainer = styled(CardContent)`
  background: ${palette.white};
  .MuiBox-root {
    padding: 5px 0 10px;
  }
  table .MuiBox-root {
    padding: 0;
  }
  .MuiTableCell-root {
    padding: 10px 20px;
  }
  .MuiTableCell-head {
    min-width: 150px;
    text-transform: capitalize;
  }
`;

export const StyledCard = styled(Card)`
  padding: 10px;
  height: 100%;
  &.MuiPaper-root {
    box-shadow: none;
  }
`;
