import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Button from "components/Button";
import InputEditor from "components/InputEditor";
import { BUTTON_STYLE_TYPES, BUTTON_TYPE } from "common/constants";
import { StyledGrid, StyledContainerGrid, StyledButtonGrid } from "./styles";

const FeedbackSimple = (props) => {
  const {
    t,
    feedbackStates,
    hookFeedbackForm,
    feedbackProcess,
    validateInputEdit,
  } = props;

  const { disabledFeedbackSimple } = feedbackStates;
  const { control, isValid } = hookFeedbackForm;
  const { isLoadingProcess } = feedbackProcess;

  return (
    <StyledContainerGrid container data-testid={ "feedback-simple-view" }>
      <StyledGrid item xs={ 12 }>
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputEdit } /> }
          name={ "feedback_simple.message" }
          control={ control }
        />
      </StyledGrid>
      <StyledButtonGrid item xs={ 12 }>
        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          disabled={ !isValid || disabledFeedbackSimple }
          isLoading={ isLoadingProcess }
        >
          {t("feedback:send_feedback")}
        </Button>
      </StyledButtonGrid>
    </StyledContainerGrid>
  );
};

FeedbackSimple.propTypes = {
  t: PropTypes.func.isRequired,
  feedbackStates: PropTypes.object.isRequired,
  hookFeedbackForm: PropTypes.object.isRequired,
  feedbackProcess: PropTypes.object.isRequired,
  validateInputEdit: PropTypes.func.isRequired,
};

export default FeedbackSimple;
