import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";

const CheckboxInput = (props) => {
  const {
    onChange,
    disabled,
    isChecked,
    id,
    label,
    name,
    indeterminate,
    inputRef,
    value,
    defaultChecked,
  } = props;

  const handleChange = (prop) => (event) => {
    onChange && onChange(prop, event);
  };

  const checkboxReturn = () => (
    <Checkbox
      data-testid={ "checkboxValidator" }
      checked={ isChecked }
      id={ id }
      disabled={ disabled }
      color={ "secondary" }
      onChange={ handleChange(id) }
      inputProps={ { "aria-label": "secondary checkbox" } }
      name={ name }
      value={ value }
      indeterminate={ indeterminate }
      inputRef={ inputRef }
      defaultChecked={ defaultChecked }
    />
  );

  return label ? (
    <FormControlLabel control={ checkboxReturn() } label={ label } value={ value } />
  ) : (
    checkboxReturn()
  );
};

CheckboxInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  inputLabelProps: PropTypes.object,
  onChange: PropTypes.func,
  name: PropTypes.string,
  indeterminate: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputRef: PropTypes.func,
  defaultChecked: PropTypes.bool,
};

export default CheckboxInput;
