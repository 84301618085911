import { ReactComponent as LogoNalaMin } from "assets/images/general/logo_nala_min.svg";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import clsx from "clsx";
import XLSX from "xlsx";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TooltipIcon from "components/TooltipIcon";
import {
  FORMAT_EMAIL,
  ERROR,
  BULK_UPLOAD,
  TYPES,
  CIRCULAR_PROGRESS,
  OBJECT_KEYS,
  LOCAL_STORAGE_NAMES,
  RADIO_OPTIONS,
  INDEX,
} from "common/constants";
import { isNullOrUndefined } from "common/validators";
import {
  convertSnakeCase, formatDate, formatPhoneNumber, getItemFromLocalStorage, isValidDate,
} from "common/utils";
import { isEqual, isNotValid, isNull } from "common/helpers";

// eslint-disable-next-line default-param-last
const excelHeader = (key, name, isRequired, format, list = [], keyList) => ({
  key, name, isRequired, format, list, keyList, active: true,
});

const turnoverTypes = [
  { name: RADIO_OPTIONS.voluntary },
  { name: RADIO_OPTIONS.involuntary },
];

export const excelHeaderByDefault = (
  t,
  genderList = [],
  dynamicAttributesList = [],
  turnoverReasonsList = [],
) => {
  const header = [
    excelHeader(OBJECT_KEYS.id, t("excelData.id"), true, TYPES.string),
    excelHeader(OBJECT_KEYS.name, t("excelData.name"), true, TYPES.string),
    excelHeader(
      OBJECT_KEYS.startingDate,
      t("excelData.startDate"),
      true,
      TYPES.date,
    ),
    excelHeader(
      OBJECT_KEYS.terminationDate,
      t("excelData.terminationDate"),
      false,
      TYPES.date,
    ),
    excelHeader(
      OBJECT_KEYS.turnoverType,
      t("excelData.turnoverType"),
      false,
      TYPES.string,
      turnoverTypes,
      OBJECT_KEYS.name,
    ),
    excelHeader(
      OBJECT_KEYS.turnoverReason,
      t("excelData.turnoverReason"),
      false,
      TYPES.string,
      turnoverReasonsList,
      OBJECT_KEYS.name,
    ),
    excelHeader(
      OBJECT_KEYS.turnoverComments,
      t("excelData.turnoverComments"),
      false,
      TYPES.string,
    ),
    excelHeader(
      OBJECT_KEYS.companyEmail,
      t("excelData.companyEmail"),
      true,
      TYPES.email,
    ),
    excelHeader(
      OBJECT_KEYS.managerId,
      t("excelData.leaderId"),
      false,
      TYPES.string,
    ),
    excelHeader(
      OBJECT_KEYS.position,
      t("excelData.position"),
      true,
      TYPES.string,
    ),
    excelHeader(OBJECT_KEYS.level, t("excelData.level"), true, TYPES.string),
    excelHeader(
      OBJECT_KEYS.typeOfContract,
      t("excelData.typeOfContract"),
      true,
      TYPES.string,
    ),
    excelHeader(
      OBJECT_KEYS.legalEntityCode,
      t("excelData.company"),
      false,
      TYPES.string,
    ),
    excelHeader(
      OBJECT_KEYS.country,
      t("excelData.country"),
      true,
      TYPES.string,
      getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries),
      OBJECT_KEYS.name,
    ),
    excelHeader(OBJECT_KEYS.city, t("excelData.city"), false, TYPES.string),
    excelHeader(
      OBJECT_KEYS.division,
      t("excelData.division"),
      true,
      TYPES.string,
    ),
    excelHeader(OBJECT_KEYS.area, t("excelData.area"), false, TYPES.string),
    excelHeader(
      OBJECT_KEYS.birthdate,
      t("excelData.birthdate"),
      false,
      TYPES.date,
    ),
    excelHeader(
      OBJECT_KEYS.gender,
      t("excelData.gender"),
      true,
      TYPES.string,
      genderList,
      OBJECT_KEYS.value,
    ),
    excelHeader(OBJECT_KEYS.otherGender, t("excelData.otherGender"), false, TYPES.string),
    excelHeader(
      OBJECT_KEYS.currency,
      t("excelData.typeOfCurrency"),
      true,
      TYPES.string,
      getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies),
      OBJECT_KEYS.code,
    ),
    excelHeader(
      OBJECT_KEYS.salary,
      t("excelData.salary"),
      true,
      TYPES.number,
    ),
  ];
  if (dynamicAttributesList) {
    dynamicAttributesList.forEach((item) => {
      header.push(excelHeader(
        convertSnakeCase(item.name),
        item.name,
        false,
        TYPES.string,
      ));
    });
  }
  header.push(
    excelHeader(
      OBJECT_KEYS.phoneNumber,
      t("excelData.phoneNumber"),
      true,
      TYPES.string,
    ),
  );
  return header;
};

const getHeaderNameExcel = (name, isRequired) => {
  const required = isRequired ? BULK_UPLOAD.requiredSign : "";
  return name + required;
};

export const downloadExcel = (filename, t) => {
  const data = [];
  const headerName = {};
  const headerByDefault = excelHeaderByDefault(t);
  headerByDefault.forEach((item) => {
    headerName[getHeaderNameExcel(item.name, item.isRequired)] = "";
  });
  data.push(headerName);
  return [data, filename, true];
};

const getIcon = (data, classes) => (
  <Box display={ "flex" } alignItems={ "center" }>
    <Box>
      <TooltipIcon
        element={ (
          <CancelIcon
            className={ data.errors.hasError ? classes.errorIcon : classes.iconHidden }
          />
        ) }
      >
        <ul className={ classes.errorList }>
          {data.errors.errorList.map((error, index) => <li key={ `${ERROR}-${index}` }>{error}</li>)}
        </ul>
      </TooltipIcon>
    </Box>
    <Box pl={ 2 }>{data.row_number}</Box>
  </Box>
);

export const getHeaderByDefault = (t, classes, dynamicAttributesList) => {
  const headerByDefault = excelHeaderByDefault(t, [], dynamicAttributesList);
  const headerData = [
    {
      id: BULK_UPLOAD.row_number,
      label: "",
      customRender: (rowData) => getIcon(rowData, classes),
      active: true,
    },
  ];

  return [
    ...headerData,
    ...headerByDefault.map((item) => ({ id: item.key, label: item.name, active: true })),
  ];
};

export const setAlert = (type, message) => ({ type, message });

export const validationByDataType = (type, value) => {
  switch (type) {
  case TYPES.number:
    return isNumber(value);
  case TYPES.email:
    return FORMAT_EMAIL.test(value);
  case TYPES.date:
    return isValidDate(value);
  default:
    return isNumber(value) || isString(value);
  }
};

const isValidTemplate = (headerByDefault, data) => {
  let isValidData = true;
  data.forEach((item) => {
    forOwn(item, (value, key) => {
      if (BULK_UPLOAD.row_number !== key) {
        const dataType = headerByDefault.find((header) => header.key === key);
        if (isNullOrUndefined(dataType)) {
          isValidData = false;
        }
      }
    });
  });

  if (isValidData) {
    isValidData = Object.keys(data[BULK_UPLOAD.xlsx.firstData]).length
        - BULK_UPLOAD.xlsx.col
      === headerByDefault.length;
  }
  return isValidData;
};

const setValidationData = (isValid, message) => ({ isValid, message });

const getFormat = (format) => (format === TYPES.string ? TYPES.text : format);

const validateValueByList = (value, data) => data.list.find((item) => item[data.keyList] === value);

const validateDataByKey = (value, key, t, headerByDefault) => {
  const dataType = headerByDefault.find((header) => header.key === key);
  let validationData = {};
  const isUsernameKey = isEqual(key, OBJECT_KEYS.companyEmail)
    || isEqual(key, OBJECT_KEYS.phoneNumber);

  // Validate that it is a value from the list
  if (!isNull(value) && !isEmpty(dataType.list)) {
    const isValidValue = validateValueByList(value, dataType);
    validationData = setValidationData(
      isValidValue,
      `${dataType.name} - ${t("common:common.excel.selectError")}`,
    );
  // Validate required data
  } else if (isNull(value) && dataType.isRequired && !(isUsernameKey)) {
    const requiredFileMessage = `${dataType.name} - ${t("required")}`;
    validationData = setValidationData(false, requiredFileMessage);
  } else {
  // Validate format
    const hasAValidUsername = isNull(value) && isUsernameKey;
    const isValid = hasAValidUsername || isNull(value)
    || validationByDataType(dataType.format, value);
    const invalidFileMessage = hasAValidUsername ? "" : !validationData.isValid
        && `${dataType.name} ${t("isInvalid")} "${getFormat(dataType.format)}"`;
    validationData = setValidationData(isValid, invalidFileMessage);
  }

  return validationData;
};

const orderDataByRowAndError = (data) => data
  .sort((a, b) => a.row_number - b.row_number)
  .sort((a, b) => +b.errors.hasError - a.errors.hasError);

export const validationData = (data, dynamicAttributesList, t) => {
  const headerByDefault = excelHeaderByDefault(t, [], dynamicAttributesList);
  let dataPreview = [];
  let hasError = false;
  let alertInfo = {};
  const dateColumns = headerByDefault.filter((item) => isEqual(item.format, TYPES.date));

  if (isValidTemplate(headerByDefault, data)) {
    data.forEach((item) => {
      let error = false;
      const errorList = [];
      const usernameColumns = [];
      if (!isNull(item[OBJECT_KEYS.companyEmail])) {
        usernameColumns.push(item[OBJECT_KEYS.companyEmail]);
      }
      if (!isNull(item[OBJECT_KEYS.phoneNumber])) {
        item[OBJECT_KEYS.phoneNumber] = formatPhoneNumber(item[OBJECT_KEYS.phoneNumber]);
        usernameColumns.push(item[OBJECT_KEYS.phoneNumber]);
      }

      dateColumns.forEach((col) => {
        if (!isNull(item[col.key])) {
          item[col.key] = formatDate(item[col.key]);
        }
      });

      if (isEmpty(usernameColumns)) { // Validate that it has email or phone
        error = true;
        errorList.push(t("usernameInvalid"));
      }
      forOwn(item, (value, key) => {
        if (BULK_UPLOAD.row_number !== key) {
          const validationDataResult = validateDataByKey(
            value,
            key,
            t,
            headerByDefault,
          );
          if (!validationDataResult?.isValid) {
            error = true;
            errorList.push(validationDataResult?.message);
          }
        }
      });
      if (error) {
        hasError = true;
      }
      item[OBJECT_KEYS.errors] = {
        hasError: error,
        errorList,
      };
    });
    const orderedData = orderDataByRowAndError(data);
    dataPreview = orderedData.slice(
      BULK_UPLOAD.xlsx.firstData,
      BULK_UPLOAD.xlsx.dataLimit,
    );
  } else {
    alertInfo = setAlert(ERROR, t("alertInfo.templateError"));
    hasError = true;
  }
  return { data: dataPreview, hasError, alert: alertInfo };
};

export const hasFileName = (stateFile, preview) => !isEmpty(stateFile) && isEmpty(preview);

export const hasAlert = (preview, isLoading) => preview && preview.alert && !isLoading;

export const getIconByResult = (isLoading, allData, classes) => (!isLoading && !isEmpty(allData) ? (
  <CheckCircleIcon className={ classes.checkIcon } />
) : (
  <CancelIcon
    className={ isLoading ? classes.disabledIcon : classes.enabledIcon }
  />
));

export const hasPreviewData = (preview, isLoading) => !isEmpty(preview.data) && !isLoading;

export const isFileEmptyOrIsLoading = (stateFile, isLoading) => isEmpty(stateFile) || isLoading;

export const isFileEmptyOrIsLoadingOrHasError = (
  isLoading,
  hasError,
  allData,
) => isLoading || hasError || isEmpty(allData);

const getWorksheet = (workbook, sheetNames, stateFile) => {
  const worksheet = workbook.Sheets[sheetNames];
  const range = XLSX.utils.decode_range(worksheet[BULK_UPLOAD.xlsx.ref]);

  for (let column = range.s.r; column <= range.e.c; ++column) {
    const address = XLSX.utils.encode_col(column) + BULK_UPLOAD.xlsx.col.toString();
    if (!worksheet[address]) continue;
    const stateFileHeader = stateFile.header[column + BULK_UPLOAD.xlsx.col];
    worksheet[address].w = stateFileHeader && stateFileHeader.id;
  }

  return worksheet;
};

const getTotalOfColumns = (t, dynamicAttributesList) => {
  const headerByDefault = excelHeaderByDefault(t, [], dynamicAttributesList);
  return headerByDefault.length;
};

const getRange = (t, worksheet, dynamicAttributes) => {
  const range = XLSX.utils.decode_range(worksheet[BULK_UPLOAD.xlsx.ref]);
  range.s.c = BULK_UPLOAD.xlsx.firstData;
  range.e.c = getTotalOfColumns(t, dynamicAttributes) - INDEX.one;
  return XLSX.utils.encode_range(range);
};

const getTypeStringColumns = (t, dynamicAttributes) => excelHeaderByDefault(t, [], dynamicAttributes)
  .filter((e) => isEqual(e.format, TYPES.string))
  .map((e) => e.key);

export const readData = (result, readAsBinaryString, stateFile, t, dynamicAttributes) => {
  const workbook = XLSX.read(result, {
    type: readAsBinaryString
      ? BULK_UPLOAD.xlsx.type.binary
      : BULK_UPLOAD.xlsx.type.array,
    bookVBA: true,
    cellDates: true,
  });
  const sheetNames = workbook.SheetNames[BULK_UPLOAD.xlsx.firstData];
  const worksheet = getWorksheet(workbook, sheetNames, stateFile);
  const newRange = getRange(t, worksheet, dynamicAttributes);
  const data = XLSX.utils.sheet_to_json(worksheet, {
    blankRows: true,
    defval: null,
    range: newRange,
  });
  const stringColumns = getTypeStringColumns(t, dynamicAttributes);

  data.forEach((item, index) => {
    item[BULK_UPLOAD.row_number] = index + BULK_UPLOAD.xlsx.initialCounter;
    stringColumns.forEach((column) => {
      item[column] = isNotValid(item[column]) ? null : String(item[column]);
    });
  });
  return data;
};

const createModal = (children, disabledIcon) => ({ children, disabledIcon });

const getContent = (icon, texts, t, classes, showLogo) => (
  <div className={ classes.modal }>
    {icon}
    {showLogo && <LogoNalaMin className={ classes.nalaLogoMin } />}
    <Typography variant={ "h4" } className={ classes.textTitle }>
      {texts.primary}
    </Typography>
    <Typography variant={ "h5" } className={ classes.textSubtitle }>
      {texts.secondary}
    </Typography>
  </div>
);

export const getModalData = (state, t, classes, response) => {
  let stateIcon = "";
  const texts = {
    primary: "",
    secondary: "",
  };
  let disabledIcon = false;

  switch (state) {
  case BULK_UPLOAD.state.pending:
    stateIcon = (
      <CircularProgress
        size={ CIRCULAR_PROGRESS.large }
        className={ classes.circular }
      />
    );
    texts.primary = t("alertInfo.upload");
    texts.secondary = t("alertInfo.willTakeTime");
    disabledIcon = true;
    break;
  case BULK_UPLOAD.state.successfully:
    stateIcon = (
      <CheckCircleOutlinedIcon
        className={ clsx(classes.checkIcon, classes.largeIcon) }
      />
    );
    texts.primary = t("alertInfo.bulkUploadSuccessfully");
    break;
  case BULK_UPLOAD.state.failed:
    stateIcon = (
      <CancelOutlinedIcon
        className={ clsx(classes.failedIcon, classes.largeIcon) }
      />
    );
    texts.primary = response || t("alertInfo.bulkUploadError");
    texts.secondary = t("alertInfo.tryAgain");
    break;
  default:
    break;
  }
  return createModal(
    getContent(stateIcon, texts, t, classes, disabledIcon),
    disabledIcon,
  );
};

export const getTimeout = (data) => data * BULK_UPLOAD.calculateTime.min;

export const downloadExcelCustom = (filename, t, genderList, dynamicAttributesList, turnoverReasonsList) => {
  const data = excelHeaderByDefault(t, genderList, dynamicAttributesList, turnoverReasonsList);
  return [data, filename, true];
};

export const getDynamicAttributesFormat = (dynamicAttributesList) => dynamicAttributesList?.map((e) => ({
  name: e.name,
  key: convertSnakeCase(e.name),
  active: true,
}));

export const removeEmptyByKey = (item, key) => {
  if (isEmpty(item[key])) {
    delete item[key];
  }
};
