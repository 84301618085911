import { Tooltip } from "react-svg-tooltip";
import reduce from "lodash/reduce";
import gte from "lodash/gte";
import { PATTERN_OBJECT_BOUNDING } from "common/constants";
import {
  getPercent, findAxisIndex, getAxisLimits, getImageUrl,
} from "common/utils"; import { isNullOrUndefined } from "common/helpers";
import { dataBox } from "components_refactor/LeadershipMatrix/functions";
import defaultImg from "assets/images/general/avatar.png";
import palette from "theme/palette";
import {
  StyledCircle,
  StyledGroupNumber,
  StyledFocusRect,
  StyledTooltipRect,
  StyledTooltipText,
} from "../styles";

export const getLeadershipTypeResult = async (
  concatenatedLeadershipTypeResults,
) => new Promise((resolve) => {
  const dataMatrixResult = [];
  if (concatenatedLeadershipTypeResults) {
    concatenatedLeadershipTypeResults.forEach(async (concatenatedLeadershipTypeResult, index) => {
      concatenatedLeadershipTypeResult.index = index + 1;
      concatenatedLeadershipTypeResult.quadrantData = dataBox.find((quadrant) => quadrant.id === concatenatedLeadershipTypeResult.leadership_type.slug);
      concatenatedLeadershipTypeResult.x = getPercent(concatenatedLeadershipTypeResult.x_axis_score, false);
      concatenatedLeadershipTypeResult.y = getPercent(concatenatedLeadershipTypeResult.y_axis_score, false);
      concatenatedLeadershipTypeResult.employee.profile_img_url = getImageUrl(concatenatedLeadershipTypeResult.employee);
      dataMatrixResult.push(concatenatedLeadershipTypeResult);
      if (index === concatenatedLeadershipTypeResults.length - 1) {
        resolve(dataMatrixResult);
      }
    });
  }
});

// To group or not to group employees who are in the same position
export const groupByPosition = (result) => reduce(
  result,
  (groups, item) => {
    const positions = `${item.x}_${item.y}`;
    groups[positions] = groups[positions] || {
      xPosition: item.x,
      yPosition: item.y,
      results: [],
      total: 0,
    };
    groups[positions].total += 1;
    groups[positions].results.push(item);
    return groups;
  },
  {},
);

// The position changes if the group of employees in the same position is greater than or equal to 9
const getMinPositionValue = (total) => (gte(total, 9) ? 2 : 0.5);

// For the y_axis, the total is undefined because it is not sent, and this should be the value
// it takes; otherwise, it should be calculated.
const getTextPosition = (result, total) => (total === undefined
  ? result + 0.5
  : result - getMinPositionValue(total));

// Object with the corresponding values in the graphic for each limit in axisLimits
const axisGraphic = {
  x: [0, 27, 48, 63, 100],
  y: [0, 27, 54, 81],
};

// Function to calculate the position of a point in the quadrant
export const quadrantPosition = (xPosition, yPosition, leadershipTypes) => {
  // Get the index of the axis limit that xPosition and yPosition belong to
  const axisLimits = getAxisLimits(leadershipTypes);

  const xIndex = findAxisIndex(xPosition, axisLimits.x);
  const yIndex = findAxisIndex(yPosition, axisLimits.y);

  // Calculate the ranges for x and y in the graphic based on the axis limits
  const xRange = axisGraphic.x[xIndex + 1] - axisGraphic.x[xIndex];
  const yRange = axisGraphic.y[yIndex] - axisGraphic.y[yIndex + 1];

  // Scale xPosition and yPosition based on their ranges in axisLimits
  const xScaled = (xPosition - axisLimits.x[xIndex])
    / (axisLimits.x[xIndex + 1] - axisLimits.x[xIndex]);
  const yScaled = (axisLimits.y[yIndex] - yPosition)
    / (axisLimits.y[yIndex] - axisLimits.y[yIndex + 1]);

  // Calculate the position in the graphic using the scaled values and the ranges in axisGraphic
  const xValue = xScaled * xRange + axisGraphic.x[xIndex];
  const yValue = (1 - yScaled) * yRange + axisGraphic.y[yIndex + 1];

  // Return the final position, taking into account the orientation of the y-axis
  return { xPosition: xValue, yPosition: axisGraphic.y[axisGraphic.y.length - 1] - yValue };
};

const positionResult = (value) => value - 3;

export const getCircleImage = (
  order,
  total,
  xPosition,
  yPosition,
  leadershipTypes,
  url,
  circleRef,
  focus,
) => {
  const result = quadrantPosition(xPosition, yPosition, leadershipTypes);

  return (
    <>
      {focus && (
        <>
          <StyledFocusRect
            x={ positionResult(result.xPosition) }
            y={ 0 }
            width={ 6 }
            height={ 100 }
          />
          <StyledFocusRect
            x={ 0 }
            y={ positionResult(result.yPosition) }
            width={ 100 }
            height={ 6 }
          />
        </>
      )}
      <StyledCircle
        ref={ circleRef && circleRef }
        r={ focus ? 2.7 : 1.5 }
        cx={ result.xPosition }
        cy={ result.yPosition }
        order={ order }
        fill={ focus ? url : palette.text.link }
        stroke={ focus ? palette.text.link : palette.white }
        focus={ focus }
      />
      {/* getTextPosition is only taken when the total is greater than or equal to 2 */}
      {gte(total, 2) && (
        <StyledGroupNumber
          x={ getTextPosition(result.xPosition, total) }
          y={ getTextPosition(result.yPosition) }
        >
          {total}
          {gte(total, 9) && "+"}
        </StyledGroupNumber>
      )}
    </>
  );
};

export const getTooltipName = (name, circleRef) => (
  <Tooltip triggerRef={ circleRef }>
    <StyledTooltipRect
      x={ -18 }
      y={ -2.8 }
      width={ name.length + 3 }
      height={ 3.5 }
      rx={ 0.5 }
      ry={ 0.5 }
    />
    <StyledTooltipText
      x={ -16 }
      y={ -0.5 }
    >
      {name}
    </StyledTooltipText>
  </Tooltip>
);

export const getCircle = (
  data,
  handleEmployeeList,
  handleCircleSelected,
  circleRef,
  order,
  leadershipTypes,
  isActive = true,
) => {
  const dataGroup = groupByPosition(data);
  return Object.keys(dataGroup).map((key) => {
    const result = dataGroup[key].results[0];
    if (isActive) {
      return dataGroup[key].total !== 1 ? (
        <g
          key={ `${key}-${result.id}` }
          onClick={ (e) => handleEmployeeList(e, dataGroup[key].results) }
        >
          {getCircleImage(
            order,
            dataGroup[key].total,
            dataGroup[key].xPosition,
            dataGroup[key].yPosition,
            leadershipTypes,
          )}
        </g>
      ) : (
        <g
          key={ result.id }
          onClick={ () => handleCircleSelected(result) }
        >
          {getCircleImage(
            order,
            1,
            result.x,
            result.y,
            leadershipTypes,
          )}
          {getCircleImage(
            order,
            1,
            result.x,
            result.y,
            leadershipTypes,
            `url(#person${result.index})`, // employee's photo to be displayed over the circle.
            circleRef,
          )}
          {getTooltipName(result.employee.full_name, circleRef)}
        </g>
      );
    }
    return [];
  });
};

// This function returns the employee's image. If the img parameter is not defined,
// it returns the default image
export const getEmployeeImage = (img) => (img && !isNullOrUndefined(img) ? img : defaultImg);

// This function returns a set of SVG patterns for each process in the provided data list
export const getPatternImageByProcess = (data) => (
  <defs>
    {data && data.map((result) => (
      // A pattern is created for each process in the data list
      <pattern
        key={ result.id }
        id={ `person${result.index}` }
        height={ "100%" }
        width={ "100%" }
        patternContentUnits={ PATTERN_OBJECT_BOUNDING }
      >
        {/* The employee's profile image is included in the pattern. */}
        <image
          height={ 1 }
          width={ 1 }
          preserveAspectRatio={ "none" }
          xlinkHref={ getEmployeeImage(result.employee.profile_img_url) }
        />
      </pattern>
    ))}
  </defs>
);
