import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { isEmpty } from "common/helpers";
import {
  Container, LinearProgressContainer, Title, Results, Bubble, Progress,
} from "./styledComponents";

const BubbleProgress = (props) => {
  const { data } = props;

  return (
    <Container data-testid={ "bubble-progress-component" }>
      <Progress>
        {!isEmpty(data) && data.map((item) => (
          <Bubble
            key={ item.name }
            value={ item.value }
            size={ item.size } // 0 - 100
          />
        ))}
        <LinearProgressContainer>
          <LinearProgress
            variant={ "determinate" }
            value={ 0 }
          />
        </LinearProgressContainer>
      </Progress>
      <Results>
        <Box flexGrow={ 1 }>
          <Title variant={ "h6" }>
            {`${0}%`}
          </Title>
        </Box>
        <Box>
          <Title variant={ "h6" }>
            {"100%"}
          </Title>
        </Box>
      </Results>
    </Container>
  );
};

BubbleProgress.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    }),
  ),
};

BubbleProgress.defaultProps = {
  data: [],
};

export default BubbleProgress;
