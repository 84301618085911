import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DownloadExcelButton from "components/DownloadExcelButton";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import commentIcon from "assets/images/potential/icon-recomendacion.svg";
import { EXCEL_FILES_NAME } from "common/constants";
import { mainDownloadExcel, getSortingOrder } from "common/utils";
import useSurveyResults from "../../hooks/useSurveyResults";
import {
  getGoodLeaderDataToDownload,
  getRowsDesktop,
  getHeader,
} from "./functions";
import {
  StyledGridContainer, StyledSubtitleText,
  StyledError,
} from "../../styles";

const Managers = (props) => {
  const {
    processId,
    pagination,
    handlePagination,
    handleSearch,
    query,
    setTableSort,
    defaultOrderName,
  } = props;

  const { t } = useTranslation(["collaborators", "performance", "onboardingFollowUp"]);
  const {
    surveyResults: goodLeaderSurveyResultList,
    surveyResultsTotal: goodLeaderSurveyResultTotal,
    loadingSurveyResults: goodLeaderSurveyResultLoadingList,
  } = useSelector(({ performanceGoodLeaderReducer }) => performanceGoodLeaderReducer);

  const handleSort = (columnName, direction) => {
    setTableSort(getSortingOrder(columnName, direction));
  };

  const queryKey = ["surveyResult", processId];
  const [downloadList, setDownloadList] = useState(false);

  const {
    surveyResult,
    isLoadingSurveyResult,
    isFetchingSurveyResult,
    surveyResultError,
    refetchSurveyResults,
  } = useSurveyResults(processId, query, queryKey, goodLeaderSurveyResultTotal);

  const handleDownload = () => {
    setDownloadList(true);
    refetchSurveyResults();
  };

  useEffect(() => {
    if (downloadList && surveyResult.length > 0 && !isFetchingSurveyResult) {
      const data = getGoodLeaderDataToDownload(surveyResult, t);
      mainDownloadExcel(data, EXCEL_FILES_NAME.leadersReview);
      setDownloadList(false);
    }
  }, [surveyResult, downloadList, isFetchingSurveyResult, t]);

  return (
    <div data-testid={ "managers-table-container" }>
      <StyledGridContainer container>
        <Grid item xs={ 12 }>
          <StyledSubtitleText>
            {t("performance:managers")}
          </StyledSubtitleText>
        </Grid>
      </StyledGridContainer>
      <Grid container spacing={ 4 }>
        <Grid item xs={ 12 }>
          {surveyResultError && <StyledError>{surveyResultError}</StyledError>}
          <TablePaginationCollapse
            header={ getHeader(t) }
            list={ goodLeaderSurveyResultList }
            isLoading={ goodLeaderSurveyResultLoadingList }
            getRows={ {
              mobile: getRowsDesktop,
              desktop: getRowsDesktop,
            } }
            extraParam={ processId }
            handlePagination={ handlePagination }
            pagination={ pagination }
            total={ goodLeaderSurveyResultTotal }
            rightOptions={ (
              <DownloadExcelButton
                onClick={ () => handleDownload() }
                disabled={ isLoadingSurveyResult
                  || downloadList
                  || goodLeaderSurveyResultTotal.length === 0 }
                isDownloadApart
              />
            ) }
            handleSearch={ handleSearch }
            beforeIconCollapseData={ commentIcon }
            sortAction={ handleSort }
            defaultOrderName={ defaultOrderName }
          />
        </Grid>
      </Grid>
    </div>
  );
};

Managers.propTypes = {
  processId: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  setTableSort: PropTypes.func.isRequired,
  defaultOrderName: PropTypes.string.isRequired,
};

export default Managers;
