import { useState, cloneElement } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Modal from "components/Modal";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import LossRisk from "views/CollaboratorModals/LossRisk";
import Successions from "views/Successions";
import SuccessionsByPosition from "views/SuccessionsByPosition";
import Participation from "views/Participation";
import SuccessionIcon from "assets/images/icons/succession.svg";
import palette from "theme/palette";
import { defaultProps } from "./functions";

const ViewModalComponent = (props) => {
  const { viewComponent } = props;
  const { t } = useTranslation(["common", "successions"]);

  const [showModal, setShowModal] = useState(defaultProps);

  const handleCloseModal = () => {
    window.history.pushState({}, null, showModal.lastPath);
    setShowModal(defaultProps);
  };

  const components = {
    attrition: {
      content: LossRisk,
      title: t("common.lossRisk"),
    },
    successions: {
      content: Successions,
      title: t("successions:mark_as_successor"),
    },
    successionsByPosition: {
      content: SuccessionsByPosition,
      title: t("successions:mark_as_successor"),
    },
    participation: {
      content: Participation,
      title: t("participation:title"),
    },
  };

  // Create custom modals by paths
  const modalRoutes = [
    {
      path: "/attrition-risk",
      title: components.attrition.title,
      component: components.attrition.content,
    },
    {
      path: "/nala_analytics",
      title: components.attrition.title,
      component: components.attrition.content,
    },
    {
      path: "/dashboard-good-leader",
      title: components.attrition.title,
      component: components.attrition.content,
    },
    {
      path: "/profile",
      title: components.successions.title,
      component: components.successions.content,
      icon: SuccessionIcon,
      borderIconColor: palette.background.purple,
    },
    {
      path: "/collaborators",
      title: components.successions.title,
      component: components.successions.content,
      icon: SuccessionIcon,
      borderIconColor: palette.background.purple,
    },
    // TODO: add new modals
    {
      path: "/administrator/positions",
      title: components.successionsByPosition.title,
      component: components.successionsByPosition.content,
      customHeader: true,
    },
    {
      path: "/planning",
      title: components.participation.title,
      component: components.participation.content,
      maxWidth: "80%",
    },
  ];

  const handleOpenModal = (path, id, employeeInfo = null, shouldRefetch = false) => {
    const fullPath = window.location.href.replace(window.location.origin, "");
    window.history.pushState({ modal: true }, null, fullPath);
    const modalRoute = modalRoutes.find((modalData) => path.startsWith(modalData.path));
    if (modalRoute) {
      const Component = modalRoute.component;
      // FIXME: employeeId to id, employeeInfo to data
      const clonedComponent = (
        <Component
          employeeId={ id }
          employeeInfo={ employeeInfo }
          shouldRefetch={ shouldRefetch }
        />
      );
      setShowModal({
        state: true,
        component: clonedComponent,
        title: modalRoute.title,
        lastPath: fullPath,
        path,
        id,
        employeeInfo,
        icon: modalRoute?.icon,
        borderIconColor: modalRoute?.borderIconColor,
        customHeader: modalRoute?.customHeader,
        maxWidth: modalRoute?.maxWidth,
      });
    }
  };

  return (
    <>
      { cloneElement(viewComponent, { handleOpenModal }) }
      <Modal
        isOpen={ showModal.state }
        title={ showModal.title }
        onClose={ handleCloseModal }
        iconTitle={ showModal?.icon }
        borderIconColor={ showModal?.borderIconColor }
        customHeader={ showModal?.customHeader }
        maxWidth={ showModal?.maxWidth }
      >
        <StyledScrollBar maxHeight={ "600px" } padding={ "10px" } minWidth={ "900px" }>
          { showModal.component }
        </StyledScrollBar>
      </Modal>
    </>
  );
};

ViewModalComponent.propTypes = {
  viewComponent: PropTypes.object.isRequired,
};

export default ViewModalComponent;
