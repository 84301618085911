import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/en-gb";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";

const DatePickerInput = React.forwardRef(
  ({
    value, onChange, label, locale, disablePortal, ...rest
  }, ref) => {
    moment.locale(locale);

    const handleChange = (selectedDate) => {
      onChange(selectedDate ? selectedDate.format("YYYY-MM-DD") : "");
    };

    return (
      <LocalizationProvider
        dateAdapter={ AdapterMoment }
        adapterLocale={ locale }
      >
        <DatePicker
          label={ label }
          value={ value ? moment(value) : null }
          onChange={ handleChange }
          disablePortal={ disablePortal }
          PopperProps={ {
            anchorEl: ref?.current,
          } }
          { ...rest }
          renderInput={ (params) => (
            <TextField
              { ...params }
              variant={ "standard" }
              size={ "small" }
              inputRef={ ref }
            />
          ) }
        />
      </LocalizationProvider>
    );
  },
);

DatePickerInput.displayName = "DatePickerInput";

DatePickerInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.object,
  ]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  locale: PropTypes.string,
  disablePortal: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  value: "",
  label: "",
  locale: "en",
  disablePortal: false,
};

export default DatePickerInput;
