import {
  memo, useEffect, useContext, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { SessionContext } from "modules/session/context";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import { isCandidate } from "common/utils";
import { OBJECT_KEYS } from "common/constants";
import { isNotValid } from "common/helpers";
import { getListSearchPaginated } from "redux/actions/common/managerActions";

const ManagerSelect = (props) => {
  const {
    name,
    control,
    value,
    onChange,
    isRequired,
    disabled,
    employee,
  } = props;
  const { t } = useTranslation("common");
  const [searchFilter, setSearchFilter] = useState("");
  const [defaultLabel, setDefaultLabel] = useState("");
  const dispatch = useDispatch();

  const {
    state: { user },
  } = useContext(SessionContext);

  const {
    list: collaboratorsList,
  } = useSelector(({ managerReducer }) => managerReducer);

  useEffect(() => {
    if (!isCandidate(user?.userCookies)) {
      const query = {
        q: {
          person_full_name_cont: searchFilter,
          active_in: [true],
          id_not_in: [employee?.id],
        },
      };
      dispatch(getListSearchPaginated(query));
    }
  }, [searchFilter, employee, user, dispatch]);

  const handleInputText = (text) => {
    setSearchFilter(text);
    setDefaultLabel(text);
  };

  useEffect(() => {
    if (!isNotValid(value)) {
      setDefaultLabel(value);
    }
  }, [value]);

  return (
    <AutoCompleteCreableController
      options={ collaboratorsList }
      label={ t("common.manager") }
      control={ control }
      defaultValue={ defaultLabel }
      onChangeValue={ onChange }
      rules={ { required: isRequired } }
      name={ name }
      isWithHelperText={ isRequired }
      nameOfAttr={ OBJECT_KEYS.fullname }
      disabled={ disabled }
      onInputTextChange={ handleInputText }
      isRequired={ isRequired }
    />
  );
};

ManagerSelect.propTypes = {
  control: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  employee: PropTypes.object,
  disabled: PropTypes.bool,
};

ManagerSelect.defaultProps = {
  value: null,
  onChange: null,
  isRequired: false,
  employee: null,
  disabled: false,
};

export default memo(ManagerSelect);
