import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Bar } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import MultidataCard from "components/MultidataCard";
import NoDataMessage from "components_refactor/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import HalfScreenDialog from "components/HalfScreenDialog";
import SeeMoreLink from "components/SeeMoreLink";
import TooltipIcon from "components/TooltipIcon";
import {
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
  VARIANT,
  PAGINATION,
} from "common/constants";
import { getPercent } from "common/utils";
import {
  COLOR_BY_LEVEL_RISKS,
  getCardsDetail,
  getDataBar,
  getIconByNPSScore,
  optionsBar,
  getQueryByCard,
} from "views/NalaAnalytics/functions";
import { RISK_CATEGORY } from "views/TalentDrain/functions";
import { getList as getAttritionChart, resetState } from "redux/actions/attritionActions";
import NoInfoFaceBlack from "assets/images/general/noInfoFaceBlack.svg";
import {
  StyledComparativeResults,
  StyledDetail,
  StyledGrid,
  StyledGridContainer,
  StyledResult,
  StyledText,
  StyledSubtitle,
  StyledData,
  StyledCardsGrid,
  StyledSubGrid,
  StyledCircleIcon,
  StyledNoData,
  StyledNPSGrid,
  StyledRotationTitle,
} from "./styles";
import CardDetail from "../CardDetail";

const AnalysisSummary = (props) => {
  const {
    t,
    cardsStates,
    nalaAnalyticsLoadings,
  } = props;

  const [dialog, setDialog] = useState({
    state: false,
    component: "",
    title: "",
    key: null,
  });

  const dispatch = useDispatch();

  const handleViewMore = (card, data) => {
    const queryByCard = getQueryByCard(cardsStates.query, card.key);

    dispatch(resetState());
    dispatch(getAttritionChart(PAGINATION.next, null, queryByCard));
    dispatch(getAttritionChart(PAGINATION.next, RISK_CATEGORY.high.key, queryByCard));
    dispatch(getAttritionChart(PAGINATION.next, RISK_CATEGORY.medium.key, queryByCard));
    dispatch(getAttritionChart(PAGINATION.next, RISK_CATEGORY.low.key, queryByCard));

    setDialog(
      {
        state: true,
        component: <CardDetail { ...props } data={ data } card={ card } query={ queryByCard } />,
        title: card.title,
        key: card.key,
      },
    );
  };

  const getCardContent = (card, data) => (
    <StyledGridContainer container spacing={ 1 } color={ card.textColor }>
      <Grid item xs={ 12 }>
        <Typography variant={ VARIANT.h4 }>{ card.title }</Typography>
      </Grid>
      { data ? (
        <>
          <StyledGrid item xs={ 6 } borderColor={ card.textColor } >
            <StyledRotationTitle variant={ VARIANT.h5 }>
              {t("nalaAnalytics:rotation")}
              <TooltipIcon
                title={ t("nalaAnalytics:rotationDescription") }
                isHelpIcon
              />
            </StyledRotationTitle>
            {data.rotationList.length > 0 && (
              <>
                <StyledResult size={ 40 } color={ card.textColor }>
                  {data?.rotationList.length && `${data?.rotationList[data.rotationList.length - 1]?.score}%`}
                </StyledResult>
                <Bar
                  data={ getDataBar(data?.rotationList, card.textColor, card.lastBarColor) }
                  options={ optionsBar }
                />
              </>
            )}
          </StyledGrid>

          <StyledGrid item xs={ 6 }>
            <StyledSubGrid container>
              <Grid item xs={ 12 }>
                <StyledData borderColor={ card.textColor }>
                  <Typography variant={ VARIANT.h5 }>{t("nalaAnalytics:lossRisk")}</Typography>
                  <StyledResult color={ card.textColor }>
                    {data?.lossRisk ? (
                      <StyledDetail>
                        <StyledCircleIcon color={ COLOR_BY_LEVEL_RISKS[data.lossRisk.key] } />
                        {getPercent(data.lossRisk.value, true)}
                      </StyledDetail>
                    ) : (
                      <StyledNoData src={ NoInfoFaceBlack } alt={ "No data" } />
                    )}
                  </StyledResult>
                  <Typography variant={ VARIANT.h6 }>
                    {data?.lossRisk ? (
                      <StyledSubtitle color={ card.textColor }>
                        {t(`nalaAnalytics:${data.lossRisk.key}RiskGroup`)}
                      </StyledSubtitle>
                    ) : t("common:common.no_records")}
                  </Typography>
                </StyledData>
              </Grid>
              <StyledNPSGrid item xs={ 12 }>
                <StyledData>
                  <Typography variant={ VARIANT.h5 }>{t("nalaAnalytics:nps")}</Typography>
                  <StyledDetail>
                    <img src={ data?.nps === null ? NoInfoFaceBlack : getIconByNPSScore(data?.nps)?.icon } alt={ "NPS" } />
                    {data?.nps !== null && (
                      <StyledResult color={ card.textColor }>
                        { getPercent(data?.nps) }
                      </StyledResult>
                    )}
                  </StyledDetail>
                </StyledData>
              </StyledNPSGrid>
            </StyledSubGrid>
          </StyledGrid>

          <Grid item xs={ 12 }>
            <StyledText>
              <StyledComparativeResults isPositive={ data?.percentageVariation.isPositive }>
                { data?.percentageVariation.isPositive
                  ? <ArrowDropDownIcon />
                  : <ArrowDropUpIcon />}
                { `${data?.percentageVariation.result}%`}
              </StyledComparativeResults>
              {t("nalaAnalytics:thanLastMonth")}
            </StyledText>
          </Grid>
          <Grid item xs={ 12 }>
            {card.viewMore && (
              <SeeMoreLink
                t={ t }
                onClick={ () => handleViewMore(card, data) }
              />
            )}
          </Grid>
        </>
      ) : (
        <Grid item xs={ 12 }>
          <NoDataMessage message={ t("timeOff:under_construction.comingSoon") } isHappyFace />
        </Grid>
      )}
    </StyledGridContainer>
  );

  return (
    <StyledCardsGrid container spacing={ 2 }>
      { getCardsDetail(t, cardsStates, nalaAnalyticsLoadings).map((item) => (
        <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ `card-${item.key}` }>
          {item.isLoading ? (
            <SkeletonLoader
              num={ SKELETONS_NUMBER.ONE }
              variant={ SKELETON_VARIANT.rectangular }
              height={ 316 }
              columnWidth={ 12 }
              hasPadding={ false }
            />
          ) : (
            <MultidataCard
              color={ item.cardColor }
              customRender={ getCardContent(item, item.data) }
              hasMargin={ false }
            />
          )}
        </Grid>
      ))}
      <HalfScreenDialog
        isOpen={ dialog.state }
        onClose={ () => setDialog({ state: false }) }
        title={ dialog?.title }
      >
        {dialog?.component}
      </HalfScreenDialog>
    </StyledCardsGrid>
  );
};

AnalysisSummary.propTypes = {
  t: PropTypes.object.isRequired,
  cardsStates: PropTypes.object.isRequired,
  nalaAnalyticsLoadings: PropTypes.object.isRequired,
};

export default AnalysisSummary;
