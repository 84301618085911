import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  StyledPaperContainer,
  StyledSubtitle,
} from "styledComponents/View";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { useLaunchDate, useLaunchNala } from "hooks/useNotificationsSettings";
import Stack from "@mui/material/Stack";
import Button from "components/Button";
import SkeletonLoader from "components/SkeletonLoader";

export const LaunchSection = () => {
  const [isLaunching, setIsLaunching] = useState(false);
  const { t } = useTranslation();

  const { launchDate, isLoading } = useLaunchDate(t);

  const { mutate: launchNala } = useLaunchNala(t);

  const handleClick = () => {
    setIsLaunching(true);

    launchNala(
      {},
      {
        onSuccess: () => {
          setIsLaunching(false);
        },
      },
    );
  };
  if (isLoading) {
    return (<SkeletonLoader />);
  }

  return (
    <StyledPaperContainer>
      <ViewHeaderTitle
        title={ t("administrator:modules.notifications.launch.title") }
      />
      <Stack
        direction={ "column" }
        justifyContent={ "center" }
        paddingX={ 18 }
        marginTop={ 2 }
        spacing={ 4 }
      >
        { !launchDate && (
          <Button variant={ "contained" } isFullWidth typeStyle={ "submit" } onClick={ handleClick } disabled={ isLaunching }>
            { !isLaunching && t("administrator:modules.notifications.launch.action_before") }
            { isLaunching && t("administrator:modules.notifications.launch.action_processing") }
          </Button>
        )}
        { launchDate && (
          <Button variant={ "contained" } isFullWidth typeStyle={ "disabled" } disabled>
            { t("administrator:modules.notifications.launch.action_after", { date: new Date(launchDate).toLocaleDateString() }) }
          </Button>
        )}
        <StyledSubtitle fontSize={ 20 } fontWeight={ 400 } color={ "#5B5B5B" } textAlign={ "center" }>
          { !launchDate && t("administrator:modules.notifications.launch.footer_before") }
          { launchDate && t("administrator:modules.notifications.launch.footer_after") }
        </StyledSubtitle>
      </Stack>
    </StyledPaperContainer>
  );
};
