import {
  useContext, useEffect, useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import DownloadExcelButton from "components/DownloadExcelButton";
import CalibrationModal from "components_refactor/CalibrationModal";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import AlertDialog from "components/AlertDialog";
import { useDeleteCalibration } from "hooks/calibration";
import { SessionContext } from "modules/session/context";
import { CircularProgress } from "@mui/material";
import {
  EXCEL_FILES_NAME,
  OBJECT_KEYS,
  FILE_TYPE_EXCEL,
  STATE,
} from "common/constants";
import { getEvaluations } from "common/validators";
import {
  getSortingOrder, getEmployeeCompanyId,
} from "common/utils";
import { getSurveyResultsDownloadExcel } from "redux/actions/surveyProcessesActions";
import { getOne as getResultScaleById } from "redux/actions/resultScalesActions";
import { useCalibrationReasons } from "hooks/useCalibrationReasons";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import {
  getHeader, getRowsDesktop,
} from "./functions";
import {
  StyledGridContainer, StyledSubtitleText, ButtonContainer, SpinnerOverlay,
} from "../styles";

const Collaborators = (props) => {
  const {
    processId,
    pagination,
    handlePagination,
    handleSearch,
    query,
    setTableSort,
    isDownloadingParticipation = false,
    surveyProcess,
  } = props;

  const { t } = useTranslation(["collaborators", "performance", "survey"]);
  const {
    state: { user },
  } = useContext(SessionContext);
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    list: surveyResultList,
    listTotal: surveyResultTotal,
    isLoadingList: surveyResultLoadingList,
  } = useSelector(({ surveyResultReducer }) => surveyResultReducer);

  const {
    isLoadingOne: isLoadingScale,
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (surveyProcess) {
      const resultScaleId = surveyProcess?.result_scale_id;
      if (resultScaleId) {
        dispatch(getResultScaleById(resultScaleId));
      }
    }
    // eslint-disable-next-line
  }, [surveyProcess]);

  const evaluations = getEvaluations(surveyResultList, t);
  const companyId = getEmployeeCompanyId(user);

  const handleDownloadPython = async () => {
    setIsDownloading(true);
    const response = await getSurveyResultsDownloadExcel(processId, query, companyId);

    if (response?.data) {
      const blob = new Blob([response.data], { type: FILE_TYPE_EXCEL });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.download = `${EXCEL_FILES_NAME.performanceReview}.xlsx`;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsDownloading(false);
    }
  };

  const handleSort = (columnName, direction) => {
    setTableSort(getSortingOrder(columnName, direction));
  };

  const hasCalibration = surveyProcess?.calibration_activated;
  const hasCalibrationActivated = hasCalibration
  && !surveyProcess?.published
  && surveyProcess?.state === STATE.finished;
  const goalsActive = surveyProcess?.goals_active;

  // Calibration modal
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState({});

  // Calibration Reason
  const { calibrationReasons, isLoading } = useCalibrationReasons();

  const handleOpenCalibrationModal = (resultSelected) => {
    setResult({});
    setModal(true);
    setResult(resultSelected);
  };

  const handleCloseCalibrationModal = () => {
    setModal(false);
    setResult({});
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [calibrationId, setCalibrationId] = useState(null);
  const { mutate: deleteCalibration } = useDeleteCalibration(t);

  const handleOpenRestoreModal = (id, resultSelected) => {
    setResult(resultSelected);
    setIsAlertOpen(true);
    setCalibrationId(id);
  };

  const handleRestore = () => {
    if (calibrationId) {
      deleteCalibration(calibrationId, {
        onSuccess: () => {
          result.calibration = null;
          const managerEvaluation = result.results_by_evaluation_type.find(
            (evalType) => evalType.evaluation_type === EVALUATION_TYPE.MANAGER,
          );
          if (managerEvaluation) {
            result.calibrated = false;
            result.calibratedResult = null;
          }
          setResult({});
          setIsAlertOpen(false);
        },
      });
    } else {
      setResult({});
      setIsAlertOpen(false);
    }
  };

  return (
    <div data-testid={ "collaborators-table-container" }>
      <StyledGridContainer container>
        <Grid item xs={ 12 }>
          <StyledSubtitleText>
            { `${t("performance:title")} - ${t("performance:dashboard.collaborators")}`}
          </StyledSubtitleText>
        </Grid>
      </StyledGridContainer>
      <Grid container spacing={ 4 }>
        <Grid item xs={ 12 }>
          <TablePaginationCollapse
            header={ getHeader(evaluations.map((e) => e.id), t, hasCalibration, goalsActive) }
            list={ surveyResultList }
            isLoading={ (surveyResultLoadingList
              && isLoadingScale
              && isLoading) || surveyResultList === null
              || !resultScale?.result_scale_indices }
            getRows={ {
              mobile: getRowsDesktop,
              desktop: getRowsDesktop,
            } }
            extraParam={ {
              processId,
              evaluations,
              hasCalibration,
              resultScale,
              handleOpenCalibrationModal,
              handleOpenRestoreModal,
              hasCalibrationActivated,
              goalsActive,
            } }
            handlePagination={ handlePagination }
            pagination={ pagination }
            total={ surveyResultTotal }
            rightOptions={ (
              <ButtonContainer>
                <DownloadExcelButton
                  onClick={ () => handleDownloadPython() }
                  disabled={ isDownloadingParticipation || isDownloading }
                  isDownloadApart
                />
                {(isDownloadingParticipation || isDownloading) && (
                  <SpinnerOverlay>
                    <CircularProgress size={ 24 } />
                  </SpinnerOverlay>
                )}
              </ButtonContainer>
            ) }
            handleSearch={ handleSearch }
            sortAction={ handleSort }
            defaultOrderName={ OBJECT_KEYS.score }
          />
        </Grid>
      </Grid>
      <AlertDialog
        isOpen={ isAlertOpen }
        onClose={ () => setIsAlertOpen(false) }
        title={ t("common:calibration.confirm_restore") }
        onSubmit={ handleRestore }
      />
      {resultScale?.result_scale_indices && result?.id && calibrationReasons.length > 0
      && (
        <CalibrationModal
          isOpen={ modal }
          onClose={ handleCloseCalibrationModal }
          selectResult={ result }
          scale={ resultScale.result_scale_indices }
          isValidToUpdate={ hasCalibrationActivated }
        />
      )}
    </div>
  );
};

Collaborators.propTypes = {
  processId: PropTypes.number.isRequired,
  pagination: PropTypes.number.isRequired,
  handlePagination: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  setTableSort: PropTypes.func.isRequired,
  isDownloadingParticipation: PropTypes.bool,
  surveyProcess: PropTypes.object.isRequired,
};

export default Collaborators;
