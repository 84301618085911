import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { SessionContext } from "modules/session/context";
import { getModules } from "./functions";
import { useStyles } from "./styles";

const GeneralAdministrator = () => {
  const classes = useStyles();
  const { t } = useTranslation(["administrator"]);
  const {
    state: { user },
  } = useContext(SessionContext);

  return (
    <div className={ classes.root } data-testid={ "general-administrator-view" }>
      <ViewHeaderTitle title={ t("title") } subtitle={ t("subtitle") } />
      <Grid container>
        <Grid container className={ classes.cardsContainer }>
          {getModules(t, user?.userCookies)}
        </Grid>
      </Grid>
    </div>
  );
};

export default GeneralAdministrator;
