import clsx from "clsx";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import SimpleAccordion from "components/SimpleAccordion";
import { COMPANY_STATES } from "common/constants";
import useStyles, {
  StyledItem, StyledTooltipTitle, StyledButton, StyledIcon,
} from "./styles";
import { CustomRouterLink } from "./functions";
import { MENU_ICONS } from "../../functions";

const SidebarNav = (props) => {
  const {
    pages, className, t, ...rest
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const tooltipByInactiveOptions = {
    title: <StyledTooltipTitle>{t("common:sidebar:inactive-option")}</StyledTooltipTitle>,
    placement: "right-start",
  };

  const isActiveClass = (href) => {
    const { pathname, searchParams } = new URL(window.location.href);

    if (searchParams.has("collaborator") && href === "/collaborators") {
      return true;
    }

    if (searchParams.has("candidate") && href === "/onboarding") {
      return true;
    }

    return pathname === href;
  };

  const getSubPages = (subPage) => {
    const isInactiveOption = subPage.state === COMPANY_STATES.inactive;
    return (
      <StyledItem key={ subPage.slug } sub={ "true" } disableGutters>
        <StyledButton
          active={ isActiveClass(subPage.href) }
          component={ CustomRouterLink }
          href={ subPage.href }
          disabled={ isInactiveOption }
          tooltip={ isInactiveOption ? tooltipByInactiveOptions : null }
          mobile={ isMobile }
        >
          <StyledIcon
            active={ isActiveClass(subPage.href) }
            className={ isInactiveOption
              ? classes.iconDisabled
              : classes.icon }
          >
            {MENU_ICONS[subPage.icon]}
          </StyledIcon>
          {t(`common:sidebar:${subPage.slug}`)}
        </StyledButton>
      </StyledItem>
    );
  };

  const getPages = (page) => {
    const isInactiveOption = page.state === COMPANY_STATES.inactive;
    if (page?.subMenu) {
      return (
        <SimpleAccordion
          isTitleImage
          title={ t(`common:sidebar:${page.slug}`) }
          icon={ MENU_ICONS[page.icon] }
          customImage={ isInactiveOption
            ? classes.iconDisabled
            : classes.icon }
          expandedPanel={ "dashboard" }
          customStyles={ {
            accordion: classes.accordion,
            headerContainer: classes.accordionHeader,
            title: classes.accordionTitleMain,
            container: classes.accordionContainer,
          } }
          isExpanded
        >
          {page.subMenu.map((subPage) => getSubPages(subPage))}
        </SimpleAccordion>
      );
    }
    return !page.slug ? (
      page.subMenu.map((subPage) => getSubPages(subPage))
    ) : (
      <StyledItem disableGutters>
        <StyledButton
          active={ isActiveClass(page.href) }
          component={ CustomRouterLink }
          href={ page.href }
          disabled={ isInactiveOption }
          tooltip={ isInactiveOption ? tooltipByInactiveOptions : null }
          mobile={ isMobile }
        >
          <StyledIcon
            active={ isActiveClass(page.href) }
            className={ isInactiveOption
              ? classes.iconDisabled
              : classes.icon }
          >
            {MENU_ICONS[page.icon]}
          </StyledIcon>
          {t(`common:sidebar:${page.slug}`)}
        </StyledButton>
      </StyledItem>
    );
  };

  return (
    <List { ...rest } className={ clsx(classes.root, className) }>
      {pages?.map((page, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={ `${page.slug}-${index}` }>
          {page.divider && <Divider className={ classes.divider } />}
          {getPages(page)}
        </div>
      ))}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

SidebarNav.defaultProps = {
  className: "",
};

export default SidebarNav;
