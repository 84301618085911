import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";
import posthog from "posthog-js";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@mui/material";
import Routes from "Routes";
import { Provider } from "Context";
import { SessionProvider } from "modules/session/context";
import { chartjs } from "helpers";
import { setUserAttributes } from "helpers/user_attributes";
import theme from "theme";
import Toast from "components/Toast";
import ErrorBoundary from "components/ErrorBoundary";
import GA4PageViewWrapper from "components_refactor/GA4PageViewWrapper";
import {
  GA_TRACKING_ID,
  CLARITY_ID,
  POSTHOG_KEY,
  POSTHOG_HOST,
} from "common/constants";
import "react-perfect-scrollbar/dist/css/styles.css";
import "assets/scss/index.scss";

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

ReactGA.initialize(GA_TRACKING_ID);

if (
  process.env.REACT_APP_ENV === "development"
  || process.env.REACT_APP_ENV === "production"
) {
  // initialize Clarity
  clarity.init(CLARITY_ID);
  setUserAttributes("clarity");
  // initialize PostHog
  posthog.init(POSTHOG_KEY, { api_host: POSTHOG_HOST });
  setUserAttributes("posthog");
}

const App = () => (
  <ErrorBoundary>
    <Provider>
      <SessionProvider>
        <ThemeProvider theme={ theme }>
          <BrowserRouter>
            <GA4PageViewWrapper>
              <Routes />
              <Toast />
            </GA4PageViewWrapper>
          </BrowserRouter>
        </ThemeProvider>
      </SessionProvider>
    </Provider>
  </ErrorBoundary>
);

export default App;
