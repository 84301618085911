import { AbilityBuilder } from "@casl/ability";
import { MODULES, MODULE_ACTIONS } from "common/constants";
import { getUserRoles } from "common/utils";
import { ROLE_MODULES, handleModulesValidation } from "../functions";

export const createAcknowledgmentAbilities = (user) => {
  const { can, rules } = new AbilityBuilder();
  const userRoles = getUserRoles(user?.userCookies);
  const companySlug = user?.company?.slug;
  const acknowledgmentModule = ROLE_MODULES.find(
    (moduleItem) => moduleItem.slug === MODULES.acknowledgments,
  );

  if (acknowledgmentModule) {
    const { roles, companiesValidation } = acknowledgmentModule;

    if (roles.some((role) => userRoles.includes(role))) {
      handleModulesValidation(
        can, userRoles, companiesValidation, MODULES.acknowledgments, companySlug, [MODULE_ACTIONS.view, MODULE_ACTIONS.create],
      );
    }
  }

  return rules;
};
