import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import i18n from "i18next";
import { useForm } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import AlertDialog from "components/AlertDialog";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  ALIGN_ITEMS,
  TYPES,
} from "common/constants";
import LanguageSelector from "components_refactor/LanguageSelector";
import { useUpdateNineBoxType } from "hooks/useNineBoxType";
import { StyledLanguage, StyledFormContainer } from "./styles";

const NineBoxTypeForm = (props) => {
  const {
    t, handleCancelEdit, nineBoxType,
  } = props;

  const { language } = i18n;
  const [dialog, setDialog] = useState(false);
  const [formLanguage, setFormLanguage] = useState(language);

  const { mutate: updateNineBoxType } = useUpdateNineBoxType(t);
  const defaultValues = {
    name_es: nineBoxType.name_es,
    name_en: nineBoxType.name_en,
    name_pt: nineBoxType.name_pt,
    description_es: nineBoxType.description_es,
    description_en: nineBoxType.description_en,
    description_pt: nineBoxType.description_pt,
  };

  const {
    control, handleSubmit, reset, watch,
  } = useForm({
    defaultValues,
  });

  const languageCodes = ["es", "en", "pt"];

  useEffect(() => {
    if (nineBoxType) {
      reset(defaultValues);
    }
  }, [nineBoxType, reset]);

  // Handle language change
  const onLanguageChange = (lang) => {
    setFormLanguage(lang);
  };

  const nameValues = watch(["name_es", "name_en", "name_pt"]);
  const descriptionValues = watch(["description_es", "description_en", "description_pt"]);

  const onSubmit = (data) => {
    const missingFields = languageCodes.some(
      (langCode) => !data[`name_${langCode}`] || !data[`description_${langCode}`],
    );

    if (missingFields) {
      const toastMessage = {
        title: t("common:common.api_responses.warning.title"),
        message: t("common:common.modal_messages.language_warning"),
      };
      toast(MESSAGE_TYPES.warning, toastMessage);
      return;
    }

    const nineBoxTypeData = {
      nine_box_type: data,
    };

    updateNineBoxType({ id: nineBoxType.id, data: nineBoxTypeData }, {
      onSuccess: (updatedNineBoxType) => {
        setDialog(false);
        handleCancelEdit(updatedNineBoxType);
      },
    });
  };

  return (
    <form>
      <StyledLanguage>
        <LanguageSelector onLanguageChange={ onLanguageChange } value={ formLanguage } />
      </StyledLanguage>
      <StyledFormContainer>
        {languageCodes.map((langCode) => (
          <div
            key={ `name_${langCode}` }
            style={ { display: formLanguage === langCode ? "block" : "none" } }
          >
            <InputTextController
              type={ TYPES.text }
              label={ `${t("common:common.quadrant_number")} (${t(`common:locales:${langCode}`)})` }
              control={ control }
              name={ `name_${langCode}` }
              inputProps={ { maxLength: 26 } }
              endAdornment={ <InputAdornment position={ "end" }>{`${nameValues[`name_${langCode}`]?.length}/26`}</InputAdornment> }
              size={ "small" }
            />
            <InputTextController
              type={ TYPES.text }
              label={ `${t("tables:headers.description")} (${t(`common:locales:${langCode}`)})` }
              control={ control }
              name={ `description_${langCode}` }
              multiline={ 10 }
              inputProps={ { maxLength: 500 } }
              endAdornment={ <InputAdornment position={ "end" }>{`${descriptionValues[`description_${langCode}`]?.length}/500`}</InputAdornment> }
              size={ "small" }
            />
          </div>
        ))}
      </StyledFormContainer>
      <Box display={ ALIGN_ITEMS.flex } justifyContent={ ALIGN_ITEMS.flexEnd }>
        <Box mt={ 2 } ml={ 2 }>
          <Button
            onClick={ () => handleCancelEdit() }
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
            typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
          >
            {t("common:common.cancel")}
          </Button>
        </Box>
        <Box mt={ 2 } ml={ 2 }>
          <Button
            onClick={ handleSubmit(onSubmit) }
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          >
            {t("common:common.save")}
          </Button>
        </Box>
      </Box>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("common:common.modal_messages.sure_question") }
        message={ t("common:common.modal_messages.quadrant_edit") }
        onSubmit={ handleSubmit(onSubmit) }
      />
    </form>
  );
};

NineBoxTypeForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleCancelEdit: PropTypes.func.isRequired,
  nineBoxType: PropTypes.object.isRequired,
};

export default NineBoxTypeForm;
