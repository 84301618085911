import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import ParticipationCardCollapse from "components_refactor/ParticipationCardCollapse";
import TablePaginationInfinityScroll from "components/TablePaginationInfinityScroll";
import ProfileImageDetail from "components_refactor/ProfileImageDetail";
import SkeletonLoader from "components/SkeletonLoader";
import LeaderIcon from "assets/images/participation/leader.svg";
import CollaboratorIcon from "assets/images/participation/collaborators.svg";
import {
  getLeaderEvaluators,
  getCollaboratorEvaluators,
  resetStateManagerEvaluators,
  resetStateCollaboratorEvaluators,
} from "redux/actions/surveyProcessesActions";
import { SKELETONS_NUMBER } from "common/constants";

const Evaluators = (props) => {
  const { evaluation, filterQuery, isPending } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  // States for pagination, separate for leaders and collaborators
  const [leaderPagination, setLeaderPagination] = useState(1);
  const [collaboratorPagination, setCollaboratorPagination] = useState(1);

  // States to accumulate leaders and collaborators across pagination
  const [leaders, setLeaders] = useState([]);
  const [collaborators, setCollaborators] = useState([]);

  // Selector to fetch data from Redux store
  const {
    leaderEvaluators,
    leaderEvaluatorsTotal,
    leaderEvaluatorsIsLoadingList,
    collaboratorEvaluators,
    collaboratorEvaluatorsTotal,
    collaboratorEvaluatorsIsLoadingList,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  // Handle pagination for leaders
  const handleLeaderPagination = (newPage) => {
    setLeaderPagination(leaderPagination + newPage);
  };

  // Handle pagination for collaborators
  const handleCollaboratorPagination = (newPage) => {
    setCollaboratorPagination(collaboratorPagination + newPage);
  };

  useEffect(() => {
    if (!leaderEvaluatorsIsLoadingList && leaderEvaluators && leaderEvaluators.length > 0) {
      dispatch(getLeaderEvaluators(evaluation, leaderPagination, filterQuery, isPending));
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [leaderPagination]);

  useEffect(() => {
    if (!collaboratorEvaluatorsIsLoadingList && collaboratorEvaluators
      && collaboratorEvaluators.length > 0) {
      dispatch(
        getCollaboratorEvaluators(evaluation, collaboratorPagination, filterQuery, isPending),
      );
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [collaboratorPagination]);

  const updateUniqueItems = (newItems, setItems) => {
    setItems((prev) => {
      const allItems = [...prev, ...newItems];
      const uniqueIds = new Set();
      const uniqueItems = allItems.filter((item) => {
        const isDuplicate = uniqueIds.has(item.id);
        uniqueIds.add(item.id);
        return !isDuplicate;
      });
      return uniqueItems;
    });
  };

  useEffect(() => {
    if (leaderEvaluators && leaderEvaluators.length > 0) {
      updateUniqueItems(leaderEvaluators, setLeaders);
    }
    // eslint-disable-next-line
  }, [leaderEvaluators]);

  useEffect(() => {
    if (collaboratorEvaluators && collaboratorEvaluators.length > 0) {
      updateUniqueItems(collaboratorEvaluators, setCollaborators);
    }
    // eslint-disable-next-line
  }, [collaboratorEvaluators]);

  useEffect(() => {
    setLeaders([]);
    setCollaborators([]);
    setIsLoading(true);
    dispatch(resetStateManagerEvaluators());
    dispatch(resetStateCollaboratorEvaluators());
    dispatch(getLeaderEvaluators(evaluation, 1, filterQuery, isPending));
    dispatch(getCollaboratorEvaluators(evaluation, 1, filterQuery, isPending));
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [isPending]);

  // rows for the table
  const getRows = (data) => data?.map((rowData) => [
    {
      content: <ProfileImageDetail collaborator={ rowData } />,
    },
  ]);

  return isLoading ? (
    <SkeletonLoader
      num={ SKELETONS_NUMBER.TWO }
      columnWidth={ 12 }
    />
  ) : (
    <>
      <ParticipationCardCollapse
        title={ t("common:common.managers") }
        total={ leaderEvaluatorsTotal }
        icon={ <img src={ LeaderIcon } alt={ "Leaders" } /> }
        content={ (
          <TablePaginationInfinityScroll
            header={ ["name"] }
            list={ leaders }
            isLoading={ leaderEvaluatorsIsLoadingList }
            getRows={ {
              desktop: getRows,
              mobile: getRows,
            } }
            handlePagination={ () => handleLeaderPagination(1) }
            total={ leaderEvaluatorsTotal }
          />
        ) }
      />
      <ParticipationCardCollapse
        title={ t("common:common.collaborators") }
        total={ collaboratorEvaluatorsTotal }
        icon={ <img src={ CollaboratorIcon } alt={ "Collaborators" } /> }
        content={ (
          <TablePaginationInfinityScroll
            header={ ["name"] }
            list={ collaborators }
            isLoading={ collaboratorEvaluatorsIsLoadingList }
            getRows={ {
              desktop: getRows,
              mobile: getRows,
            } }
            handlePagination={ () => handleCollaboratorPagination(1) }
            total={ collaboratorEvaluatorsTotal }
          />
        ) }
      />
    </>
  );
};

Evaluators.propTypes = {
  evaluation: PropTypes.number.isRequired,
  filterQuery: PropTypes.string.isRequired,
  isPending: PropTypes.bool.isRequired,
};

export default Evaluators;
