import configAxios from "../configAxios";

const fetchData = async (endpoint, { queryKey: [, params] }) => {
  const response = await configAxios.get(endpoint, { params });
  return response.data;
};

export const getOrganizationUnits = async (params) => fetchData("/filters/organization_units", params);

export const getManagers = async (params) => fetchData("/filters/managers", params).then((data) => data.collaborators);

export const getCountries = async (params) => fetchData("/filters/countries", params).then((data) => data.countries);

export const getCities = async (params) => fetchData("/filters/cities", params).then((data) => data.cities);

export const getStates = async (params) => fetchData("/filters/states", params);
