import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import RelocateListForm from "components/RelocateListForm";
import Button from "components/Button";
import {
  ROLES,
  OBJECT_KEYS,
  BUTTON_STYLE_TYPES,
  TABLE_CELL_ALIGN,
  VARIANT,
} from "common/constants";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import TerminateEmploymentForm from "../TerminateEmploymentForm";
import { useStyles } from "./styles";

const RelocateEmployee = (props) => {
  const {
    id,
    title,
    subtitle,
    collaborators,
    options,
    action,
    modalHandler,
  } = props;
  const { t } = useTranslation(["common, collaborators"]);
  const classes = useStyles();
  const [toRelocate, setToRelocate] = useState();
  const [disabled, setIsDisabled] = useState(true);
  const [isTurnoverForm, setIsTurnoverForm] = useState(false);

  const handleData = (data) => {
    setToRelocate(data);
  };

  const setCollaboratorsToRelocate = (data) => {
    setIsDisabled(!isEmpty(data));
  };

  const handleAction = (id, data) => {
    action(id, data);
  };

  const closeModal = () => {
    modalHandler(false);
  };

  const MAIN_RELOCATE_EMPLOYEE = [
    {
      id: OBJECT_KEYS.profileImgUrl,
      align: TABLE_CELL_ALIGN.left,
      isDisablePadding: true,
      label: "",
      isAvatar: true,
      customRender: (rowData) => (
        <Avatar
          alt={ "profile picture" }
          src={ rowData.profile_img_url && rowData.profile_img_url }
          className={ classes.avatar }
        />
      ),
    },
    {
      id: OBJECT_KEYS.fullname,
      align: TABLE_CELL_ALIGN.left,
      isDisablePadding: true,
      label: t("common:common.name"),
    },
  ];

  const RELOCATE_EMPLOYEE = [
    {
      id: OBJECT_KEYS.profileImgUrl,
      align: TABLE_CELL_ALIGN.left,
      isDisablePadding: true,
      label: "",
      isAvatar: true,
      customRender: (rowData) => (
        <Avatar
          alt={ "profile picture" }
          src={ rowData.profile_img_url && rowData.profile_img_url }
          className={ classes.avatar }
        />
      ),
    },
    {
      id: OBJECT_KEYS.fullname,
      align: TABLE_CELL_ALIGN.left,
      isDisablePadding: true,
      label: t("common:common.name"),
    },
    {
      id: OBJECT_KEYS.managerName,
      align: TABLE_CELL_ALIGN.left,
      isDisablePadding: true,
      label: t("common:common.new_manager"),
    },
  ];

  return (
    <Grid container data-testid={ "relocate-employee-form" }>
      {isTurnoverForm ? (
        <TerminateEmploymentForm
          id={ id }
          action={ handleAction }
          relocatedData={ toRelocate }
          modalHandler={ modalHandler }
        />
      ) : (
        <StyledScrollBar maxHeight={ "600px" }>
          <Grid item xs={ 12 } className={ classes.itemGrid }>
            <Typography className={ classes.subtitleText }>{title}</Typography>
            <Typography variant={ VARIANT.subtitleTwo }>{subtitle}</Typography>
          </Grid>
          <Grid item xs={ 12 }>
            <RelocateListForm
              employeeId={ id }
              mainBoxTitle={ t("collaborators:title") }
              findBy={ {
                main: ROLES.MANAGER,
                key: OBJECT_KEYS.id,
                label: OBJECT_KEYS.fullname,
              } }
              options={ options }
              headerMainTable={ MAIN_RELOCATE_EMPLOYEE }
              headerTable={ RELOCATE_EMPLOYEE }
              dataTable={ collaborators }
              handleData={ handleData }
              labels={ {
                button: t("common:common.move"),
                input: t("common:common.manager"),
              } }
              setCollaboratorsToRelocate={ setCollaboratorsToRelocate }
            />
          </Grid>
          <Grid item xs={ 12 }>
            <div className={ classes.actionButton }>
              <Button
                onClick={ closeModal }
                variant={ VARIANT.contained }
                typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                cs={ classes.cancel }
              >
                {t("collaborators:buttons.cancel")}
              </Button>
              <Button
                variant={ BUTTON_STYLE_TYPES.CONTAINED }
                autoFocus
                type={ BUTTON_STYLE_TYPES.SUBMIT }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                onClick={ () => setIsTurnoverForm(true) }
                disabled={ disabled }
              >
                {t("common:common.relocate")}
              </Button>
            </div>
          </Grid>
        </StyledScrollBar>
      )}
    </Grid>
  );
};

RelocateEmployee.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  collaborators: PropTypes.array,
  options: PropTypes.array,
  action: PropTypes.func,
  modalHandler: PropTypes.func,
};

export default RelocateEmployee;
