import PropTypes from "prop-types";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";
import { StyledButton, StyledTooltip } from "./styles";

const Button = (props) => {
  const {
    id,
    variant,
    component,
    onClick,
    disabled,
    type,
    size,
    isFullWidth,
    children,
    isLoading,
    icon,
    iconSide,
    typeStyle,
    cs,
    className,
    href,
    onBlur,
    tooltip,
  } = props;
  const handleClick = (event) => onClick(event);

  let render = children;

  if (icon) {
    render = (
      <div className={ "buttonIconContainer" }>
        <div className={ iconSide }>{icon}</div>
        {children}
      </div>
    );
  }
  let finalRender = render;

  if (isLoading) {
    finalRender = <CircularProgress size={ 30 } />;
  }

  const buttonComponent = (
    <StyledButton
      { ...props } // FIXME
      id={ id }
      variant={ variant }
      className={ clsx(typeStyle, "general", cs, className) }
      component={ component }
      onClick={ handleClick }
      disabled={ disabled || isLoading }
      type={ type }
      size={ size }
      fullWidth={ isFullWidth }
      to={ disabled ? "" : href }
      onBlur={ onBlur }
    >
      {finalRender}
    </StyledButton>
  );

  return disabled && tooltip
    ? (
      <StyledTooltip title={ tooltip?.title } placement={ tooltip?.placement }>
        <span>
          {buttonComponent}
        </span>
      </StyledTooltip>
    )
    : buttonComponent;
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  variant: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  id: PropTypes.string,
  cs: PropTypes.string,
  component: PropTypes.any,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  isFullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  icon: PropTypes.object || PropTypes.string,
  iconSide: PropTypes.string,
  typeStyle: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  tooltip: PropTypes.object || PropTypes.boolean,
};

Button.defaultProps = {
  id: "",
  variant: "text",
  type: "button",
  cs: "",
  component: "button",
  disabled: false,
  size: "",
  isFullWidth: false,
  isLoading: false,
  icon: null,
  iconSide: "",
  typeStyle: "",
  href: "",
  className: "",
  onBlur: null,
  onClick: () => {},
  tooltip: null,
};

export default Button;
