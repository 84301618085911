import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import defaultImg from "assets/images/general/avatar.png";
import { ALIGN_ITEMS } from "common/constants";
import {
  StyledBoxContainer,
  StyledImageContainer,
  StyledTitleWithSubtitle,
  StyledTitle,
} from "./styles";

const ProfileInfo = (props) => {
  const {
    name,
    img,
  } = props;

  return (
    <Box display={ ALIGN_ITEMS.flex }>
      <Box flexGrow={ 1 }>
        <StyledBoxContainer>
          <StyledImageContainer>
            <Avatar src={ img } />
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <StyledTitle>
              {name.toLowerCase()}
            </StyledTitle>
          </StyledTitleWithSubtitle>
        </StyledBoxContainer>
      </Box>
    </Box>
  );
};

ProfileInfo.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.string,
};

ProfileInfo.defaultProps = {
  img: defaultImg,
};

export default ProfileInfo;
