import PropTypes from "prop-types";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components_refactor/NoDataMessage";
import { isEmpty } from "common/helpers";
import { StyledSectionTitle } from "styledComponents/View";
import Card from "../Card";
import { StyledRecommendationTitle, StyledRecommendationDescription } from "./styles";

const Recommendations = (props) => {
  const { t, data, isLoading } = props;

  const dataValidation = isLoading
    ? (
      <SkeletonLoader
        num={ 7 }
        isInline={ false }
      />
    ) : isEmpty(data) && <NoDataMessage isHappyFace />;

  return (
    <div>
      <StyledSectionTitle>
        {t("talentDrain:recommendations")}
      </StyledSectionTitle>
      { dataValidation }
      { data?.map((item) => (
        <Card
          key={ item.title + item.percentage }
          icon={ item.icon }
          title={ item.title }
          percentage={ item.percentage }
        >
          <StyledRecommendationTitle>
            {item.recommendation.icon}
            {item.recommendation.title}
          </StyledRecommendationTitle>
          <StyledRecommendationDescription>
            {item.recommendation.text}
          </StyledRecommendationDescription>
        </Card>
      ))}
    </div>
  );
};

Recommendations.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
};

Recommendations.defaultProps = {
  isLoading: false,
};

export default Recommendations;
