import styled from "styled-components";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import palette from "theme/palette";

export const CustomPopper = styled(Popper)`
  .MuiAutocomplete-listbox {
    max-height: 240px;
    overflow-y: auto;
  }
`;

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${(props) => props.level * 16}px;
  padding-bottom: 0;
  padding-top: 0;
  width: 100%;
  color: rgba(0, 0, 0, ${(props) => 0.9 - props.level * 0.2});
  font-weight: ${(props) => (props.level === 0 ? "bold" : "normal")};
  svg {
    padding: 0;
  }
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-root {
    height: 24px;
    background-color: ${palette.background.iconTags};
  }
  .MuiChip-label{
    color: #fff;
  }
  .MuiChip-deleteIcon {
    color: #fff;
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: ${palette.background.inputTags};
    cursor: alias;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &.MuiCheckbox-root {
    margin-right: 3px;
    color: ${palette.text.link};
  }
  .MuiIconButton-label {
    color: ${palette.text.link};
  }
`;

export const StyledIconButton = styled(IconButton)`
  opacity: ${(props) => !props.active && 0};
  padding: 0;
  margin: 0 0 0 10px;
`;
