import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

import { useStyles } from "./styles";

const Loader = ({ size }) => {
  const classes = useStyles();
  return (
    <Grid
      data-testid={ "loader-container" }
      container
      direction={ "row" }
      justify={ "center" }
      alignItems={ "center" }
      className={ classes.loader }
    >
      <CircularProgress size={ size } />
    </Grid>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
};

Loader.defaultProps = {
  size: 40,
};

export default Loader;
