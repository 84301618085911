import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import LinearProgress from "@mui/material/LinearProgress";
import palette from "theme/palette";
import Charts from "theme/charts";
import { hexToRgbA } from "common/utils";

export const StyledCardContainer = styled.div`
  margin-top: ${(props) => props.mt}px;
  @media (max-width: 600px) {
    padding: 10px 0;
  }
`;

export const StyledCard = styled(Card)`
  &.MuiPaper-root {
    background: ${(props) => (props.isModal ? "" : hexToRgbA(props.color, Charts.opacity.fillOpacity))};
    border: ${(props) => props.isModal && "none"};
    box-shadow: ${(props) => props.isModal && "none"};
  }
  width: ${(props) => (props.isFullWidth ? "100%" : "95%")};
  @media (max-width: 600px) {
    width: 100%;
  }
  .MuiGrid-root {
    padding: ${(props) => (props.isModal && "20px 10px !important")};
  }
`;

export const StyledCardContent = styled(CardContent)`
  padding: ${(props) => (props.isModal ? "0px !important" : "24px 16px 16px 16px !important")};
`;

export const StyledIcon = styled.img`
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  margin-right: 5px;
`;

export const StyledText = styled(Typography)`
  &.MuiTypography-body1 {
    font-size: ${(props) => `${props.fontSize}px`};
    color: ${(props) => (props.isTitle ? palette.text.purpleLightDark : props.color || palette.text.secondaryTitle)};
    font-weight: ${(props) => (props.isBold ? "bold" : "normal") };
    line-height: ${(props) => `${props.fontSize + 5}px`};
  }
`;

export const StyledContent = styled.div`
  .MuiTypography-h5 {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  min-width: 100px;
  border-radius: 5px;
  margin: 10px 0;
  &.MuiLinearProgress-root {
    height: 12px;
    background-color: ${(props) => (props.isModal ? palette.background.light : palette.white)};
    margin-top: 1px;
  }
  .MuiLinearProgress-bar {
    border-radius: 5px;
    background-color: ${palette.background.purple};
  }
`;

export const StyledPercent = styled.p`
  text-align: right;
  font-size: 12px;
`;

export const StyledLink = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${palette.text.purpleLink};
  text-align: right;
  text-decoration: underline;
  :hover{
    cursor: pointer;
    color: ${palette.text.purple};
  }
  svg {
    font-size: 14px;
  }
`;
