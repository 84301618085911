import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import NoDataMessage from "components_refactor/NoDataMessage";
import DoughnutChart from "components_refactor/DoughnutChart";
import Button from "components/Button";
import {
  DOUGHNUT_SIZE,
  OBJECT_KEYS,
  VARIANT,
  TYPES,
} from "common/constants";
import { orderByAsc, orderByDesc } from "common/helpers";
import { CARD_RESULT } from "theme/palette";
import noInfoFace from "assets/images/general/noInfoFaceBlack.svg";
import {
  StyledCardContainer,
  StyledCard,
  StyledCardContent,
  StyledHeader,
  StyledIcon,
  StyledTitleItem,
  StyledOneResult,
  StyledDivider,
  StyledGrid,
  StyledDetail,
  StyledButton,
} from "./styles";

const MultidataCard = (props) => {
  const {
    icon,
    size,
    title,
    subtitle,
    color,
    customRender,
    hasScore,
    hasSymbol,
    dataList,
    hasMargin,
  } = props;

  const getLabel = (item, isHorizontal = null) => {
    // Check if both onClick and label properties are present
    if (item?.handleOnClick && item?.label && item?.hasResult) {
      // Render a Button component if conditions are met
      return (
        <StyledButton
          isHorizontal={ isHorizontal }
        >
          <Button
            variant={ VARIANT.text }
            type={ TYPES.text }
            onClick={ item.handleOnClick }
          >
            { item.label }
          </Button>
        </StyledButton>
      );
    }
    // Return the label as is if conditions are not met
    return item?.label;
  };

  const renderValue = (item, titleValue, hasOne, sizeValue, isHorizontal = false) => {
    const label = getLabel(item, isHorizontal);

    // Render a DoughnutChart if hasScore is true
    if (hasScore) {
      return (
        <DoughnutChart
          title={ titleValue || (
            <StyledTitleItem>
              {label}
              {subtitle && hasOne && <Typography>{subtitle}</Typography>}
            </StyledTitleItem>
          ) }
          participation={ item?.participation }
          color={ color }
          size={ sizeValue }
          hasSymbol={ hasSymbol }
          isHorizontal={ isHorizontal }
        />
      );
    }
    // Render a StyledDetail component if hasScore is false
    return (
      <StyledDetail size={ sizeValue } isHorizontal={ isHorizontal }>
        {!isHorizontal && <Typography>{label}</Typography>}
        {item.result}
        {isHorizontal && (
          <StyledTitleItem>
            <Typography>{label}</Typography>
            {subtitle && hasOne && (
              <Typography variant={ VARIANT.h5 }>{subtitle}</Typography>
            )}
          </StyledTitleItem>
        )}
      </StyledDetail>
    );
  };

  const getLastResults = (list) => (
    // Render a Grid container with spacing
    <Grid container spacing={ 1 }>
      {list?.map((item, index) => (
        // Render a StyledGrid component for each item in the list
        <StyledGrid
          item
          key={ `chart-${item.id}` }
          xs={ Math.floor(12 / list?.length) }
          borderColor={ index === list?.length - 1 ? null : color }
        >
          {/* Render the value using the renderValue function */}
          {renderValue(
            item,
            getLabel(item),
            false,
            hasScore ? DOUGHNUT_SIZE.medium : DOUGHNUT_SIZE.small,
          )}
        </StyledGrid>
      ))}
    </Grid>
  );

  const getValues = () => {
    // Check if dataList is null or empty
    if (dataList === null || dataList.length === 0) {
      return <NoDataMessage iconImage={ noInfoFace } />;
    }
    // Check if dataList has only one item
    if (dataList?.length === 1) {
      return (
        <StyledOneResult>
          {/* Render the value using the renderValue function */}
          {renderValue(
            dataList[0],
            null,
            true,
            hasScore ? DOUGHNUT_SIZE.big : DOUGHNUT_SIZE.medium,
            true,
          )}
        </StyledOneResult>
      );
    }
    // Order the dataList in descending order based on value
    const orderList = orderByDesc(dataList, OBJECT_KEYS.value);
    return (
      <>
        {/* Render the first value using the renderValue function */}
        {renderValue(
          orderList[0],
          null,
          false,
          hasScore ? DOUGHNUT_SIZE.normal : DOUGHNUT_SIZE.medium,
          true,
        )}
        {/* Render a StyledDivider component */}
        <StyledDivider color={ color } />
        {/* Render the last results using the getLastResults function */}
        {getLastResults(
          orderByAsc(orderList?.slice(1, orderList.length), OBJECT_KEYS.value),
        )}
      </>
    );
  };

  return (
    <StyledCardContainer hasMargin={ hasMargin }>
      <StyledCard color={ color }>
        <StyledCardContent >
          <StyledHeader>
            {icon && <StyledIcon src={ icon } alt={ title } size={ size } />}
            <Typography variant={ VARIANT.h5 }>{ title }</Typography>
          </StyledHeader>
          { customRender || getValues()}
        </StyledCardContent>
      </StyledCard>
    </StyledCardContainer>
  );
};

MultidataCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  dataList: PropTypes.array,
  size: PropTypes.number,
  customRender: PropTypes.element,
  hasScore: PropTypes.bool,
  hasSymbol: PropTypes.bool,
  hasMargin: PropTypes.bool,
};

MultidataCard.defaultProps = {
  title: null,
  subtitle: null,
  icon: null,
  color: CARD_RESULT.purpleCard,
  dataList: null,
  size: 28,
  customRender: null,
  hasScore: false,
  hasSymbol: false,
  hasMargin: true,
};

export default MultidataCard;
