import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import isEqual from "lodash/isEqual";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import AutoCompleteCreable from "components/AutocompleteCreable";
import InputTextController from "components/InputTextController";
import AlertDialog from "components/AlertDialog";
import AlertAutoHide from "components/AlertAutohide";
import ManagerSelect from "components/commons/ManagerSelect";
import {
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
  ENTITY_OPTION,
  ERROR,
  TYPES,
} from "common/constants";
import {
  getItemFromLocalStorage,
  getAreas,
  getCitiesByCountry,
  getDivisions,
  getAutocompleteAttr,
  getElementNewValue,
  onCloseAlertAutohide,
} from "common/utils";
import { isNullOrEmpty } from "common/validators";
import { useCities } from "hooks/useCities";
import { getList as getPositions, create as createPosition } from "redux/actions/position/positionActions";
import { create as createCity } from "redux/actions/common/cityActions";
import { getList as getHierarchyLevelList, create as createLevel } from "redux/actions/common/hierarchyLevelActions";
import { getList as getLegalEntityList, create as createEntity } from "redux/actions/common/legalEntityActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const JobInformation = (props) => {
  const {
    control, register, setValue, defaultValue, setDefaultValue,
  } = props;
  const { t } = useTranslation("common");
  const [dispatched, setDispatched] = useState(false);
  const countries = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries);
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");
  const [cities, setCities] = useState([]);
  const [positions, setPositions] = useState(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.positions));
  const [levels, setLevels] = useState([]);
  const [entities, setEntities] = useState([]);
  const [inputTextValue, setInputTextValue] = useState("");
  const [dialog, setDialog] = useState(false);
  const [countrySelected, setCountrySelected] = useState();
  const [alertAutohide, setAlertAutohide] = useState({
    open: false,
    type: ERROR,
    message: "",
    title: "",
  });

  const [newCreation, setNewCreation] = useState();

  const organizationUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);

  const divisions = getDivisions(organizationUnits);

  const dispatch = useDispatch();

  const {
    list: listPositions,
    isLoadingList: isLoadingPositions,
  } = useSelector(({ positionReducer }) => positionReducer);

  const {
    list: hierarchyLevelsList,
    isLoadingList: isLoadingHierarchyLevels,
  } = useSelector(({ hierarchyLevelReducer }) => hierarchyLevelReducer);

  const {
    list: legalEntitiesList,
    isLoadingList: isLoadingLegalEntities,
  } = useSelector(({ legalEntityReducer }) => legalEntityReducer);

  // Cities
  const {
    cities: allCities, queryClient,
  } = useCities();

  useEffect(() => {
    if (isEmpty(positions) && !dispatched) {
      dispatch(getPositions());
    }
    dispatch(getHierarchyLevelList());
    dispatch(getLegalEntityList());
    setLevels(hierarchyLevelsList);
    setEntities(legalEntitiesList);
    setDispatched(true);
  }, [
    dispatch,
    dispatched,
    positions,
    hierarchyLevelsList,
    legalEntitiesList,
  ]);

  useEffect(() => {
    if (!isLoadingPositions && !isNullOrEmpty(listPositions)) {
      setPositions(listPositions);
    }
  }, [listPositions, isLoadingPositions]);

  const onAutocompleteChangeOrgUnits = (nameOfAttr, orgUnit) => (
    prop,
    event,
    newValue,
  ) => {
    const orgUnitName = getAutocompleteAttr(newValue, nameOfAttr);

    if (isEqual(orgUnit, OBJECT_KEYS.division)) {
      setValue("collaborator.job_position_attributes.division_name", getElementNewValue(newValue, orgUnitName));
      setValue("collaborator.job_position_attributes.area_name", "");
      setAreas(getAreas(organizationUnits, newValue?.id));
    } else {
      setValue("collaborator.job_position_attributes.area_name", getElementNewValue(newValue, orgUnitName));
      setAreaSelected(getElementNewValue(newValue, orgUnitName));
    }
  };

  const onAutocompleteChange = () => (prop, event, newValue) => {
    if (isEqual(prop, ENTITY_OPTION.country)) {
      setCountrySelected(newValue?.id);
      setDefaultValue({
        ...defaultValue,
        [prop]: newValue,
        city: null,
      });
      if (allCities) {
        setCities(getCitiesByCountry(newValue?.id, allCities));
      }
    } else {
      setDefaultValue({
        ...defaultValue,
        [prop]: newValue,
      });
    }
  };

  const handleDialog = (option) => {
    setDialog(option);
  };

  const handleDialogSubmit = (text, prop) => {
    setInputTextValue(text);
    setNewCreation(prop);
    handleDialog(true);
  };

  const creations = {
    city: {
      list: cities,
      data: {
        country_id: countrySelected,
        name: inputTextValue,
      },
      create: createCity,
      queryName: "cities",
    },
    position: {
      list: positions,
      localName: LOCAL_STORAGE_NAMES.positions,
      data: {
        name: inputTextValue,
      },
      create: createPosition,
    },
    level: {
      list: levels,
      data: {
        name: inputTextValue,
      },
      create: createLevel,
    },
    legalEntity: {
      list: entities,
      data: {
        name: inputTextValue,
        code: inputTextValue,
      },
      create: createEntity,
    },
  };

  const handleNew = async () => {
    const newItem = creations[newCreation];
    const createdElement = await dispatch(newItem.create(newItem.data));

    if (!isEmpty(createdElement)) {
      //  error handle
      if (createdElement.error) {
        toast(MESSAGE_TYPES.error, createdElement.message, t);
      } else {
        newItem.list.push(createdElement);
        setDefaultValue({
          ...defaultValue,
          [newCreation]: createdElement,
        });

        if (newItem?.localName) {
          const tempLocalstorage = getItemFromLocalStorage(newItem.localName);
          tempLocalstorage.push(createdElement);
          localStorage.setItem(newItem.localName, JSON.stringify(tempLocalstorage));
        }
        if (newItem?.queryName) {
          queryClient.setQueryData(newItem.queryName, (old) => [...old, createdElement]);
        }
      }

      handleDialog(false);
    }
  };

  return (
    <>
      <Card data-testid={ "job-information-collaborator" }>
        <Divider />
        <CardContent>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 6 }>
              <ManagerSelect name={ "collaborator.manager_id" } control={ control } />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.position }
                options={ positions }
                label={ t("common:common.position") }
                register={ register }
                value={ defaultValue.position && defaultValue.position?.name }
                onChange={ onAutocompleteChange() }
                name={ "collaborator.job_position_attributes.position_id" }
                nameOfAttr={ OBJECT_KEYS.name }
                addNew={ {
                  handleNew: handleDialogSubmit,
                  text: t("common.add_new_position"),
                  prop: OBJECT_KEYS.position,
                } }
                isRequired
                loading={ isLoadingPositions }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.division }
                options={ divisions }
                label={ t("common.division") }
                register={ register }
                onChange={ onAutocompleteChangeOrgUnits(
                  OBJECT_KEYS.name,
                  OBJECT_KEYS.division,
                ) }
                name={ "collaborator.job_position_attributes.division_name" }
                nameOfAttr={ OBJECT_KEYS.name }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.area }
                options={ areas }
                label={ t("common.area") }
                register={ register }
                onChange={ onAutocompleteChangeOrgUnits(
                  OBJECT_KEYS.name,
                  OBJECT_KEYS.area,
                ) }
                value={ areaSelected }
                name={ "collaborator.job_position_attributes.area_name" }
                nameOfAttr={ OBJECT_KEYS.name }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.level }
                options={ levels }
                label={ t("common:common.hierarchyLevel") }
                register={ register }
                value={ defaultValue.level && defaultValue.level?.name }
                onChange={ onAutocompleteChange() }
                name={ "collaborator.job_position_attributes.hierarchy_level_id" }
                nameOfAttr={ OBJECT_KEYS.name }
                addNew={ {
                  handleNew: handleDialogSubmit,
                  text: t("common.add_new_level"),
                  prop: OBJECT_KEYS.level,
                } }
                loading={ isLoadingHierarchyLevels }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.legalEntity }
                options={ entities }
                label={ t("common:common.legalEntity") }
                register={ register }
                value={ defaultValue.legalEntity && defaultValue.legalEntity?.name }
                onChange={ onAutocompleteChange() }
                name={ "collaborator.legal_entity_id" }
                nameOfAttr={ OBJECT_KEYS.name }
                addNew={ {
                  handleNew: handleDialogSubmit,
                  text: t("common.add_new_legalEntity"),
                  prop: OBJECT_KEYS.legalEntity,
                } }
                loading={ isLoadingLegalEntities }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.country }
                options={ countries }
                label={ t("common.country") }
                register={ register }
                value={ defaultValue.country && defaultValue.country?.name }
                onChange={ onAutocompleteChange() }
                name={ "collaborator.job_position_attributes.country_id" }
                nameOfAttr={ OBJECT_KEYS.name }
                isRequired
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteCreable
                id={ OBJECT_KEYS.city }
                options={ cities }
                label={ t("common.city") }
                register={ register }
                value={ defaultValue.city && defaultValue.city?.name }
                onChange={ onAutocompleteChange() }
                name={ "collaborator.job_position_attributes.city_id" }
                nameOfAttr={ OBJECT_KEYS.name }
                addNew={ {
                  handleNew: handleDialogSubmit,
                  text: t("common.add_new_city"),
                  prop: OBJECT_KEYS.city,
                } }
                disabled={ isUndefined(countrySelected) }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <InputTextController
                type={ TYPES.email }
                label={ t("common.email") }
                control={ control }
                name={ "collaborator.email" }
                required
                rules={ { required: true } }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AlertAutoHide
        open={ alertAutohide.open }
        onClose={ onCloseAlertAutohide(setAlertAutohide) }
        message={ alertAutohide.message }
        title={ alertAutohide.title }
        type={ alertAutohide.type }
      />
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ `${t(`common.add_new_${newCreation}`)}: ${inputTextValue}` }
        message={ t("common.modal_messages.sure_text") }
        onSubmit={ handleNew }
        buttons={ {
          isLoading: false,
        } }
      />
    </>
  );
};

JobInformation.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
  setDefaultValue: PropTypes.func.isRequired,
};

export default JobInformation;
