import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import {
  VARIANT,
  BUTTON_STYLE_TYPES,
  SIZE,
  SKELETONS_NUMBER,
} from "common/constants";
import SkeletonLoader from "components/SkeletonLoader";
import TimeOffForm from "./components/TimeOffForm";
import { getTimeOffTypes } from "../../functions";
import { StyledModal, StyledButton } from "./styles";

const PopUpForm = (props) => {
  const { data, setData } = props;
  const { t } = useTranslation(["common", "timeOff"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const [isActive, setIsActive] = useState(false);
  const handleClose = () => {
    setIsActive(false);
    setData(null);
  };

  const {
    list: timeOffTypesList,
    isLoading: isLoadingTimeOffTypes,
  } = useSelector(({ timeOffTypeReducer }) => timeOffTypeReducer);

  const dataTestId = "popup-view-component";

  return (
    <div data-testid={ dataTestId }>
      { isLoadingTimeOffTypes
        ? <SkeletonLoader num={ SKELETONS_NUMBER.EIGTH } />
        : (
          <>
            <StyledButton
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              size={ SIZE.medium }
              onClick={ () => setIsActive(true) }
              isFullWidth
              isMobile={ isMobile }
            >
              {t("common:common.request_time_off")}
            </StyledButton>

            <StyledModal
              isOpen={ isActive || data }
              onClose={ () => handleClose() }
              title={ data ? t("timeOff:editTimeOffTitle") : t("common:common.request_time_off") }
            >
              <TimeOffForm
                data={ data }
                isMobile={ isMobile }
                onClose={ () => handleClose() }
                onChangeFile={ () => { } }
                // TODO: this should be updated when service are ready
                selectOptions={ getTimeOffTypes(timeOffTypesList, t) }
              />
            </StyledModal>
          </>
        )}
    </div>
  );
};

PopUpForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  isMobile: PropTypes.bool,
};

PopUpForm.defaultProps = {
  data: null,
  setData: () => {},
  isMobile: false,
};

export default PopUpForm;
