import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Accordion from "components/Accordion";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components_refactor/NoDataMessage";
import { BORDER_ACCORDION_TYPE } from "common/constants";
import { getCommentsByDivision } from "../../functions";
import { StyledTitle } from "../GeneralContent/styles";
import { StyledAccordionContainer } from "../../styledComponents";

const CommentsByDepartment = ({ t, engagementComments, isLoading }) => {
  const [commentsByDepartment, setCommentsByDepartment] = useState(null);

  useEffect(() => {
    if (engagementComments && !isLoading) {
      const comments = engagementComments?.filter(
        (item) => item.keep_doing?.length !== 0
        || item.stop_doing?.length !== 0,
      );
      setCommentsByDepartment(comments);
    }
  }, [engagementComments, isLoading]);

  if (isLoading) {
    return (<SkeletonLoader num={ 8 } />);
  }

  return (
    <Grid container spacing={ 4 }>
      <Grid item xs={ 12 }>
        <StyledTitle>{t("comments")}</StyledTitle>
        <StyledAccordionContainer>
          { commentsByDepartment?.length > 0
            ? (
              <Accordion
                isDefaultIconStyle
                type={ BORDER_ACCORDION_TYPE.square }
                data={ getCommentsByDivision(commentsByDepartment, t) }
                iconLeft
              />
            )
            : <NoDataMessage />}
        </StyledAccordionContainer>
      </Grid>
    </Grid>
  );
};

CommentsByDepartment.propTypes = {
  t: PropTypes.func.isRequired,
  engagementComments: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CommentsByDepartment;
