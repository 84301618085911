import { useTranslation } from "react-i18next";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import { isEqual } from "common/helpers";
import { PAGINATION, MIN_VALUE } from "common/constants";
import { StyledButtonPaginator, StyledPaginationContainer } from "../../styles";

const Paginator = (props) => {
  const { total, pagination, handlePagination } = props;
  const { t } = useTranslation("tables");

  const limitPagination = pagination * PAGINATION.maxPerPage >= total
  ? total
  : pagination * PAGINATION.maxPerPage;
  
  const paginationLegend = `${
    total < PAGINATION.limited
      ? ""
      : `${limitPagination - PAGINATION.limited} - `
  }${limitPagination} ${t("tables:footer.of")} ${total}`;

  return !isEqual(total, MIN_VALUE) && (
    <StyledPaginationContainer>
      <div>{paginationLegend}</div>
      <StyledButtonPaginator
        onClick={ () => handlePagination(-1) }
        disabled={ isEqual(pagination, 1) }
      >
        <ChevronLeft />
      </StyledButtonPaginator>
      <StyledButtonPaginator
        onClick={ () => handlePagination(1) }
        disabled={ isEqual(limitPagination, total) }
      >
        <ChevronRight />
      </StyledButtonPaginator>
    </StyledPaginationContainer>
  );
};

export default Paginator;
