import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Select from "components/Select";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import SkeletonLoader from "components/SkeletonLoader";
import { isAdmin, enableComponent } from "common/utils";
import { isAdminOrManager } from "common/validators";
import {
  COMPANIES_WITHOUT_PERSONALITY_TEST, INDEX, SKELETONS_NUMBER,
} from "common/constants";
import { isEqual, isNull, isEmpty, isUndefined } from "common/helpers";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { getList as getPersonality, resetState as resetPersonality } from "redux/actions/personalityActions";
import NineBoxProfile from "./components/NineBoxProfile";
import PersonalityTest from "./components/PersonalityTest";
import BadgeRecommendation from "./components/BadgeRecommendation";
import { noDataMessage } from "../../functions/potential";
import { useStyles } from "../../styles";
import { dataAccordingUser } from "./functions";

// TODO: remove
const PotentialTab = (props) => {
  const { user, from, reducerToUse } = props;
  const { t } = useTranslation("potential");
  const classes = useStyles();
  const [values, setValues] = useState({
    evaluation: "",
  });
  const [userSurveyResult, setUserSurveyResult] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const {
    list: personality,
    loadinglist: isLoadingPersonality,
  } = useSelector(({ personalityReducer }) => personalityReducer);

  const {
    list: surveyResults,
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer);

  const {
    list: potentialProcessesList,
    isLoading: isLoadingPotentialProcessesList,
  } = useSelector(({ potentialReducer }) => potentialReducer);

  const dispatch = useDispatch();
  const collaboratorReducerValidated = reducerToUse?.one?.person?.employee && reducerToUse.one;
  const hasValidScope = isAdmin(user?.userCookies);

  const isUserRol = user?.userCookies;

  const getEvaluations = useCallback(() => {
    const evaluations = [];
    if (!isLoadingSurveyResults && !isEmpty(surveyResults) && !isNull(surveyResults)) {
      surveyResults.forEach((item) => {
        const isPotentialProcess = potentialProcessesList?.find(
          (process) => isEqual(process.id, item.survey_process?.id),
        );
        if (isPotentialProcess) {
          evaluations.push({
            value: item.survey_process?.id,
            label: item.survey_process?.name,
          });
        }
      });
    }
    return evaluations;
  },
  [
    isLoadingSurveyResults,
    surveyResults,
    potentialProcessesList,
  ]);

  const handleChange = (prop, event) => {
    setIsLoading(true);
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    if (!isLoadingSurveyResults && !isLoadingPotentialProcessesList && !isNull(surveyResults)) {
      const surveyResultByProcess = surveyResults ? surveyResults.find((surveyResult) => isEqual(surveyResult?.survey_process?.id, values.evaluation)) : {};
      setUserSurveyResult(surveyResultByProcess);
      setIsLoading(false);
    }
  }, [values.evaluation, surveyResults, isLoadingSurveyResults, isLoadingPotentialProcessesList]);

  useEffect(() => {
    if (!isLoadingSurveyResults && !isLoadingPotentialProcessesList) {
      const EVAL = getEvaluations();
      if (!isEmpty(potentialProcessesList) && EVAL[INDEX.zero]?.value) {
        setValues({ evaluation: EVAL[INDEX.zero]?.value });
      }
    }
  }, [potentialProcessesList, getEvaluations, dispatch, isLoadingSurveyResults, isLoadingPotentialProcessesList]);

  const hasPersonalitySection = enableComponent(user?.company?.slug, COMPANIES_WITHOUT_PERSONALITY_TEST);

  useEffect(() => {
    const userToUse = dataAccordingUser(
      from,
      user,
      collaboratorReducerValidated,
    );
    if (userToUse.userId) {
      dispatch(getIndividualSurveyResult(userToUse.userId));
      if (hasPersonalitySection) {
        dispatch(resetPersonality());
        dispatch(getPersonality(collaboratorReducerValidated.person.id));
      }
    }
  }, [
    dispatch,
    from,
    user,
    collaboratorReducerValidated,
    hasPersonalitySection,
  ]);

  const validatedData = () => (!isUndefined(personality)
    && !isEmpty(personality)
    ? personality
    : []);

  const validatedEmptyData = (isCollaborator) => {
    const mainValidation = isEmpty(personality)
      && isEmpty(surveyResults)
      && !isLoadingSurveyResults
      && !isLoadingPersonality;

    return isCollaborator
      ? isUserRol && !isAdmin(user?.userCookies) && mainValidation
      : mainValidation;
  };

  const isLoadingResults = isLoading || isLoadingSurveyResults || isLoadingPotentialProcessesList;

  return (
    <div data-testid={ "account-potential-tab" }>
      {hasPersonalitySection
      && (
        <Paper className={ classes.root }>
          {validatedEmptyData(true) && noDataMessage(t)}
          <PersonalityTest data={ validatedData() } />
        </Paper>
      )}
      {isAdminOrManager(user?.userCookies, from)
        && collaboratorReducerValidated && hasValidScope && (
        <NineBoxProfile userEmployee={ collaboratorReducerValidated } />
      )}
      <Paper className={ classes.root }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } sm={ 6 } md={ 8 } lg={ 9 }>
            <ViewHeaderTitle title={ t("potential:tabPotential.title") } subtitle={ t("potential:tabPotential.introduction") } />
          </Grid>
          <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
            <Select
              id={ "evaluation" }
              label={ t("performance:dashboard.filter_evaluation") }
              menuItems={ getEvaluations() }
              value={ values.evaluation }
              onChange={ handleChange }
              className={ "custom-select" }
              disabled={ isLoadingPotentialProcessesList
              || isEmpty(potentialProcessesList) }
            />
          </Grid>
        </Grid>
        {isLoadingResults
          ? <SkeletonLoader num={ SKELETONS_NUMBER.SIX } />
          : <BadgeRecommendation userSurveyResult={ userSurveyResult } />}
      </Paper>
    </div>
  );
};

PotentialTab.propTypes = {
  user: PropTypes.object,
  from: PropTypes.string,
  reducerToUse: PropTypes.object,
};

export default PotentialTab;
