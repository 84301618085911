import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import TextField from "@mui/material/TextField";
import FormHelperText from "components_refactor/FormHelperText";
import {
  MAX_LENGTH_INPUT, VARIANT, DECIMAL_SCALE_NUMBER_INPUT, DEFAULT_MIN_DECIMAL, SIZE,
} from "common/constants";

const CustomNumberFormat = forwardRef((props, ref) => {
  const { onChange, ...other } = props;

  // Handle change to correctly parse the value for react-hook-form
  const handleChange = (values) => {
    onChange(values.value);
  };

  return (
    <NumberFormat
      { ...other }
      onValueChange={ handleChange }
      getInputRef={ ref }
      thousandSeparator
      isNumericString
      fixedDecimalScale
      decimalScale={ props.isInteger ? DECIMAL_SCALE_NUMBER_INPUT : DEFAULT_MIN_DECIMAL }
      allowNegative
    />
  );
});

CustomNumberFormat.propTypes = {
  onChange: PropTypes.func.isRequired,
  isInteger: PropTypes.bool.isRequired,
};

// eslint-disable-next-line
const InputNumberController = (props) => {
  const {
    label,
    isRequired,
    disabled,
    control,
    name,
    isPercentage,
    isInteger,
    maxLength = MAX_LENGTH_INPUT.number,
    showHelperText,
    size,
  } = props;

  return (
    <>
      <Controller
        control={ control }
        name={ name }
        render={ ({ field, ref, onChange, value }) => (
          <TextField
            { ...field }
            label={ label }
            required={ isRequired }
            disabled={ disabled }
            variant={ VARIANT.outlined }
            fullWidth
            margin={ VARIANT.dense }
            InputProps={ {
              inputComponent: isPercentage ? NumberFormat : CustomNumberFormat,
            } }
            InputLabelProps={ { required: false } }
            inputProps={ {
              maxLength,
              ref: ref, // Ensure ref is passed to TextField for focus management
              isInteger, // Pass additional props to CustomNumberFormat
              onChange,
              value,
            } }
            size={ size }
          />
        ) }
      />
      {!disabled && isRequired && showHelperText && <FormHelperText />}
    </>
  );
};

InputNumberController.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  isPercentage: PropTypes.bool,
  showHelperText: PropTypes.bool,
  maxLength: PropTypes.number,
  isInteger: PropTypes.bool,
  size: PropTypes.string,
};

InputNumberController.defaultProps = {
  size: SIZE.medium,
  isInteger: false,
  isPercentage: false,
  showHelperText: false,
  isRequired: false,
  disabled: false,
};

export default InputNumberController;
