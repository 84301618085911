import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import concat from "lodash/concat";
import reverse from "lodash/reverse";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import { create as createBenefit } from "redux/actions/benefitsActions";
import SimpleTable from "components/SimpleTable";
import TableCheckbox from "components/TableCheckbox";
import SkeletonLoader from "components/SkeletonLoader";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import AlertModal from "components/AlertModal";
import InputTag from "components/InputTag";
import CurrencyInputTextController from "components/CurrencyInputTextController";
import SimpleAlert from "components/SimpleAlert";
import { useUserBenefits } from "hooks/useUserBenefits";
import {
  SKELETONS_NUMBER,
  BUTTON_STYLE_TYPES,
  ROLES,
  ERROR,
  OBJECT_KEYS,
  SIZE,
  TIMEOUT_DISPATCH,
  TYPES,
} from "common/constants";
import { preventEnter } from "common/utils";
import {
  getBenefitsCost,
  resetBenefitsValues,
  getUnusedBenefits,
  getBenefitsInitFormat,
  deleteUnusedBenefits,
  isValiBenefit,
  getDeletedItemsObjects,
  getDeletedFormat,
  getAssembledObject,
} from "../../../../functions/salary";
import { dispatchUpdate } from "../../../../functions/profile";

const BenefitsTable = (props) => {
  const {
    isLoading,
    isEditable,
    classes,
    handleToggleBenefits,
    collaboratorId,
    reducerToUse,
    isCollaborator,
  } = props;
  const { t } = useTranslation(["common", "account"]);
  const [
    userBenefits,
    userBenefitsIsLoading,
    userBenefitsError,
  ] = useUserBenefits(isCollaborator ? collaboratorId : null);
  const [values, setValues] = useState([]);
  const [temporalList, setTemporalList] = useState([]);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [inputTextValue, setInputTextValue] = useState("");
  const [newBenefit, setNewBenefit] = useState({});
  const [deletedItems, setDeletedItems] = useState([]);
  const [isDeletedAll, setIsDeletedAll] = useState(false);

  const dispatch = useDispatch();

  const { list: benefitsList } = useSelector(({ benefitsReducer }) => benefitsReducer);

  const ALL_BENEFITS = benefitsList;

  const handleNewBenefit = useCallback(() => {
    const newBenefitObj = {
      id: newBenefit.id,
      name: newBenefit.name,
    };

    inputTagsSelected.push(getBenefitsInitFormat([newBenefitObj], t, false)[0]);

    temporalList.unshift(getBenefitsInitFormat([newBenefitObj], t, false)[0]);
    setTemporalList(temporalList);
    ALL_BENEFITS.push(newBenefitObj);
  }, [newBenefit, ALL_BENEFITS, inputTagsSelected, temporalList, t]);

  useEffect(() => {
    if (!isEmpty(newBenefit)) {
      handleNewBenefit();
      setNewBenefit({});
    }
  }, [benefitsList, newBenefit, handleNewBenefit]);
  useEffect(() => {
    if (!isEmpty(userBenefits) && benefitsList) {
      if (isDeletedAll) {
        setValues([]);
      } else {
        setValues(
          getBenefitsCost(userBenefits, true, benefitsList),
        );
      }

      if (isEditable) {
        setInputTagsSelected([]);
        setTemporalList(
          getBenefitsCost(userBenefits, false, benefitsList),
        );
      }
    }
  }, [userBenefits, isEditable, benefitsList, isDeletedAll]);

  const { handleSubmit, control } = useForm({
    defaultValues: resetBenefitsValues(ROLES.COLLABORATOR),
  });

  const openCloseModal = () => {
    setModal(!modal);
  };

  const createModal = (title, text, approved, cancel, onClick, children) => {
    return { title, text, approved, cancel, onClick, children };
  };

  const viewModal = (
    title,
    children,
    text = "",
    approved = "",
    cancel = "",
    onClick
  ) => {
    setModalData(createModal(title, text, approved, cancel, onClick, children));
    openCloseModal();
  };

  const handleModalSubmit = () => {
    viewModal(
      t("common:common.modal_messages.sure_question"),
      "",
      t("account:accountDetails.benefits.edit_message"),
      t("common:common.modal_messages.yes_confirm"),
      t("common:common.modal_messages.no_cancel"),
      onSubmit
    );
  };

  const BENEFITS_HEADER = [
    {
      id: "name",
      align: "left",
      disablePadding: true,
      label: t("common:common.type"),
      customRender: (rowData) => rowData.label,
    },
    {
      id: "value",
      align: "left",
      disablePadding: false,
      label: t("account:accountDetails.benefits.monthlyPaymentAverage"),
      customRender: (rowData) => {
        if (isEditable) {
          const inputsKeyPrefix = "benefits";
          const inputNamePrefix = "collaborator.job_position_attributes.benefit";
          return (
            <CurrencyInputTextController
              id={ `${inputsKeyPrefix}-${rowData.payroll_item_id}-${rowData.id}` }
              label={ "" }
              control={ control }
              name={ `${inputNamePrefix}_${rowData.payroll_item_id}_${rowData.id}` }
              defaultValue={ rowData.value }
              cs={ classes.inputRow }
            />
          );
        }
        return rowData.value;
      },
    },
    {
      id: "frequency",
      align: "left",
      disablePadding: false,
      label: t("account:accountDetails.benefits.paymentFrequency"),
      customRender: (rowData) => {
        if (isEditable) {
          const inputsKeyPrefix = "frequency";
          const inputNamePrefix = "collaborator.job_position_attributes.frequency";
          return (
            <InputTextController
              id={ `${inputsKeyPrefix}-${rowData.payroll_item_id}-${rowData.id}` }
              type={ TYPES.text }
              label={ "" }
              control={ control }
              name={ `${inputNamePrefix}_${rowData.payroll_item_id}_${rowData.id}` }
              defaultValue={ rowData.frequency }
            />
          );
        }
        return rowData.frequency;
      },
    },
  ];

  const handleToolbarClick = (itemsSelected) => {
    setDeletedItems(itemsSelected);
    setTemporalList(deleteUnusedBenefits(temporalList, itemsSelected));
    setValues(deleteUnusedBenefits(values, itemsSelected));

    setIsDeleteAction(true);
    setInputTagsSelected(
      deleteUnusedBenefits(inputTagsSelected, itemsSelected)
    );

    setTimeout(() => {
      setIsDeleteAction(false);
    }, TIMEOUT_DISPATCH);
  };

  const handleTags = (addedValues) => {
    setTemporalList(concat(values, reverse(addedValues)));
    setInputTagsSelected(addedValues);
  };

  const handleInputText = (text) => {
    setInputTextValue(text);
  };

  const handleAddBenefit = async () => {
    if (
      !isEmpty(inputTextValue)
      && isEmpty(isValiBenefit(inputTextValue, inputTagsSelected))
    ) {
      const dataNewBenefit = {
        benefit: {
          name: inputTextValue,
        },
      };

      const creationBenefit = await createBenefit(dataNewBenefit);
      if (!isEmpty(creationBenefit)) {
        setNewBenefit(creationBenefit);
      }
    }
  };

  const handleInputData = () => getUnusedBenefits(
    getBenefitsInitFormat(ALL_BENEFITS, false),
    values,
  );

  const handleUpdateDispatch = () => {
    setModal(false);
    setDeletedItems([]);
    handleToggleBenefits(false);
  };

  const onSubmit = async () => {
    const controlData = control.getValues();
    let salaryAttributesData = {
      id: collaboratorId,
      payroll_items_salaries_attributes: {},
    };

    const deletedItemsObject = getDeletedFormat(
      getDeletedItemsObjects(userBenefits, deletedItems)
    );
    let benefitsArrObject = [];
    if (!isEmpty(controlData)) {
      const benefits = controlData.collaborator.job_position_attributes;

      benefitsArrObject = getAssembledObject(benefits);

      setIsDeletedAll(false);
    } else {
      setIsDeletedAll(true);
    }
    salaryAttributesData = {
      salary_attributes: {
        id: collaboratorId,
        payroll_items_salaries_attributes: isEmpty(controlData)
          ? deletedItemsObject
          : !isEmpty(deletedItemsObject)
          ? benefitsArrObject.concat(deletedItemsObject)
          : benefitsArrObject,
      },
    };

    dispatchUpdate(
      dispatch,
      ROLES.COLLABORATOR,
      salaryAttributesData,
      reducerToUse,
    ).then(() => {
      handleUpdateDispatch();
    });
  };

  return (
    <Card data-testid={"salary-tab-benefits"}>
      <CardHeader title={t("account:accountDetails.benefits.title")} />
      <CardContent>
        {isLoading || userBenefitsIsLoading ? (
          <SkeletonLoader num={SKELETONS_NUMBER.EIGTH} />
        ) : userBenefitsError ? (
          <SimpleAlert
            type={ERROR}
            message={!isUndefined(userBenefitsError) ? userBenefitsError : ""}
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isEditable ? (
                <>
                  <form
                    autoComplete="off"
                    onKeyPress={preventEnter}
                    onSubmit={handleSubmit(handleModalSubmit)}
                    className={classes?.tablePaper}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.inputTagContainer}
                      >
                        <InputTag
                          id={OBJECT_KEYS.rolesName}
                          label={t("account:accountDetails.benefits.select")}
                          placeholder={""}
                          size={SIZE.small}
                          itemsSelected={inputTagsSelected}
                          onChange={handleTags}
                          data={handleInputData()}
                          hasCheckbox
                          textAddDinamicTag={t(
                            "account:accountDetails.benefits.add"
                          )}
                          onAddDinamicTag={handleAddBenefit}
                          onInputTextChange={handleInputText}
                        />
                      </Grid>
                    </Grid>
                    <TableCheckbox
                      id="benefits-table"
                      header={BENEFITS_HEADER}
                      data={temporalList}
                      toolbarTipTitle={"Delete"}
                      toolbarTipIcon={DeleteIcon}
                      toolbarOnClick={handleToolbarClick}
                      isPulse={false} //FIXME: implement later
                      isToolbarAction={isDeleteAction}
                      findBy={OBJECT_KEYS.payrollItemId}
                    />

                    <Grid container className={classes.submitButtons}>
                      <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                          <Box>
                            <Button
                              onClick={() => handleToggleBenefits(false)}
                              variant={BUTTON_STYLE_TYPES.CONTAINED}
                              typeStyle={BUTTON_STYLE_TYPES.CANCEL}
                            >
                              {t("collaborators:buttons.cancel")}
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              variant={BUTTON_STYLE_TYPES.CONTAINED}
                              type={BUTTON_STYLE_TYPES.SUBMIT}
                              // isLoading={reducerToUse?.isLoading}
                              isLoading={false}
                              typeStyle={BUTTON_STYLE_TYPES.SUBMIT}
                              // disabled={isEmpty(temporalList)}
                            >
                              {t("collaborators:buttons.save_edit")}
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>

                  {modalData && (
                    <AlertModal
                      title={modalData.title}
                      text={modalData.text}
                      textDisagree={modalData.cancel}
                      textAgree={modalData.approved}
                      onClick={modalData.onClick}
                      open={modal}
                      isLoading={reducerToUse?.isLoadingProcess}
                      handleClose={openCloseModal}
                    >
                      {modalData.children}
                    </AlertModal>
                  )}
                </>
              ) : (
                <SimpleTable header={BENEFITS_HEADER} data={values} />
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

BenefitsTable.propTypes = {
  isLoading: PropTypes.bool,
  isEditable: PropTypes.bool,
  classes: PropTypes.object,
  control: PropTypes.object,
  handleToggleBenefits: PropTypes.func,
  collaboratorId: PropTypes.number,
  reducerToUse: PropTypes.object,
  isCollaborator: PropTypes.bool,
};

export default BenefitsTable;
