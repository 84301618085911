import { AbilityBuilder } from "@casl/ability";
import { MODULES, MODULE_ACTIONS } from "common/constants";
import { getUserRoles, getValidationRoutes } from "common/utils";
import { ROLE_MODULES, handleModulesValidation } from "../functions";

export const createGoalAbilities = (user) => {
  const { can, rules } = new AbilityBuilder();
  const userRoles = getUserRoles(user?.userCookies);
  const companySlug = user?.company?.slug;
  const goalModule = ROLE_MODULES.find(
    (moduleItem) => moduleItem.slug === MODULES.goals,
  );

  const isTesting = process.env.NODE_ENV === "test";

  const isAllowedRoute = isTesting ? true : getValidationRoutes("/goals");

  if (goalModule && isAllowedRoute) {
    const { roles, companiesValidation } = goalModule;

    if (roles.some((role) => userRoles.includes(role))) {
      handleModulesValidation(
        can,
        userRoles,
        companiesValidation,
        MODULES.goals,
        companySlug,
        [
          MODULE_ACTIONS.view,
          MODULE_ACTIONS.create,
          MODULE_ACTIONS.update,
        ],
      );
    }
  }

  return rules;
};
