import { useState} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import InputEditor from "components/InputEditor";
import { BUTTON_STYLE_TYPES, BUTTON_TYPE } from "common/constants";
import { useStyles } from "./styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const FeedbackSimple = (props) => {
  const { control, isSubmitDisabled, isLoading } = props;
  const [disabled, setIsDisabled] = useState(true);
  const { t } = useTranslation("feedback");
  const classes = useStyles();

  const validateInputEdit = (value) => setIsDisabled(isEmpty(value) || false);

  return (
    <Grid container className={ classes.root } data-testid={ "feedback-simple-view" }>
      <Grid item xs={ 12 } className={ classes.content }>
        <Controller
          as={ <InputEditor validateInputEdit={ validateInputEdit } /> }
          name={ "feedback_simple.message" }
          control={ control }
        />
      </Grid>
      <Grid item xs={ 12 } className={ classes.btnSubmit }>
        <Button
          type={ BUTTON_TYPE.submit }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          disabled={ isSubmitDisabled || disabled }
          isLoading={ isLoading }
        >
          {t("send_feedback")}
        </Button>
      </Grid>
    </Grid>
  );
};

FeedbackSimple.propTypes = {
  control: PropTypes.object.isRequired,
  isSubmitDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default FeedbackSimple;
