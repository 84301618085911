import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  useOrganizationUnits,
  useManagers,
  useCountries,
  useCities,
  useStates,
} from "hooks/useFilters";
import InputTag from "components/InputTag";
import InputTagTree from "../InputTagTree";
import {
  FilterButton,
  FilterModal,
  ModalContent,
  FilterActions,
  ApplyButton,
  CancelButton,
  ClearButton,
  RightActions,
} from "./styles";

const Filter = (props) => {
  const {
    surveyProcessId, hasScore,
    onApplyFilters, hasDefaultFilter,
    isLoading,
  } = props;
  const { t } = useTranslation(["common", "tables", "timeOff"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState(
    hasDefaultFilter
      ? {
        [t("tables:filters.states")]: [{ id: "active", label: t("tables:state.active") }],
      } : {},
  );

  const [tempFilters, setTempFilters] = useState(selectedFilters);
  const [statesOptions, setStatesOptions] = useState([]);

  const params = useMemo(() => {
    const p = {};
    if (surveyProcessId) p.survey_process_id = surveyProcessId;
    if (hasScore) p.with_score = true;
    return p;
  }, [surveyProcessId, hasScore]);

  const { data: orgUnitsData } = useOrganizationUnits(params, t);
  const { data: managersData } = useManagers(params, t);
  const { data: countriesData } = useCountries(params, t);
  const { data: citiesData } = useCities(params, t);
  const { data: statesData } = useStates(params, t);

  useEffect(() => {
    if (statesData?.length > 0) {
      setStatesOptions(
        statesData.map((state) => ({
          id: state,
          label: t(`tables:state.${state}`),
        })),
      );
    }
  }, [statesData, t]);

  useEffect(() => {
    setSelectedFilters(
      hasDefaultFilter
        ? {
          [t("tables:filters.states")]: [{ id: "active", label: t("tables:state.active") }],
        }
        : {},
    );
    setTempFilters(
      hasDefaultFilter
        ? {
          [t("tables:filters.states")]: [{ id: "active", label: t("tables:state.active") }],
        }
        : {},
    );
  }, [surveyProcessId, hasDefaultFilter, t]);

  const handleFilterChange = (key, value) => {
    setTempFilters((prev) => ({ ...prev, [key]: value }));
  };

  const applyFilters = () => {
    setSelectedFilters(tempFilters);
    setIsModalOpen(false);

    const selectedStates = tempFilters[t("tables:filters.states")]?.map((f) => f.id) || [];
    let activeState = [];

    if (selectedStates.includes("active") && !selectedStates.includes("inactive")) {
      activeState = [true];
    } else if (selectedStates.includes("inactive") && !selectedStates.includes("active")) {
      activeState = [false];
    } else {
      activeState = [];
    }

    const query = {
      q: {
        manager_id_special_in: tempFilters[t("tables:filters.manager")]?.map((f) => f.id) || [],
        country_id_in: tempFilters[t("tables:filters.country")]?.map((f) => f.id) || [],
        city_id_in: tempFilters[t("tables:filters.city")]?.map((f) => f.id) || [],
        organization_unit_id_in: tempFilters[t("tables:filters.unit")]?.map((f) => f.id) || [],
        active_in: activeState,
        employee_active_in: activeState,
      },
    };

    if (onApplyFilters) {
      onApplyFilters(query);
    }
  };

  const cancelFilters = () => {
    setTempFilters(selectedFilters);
    setIsModalOpen(false);
  };

  const clearFilters = () => {
    setSelectedFilters({
      [t("tables:filters.states")]: [{ id: "active", label: t("tables:state.active") }],
    });
    setTempFilters({
      [t("tables:filters.states")]: [{ id: "active", label: t("tables:state.active") }],
    });

    setIsModalOpen(false);

    if (onApplyFilters) {
      onApplyFilters({
        q: {
          manager_id_special_in: [],
          country_id_in: [],
          city_id_in: [],
          organization_unit_id_in: [],
          active_in: [true],
          employee_active_in: [true],
        },
      });
    }
  };

  const filteredKeys = Object.keys(selectedFilters).filter((key) => {
    const items = selectedFilters[key];
    return Array.isArray(items) && items.length > 0;
  });

  const renderFilterText = () => {
    if (filteredKeys.length === 0) return `${t("common:add_filter")}...`;

    const text = filteredKeys
      .map((key) => {
        const values = selectedFilters[key] || [];
        const labels = values.map((v) => v.complete_path ?? v.label ?? v).join(", ");
        return `${key}: ${labels}`;
      })
      .join(" | ");

    return `${t("common:filtering_by")}: ${text}`;
  };

  return (
    <div>
      <FilterButton
        active={ filteredKeys?.length > 0 }
        onClick={ () => {
          if (!isLoading) {
            setTempFilters(selectedFilters);
            setIsModalOpen(true);
          }
        } }
        disabled={ isLoading }
      >
        {renderFilterText()}
      </FilterButton>
      {isModalOpen && (
        <FilterModal>
          <ModalContent>
            <h3>{t("common:filter_by")}</h3>
            <InputTagTree
              label={ t("tables:filters.unit") }
              data={ orgUnitsData || [] }
              value={ tempFilters[t("tables:filters.unit")] || [] }
              onChange={ (value) => handleFilterChange(t("tables:filters.unit"), value) }
              size={ "small" }
              disabled={ orgUnitsData?.length === 0 }
            />

            <InputTag
              label={ t("tables:filters.manager") }
              data={ managersData?.map((m) => ({
                id: m.id,
                label: m.full_name,
              })) || [] }
              defaultValues={ tempFilters[t("tables:filters.manager")] || [] }
              onChange={ (value) => handleFilterChange(t("tables:filters.manager"), value) }
              size={ "small" }
              hasCheckbox
            />

            <InputTag
              label={ t("tables:filters.country") }
              data={ countriesData?.map((c) => ({
                id: c.id,
                label: c.name,
              })) || [] }
              defaultValues={ tempFilters[t("tables:filters.country")] || [] }
              onChange={ (value) => handleFilterChange(t("tables:filters.country"), value) }
              size={ "small" }
              hasCheckbox
            />

            <InputTag
              label={ t("tables:filters.city") }
              data={ citiesData?.map((city) => ({
                id: city.id,
                label: city.name,
              })) || [] }
              defaultValues={ tempFilters[t("tables:filters.city")] || [] }
              onChange={ (value) => handleFilterChange(t("tables:filters.city"), value) }
              size={ "small" }
              hasCheckbox
            />

            <InputTag
              label={ t("tables:filters.states") }
              data={ statesOptions }
              defaultValues={ tempFilters[t("tables:filters.states")] || [] }
              onChange={ (value) => handleFilterChange(t("tables:filters.states"), value) }
              size={ "small" }
              hasCheckbox
              disabled={ statesOptions.length <= 1 }
            />

            <FilterActions>
              {Object.keys(selectedFilters).length > 0 && (
                <ClearButton onClick={ clearFilters }>{t("common:clear_filters")}</ClearButton>
              )}
              <RightActions>
                <CancelButton onClick={ cancelFilters }>{t("common:common.cancel")}</CancelButton>
                <ApplyButton onClick={ applyFilters }>{t("common:apply_filter")}</ApplyButton>
              </RightActions>
            </FilterActions>
          </ModalContent>
        </FilterModal>
      )}
    </div>
  );
};

Filter.propTypes = {
  surveyProcessId: PropTypes.number,
  hasScore: PropTypes.bool,
  onApplyFilters: PropTypes.func,
  hasDefaultFilter: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Filter.defaultProps = {
  surveyProcessId: null,
  hasScore: false,
  onApplyFilters: () => {},
  hasDefaultFilter: true,
  isLoading: false,
};

export default Filter;
