import {
  useState, useEffect, useCallback, useContext,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { SessionContext } from "modules/session/context";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import CheckboxInput from "components/CheckboxInput";
import InputTextController from "components/InputTextController";
import SkeletonLoader from "components/SkeletonLoader";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  getList as getSurveyProcesses,
  getOne as getSurvey,
  createOrUpdate as createOrUpdateSurveyProcess,
  resetState,
  resetStateOne,
  resetStateProcess,
} from "redux/actions/surveyProcessesActions";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  TYPES,
  SKELETONS_NUMBER,
  INDEX,
  LOCATION_PROPS,
  STATE,
} from "common/constants";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { preventEnter, getPropertyByLocation } from "common/utils";
import {
  getEvaluationTypes,
  buildSurveyProcess,
  processDefaults,
  resetFormData,
  validEvaluationProcess,
  isEditProcess,
} from "./functions";
import ProcessInformation from "./components/ProcessInformation";
import EvaluationType from "./components/EvaluationType";
import {
  StyledCard,
  StyledContent,
  StyledTitle,
  StyledHiddenInput,
  StyledGridSubmit,
  StyledButton,
  StyledGrid,
} from "./styles";

const SurveyProcess = () => {
  const { t } = useTranslation("surveys", "common");
  const dispatch = useDispatch();
  const {
    successProcess,
    loadingProcess,
    one: oneResultProcess,
  } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );
  const {
    state: { user },
  } = useContext(SessionContext);

  const location = useLocation();
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processType = locationSearch?.split("=")[INDEX.zero]?.replace("?", "");
  const processId = locationSearch?.split("=")[INDEX.one];

  const hasInternalClient = user?.company?.internal_client;

  const history = useHistory();

  const {
    register, control, handleSubmit, watch, reset,
  } = useForm({
    defaultValues: processDefaults,
  });

  const [previousEvaluationsData, setPreviousEvaluationsData] = useState([]);
  const [previousGeneralData, setPreviousGeneralData] = useState([]);

  const resetForm = useCallback(
    async (data) => {
      const editData = resetFormData(processType, data, t);
      setPreviousEvaluationsData(editData.evaluations_attributes);
      setPreviousGeneralData(editData);
      if (editData) {
        reset(editData);
      }
    },
    [reset, t, processType],
  );

  const getSurveyProcess = useCallback(
    () => {
      dispatch(getSurvey(processType, processId));
    },
    [dispatch, processType, processId],
  );

  useEffect(() => {
    if (isEditProcess(processType, processId)) {
      getSurveyProcess();
    }
  }, [processType, processId, getSurveyProcess]);

  useEffect(() => {
    if (!loadingProcess && oneResultProcess && processType) {
      resetForm(oneResultProcess);
    }
  }, [
    loadingProcess,
    oneResultProcess,
    resetForm,
    processType,
  ]);

  const validateDates = (startingDate, endDate) => {
    if (!startingDate || !endDate) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.required_dates") });
      return false;
    }
    if (new Date(endDate) <= new Date(startingDate)) {
      toast(MESSAGE_TYPES.error, { title: t("goals:form.title"), message: t("goals:form.validation_dates") });
      return false;
    }
    return true;
  };

  // submit - create or update
  const onSubmit = (surveyProcess) => {
    let data = buildSurveyProcess(surveyProcess, processId);
    const { start_date: startDate, end_date: endDate } = data;
    if (!validateDates(startDate, endDate)) {
      return;
    }
    if (processId) {
      data = validEvaluationProcess(data, previousEvaluationsData);
    }
    let transition = null;
    if (previousGeneralData.state === STATE.finished && endDate >= moment()) {
      transition = {
        state_transition: STATE.launch,
      };
    } else if (previousGeneralData.state === STATE.running && endDate < moment()) {
      transition = {
        state_transition: STATE.finish,
      };
    }
    dispatch(createOrUpdateSurveyProcess(data, data.type, processId, t, transition));
  };

  // reset data and redirect
  const resetDataAndRedirect = useCallback(() => {
    dispatch(resetState());
    dispatch(resetStateOne());
    dispatch(resetStateProcess());
    toast(MESSAGE_TYPES.success, handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t));
    dispatch(getSurveyProcesses());
    setTimeout(() => {
      history.push("./");
    });
  }, [dispatch, history, t]);

  useEffect(() => {
    if (successProcess) {
      resetDataAndRedirect();
    }
  }, [successProcess, resetDataAndRedirect]);

  return (
    <StyledCard data-testid={ "survey-process" }>
      <StyledContent>
        {!isEmpty(previousEvaluationsData) || !processType ? (
          <form
            id={ "survey-form" }
            onSubmit={ handleSubmit(onSubmit) }
            onKeyPress={ preventEnter }
            autoComplete={ "off" }
          >
            <ProcessInformation
              control={ control }
              isEdit={ isEditProcess(processType, processId) }
              watch={ watch }
            />
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledTitle variant={ VARIANT.h5 }>
                  {t("surveys:form.fields.general_data.title")}
                </StyledTitle>
              </Grid>
            </Grid>
            <Grid item xs={ 12 }>
              <FormControlLabel
                control={ (
                  <CheckboxInput
                    name={ "has_nala_form" }
                    inputRef={ register }
                    defaultChecked={ previousGeneralData.has_nala_form }
                  />
                ) }
                label={ t("surveys:form.fields.general_data.nala_form") }
              />
            </Grid>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                <StyledTitle variant={ VARIANT.h5 }>
                  {t("surveys:form.fields.select_evaluations")}
                </StyledTitle>
              </Grid>
            </Grid>
            {getEvaluationTypes(t)
              .filter((type) => hasInternalClient || type.id !== EVALUATION_TYPE.INTERNAL_CLIENT)
              ?.map((type, index) => (
                <StyledGrid container spacing={ 3 } key={ type.id }>
                  <StyledHiddenInput>
                    <InputTextController
                      type={ TYPES.hidden }
                      control={ control }
                      name={ `evaluations_attributes[${index}].id` }
                    />
                  </StyledHiddenInput>
                  <Grid item xs={ 12 }>
                    <FormControlLabel
                      control={ (
                        <CheckboxInput
                          name={ `evaluations_attributes[${index}].type` }
                          value={ type.id }
                          inputRef={ register }
                          defaultChecked={ !isEmpty(previousEvaluationsData[index]) }
                        />
                      ) }
                      label={ type.name }
                    />
                  </Grid>
                  {watch(`evaluations_attributes[${index}].type`) && (
                    <EvaluationType
                      type={ type }
                      index={ index }
                      control={ control }
                      watch={ watch }
                      register={ register }
                      data={ previousEvaluationsData[index] }
                    />
                  )}
                </StyledGrid>
              ))}

            <StyledGridSubmit container>
              <StyledButton
                variant={ VARIANT.contained }
                color={ COLORS_NAME.primary }
                type={ BUTTON_STYLE_TYPES.SUBMIT }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                isLoading={ loadingProcess }
              >
                {t("common:common.save")}
              </StyledButton>
            </StyledGridSubmit>
          </form>
        ) : (
          <SkeletonLoader num={ SKELETONS_NUMBER.FIVE } />
        )}
      </StyledContent>
    </StyledCard>
  );
};

export default SurveyProcess;
