import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { CARD_RESULT } from "theme/palette";
import ParticipationIcon from "assets/images/performance/autoevaluation.svg";
import { StyledCardContainer, StyledCard, StyledCardContent } from "./styles";
import CardHeader from "./components/CardHeader";
import CardProgress from "./components/CardProgress";
import CardResults from "./components/CardResults";
import CardLink from "./components/CardLink";

const ParticipationCard = ({
  title, participation, icon, color, isFullWidth, onClick, isModal,
}) => (
  <StyledCardContainer mt={ 10 }>
    <StyledCard color={ color } isFullWidth={ isFullWidth } isModal={ isModal }>
      <StyledCardContent isModal={ isModal }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <CardHeader icon={ icon } title={ title } isModal={ isModal } />
            <CardProgress participation={ participation } isModal={ isModal } />
            <CardResults participation={ participation } color={ color } />
            {onClick && <CardLink onClick={ onClick } />}
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  </StyledCardContainer>
);

ParticipationCard.propTypes = {
  title: PropTypes.string.isRequired,
  participation: PropTypes.shape({
    total: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  isFullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  isModal: PropTypes.bool,
};

ParticipationCard.defaultProps = {
  color: CARD_RESULT.purpleCard,
  isFullWidth: false,
  icon: ParticipationIcon,
  onClick: null,
  isModal: false,
};

export default ParticipationCard;
