import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import SkeletonLoader from "components/SkeletonLoader";
import { SKELETONS_NUMBER, TYPES_RESULTS } from "common/constants";
import { getSkillSection } from "common/utils";
import { isEqual } from "common/helpers";
import Talented from "assets/images/potential/skills/talented.svg";
import Developed from "assets/images/potential/skills/developed.svg";
import ToDevelop from "assets/images/potential/skills/to_develop.svg";
import Tips from "assets/images/potential/skills/tips.svg";
import SkillCard from "../SkillCard";
import { skillType } from "../../functions";

const BadgeRecommendation = (props) => {
  const { userSurveyResult } = props;
  const { t } = useTranslation(["potential", "common"]);
  const [skills, setSkills] = useState({
    talented: [],
    developed: [],
    toDevelop: [],
  });
  const [tips, setTips] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer);

  const setSkillCategory = useCallback((category, item) => {
    let skillAux = [];
    let skillProp;
    if (isEqual(category, TYPES_RESULTS.large) || isEqual(category, TYPES_RESULTS.en.hight)) {
      skillAux = skills.talented;
      skillProp = TYPES_RESULTS.skillProp.talented;
    } else if (isEqual(category, TYPES_RESULTS.balanced) || isEqual(category, TYPES_RESULTS.en.moderate)) {
      skillAux = skills.developed;
      skillProp = TYPES_RESULTS.skillProp.developed;
    } else {
      skillAux = skills.toDevelop;
      skillProp = TYPES_RESULTS.skillProp.toDevelop;
    }

    skillAux.push(item);
    setSkills({
      ...skills,
      [skillProp]: skillAux,
    });
  }, [skills]);

  const setSkillTips = useCallback((item) => {
    const auxTips = tips;
    auxTips.push(item);
    setTips(auxTips);
  }, [tips]);

  const resetAllStates = () => {
    setSkills({
      talented: [],
      developed: [],
      toDevelop: [],
    });
    setTips([]);
  }

  useEffect(() => {
    if (userSurveyResult && !isLoaded) {
      const { result_scale_indices, complete_results_by_section } = userSurveyResult;
      const scale = result_scale_indices;
      if (userSurveyResult.result) {
        complete_results_by_section.forEach(((item) => {
          if (skillType.includes(item.name)) {
            setSkillCategory(getSkillSection(item?.score, scale), item);
            if (isEqual(item.result, TYPES_RESULTS.deep) || isEqual(item.result, TYPES_RESULTS.en.limited)) {
              setSkillTips(item);
            }
          }
        }));
      } else {
        resetAllStates();
      }
      setIsLoaded(true);
    } else if (!userSurveyResult && isLoaded) {
      setIsLoaded(false);
      resetAllStates();
    }
  }, [userSurveyResult, isLoaded, setSkillCategory, setSkillTips]);

  return (
    <div data-testid={ "badge-recommendation-tab" }>
      {isLoadingSurveyResults ? (
        <SkeletonLoader num={ SKELETONS_NUMBER.SIX } />
      ) : (
        <>
          <SkillCard title={ t("potential:tabPotential.skills.titles.talented") } icon={ Talented } skills={ skills.talented } />
          <SkillCard title={ t("potential:tabPotential.skills.titles.developed") } icon={ Developed } skills={ skills.developed } />
          <SkillCard title={ t("potential:tabPotential.skills.titles.toDevelop") } icon={ ToDevelop } skills={ skills.toDevelop } />
          <SkillCard title={ t("potential:tabPotential.skills.titles.tips") } icon={ Tips } tips={ tips } />
        </>
      )}
    </div>
  );
};

export default BadgeRecommendation;
