import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import SimpleAccordion from "components/SimpleAccordion";
import StarRating from "components/StarRating";
import InputText from "components/InputText";
import {
  VARIANT,
  ALIGN_ITEMS,
  AUTOCOMPLETE,
  ORIENTATION,
  SCALE_ORDER,
} from "common/constants";
import { isEmpty, isEqual, isNumber } from "common/helpers";
import { getLabelAnswer, getValue } from "components/MultipleAnswers/functions";
import { StyledSliderContent } from "styledComponents/SliderQuestion";
import { getAvatar } from "common/utils";
import DesktopScale from "./components/DesktopScale";
import {
  StyledCard,
  StyledAnswerContent,
  StyledMember,
  StyledResults,
  StyledCheckCircleOutlineIcon,
  StyledTextInfo,
} from "./styles";

const MultipleAnswers = (props) => {
  const {
    id,
    scale,
    members,
    teamAnswers,
    handleTeamAnswers,
    disabled,
  } = props;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const language = i18n?.language;
  const firstScaleId = scale?.indices[0]?.id;
  // eslint-disable-next-line no-unsafe-optional-chaining
  const lastScaleId = scale?.indices[scale?.indices?.length - 1]?.id;
  const isNumberScale = isNumber(parseInt(scale?.indices[0]?.answer));
  //const isShortAnswer = scale?.indices[0]?.answer_es?.length <= SCALE_ORDER.min;
  const isShortAnswer = false;
  const [selectedExpanded, setSelectedExpanded] = useState(null);

  const marks = scale?.indices?.map((item, index) => ({
    value: index,
    label: isMobile ? getLabelAnswer(item.id, item[`answer_${language}`], scale, firstScaleId, lastScaleId, isMobile) : "",
    firstLabel: item[`answer_${language}`],
  }));

  // handle the touch end event of a slider
  const handleSliderTouchEnd = (memberId, value) => {
    const newValue = marks[value].firstLabel;
    handleTeamAnswers(id, newValue, memberId);
  };

  const sliderRender = (memberId) => {
    let value;
    if (scale) {
      value = getValue(id, teamAnswers, marks, memberId);
    } else if (teamAnswers[memberId] && id in teamAnswers[memberId]) {
      const answer = teamAnswers[memberId][id];
      value = answer || "";
    } else {
      value = "";
    }
    return scale ? (
      <StyledSliderContent
        isVertical={ !isNumberScale }
        height={ scale?.indices?.length > 3 ? "330px" : "220px" }
        isShortAnswer={ scale?.indices[0]?.answer_es?.length <= SCALE_ORDER.min }
        value={ value }
      >
        <Slider
          orientation={ isNumberScale ? ORIENTATION.horizontal : ORIENTATION.vertical }
          valueLabelDisplay={ AUTOCOMPLETE.off }
          value={ value }
          step={ null }
          track={ false }
          marks={ marks }
          max={ marks ? marks.length - 1 : 0 }
          disabled={ disabled }
          onChange={ (event, newValue) => handleTeamAnswers(
            id, marks[newValue].firstLabel, memberId, false,
          ) }
          onTouchEnd={ () => handleSliderTouchEnd(memberId, value) }
          data-member-id={ memberId }
        />
        {isNumberScale && marks && (
          <StyledAnswerContent>
            <Typography>{ `${t("surveys:your_answer")}:` }</Typography>
            <Typography
              variant={ VARIANT.h5 }
            >
              { teamAnswers[memberId][id] || marks[0]?.firstLabel }
            </Typography>
          </StyledAnswerContent>
        )}
      </StyledSliderContent>
    ) : (
      <InputText
        id={ id }
        label={ "" }
        name={ id }
        variant={ VARIANT.outlined }
        onChange={ (prop, event) => handleTeamAnswers(id, event.target.value, memberId, false) }
        value={ value }
        multiline={ 4 }
        onBlur={ () => handleTeamAnswers(id, value, memberId) }
        disabled={ disabled }
      />
    );
  };

  const handleExpanded = (panel) => {
    setSelectedExpanded(isEqual(panel, selectedExpanded) ? null : panel);
  };

  const renderAccordionOrTable = isMobile ? members.map((member) => (
    <SimpleAccordion
      key={ member }
      isExpanded={ isEqual(selectedExpanded, `member-${member.id}`) }
      expandedPanel={ `member-${member.id}` }
      setIsExpanded={ handleExpanded }
      avatar={ (
        <Grid container spacing={ 1 } alignItems={ ALIGN_ITEMS.center }>
          <Grid item xs={ 8 }>
            <StyledMember>
              <Avatar
                alt={ member.full_name }
                src={ getAvatar(member.evaluationResult?.evaluation_details?.evaluated) }
                key={ member.id }
              />
              <Typography>{member.full_name }</Typography>
            </StyledMember>
          </Grid>
          <Grid item xs={ 4 }>
            <StyledResults>
              { teamAnswers[member.id]
                && id in teamAnswers[member.id]
                && !isEmpty(teamAnswers[member.id][id])
                ? (
                  <>
                    <StyledCheckCircleOutlineIcon />
                    {/* // TODO: new value */}
                    { scale ? (
                      <StarRating
                        value={ getValue(id, teamAnswers, marks, member.id) + 1 }
                        maxRating={ scale?.indices?.length }
                        max={ scale?.indices?.length }
                        isReadOnly
                      />
                    ) : ""}
                  </>
                ) : <StyledTextInfo>{ t("common:common.notEvaluated") }</StyledTextInfo>}
            </StyledResults>
          </Grid>
        </Grid>
      ) }
    >
      { sliderRender(member.id) }
    </SimpleAccordion>
  )) : (
    <DesktopScale
      id={ id }
      isShortAnswer={ isShortAnswer }
      isNumberScale={ isNumberScale }
      scale={ scale }
      members={ members }
      teamAnswers={ teamAnswers }
      marks={ marks }
      language={ language }
      handleChange={ handleTeamAnswers }
      disabled={ disabled }
    />
  );

  return (
    <StyledCard isScale={ scale }>
      <CardContent>
        {renderAccordionOrTable}
      </CardContent>
    </StyledCard>
  );
};

MultipleAnswers.propTypes = {
  id: PropTypes.string.isRequired,
  teamAnswers: PropTypes.object.isRequired,
  handleTeamAnswers: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  scale: PropTypes.object,
  isMobile: PropTypes.bool,
  disabled: PropTypes.bool,
};

MultipleAnswers.defaultProps = {
  scale: null,
  isMobile: false,
  disabled: false,
};

export default MultipleAnswers;
