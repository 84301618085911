import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import {
  INFO, SUCCESS, WARNING, ERROR,
} from "common/constants";
import AlertTitle from "@mui/material/AlertTitle";
import {
  StyledAlert,
  InfoIconStyled,
  CheckCircleIconStyled,
  WarningIconStyled,
  ErrorIconStyled,
} from "./styles";

const SimpleAlert = forwardRef(({
  type, message, title, onClose, cs, icon, children,
}, ref) => {
  const getIcon = (iconType) => {
    switch (iconType) {
    case INFO:
      return <InfoIconStyled />;
    case SUCCESS:
      return <CheckCircleIconStyled />;
    case WARNING:
      return <WarningIconStyled />;
    case ERROR:
      return <ErrorIconStyled />;
    default:
      return null;
    }
  };

  return (
    <StyledAlert
      ref={ ref }
      data-testid={ "simplealert" }
      icon={ icon || getIcon(type) }
      severity={ type }
      onClose={ onClose }
      variant={ cs }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message}
      {children}
    </StyledAlert>
  );
});

SimpleAlert.displayName = "SimpleAlert";

SimpleAlert.propTypes = {
  type: PropTypes.string,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  title: PropTypes.string,
  onClose: PropTypes.func,
  cs: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.node,
};

export default SimpleAlert;
