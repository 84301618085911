import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import LoaderImageProfile from "views/OnboardingFollowUp/components/LoaderImageProfile";
import { hasPreviewImage, getAvatar } from "common/utils";
import { ALIGN_ITEMS } from "common/constants";
import {
  StyledBoxContainer,
  StyledImageContainer,
  StyledStatus,
  StyledTitleWithSubtitle,
} from "components_refactor/ProfileImageDetail/styles";
import { StyledText, StyledTitle } from "./styles";

const ProfileImageDetailSimple = (props) => {
  const {
    collaborator,
    hasStatus,
    width,
    height,
  } = props;

  const getProfileImage = () => (hasPreviewImage(collaborator)
    ? <LoaderImageProfile collaborator={ collaborator } component={ Avatar } />
    : <Avatar src={ getAvatar(collaborator) } />);

  const jobPosition = collaborator?.job_position;
  const positionName = (jobPosition?.position_name || collaborator?.position_name || "").toLowerCase();

  return (
    <Box display={ ALIGN_ITEMS.flex }>
      <Box flexGrow={ 1 }>
        <StyledBoxContainer>
          <StyledImageContainer width={ width } height={ height }>
            {getProfileImage()}
            {hasStatus
              && (
                <StyledStatus isActive={ collaborator.is_active }>
                  {collaborator.is_active ? <Check /> : <Clear />}
                </StyledStatus>
              )}
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <StyledTitle>
              {collaborator.full_name.toLowerCase()}
            </StyledTitle>
            {positionName && (
              <StyledText>
                {positionName}
              </StyledText>
            ) }
          </StyledTitleWithSubtitle>
        </StyledBoxContainer>
      </Box>
    </Box>
  );
};

ProfileImageDetailSimple.propTypes = {
  collaborator: PropTypes.object.isRequired,
  hasStatus: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

ProfileImageDetailSimple.defaultProps = {
  hasStatus: false,
  width: 40,
  height: 40,
};

export default ProfileImageDetailSimple;
