import forEach from "lodash/forEach";
import includes from "lodash/includes";
import StarRating from "components/StarRating";
import {
  TYPES,
  FORMAT_TEXT,
  ROLES,
  DASHBOARD_REDUCER,
  NUMBER_OF_EVALUATIONS,
  OBJECT_KEYS,
  COMPANY_SLUGS,
  ORG_UNITS,
  INDEX,
} from "common/constants";
import {
  isEqual, isNull, isUndefined, isEmpty,
} from "common/helpers";
import {
  EVALUATION_TYPE,
} from "common/constants/surveyProcess";
import {
  getUserRoles,
  getPercent,
  findDataInScale,
  formatDate,
} from "./utils";

export const getMaxScale = (scale) => scale.sort((a, b) => b.top - a.top)[0]?.top;

export const removeDataEmpty = (data) => {
  data && forEach(Object.keys(data), (value) => {
    isEqual(typeof data[value], TYPES.string)
      ? (isEmpty(data[value]) || isEmptyDate(data[value], value))
        && delete data[value]
      : isEqual(typeof data[value], TYPES.object)
        && removeDataEmpty(data[value]);
  });
};

export const isEmptyDate = (selectedDate, value) => (
  (!includes(selectedDate, "-") && isEqual(value, OBJECT_KEYS.birthDate))
    || isNull(selectedDate)
);

// PeersEvaluation text
// FIXME: evaluation name by company
export const getPeersEvaluationText = (t) => {
  const companySlug = localStorage?.user && JSON.parse(localStorage.user).user?.company.slug;
  const evaluationName = companySlug && companySlug === COMPANY_SLUGS.onelink
    ? OBJECT_KEYS.human_resources : OBJECT_KEYS.pairs;
  return t(`surveys:evaluation_types.${evaluationName }`);
};

export const getEvaluationType = (type, t) => {
  let evaluationType = "";
  switch (type) {
  case EVALUATION_TYPE.AUTOEVALUATION:
    evaluationType = t("surveys:evaluation_types.self_evaluation");
    break;
  case EVALUATION_TYPE.MANAGER:
    evaluationType = t("surveys:evaluation_types.manager");
    break;
  case EVALUATION_TYPE.PAIRS:
    evaluationType = getPeersEvaluationText(t);
    break;
  case EVALUATION_TYPE.COLLABORATORS:
    evaluationType = t("surveys:evaluation_types.collaborators");
    break;
  case EVALUATION_TYPE.OVERALL:
    evaluationType = t("surveys:evaluation_types.overall");
    break;
  case EVALUATION_TYPE.INTERNAL_CLIENT:
    evaluationType = t("surveys:evaluation_types.internal_client");
    break;
  default:
    break;
  }
  return evaluationType;
};
/**
 * @deprecated Please don't use this method this method was deprecated
 */
export const getValidUser = (user) => (user);

export const isRolAdmin = (roles) => includes(getUserRoles(roles), ROLES.ADMIN) || includes(getUserRoles(roles), ROLES.ADMIN_NALA);

export const allEvaluations = (surveyResultList) => {
  const evsAns = [];
  if (surveyResultList) {
    for (let i = 0; i < surveyResultList.length; i += 1) {
      forEach(surveyResultList[i].results_by_evaluation_type, (evalType) => {
        if (!includes(evsAns, evalType.evaluation_type)) {
          evsAns.push(evalType.evaluation_type);
        }
      });
      if (isEqual(evsAns.length, NUMBER_OF_EVALUATIONS)) break;
    }
  }
  return evsAns;
};

export const getEvaluations = (surveyResultList, t) => {
  const aux = [];
  const evaluations = allEvaluations(surveyResultList);

  if (evaluations && evaluations.length > 0) {
    evaluations.forEach((evaluationType) => {
      aux.push({
        id: evaluationType,
        label: getEvaluationType(evaluationType, t),
        customRender: (rowData) => {
          const object = rowData.results_by_evaluation_type.find(
            ({ evaluation_type: evaluationTypeKey }) => evaluationTypeKey === evaluationType,
          );

          const calibratedResult = rowData?.calibrated && rowData?.calibratedResult
            ? rowData?.calibratedResult
            : object?.result;
          return object ? calibratedResult : null;
        },
        customData: (rowData, scale = []) => {
          const scaleMaxIndex = scale?.length;
          const maxScale = scale ? getPercent(getMaxScale(scale), true) : null;
          const object = rowData.results_by_evaluation_type.find(
            ({ evaluation_type: evaluationTypeKey }) => evaluationTypeKey === evaluationType,
          );

          const isValidToCalibration = EVALUATION_TYPE.MANAGER === evaluationType;
          const calibratedResult = isValidToCalibration && rowData?.calibrated
            ? rowData.calibration?.calibrated_score
            : object?.result;
          const resultIndex = scaleMaxIndex && calibratedResult
            ? findDataInScale(scale, calibratedResult, isValidToCalibration
              ? !rowData?.calibrated : true)
            : null;

          return object ? (
            <StarRating
              name={ `star-rating-${rowData.id}` }
              value={ resultIndex?.position }
              isReadOnly
              maxRating={ scaleMaxIndex }
              max={ scaleMaxIndex }
              precision={ 1 }
              hasNoFormattedValue
              label={ resultIndex?.result }
              tooltip={ object?.score && maxScale ? {
                score: getPercent(object.score, true, 2),
                maxScore: maxScale,
              } : null }
            />
          ) : null;
        },
      });
    });
  }

  return aux;
};

export const getDataToDownload = (
  surveyResultList,
  enablePercent,
  scale,
  t,
  type,
  goalsActive = false,
) => {
  const data = [];
  // search evaluations by survey results
  const evaluations = getEvaluations(surveyResultList, t);
  const maxScale = getMaxScale(scale);
  surveyResultList.forEach((userData, index) => {
    let performanceResult = null;
    const jobPosition = userData.job_position;
    if (userData?.employee) {
      performanceResult = userData.employee?.performance_results?.result;

      data.push(
        {
          [t("collaborators:table.table-head.personal_id")]: userData.employee?.personal_id,
          [t("collaborators:table.table-head.name")]: userData.employee?.full_name,
          [t("collaborators:table.excel.manager_name")]: jobPosition?.manager_name,
          [t("collaborators:table.table-head.position")]: jobPosition?.position_name,
          [t("collaborators:table.excel.country")]: jobPosition?.country?.name,
          [t("collaborators:table.excel.city")]: jobPosition?.city?.name,
        },
      );
    }
    if (evaluations) {
      // Create column by evaluation type
      evaluations.forEach((evaluation) => {
        const totalResult = userData.results_by_evaluation_type?.find(
          (item) => item.evaluation_type === evaluation.id,
        );
        data[index][evaluation.label] = totalResult?.result || "";
      });
    }

    if (goalsActive) {
      data[index][t("goals:excel_label")] = findDataInScale(scale, userData.goal_score, false)?.result || "";
    }
    // Total result
    // Calibration
    if (userData?.calibration?.id) {
      data[index][`${t(`tables:headers.${type}`)} ${t("common:calibration.general")}`] = findDataInScale(scale, userData.calibration?.previous_score, false)?.result || "";
      if (enablePercent) {
        data[index][`% ${t("common:calibration.general")}`] = `${getPercent(userData.calibration?.previous_score)}/${getPercent(maxScale)}`;
      }
      data[index][`${t(`tables:headers.${type}`)} ${t("common:calibration.calibrated")}`] = findDataInScale(scale, userData.calibration?.calibrated_score, false)?.result || "";
      if (enablePercent) {
        data[index][`% ${t("common:calibration.calibrated")}`] = `${getPercent(userData.calibration?.calibrated_score)}/${getPercent(maxScale)}`;
      }
      data[index][t("common:calibration.excel.last_update_at")] = userData.calibration?.last_updated_at ? formatDate(userData.calibration.last_updated_at) : "";
      data[index][t("common:calibration.excel.last_update_by")] = userData.calibration?.last_updated_by || "";
      data[index][t("common:calibration.excel.comments")] = userData.calibration?.comments || "";
      data[index][t("common:calibration.modal.reason")] = userData.calibration?.calibration_reason?.name || "";
    } else {
      data[index][`${t(`tables:headers.${type}`)} ${t("common:calibration.general")}`] = userData.result;
      if (enablePercent) {
        data[index][`% ${t("common:calibration.general")}`] = `${getPercent(userData.score)}/${getPercent(maxScale)}`;
      }
    }

    if (performanceResult && type !== "performance") {
      data[index][t("tables:headers.performance")] = performanceResult || "";
    }

    if (userData?.results_by_sections_questions) {
      // Create column by sections/question
      userData.results_by_sections_questions.forEach((sectionQuestion) => {
        sectionQuestion.questions.forEach((question) => {
          data[index][`${question.name} (${sectionQuestion.name})`] = question.result || "";
        });
        if (!sectionQuestion.open) {
          data[index][sectionQuestion.name] = sectionQuestion.result || "";
        }
      });
    }
  });

  return data;
};
export const isNullOrUndefined = (toValidate) => isNull(toValidate) || isUndefined(toValidate);

export const isNullOrEmpty = (toValidate) => isNull(toValidate) || isEmpty(toValidate);

export const isNullOrUndefinedOrEmpty = (toValidate) => isNullOrUndefined(toValidate) || isEmpty(toValidate);

export const isRolManager = (userCookies, roles = null) => includes(roles || getUserRoles(userCookies), ROLES.MANAGER);

export const isAdminOrManager = (userCookies, from) => isRolAdmin(userCookies) || (from && isRolManager(userCookies));

const getOrganizationUnits = (orgUnits) => {
  let department = "";
  let area = "";
  let subarea = "";

  if (orgUnits) {
    department = orgUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.division))?.name;
    area = orgUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.area))?.name;
    subarea = orgUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.subarea))?.name;
  }

  return { department, area, subarea };
};

export const evaluationTypeNames = (t) => ({
  [EVALUATION_TYPE.AUTOEVALUATION]: {
    submittedName: t("surveys:evaluation_types.self_evaluation"),
    receivedName: t("surveys:evaluation_types.self_evaluation"),
  },
  [EVALUATION_TYPE.MANAGER]: {
    submittedName: t("surveys:evaluations.manager.submitted"),
    receivedName: t("surveys:evaluations.manager.received"),
  },
  [EVALUATION_TYPE.PAIRS]: {
    submittedName: t("surveys:evaluations.pairs.submitted"),
    receivedName: t("surveys:evaluations.pairs.received"),
  },
  [EVALUATION_TYPE.COLLABORATORS]: {
    submittedName: t("surveys:evaluations.collaborators.submitted"),
    receivedName: t("surveys:evaluations.collaborators.received"),
  },
  [EVALUATION_TYPE.OVERALL]: {
    submittedName: t("surveys:evaluation_types.overall"),
    receivedName: t("surveys:evaluation_types.overall"),
  },
  [EVALUATION_TYPE.INTERNAL_CLIENT]: {
    submittedName: t("surveys:evaluations.internal_client.submitted"),
    receivedName: t("surveys:evaluations.internal_client.received"),
  },
});

const hasReceivedEvaluation = (
  evalType,
) => !isEqual(evalType.evaluation_type, EVALUATION_TYPE.AUTOEVALUATION)
&& !isEqual(evalType.evaluation_type, EVALUATION_TYPE.OVERALL);

// Results by employee
export const getResultByEmployeeToDownload = (surveyResultList, t) => {
  const participationData = [];
  const evaluationDetails = [];
  const completeEvaluationAnswers = [];
  // search evaluations by survey results
  const evaluations = surveyResultList?.employees[INDEX.zero]?.results;

  surveyResultList.employees.forEach((employee, index) => {
    const organizationUnits = getOrganizationUnits(employee?.organization_units);

    participationData.push(
      {
        [t("collaborators:table.table-head.personal_id")]: employee?.personal_id,
        [t("collaborators:table.table-head.name")]: employee?.full_name,
        [t("collaborators:table.table-head.email")]: employee?.email,
        [t("collaborators:table.excel.manager_name")]: employee?.manager_name,
        [t("collaborators:table.table-head.position")]: employee?.position_name,
        [t("collaborators:table.excel.department")]: organizationUnits?.department,
        [t("collaborators:table.excel.area")]: organizationUnits?.area,
        [t("collaborators:table.excel.subarea")]: organizationUnits?.subarea,
        [t("surveys:report")]: employee?.total_team,
        [t("surveys:evaluations_to_answers")]: employee?.total_to_evaluate,
      },
    );

    if (evaluations) {
      // Evaluations submitted
      evaluations.forEach((evaluation) => {
        const evaluationLabel = evaluationTypeNames(t)[evaluation.evaluation_type]?.submittedName;
        const totalResult = employee.results?.find(
          (item) => isEqual(
            item.evaluation_type,
            evaluation.evaluation_type,
          ),
        );
        participationData[index][evaluationLabel] = totalResult.forms_filled_submitted;
      });
      // Status
      participationData[index][t("tables:headers.state")] = t(`planning:processDetail.status.${employee?.status}`);
      // Evaluations received
      evaluations
        .filter(hasReceivedEvaluation)
        .forEach((evaluation) => {
          const evaluationType = evaluation?.evaluation_type;
          const evaluationLabelReceived = evaluationTypeNames(t)[evaluationType]?.receivedName;

          if (!evaluationLabelReceived) return;

          const totalResult = employee.results?.find(
            (item) => isEqual(item.evaluation_type, evaluationType),
          );

          if (totalResult?.forms_filled_received !== undefined) {
            participationData[index][evaluationLabelReceived] = totalResult.forms_filled_received;
          }
        });
    }
  });

  surveyResultList.results.forEach((result, index) => {
    const evaluator = result?.evaluation_details?.evaluator;
    const evaluated = result?.evaluation_details?.evaluated;
    const organizationUnitsByEvaluator = getOrganizationUnits(evaluator?.organization_units);

    evaluationDetails.push(
      {
        [t("surveys:evaluator_name")]: evaluator?.name,
        [t("surveys:evaluator_email")]: evaluator?.email,
        [t("collaborators:table.excel.department")]: organizationUnitsByEvaluator?.department,
        [t("collaborators:table.excel.area")]: organizationUnitsByEvaluator?.area,
        [t("collaborators:table.excel.subarea")]: organizationUnitsByEvaluator?.subarea,
        [t("surveys:evaluation_type")]: getEvaluationType(result.evaluation_type, t),
        [t("surveys:evaluated_name")]: evaluated?.name,
        [t("surveys:evaluated_email")]: evaluated?.email,
        [t("surveys:comments")]: result?.comment,
      },
    );
    if (!isEmpty(result?.open_questions)) {
      result.open_questions.forEach((question) => {
        evaluationDetails[index][`${question.name}`] = question.answer || "";
      });
    }
    evaluationDetails[index]["%"] = result.score ? getPercent(result.score) : "";
    evaluationDetails[index][t("tables:headers.status")] = result.result || "";
  });
  surveyResultList.results.forEach((result, index) => {
    const evaluator = result?.evaluation_details?.evaluator;
    const evaluated = result?.evaluation_details?.evaluated;
    const organizationUnitsByEvaluated = getOrganizationUnits(evaluated?.organization_units);
    if (surveyResultList?.process?.performance_process) {
      completeEvaluationAnswers.push(
        {
          [t("surveys:evaluator_name")]: evaluator?.name,
          [t("surveys:evaluator_email")]: evaluator?.email,
          [t("surveys:evaluated_name")]: evaluated?.name,
          [t("surveys:evaluated_email")]: evaluated?.email,
          [t("surveys:country")]: evaluated?.country,
          [t("collaborators:table.excel.department")]: organizationUnitsByEvaluated?.department,
          [t("collaborators:table.excel.area")]: organizationUnitsByEvaluated?.area,
          [t("collaborators:table.excel.subarea")]: organizationUnitsByEvaluated?.subarea,
          [t("surveys:position")]: evaluated?.position,
          [t("surveys:evaluation_type")]: getEvaluationType(result?.evaluation_type, t),
        },
      );
    } else if (surveyResultList?.process?.engagement_process) {
      completeEvaluationAnswers.push(
        {
          [t("collaborators:table.table-head.name")]: evaluated?.name,
          [t("collaborators:table.table-head.email")]: result?.evaluation_details?.evaluated_?.email,
          [t("collaborators:table.excel.manager_name")]: evaluated?.manager,
          [t("collaborators:table.table-head.position")]: evaluated?.position,
          [t("collaborators:table.table-head.country")]: evaluated?.country_name,
          [t("collaborators:table.excel.department")]: organizationUnitsByEvaluated?.department,
          [t("collaborators:table.excel.area")]: organizationUnitsByEvaluated?.area,
          [t("collaborators:table.excel.subarea")]: organizationUnitsByEvaluated?.subarea,
        },
      );
    }
    if (!isEmpty(result?.complete_answers && completeEvaluationAnswers)) {
      result.complete_answers.forEach((completeAnswer) => {
        completeEvaluationAnswers[index][`${t("surveys:comments")}: ${completeAnswer.section}`] = completeAnswer.comment;
        completeAnswer.answers.forEach((answer) => {
          completeEvaluationAnswers[index][`${answer.question}`] = answer.answer;
        });
      });
    }
  });

  const allData = [
    {
      name: t("planning:modal.title"),
      data: participationData,
    },
    {
      name: t("surveys:details"),
      data: evaluationDetails,
    },
    {
      name: t("surveys:complete_answers"),
      data: completeEvaluationAnswers,
    },
  ];

  return allData;
};
