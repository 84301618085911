import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "components/Button";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import AlertModal from "components/AlertModal";
import { getAvatar } from "common/utils";
import { orderByDesc } from "common/helpers";
import { useEvaluationScale } from "hooks/useEvaluationScale";
import { useEvaluationResult } from "hooks/useEvaluationResult";
import GeneralEvaluationResult from "../GeneralEvaluationResult";
import GoalList from "../GoalList";
import {
  StyledContainer,
  StyledMemberSection,
  StyledAvatar,
  StyledTeamSection,
  StyledStepButtons,
} from "../styles";

const TeamEvaluation = (props) => {
  const {
    t, membersWithGoals,
    isMobile, goalSection,
    handleToCoverOrHomeProcess,
    updateGoalProgress,
    evaluation, type,
    isCompletedGoal,
    isCompletedGeneral,
  } = props;
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const [members, setMembers] = useState(membersWithGoals);
  const [showGeneralResult, setShowGeneralResult] = useState(false);
  const [isUpdateData, setIsUpdateData] = useState(false);
  const [modal, setModal] = useState(false);

  const [updateData, setUpdateData] = useState(false);
  const currentMember = members[currentMemberIndex];
  const isLastMember = currentMemberIndex === members.length - 1;
  const isFirstMember = currentMemberIndex === 0;

  const evaluationScaleId = goalSection?.evaluation_scale_id;

  const { evaluationScale, isLoading, isError } = useEvaluationScale(evaluationScaleId);

  const handleModalSubmit = () => {
    setModal(!modal);
  };

  const {
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const scaleProcessIndices = resultScale?.result_scale_indices;

  let scaleIndices = null;

  if (evaluationScale && !isError) {
    scaleIndices = orderByDesc(evaluationScale.indices, "top");
  }

  const handleMemberClick = (index) => {
    setCurrentMemberIndex(index);
  };

  const updateGoalEvaluationsForMember = (memberId, updatedGoalEvaluations) => {
    const updatedMembers = members.map((member) => (member.id === memberId
      ? {
        ...member,
        evaluationResult: {
          ...member.evaluationResult,
          goal_evaluations: updatedGoalEvaluations,
        },
      }
      : member));
    setMembers(updatedMembers);
    setUpdateData(true);
    setIsUpdateData(true);
  };

  useEffect(() => {
    if (updateData) {
      updateGoalProgress(members);
      setUpdateData(false);
    }
    // eslint-disable-next-line
  }, [updateData]);

  const goToNextMember = () => {
    if (isLastMember) {
      setShowGeneralResult(true);
    } else {
      setCurrentMemberIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        toast(MESSAGE_TYPES.info, { message: t("surveys:goals.form.evaluated", { name: members[nextIndex].full_name }) });
        return nextIndex;
      });
    }
  };

  const goToPreviousMember = () => {
    if (!isFirstMember) {
      setCurrentMemberIndex((prevIndex) => {
        const prevMemberIndex = prevIndex - 1;
        toast(MESSAGE_TYPES.info, { message: t("surveys:goals.form.last_evaluated", { name: members[prevMemberIndex].full_name }) });
        return prevMemberIndex;
      });
    }
  };

  useEffect(() => {
    if (!isUpdateData) {
      setShowGeneralResult(isCompletedGeneral && isCompletedGoal);
    }
    // eslint-disable-next-line
  }, [isCompletedGeneral, isCompletedGoal]);

  const buttons = (
    <StyledStepButtons>
      <Button
        variant={ "outlined" }
        type={ "text" }
        onClick={ handleToCoverOrHomeProcess }
        typeStyle={ "outlined" }
      >
        {isMobile ? t("common:common.cancel") : t("surveys:continueLater")}
      </Button>
      <Button
        variant={ "outlined" }
        type={ "text" }
        onClick={ () => setShowGeneralResult(false) }
        typeStyle={ "outlined" }
      >
        {t("common:common.previous")}
      </Button>
      <Button
        disabled={ !(isCompletedGoal && isCompletedGeneral) }
        variant={ "contained" }
        color={ "primary" }
        typeStyle={ "submit" }
        onClick={ handleModalSubmit }
      >
        {t("common:common.submit")}
      </Button>
    </StyledStepButtons>
  );

  const { updateEvaluationResult } = useEvaluationResult(t);
  const { updateState } = updateEvaluationResult();

  const handleUpdateStates = async () => {
    const updates = membersWithGoals.map((member) => ({
      processId: evaluation.survey_process_id,
      evaluationResultId: member.evaluationResult.id,
      evaluationId: evaluation.id,
    }));

    await updates.reduce(async (prevPromise, update) => {
      await prevPromise;
      return updateState(update);
    }, Promise.resolve());
    const messageType = MESSAGE_TYPES.success;
    toast(messageType, {
      title: t(`alert.${messageType}.title`),
      message: t(`alert.${messageType}.message`),
    });
    handleModalSubmit();
    handleToCoverOrHomeProcess();
  };

  return showGeneralResult ? (
    <>
      <GeneralEvaluationResult
        members={ membersWithGoals.map(
          (member) => member.evaluationResult,
        ) }
        scaleIndices={ scaleProcessIndices }
        evaluation={ evaluation }
        t={ t }
        isMobile={ isMobile }
        type={ type }
      />
      {buttons}
      <AlertModal
        title={ t("common:common.modal_messages.sure_question") }
        text={ `${t("alert.message")} ${t("common:common.modal_messages.sure_text")}` }
        textDisagree={ t("common:common.modal_messages.no_cancel") }
        textAgree={ t("common:common.modal_messages.yes_confirm") }
        onClick={ handleUpdateStates }
        open={ modal }
        handleClose={ handleModalSubmit }
        // isLoading={ isLoadingProcess }
      />
    </>
  ) : (
    <>
      <StyledContainer>
        <Grid container alignItems={ "center" } spacing={ 2 }>
          {/* Evaluated Member Section */}
          <Grid item>
            <StyledMemberSection>
              <Typography variant={ "h6" } fontWeight={ "bold" }>
                {t("surveys:form.fields.evaluated")}
              </Typography>
              <StyledAvatar
                alt={ currentMember?.full_name }
                src={ getAvatar(
                  currentMember.evaluationResult?.evaluation_details
                    ?.evaluated,
                ) }
                active
              />
            </StyledMemberSection>
          </Grid>

          {/* Team Section */}
          {membersWithGoals.length > 1 && (
            <Grid item>
              <StyledTeamSection>
                <Typography variant={ "h6" }>
                  {t("surveys:form.fields.team")}
                </Typography>
                <Grid container spacing={ 1 } justifyContent={ "center" }>
                  {membersWithGoals.map((member, index) => (
                    <Grid item key={ member.id }>
                      <Tooltip title={ member.full_name } arrow>
                        <StyledAvatar
                          alt={ member.full_name }
                          src={ getAvatar(
                            member.evaluationResult?.evaluation_details
                              ?.evaluated,
                          ) }
                          active={ index === currentMemberIndex }
                          isCurrent={ index === currentMemberIndex }
                          onClick={ () => handleMemberClick(index) }
                        />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </StyledTeamSection>
            </Grid>
          )}
        </Grid>
      </StyledContainer>
      {!isLoading && currentMember?.id && (
        <GoalList
          currentMember={ currentMember }
          t={ t }
          isMobile={ isMobile }
          scaleIndices={ scaleIndices }
          handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
          updateGoalEvaluations={ (updatedGoalEvaluations) => updateGoalEvaluationsForMember(
            currentMember.id,
            updatedGoalEvaluations,
          ) }
          goToNextMember={ goToNextMember }
          goToPreviousMember={ goToPreviousMember }
          isFirstMember={ isFirstMember }
        />
      )}
    </>
  );
};

TeamEvaluation.propTypes = {
  evaluation: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  membersWithGoals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      evaluationResult: PropTypes.object,
    }),
  ),
  goalSection: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
  updateGoalProgress: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  isCompletedGoal: PropTypes.bool.isRequired,
  isCompletedGeneral: PropTypes.bool.isRequired,
};

TeamEvaluation.defaultProps = {
  membersWithGoals: [],
};

export default TeamEvaluation;
