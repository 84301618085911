import {
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Button from "components/Button";
import FAQs from "components_refactor/FAQs";
import { SessionContext } from "modules/session/context";
import NalaLogoBlue from "assets/images/top-bar-minimal/logo-white.svg";
import {
  LANGUAGES_NAMES,
} from "common/constants";
import { getAvatar } from "common/utils";
import ChangePasswordModal from "../ChangePasswordModal";
import {
  StyledAppBar,
  StyledLinkButton,
  StyledSelect,
  StyledProfileButton,
  StyledContainerMenuItem,
} from "./styles";

const Topbar = (props) => {
  const {
    actions: { signOut },
    state: { user },
  } = useContext(SessionContext);
  const { onSidebarOpen, ...rest } = props;
  const { t, i18n } = useTranslation("common");
  const [cookies, setCookie] = useCookies(["language"]);

  const getInitialLanguage = useCallback(() => {
    if (cookies.language) {
      return cookies.language;
    }
    if (user?.company?.language) {
      return user.company.language;
    }
    return "es";
  }, [cookies.language, user]);

  const [language, setLanguage] = useState(getInitialLanguage);
  const [openSelect, setopenSelect] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const [dataUser] = useState(user);

  useEffect(() => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  const handleChange = (event) => {
    const newLang = event.target.value;
    setCookie("language", newLang);
    setLanguage(newLang);
  };

  const handleClose = () => {
    setopenSelect(false);
  };

  const handleOpen = () => {
    setopenSelect(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosedProfile = () => {
    setAnchorEl(null);
  };

  const onCloseChangePasswordModal = () => {
    setChangePasswordModal(!changePasswordModal);
  };

  const expandIcon = anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <StyledAppBar>
      <AppBar { ...rest }>
        <Toolbar>
          <Box
            display={ {
              xs: "none",
              md: "block",
              lg: "none",
              xl: "none",
            } }
          >
            <IconButton onClick={ onSidebarOpen }>
              <MenuIcon />
            </IconButton>
          </Box>
          <Button>
            <StyledLinkButton to={ "/" }>
              <img
                alt={ "Logo Nala" }
                src={ NalaLogoBlue }
                width={ 72 }
                height={ 34 }
              />
            </StyledLinkButton>
          </Button>
          <div className={ "flexGrow" } />
          <FAQs />
          <Button onClick={ handleOpen } className={ "gTranslateIcon" }>
            <GTranslateIcon />
          </Button>
          <FormControl>
            <StyledSelect
              open={ openSelect }
              onClose={ handleClose }
              onOpen={ handleOpen }
              value={ language }
              onChange={ handleChange }
            >
              <MenuItem value={ "es" }>{LANGUAGES_NAMES.spanish}</MenuItem>
              <MenuItem value={ "en" }>{LANGUAGES_NAMES.english}</MenuItem>
              <MenuItem value={ "pt" }>{LANGUAGES_NAMES.portuguese}</MenuItem>
            </StyledSelect>
          </FormControl>
          <Box px={ 2 } />
          <Hidden smDown>
            <StyledProfileButton
              aria-controls={ "simple-menu" }
              aria-haspopup={ "true" }
              onClick={ handleClick }
            >
              {dataUser?.full_name || ""}
              {expandIcon}
              <Avatar
                alt={ dataUser?.full_name || "" }
                src={ getAvatar(dataUser, true) }
              />
            </StyledProfileButton>
            <Menu
              id={ "simple-menu" }
              anchorEl={ anchorEl }
              keepMounted
              open={ Boolean(anchorEl) }
              onClose={ handleClosedProfile }
            >
              <StyledContainerMenuItem>
                <Typography className={ "menuItemName" }>
                  {dataUser?.full_name}
                </Typography>
                <Typography className={ "menuItemCPF" }>
                  {dataUser?.personal_id}
                </Typography>
                <Divider />
                <MenuItem onClick={ onCloseChangePasswordModal }>
                  <VpnKeyIcon />
                  {t("top-nav-bar.change-password")}
                </MenuItem>
                <MenuItem onClick={ signOut }>
                  <ExitToAppIcon />
                  {t("top-nav-bar.logout")}
                </MenuItem>
              </StyledContainerMenuItem>
            </Menu>
          </Hidden>
          <Hidden mdUp>
            <IconButton onClick={ onSidebarOpen }>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      <ChangePasswordModal
        open={ changePasswordModal }
        onClose={ onCloseChangePasswordModal }
      />
    </StyledAppBar>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
