import { getListPaginated as getCollaboratorListPaginated, getOrchartList as getOrgchartCollaboratorList } from "redux/actions/collaboratorActions";
import { getList as getAttritionCollaboratorChart } from "redux/actions/collaborators/attritionActions";
import { getList as getAttritionChart } from "redux/actions/attritionActions";
import { getOverviewCards } from "redux/actions/nalaAnalytics/nalaAnalyticsActions";
import { ENABLE_STATES_COLLABORATOR_FILTER, FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { isManager } from "common/utils";

export const getFilterAction = (type) => {
  let action;
  switch (type) {
  case FILTER_ACTIONS_TYPES.collaborators:
    action = getCollaboratorListPaginated;
    break;
  case FILTER_ACTIONS_TYPES.attritionCollaborator:
    action = getAttritionCollaboratorChart;
    break;
  case FILTER_ACTIONS_TYPES.talentDrain:
    action = [
      { func: getAttritionChart, indexParams: [0, 1] },
      { func: getAttritionChart, indexParams: [0, 2] },
      { func: getAttritionChart, indexParams: [0, 3] },
      { func: getAttritionChart, indexParams: [0, 4] },
    ];
    break;
  case FILTER_ACTIONS_TYPES.orgChart:
    action = getOrgchartCollaboratorList;
    break;
  case FILTER_ACTIONS_TYPES.timeOff:
    /*  Note: filter actions for timeoff whould dispatch at the view,
        there is a few elements that we need to handle there like input
        date search and pagination
    */
    action = [];
    break;
  case FILTER_ACTIONS_TYPES.personalTimeOff:
    action = [];
    break;
  case FILTER_ACTIONS_TYPES.nalaAnalytics:
    action = [
      { func: getOverviewCards, indexParams: [0] },
      { func: getOverviewCards, indexParams: [1] },
      { func: getOverviewCards, indexParams: [2] },
    ];
    break;
  case FILTER_ACTIONS_TYPES.goals:
    action = null;
    break;
  case FILTER_ACTIONS_TYPES.noActions:
    // It is added as null because I don't need it to perform any action,
    // I just need to have access to the query generated through the filters.
    // TODO: validate if it is advisable to create a new filter component
    // that can handle React Query.
    action = null;
    break;
  default:
    break;
  }
  return action;
};

export const handleUpdatedValues = (defaultValues, filterType, values) => defaultValues[filterType]?.map((item) => values[filterType].find((element) => element === item.id));

export const enableFilter = (type) => ENABLE_STATES_COLLABORATOR_FILTER.includes(type);

/**
 * @param {string} type
 * @returns {boolean}
 * @description This function is used to validate if the filter type is a process type
 */
export const isRequiredAddManager = (type) => type !== FILTER_ACTIONS_TYPES.collaborators
&& type !== FILTER_ACTIONS_TYPES.orgChart;

/**
 * @param {object} user
 * @param {array} collaboratorsMainList
 * @param {string} type
 * @param {string} searchFilter
 * @returns {array}
 * @description This function is used to add the manager to the collaborators list
 * if the user is a manager and the filter type is a process type
 * and the search filter is empty
 * otherwise it returns the collaborators list
 */
export const addManagerToList = (user, collaboratorsMainList, type, searchFilter) => {
  const userIsManager = isManager(user?.userCookies);
  let newCollaboratorsMainList = [];
  let managerAdded = false;
  const actualUser = userIsManager
    ? [{ id: user?.employee?.id, full_name: user?.full_name }]
    : [];

  if (actualUser && collaboratorsMainList && isRequiredAddManager(type)) {
    newCollaboratorsMainList = collaboratorsMainList?.filter(
      (item) => item?.id !== user?.employee?.id,
    );
  }

  const shouldAddManager = userIsManager && isRequiredAddManager(type)
    && (searchFilter === null || searchFilter.trim() === "" || user?.full_name?.toLowerCase().includes(searchFilter.toLowerCase()));

  const customCollaboratorsMainList = shouldAddManager
    ? actualUser.concat(newCollaboratorsMainList)
    : collaboratorsMainList;

  managerAdded = shouldAddManager;

  return { customCollaboratorsMainList, managerAdded };
};
