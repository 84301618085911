import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ALIGN_ITEMS } from "common/constants";
import { ReactComponent as EditIcon } from "assets/images/icons/edit.svg";
import SectionContainer from "components_refactor/SectionContainer";
import ProfileImageDetail from "components_refactor/ProfileImageDetail";
import constants from "styledComponents/constants";
import { StyledButton, StyledNoData } from "views_refactor/Profile/styles";
import EditManager from "./components/EditManager";
import {
  StyledTitle,
  StyledManagerInfoContainer,
  StyledProfileImageDetail,
} from "./styles";

const LeaderInfo = (props) => {
  const {
    t, collaborator, managerStates, handleEditManager, isAdmin, isOwnProfile, hasAuthorization,
  } = props;

  const manager = collaborator?.manager ? (
    <StyledProfileImageDetail container alignItems={ ALIGN_ITEMS.center } >
      <ProfileImageDetail
        collaborator={ collaborator.manager }
        width={ 64 }
        height={ 64 }
        canViewProfile={ false }
      />
    </StyledProfileImageDetail>
  ) : (
    <StyledNoData>{t("profile:noData")}</StyledNoData>
  );

  const initialRender = managerStates.isEditable ? (
    <EditManager { ...props } />
  ) : manager;

  return (
    <SectionContainer>
      <Grid container>
        <Grid item xs={ 12 }>
          <StyledManagerInfoContainer>
            <StyledTitle weight={ constants.fontConstants.bold }>
              {isOwnProfile ? t("profile:yourLeader") : t("common:common.manager")}
            </StyledTitle>
            {isAdmin && hasAuthorization && (
              <StyledButton onClick={ () => handleEditManager() }>
                <EditIcon />
              </StyledButton>
            )}
          </StyledManagerInfoContainer>
        </Grid>
        <Grid item xs={ 12 }>
          { initialRender }
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

LeaderInfo.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  managerStates: PropTypes.object.isRequired,
  handleEditManager: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  hasAuthorization: PropTypes.bool.isRequired,
};

LeaderInfo.defaultProps = {
  collaborator: null,
};

export default LeaderInfo;
