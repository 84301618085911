import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import SkeletonLoader from "components/SkeletonLoader";
import { SKELETONS_NUMBER } from "common/constants";
import { isEqual } from "common/helpers";
import {
  StyledText, StyledGrid, StyledBox, StyledStatus, StyledNumber, StyledClearIcon, StyledEmail,
} from "./styles";

const EmployeeValidator = (props) => {
  const {
    collaborators,
    removeItem,
    isLoading,
    height,
  } = props;
  const { t } = useTranslation("audiences");
  const countByState = (attr, state) => collaborators.filter(
    (item) => isEqual(item[attr], state),
  ).length;
  const hasInactive = countByState("isActive", false);
  const doesntExist = countByState("noExist", true);

  const collaboratorsList = isLoading
    ? <SkeletonLoader isInline={ false } num={ SKELETONS_NUMBER.FOUR } />
    : collaborators.map((item, index) => (
      <StyledBox key={ `validate-email-${index}` }>
        <Box flexGrow={ 1 }>
          <StyledStatus isActive={ item.isActive }>
            {item.isActive ? <Check /> : <Clear />}
          </StyledStatus>
          <StyledEmail noExist={ item.noExist }>
            { item.email }
          </StyledEmail>
        </Box>
        <StyledClearIcon onClick={ () => removeItem(item.id) } />
      </StyledBox>
    ));

  return (
    <Grid container >
      <Grid item xs={ 12 }>
        <StyledText>
          <StyledNumber>{ countByState("isActive", true) }</StyledNumber>
          { ` ${t("validate.actives")}` }
          { hasInactive > 0 && (
            <>
              { ", " }
              <StyledNumber>{hasInactive}</StyledNumber>
              {` ${t("validate.inactives")}`}
            </>
          )}
          { doesntExist > 0 && (
            <>
              { ", " }
              <StyledNumber>{doesntExist}</StyledNumber>
              {` ${t("validate.noExist")}`}
            </>
          )}
        </StyledText>
      </Grid>
      <StyledGrid item xs={ 12 } height={ height }>
        { collaboratorsList }
      </StyledGrid>
    </Grid>
  );
};

EmployeeValidator.propTypes = {
  removeItem: PropTypes.func.isRequired,
  collaborators: PropTypes.array,
  isLoading: PropTypes.bool,
  height: PropTypes.string,
};

EmployeeValidator.defaultProps = {
  collaborators: [],
  isLoading: false,
  height: "360px",
};

export default EmployeeValidator;
