import {
  useEffect, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "components/Button";
import InputTag from "components/InputTag";
import InputCodeEditor from "components/InputCodeEditor";
import InputTextController from "components/InputTextController";
import {
  handleMessages, HTTP_STATUS_RESPONSE, MESSAGE_TYPES, toast,
} from "components/Toast/functions";
import { isEmpty, isEqual, isBlank } from "common/helpers";
import { getFormattedTags, mergeListsOfObjects, removeById } from "common/utils";
import {
  ALIGN_ITEMS,
  BUTTON_STYLE_TYPES, INPUT_TYPE, MAX_EMPLOYEES_QUERY, OBJECT_KEYS, SIZE,
} from "common/constants";
import { getCollaboratorByEmails, getListSearchPaginated } from "redux/actions/collaboratorActions";
import { getList as getAudiences, create as createAudience, resetStateProcess } from "redux/actions/audience/audienceActions";
import { getCollaboratorByParts } from "views/Collaborators/functions";
import EmployeeValidator from "../EmployeeValidator";
import {
  StyledDialogActions, StyledTitleBox, StyledTypography, StyledDialogContent,
} from "./styles";

const AudienceByCollaboratorModal = (props) => {
  const {
    isOpen,
    onClose,
  } = props;
  const { t } = useTranslation("audiences");
  const [searchFilter, setSearchFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [collaboratorsSelectedList, setCollaboratorsSelectedList] = useState([]);
  const [collaboratorsBySearchList, setCollaboratorsBySearchList] = useState([]);
  const [collaboratorsByEmailList, setCollaboratorsByEmailList] = useState([]);
  const {
    control, handleSubmit, register,
  } = useForm({
    defaultValues: {
      "audience.name": "",
      "audience.description": "",
    },
  });

  const {
    searchList: collaboratorList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    successProcessAudience,
    isLoadingProcessAudience,
  } = useSelector(({ audienceReducer }) => audienceReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
      },
    };
    dispatch(getListSearchPaginated(query));
  }, [searchFilter, dispatch]);

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  const handleTags = (tags) => {
    setInputTagsSelected([]);
    setCollaboratorsBySearchList(
      mergeListsOfObjects(collaboratorsBySearchList, tags),
    );
  };

  const removeItem = (id) => {
    const itemInSearchList = collaboratorsBySearchList.filter((item) => item.id === id);
    if (isEmpty(itemInSearchList)) {
      setCollaboratorsByEmailList(removeById(collaboratorsByEmailList, id));
    } else {
      setCollaboratorsBySearchList(removeById(collaboratorsBySearchList, id));
    }
  };

  const getEmployees = async (emails) => {
    let allResponse = [];
    if (emails.length > MAX_EMPLOYEES_QUERY) {
      allResponse = await getCollaboratorByParts(emails);
    } else {
      allResponse = await getCollaboratorByEmails(emails);
    }
    return allResponse;
  };

  const removeCollaboratorsList = () => {
    setCollaboratorsSelectedList([]);
    setCollaboratorsBySearchList([]);
    setCollaboratorsByEmailList([]);
  };

  const onChangeEditor = (newValue) => {
    const emails = [...new Set(newValue.split("\n").filter((e) => !isBlank(e)))];
    if (isEmpty(emails)) {
      removeCollaboratorsList();
    } else {
      setIsLoading(true);
      getEmployees(emails).then(async (allResponse) => {
        const orderByEmail = emails.map((email) => {
          const employee = allResponse?.find((item) => isEqual(item.email.trim(), email.trim()));
          if (employee) {
            return {
              id: employee.id,
              value: employee.full_name,
              label: employee.full_name,
              email: employee.email,
              isActive: employee.is_active,
            };
          }
          return { email, noExist: true };
        });
        setCollaboratorsByEmailList(orderByEmail);
      }).catch((error) => {
        toast(
          MESSAGE_TYPES.error,
          { title: t("common:common.api_responses.error.title") },
        );
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (collaboratorsByEmailList && !isEmpty(collaboratorsByEmailList)) {
      setIsLoading(false);
    }
  }, [collaboratorsByEmailList]);

  useEffect(() => {
    setCollaboratorsSelectedList(
      mergeListsOfObjects(collaboratorsBySearchList, collaboratorsByEmailList),
    );
  }, [collaboratorsBySearchList, collaboratorsByEmailList]);

  const onSubmit = async () => {
    const data = control.getValues();
    data.audience.employee_ids = collaboratorsSelectedList.map((item) => (item.id));
    dispatch(createAudience(data));
  };

  const handleOnClose = () => {
    removeCollaboratorsList();
    onClose();
  };

  useEffect(() => {
    if (successProcessAudience) {
      handleOnClose();
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      dispatch(getAudiences());
      dispatch(resetStateProcess());
    }
    // eslint-disable-next-line
  }, [successProcessAudience, onClose, dispatch, t]);

  const everyoneIsActive = !isEmpty(collaboratorsSelectedList) && isEqual(
    collaboratorsSelectedList.length,
    collaboratorsSelectedList.filter((item) => item.isActive).length,
  );

  return (
    <Dialog
      open={ isOpen }
      onClose={ handleOnClose }
      maxWidth={ "md" }
      fullWidth
    >
      <form onSubmit={ handleSubmit(onSubmit) }>
        <DialogTitle disableTypography>
          <Box display={ ALIGN_ITEMS.flex }>
            <StyledTitleBox flexGrow={ 1 }>
              { t("createAudience") }
            </StyledTitleBox>
            <Box>
              <IconButton onClick={ onClose }>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <StyledDialogContent>
          <StyledTypography>{ t("description") }</StyledTypography>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 6 }>
              <InputTextController
                type={ INPUT_TYPE.text }
                label={ t("fields.nameAudience") }
                control={ control }
                name={ "audience.name" }
                required
              />
              <Box mt={ 2 }>
                <InputTag
                  id={ "employee_ids" }
                  size={ SIZE.small }
                  itemsSelected={ inputTagsSelected }
                  placeholder={ t("common:common.searchCollaborators") }
                  register={ register }
                  onInputTextChange={ handleInputText }
                  onChange={ handleTags }
                  data={ getFormattedTags(collaboratorList, OBJECT_KEYS.fullname, true) }
                  withoutTags
                />
              </Box>
              <Box mt={ 2 }>
                <InputCodeEditor
                  label={ t("copyCollaborators") }
                  placeholder={ t("fields.email") }
                  onChange={ onChangeEditor }
                />
              </Box>
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <EmployeeValidator
                collaborators={ collaboratorsSelectedList }
                removeItem={ removeItem }
                isLoading={ isLoading }
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ handleOnClose }>
            { t("Onboarding:cancelButton") }
          </Button>
          <Button
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            type={ BUTTON_STYLE_TYPES.SUBMIT }
            disabled={ !everyoneIsActive }
            isLoading={ isLoadingProcessAudience }
          >
            { t("common:common.save") }
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

AudienceByCollaboratorModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AudienceByCollaboratorModal;
