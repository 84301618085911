import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isNumber from "lodash/isNumber";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import usePagination from "hooks/utils/usePagination";
import Filter from "components_refactor/Filter";
import DownloadExcelButton from "components/DownloadExcelButton";
import TablePaginationCollapse from "components/TablePaginationCollapse";
import SkeletonLoader from "components/SkeletonLoader";
import ParticipationCard from "components_refactor/ParticipationCard";
import IndividualAccordion from "components_refactor/IndividualAccordion";
import ModalInfo from "components/modals/ModalInfo";
import Modal from "components/Modal";
import SimpleAlert from "components/SimpleAlert";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import ActionCard from "components_refactor/ActionCard";
import AlertDialog from "components/AlertDialog";
import CalibrationModal from "components_refactor/CalibrationModal";
import { useDeleteCalibration } from "hooks/calibration";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import { downloadExcelFile } from "common/functions/downloadParticipation";
import { SessionContext } from "modules/session/context";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import {
  VARIANT,
  PAGINATION,
  ALIGN_ITEMS,
  EXCEL_FILES_NAME,
  OBJECT_KEYS,
  DEFAULT_SORTS,
  SKELETON_VARIANT,
  SKELETONS_NUMBER,
  INFO,
  STATE,
} from "common/constants";
import { MAX_PARTICIPATION_TO_DOWNLOAD, EVALUATION_TYPE } from "common/constants/surveyProcess";
import { getDataToDownload, getEvaluations } from "common/validators";
import {
  mainDownloadExcel, getSortingOrder, isAdmin, truncateString, isManager,
} from "common/utils";
import {
  getSurveyResults,
  getSurveyResultsToDownload,
  resetStateSurveyResultsToDownload,
  getSurveyProcessParticipation,
  sendParticipationToEmail,
  publishProcess,
} from "redux/actions/surveyProcessesActions";
import { getOne as getResultScaleById } from "redux/actions/resultScalesActions";
import { useCalibrationReasons } from "hooks/useCalibrationReasons";
import Evaluators from "views/Evaluators";
import { allCards } from "../../functions";
import {
  getHeader,
  getRowsDesktop,
  getInformationSkill,
} from "./functions";
import {
  StyledContainer,
  StyledContent,
  StyledFilterGridContainer,
  StyledGrid,
  StyledItem,
  StyledLegendTitle,
  StyledOptionsGrid,
  StyledGridCards,
  StyledGridButton,
  StyledGridParticipation,
} from "./styles";

const TableCollaborators = (props) => {
  const { processId, isMobile, isActive } = props;

  const { t } = useTranslation(["collaborators", "potential", "performance", "common"]);
  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [filterQuery, setFilterQuery] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [tableSort, setTableSort] = useState(DEFAULT_SORTS.performanceScore);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingParticipation, setIsDownloadingParticipation] = useState(false);
  const [total, setTotal] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isPending, setIsPending] = useState(null);

  const {
    list: surveyResultList,
    listTotal: surveyResultTotal,
    isLoadingList: surveyResultLoadingList,
    downloadList,
    isLoadingDownloadList,
  } = useSelector(({ surveyResultReducer }) => surveyResultReducer);

  const {
    oneProcess: surveyProcess,
    isLoadingOneProcess: isLoadingSurveyProcess,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const {
    isLoadingOne: isLoadingScale,
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const {
    state: { user },
  } = useContext(SessionContext);

  const isAdminUser = isAdmin(user?.userCookies);
  const isManagerUser = isManager(user?.userCookies);

  const dispatch = useDispatch();

  const evaluations = getEvaluations(surveyResultList, t);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (isDownloadingParticipation && !isLoadingDownloadList && downloadList) {
      const id = surveyProcess?.potential_process?.id;
      const evaluationsToShow = surveyProcess?.potential_process?.evaluations || [];
      downloadExcelFile(
        dispatch,
        id,
        filterQuery,
        t,
        setIsDownloading,
        evaluationsToShow,
        downloadList,
      );
      dispatch(resetStateSurveyResultsToDownload());
      setIsDownloadingParticipation(false);
      return;
    }

    if (downloadList && !isDownloadingParticipation) {
      const data = getDataToDownload(downloadList, false, resultScale?.result_scale_indices, t, "potential");
      mainDownloadExcel(data, EXCEL_FILES_NAME.potentialCollaborators);
      dispatch(resetStateSurveyResultsToDownload());
    }
    // eslint-disable-next-line
  }, [downloadList, t, dispatch]);

  const handleDownload = () => {
    dispatch(getSurveyResultsToDownload(processId, filterQuery, surveyResultTotal));
  };

  const handleSearch = (name = "") => {
    setSearchFilter(name);
  };

  useEffect(() => {
    if (filterQuery) {
      const query = filterQuery;
      query.q.employee_person_full_name_cont = searchFilter;
      query.q.s = tableSort;
      if (!surveyResultLoadingList && surveyResultList !== null) {
        dispatch(getSurveyResults(processId, false, PAGINATION.next, query));
        setPagination(PAGINATION.next);
      }
    }
  // Forced and Need it. Need to NOT re-render!
  // eslint-disable-next-line
  }, [searchFilter, tableSort])

  useEffect(() => {
    if (pagination && processId) {
      dispatch(getSurveyResults(processId, false, pagination, filterQuery));
    }
    // Forced and Need it. Need to NOT re-render!
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    if (filterQuery) {
      dispatch(getSurveyResults(processId, false, 1, filterQuery));
      dispatch(getSurveyProcessParticipation(processId, filterQuery));
    }
    // eslint-disable-next-line
  }, [filterQuery]);

  const handleExternalQuery = (query) => {
    const tempFilterQuery = query;
    tempFilterQuery.q.s = tableSort;
    tempFilterQuery.q.employee_person_full_name_cont = searchFilter;
    setFilterQuery(tempFilterQuery);
  };

  const handleSort = (columnName, direction) => {
    setTableSort(getSortingOrder(columnName, direction));
  };

  const handleDownloadExcel = async () => {
    setIsDownloading(true);
    setIsDownloadingParticipation(true);
    if (total > MAX_PARTICIPATION_TO_DOWNLOAD) {
      const sendParticipation = await dispatch(sendParticipationToEmail(processId, filterQuery));
      if (sendParticipation) {
        handleOpenModal();
      }
      setIsDownloadingParticipation(false);
      setIsDownloading(false);
    } else {
      dispatch(getSurveyResultsToDownload(processId, filterQuery, surveyResultTotal));
    }
  };

  useEffect(() => {
    const { meta } = surveyProcess || {};
    const maxParticipation = meta?.general_participation?.total_evaluators || 0;
    setTotal(maxParticipation);
  }, [surveyProcess]);

  const handleClose = () => {
    setIsOpen(false);
    setIsPending(null);
  };

  const handleOpen = () => {
    setIsOpen(true);
    setIsPending(true);
  };

  const onChangeOption = (value) => {
    setIsPending(value === 0);
  };

  // Calibration
  const [dialog, setDialog] = useState(false);
  const [published, setPublished] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handlePublishProcess = async () => {
    setIsLoading(true);
    const publish = await dispatch(publishProcess(processId));
    if (publish?.message) {
      toast(MESSAGE_TYPES.success, { message: publish.message });
      setPublished(false);
      setIsLoading(false);
      setDialog(false);
    }
  };

  const potentialProcess = surveyProcess?.potential_process;
  const hasCalibration = potentialProcess?.calibration_activated;
  const hasCalibrationActivated = hasCalibration
  && !potentialProcess?.published && published
  && potentialProcess?.state === STATE.finished;

  // Calibration modal
  const [modal, setModal] = useState(false);
  const [result, setResult] = useState({});

  // Calibration Reason
  const { calibrationReasons } = useCalibrationReasons();

  const handleOpenCalibrationModal = (resultSelected) => {
    setResult({});
    setModal(true);
    setResult(resultSelected);
  };

  const handleCloseCalibrationModal = () => {
    setModal(false);
    setResult({});
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [calibrationId, setCalibrationId] = useState(null);
  const { mutate: deleteCalibration } = useDeleteCalibration(t);

  const handleOpenRestoreModal = (id, resultSelected) => {
    setResult(resultSelected);
    setIsAlertOpen(true);
    setCalibrationId(id);
  };

  const handleRestore = () => {
    if (calibrationId) {
      deleteCalibration(calibrationId, {
        onSuccess: () => {
          result.calibration = null;
          const managerEvaluation = result.results_by_evaluation_type.find(
            (evalType) => evalType.evaluation_type === EVALUATION_TYPE.MANAGER,
          );
          if (managerEvaluation) {
            result.calibrated = false;
            result.calibratedResult = null;
          }
          setResult({});
          setIsAlertOpen(false);
        },
      });
    } else {
      setResult({});
      setIsAlertOpen(false);
    }
  };

  const alertInfo = <h4>{t(`surveys:calibration.publish.${potentialProcess?.closure_email ? "with_email" : "without_email"}`)}</h4>;

  const getParticipation = () => [
    {
      label: t("common:participation.pending"),
      value: 0,
      content: <Evaluators
        evaluation={ processId }
        filterQuery={ filterQuery }
        isPending={ isPending }
      />,
    },
    {
      label: t("common:participation.finished"),
      value: 1,
      content: <Evaluators
        evaluation={ processId }
        filterQuery={ filterQuery }
        isPending={ isPending }
      />,
    },
  ];

  const getCards = isLoadingSurveyProcess ? (
    <SkeletonLoader
      num={ SKELETONS_NUMBER.ONE }
      variant={ SKELETON_VARIANT.rectangular }
      height={ 150 }
      columnWidth={ isMobile ? 6 : 4 }
    />
  ) : (
    surveyProcess?.meta?.general_participation && surveyProcess?.potential_process?.name
    && allCards(surveyProcess.meta.general_participation).map((item, index) => (
      <Grid item xs={ 12 } sm={ 6 } md={ 4 } key={ `card-${index}` }>
        <IndividualAccordion
          title={ t("common:participation.title") }
          content={ (
            <ParticipationCard
              title={ t("common:participation.cardTitle") }
              participation={ item.participation }
              onClick={ handleOpen }
              isFullWidth
            />
          ) }
          isActive={ isActive && !hasCalibrationActivated }
        />
        <Modal
          isOpen={ isOpen }
          onClose={ handleClose }
        >
          <StyledScrollBar maxHeight={ "600px" }>
            <ParticipationCard
              title={ `${t("common:common.participation")} ${truncateString(surveyProcess.potential_process.name)}` }
              participation={ item.participation }
              isFullWidth
              isModal
            />
            <StyledGridParticipation container>
              <Grid item xs={ 12 }>
                <SimpleAlert
                  type={ INFO }
                  message={ t("common:participation.alert.info") }
                />
              </Grid>
              <Grid item xs={ 12 }>
                <SwitchSelectorContent
                  options={ getParticipation() }
                  hasTitle={ false }
                  onChangeOption={ onChangeOption }
                />
              </Grid>
            </StyledGridParticipation>
            {isAdminUser && (
              <StyledGridButton item xs={ 12 } md={ 3 } isModal>
                <DownloadExcelButton
                  onClick={ handleDownloadExcel }
                  disabled={ isDownloading || surveyResultLoadingList
                  || !surveyProcess || total === 0 }
                  isDownloadApart={ total > MAX_PARTICIPATION_TO_DOWNLOAD }
                >
                  { t("common:common.downloadAction") }
                </DownloadExcelButton>
                <ModalInfo
                  title={ t("common:common.modal_messages.send_data.title") }
                  text={ t("common:common.modal_messages.send_data.text", { email: user.email }) }
                  buttonText={ t("common:common.modal_messages.send_data.button") }
                  isOpen={ isModalOpen }
                  onClose={ handleCloseModal }
                />
              </StyledGridButton>
            )}
          </StyledScrollBar>
        </Modal>
      </Grid>
    ))
  );

  useEffect(() => {
    if (surveyProcess) {
      const resultScaleId = surveyProcess.potential_process?.result_scale_id;
      if (resultScaleId) {
        dispatch(getResultScaleById(resultScaleId));
      }
    }
    // eslint-disable-next-line
  }, [surveyProcess]);

  // First
  useEffect(() => {
    if (processId) {
      const query = {
        q: {
          employee_active_in: [true],
          active_in: [true],
        },
      };
      setFilterQuery(query);
    }
    // eslint-disable-next-line
  }, [processId]);

  return (
    <>
      {isNumber(processId) && (
        <StyledGrid container>
          <Grid item xs={ 12 } md={ 9 }>
            <Filter
              surveyProcessId={ processId }
              onApplyFilters={ handleExternalQuery }
              hasScore
            />
          </Grid>
          {isAdminUser && (
            <StyledGridButton item xs={ 12 } md={ 3 }>
              <DownloadExcelButton
                onClick={ handleDownloadExcel }
                disabled={ isDownloading || surveyResultLoadingList
                  || !surveyProcess || total === 0 }
                isDownloadApart={ total > MAX_PARTICIPATION_TO_DOWNLOAD }
              >
                { t("performance:dashboard.download_participation") }
              </DownloadExcelButton>
              <ModalInfo
                title={ t("common:common.modal_messages.send_data.title") }
                text={ t("common:common.modal_messages.send_data.text", { email: user.email }) }
                buttonText={ t("common:common.modal_messages.send_data.button") }
                isOpen={ isModalOpen }
                onClose={ handleCloseModal }
              />
            </StyledGridButton>
          )}
        </StyledGrid>
      )}
      <StyledGridCards container spacing={ 2 }>
        {getCards}
      </StyledGridCards>
      {(isAdminUser || isManagerUser)
        && hasCalibrationActivated
        && (
          <Grid item xs={ 12 } mt={ 1 }>
            <ActionCard
              title={ t("surveys:calibration.alert.title") }
              text={ t("surveys:calibration.alert.text") }
              actionName={ t("surveys:calibration.alert.actions.publish") }
              onClick={ isAdminUser ? () => setDialog(true) : null }
              extraInfo={ !isAdminUser ? t("surveys:calibration.alert.info") : null }
            // secondaryAction={ {
            //   title: t("surveys:calibration.alert.actions.calibrate"),
            //   handleOnClick: () => {},
            // } }
            />
            <AlertDialog
              isOpen={ dialog }
              onClose={ () => setDialog(false) }
              title={ t("surveys:calibration.publish.title") }
              message={ t("surveys:calibration.publish.text") }
              additionalInfo={ alertInfo }
              buttons={ {
                agree: t("surveys:calibration.publish.send"),
                disagree: t("common:common.cancel"),
                isLoading,
              } }
              onSubmit={ handlePublishProcess }
            />
          </Grid>
        )}
      <StyledContainer data-testid={ "collaborators-table-potential" }>
        <StyledFilterGridContainer container >
          <Grid item xs={ 12 }>
            <Typography variant={ VARIANT.h4 }>
              { `${t("potential:title")} - ${t("performance:dashboard.collaborators")}`}
            </Typography>
          </Grid>
        </StyledFilterGridContainer>
        <StyledOptionsGrid container spacing={ 2 } >
          <Grid item xs={ 12 }>
            { surveyResultList && surveyResultList?.length > 0 && (
              <Grid container >
                <Grid item xs={ 12 } md={ 3 }>
                  <StyledLegendTitle>{ `${t("potential:evaluationResult")}:`}</StyledLegendTitle>
                </Grid>
                <Grid item xs={ 12 } md={ 9 }>
                  <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                    {resultScale?.result_scale_indices.map((item) => (
                      <StyledItem key={ `item-${item.result}` }>
                        {getInformationSkill(item.result, item.nine_box_result, t, true)}
                      </StyledItem>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </StyledOptionsGrid>
        <StyledContent>
          {!processId
            ? (
              <TablePaginationCollapse
                header={ getHeader(evaluations.map((e) => e.id), t, hasCalibration) }
                list={ [] }
                getRows={ {
                  mobile: getRowsDesktop,
                  desktop: getRowsDesktop,
                } }
                rightOptions={ (
                  <DownloadExcelButton
                    onClick={ () => {} }
                    disabled
                  />
                ) }
              />
            )
            : (
              <TablePaginationCollapse
                header={ getHeader(evaluations.map((e) => e.id), t, hasCalibration) }
                list={ processId ? surveyResultList : [] }
                isLoading={ surveyResultLoadingList || isLoadingScale || !resultScale }
                getRows={ {
                  mobile: getRowsDesktop,
                  desktop: getRowsDesktop,
                } }
                extraParam={ {
                  evaluations,
                  processId,
                  resultScale,
                  hasCalibration,
                  handleOpenCalibrationModal,
                  hasCalibrationActivated,
                  handleOpenRestoreModal,
                } }
                handlePagination={ handlePagination }
                pagination={ pagination }
                total={ surveyResultTotal }
                rightOptions={ (
                  <DownloadExcelButton
                    onClick={ () => handleDownload() }
                    disabled={ isLoadingDownloadList || downloadList?.length === 0 || !processId }
                    isDownloadApart
                  />
                ) }
                handleSearch={ handleSearch }
                sortAction={ handleSort }
                defaultOrderName={ OBJECT_KEYS.performanceScore }
              />
            )}
        </StyledContent>
        <AlertDialog
          isOpen={ isAlertOpen }
          onClose={ () => setIsAlertOpen(false) }
          title={ t("common:calibration.confirm_restore") }
          onSubmit={ handleRestore }
        />
        {resultScale?.result_scale_indices && result?.id && calibrationReasons.length > 0
        && (
          <CalibrationModal
            isOpen={ modal }
            onClose={ handleCloseCalibrationModal }
            selectResult={ result }
            scale={ resultScale.result_scale_indices }
            type={ "potential" }
            isValidToUpdate={ hasCalibrationActivated }
          />
        )}
      </StyledContainer>
    </>
  );
};

TableCollaborators.propTypes = {
  processId: PropTypes.number,
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool,
};

TableCollaborators.defaultProps = {
  processId: null,
  isMobile: false,
  isActive: false,
};

export default TableCollaborators;
