import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormHelperText from "components_refactor/FormHelperText";
import InputTextController from "components/InputTextController";
import ManagerSelect from "components/commons/ManagerSelect";
import AutoCompleteCreable from "components/AutocompleteCreable";
import AlertDialog from "components/AlertDialog";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import {
  isUndefined,
  isEmpty,
  isEqual,
} from "common/helpers";
import {
  LOCAL_STORAGE_NAMES,
  ENTITY_OPTION,
  OBJECT_KEYS,
  TYPES,
} from "common/constants";
import {
  getItemFromLocalStorage,
  getDivisions,
  getAreas,
  getCitiesByCountry,
  getAutocompleteAttr,
  getElementNewValue,
} from "common/utils";
import { create as createPosition } from "redux/actions/position/positionActions";
import { create as createCity } from "redux/actions/common/cityActions";
import { create as createLevel } from "redux/actions/common/hierarchyLevelActions";
import { create as createEntity } from "redux/actions/common/legalEntityActions";
import { useCities } from "hooks/useCities";
import { useHierarchyLevel } from "hooks/useHierarchyLevel";
import { useLegalEntity } from "hooks/useLegalEntity";
import { usePosition } from "hooks/usePosition";

// TODO: refactor new architecture
const CandidateInformation = (props) => {
  const { t } = useTranslation("common");
  const {
    register,
    control,
    setValue,
    sendValidation,
    checkIsValid,
    validation,
    defaultValue,
    setDefaultValue,
  } = props;
  const countries = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries);
  const [countrySelected, setCountrySelected] = useState();
  const [areas, setAreas] = useState([]);
  const [areaSelected, setAreaSelected] = useState("");
  const [dialog, setDialog] = useState(false);
  const [cities, setCities] = useState([]);
  const [inputTextValue, setInputTextValue] = useState("");
  const [newCreation, setNewCreation] = useState();
  const dispatch = useDispatch();

  const organizationUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);
  const divisions = getDivisions(organizationUnits);
  const personAttributes = "candidate.person_attributes";
  const jobPositionAttributes = "candidate.job_position_attributes";

  // Cities
  const {
    cities: allCities, queryClient,
  } = useCities();

  // Hierarchy Level
  const {
    hierarchyLevels, isLoading: isLoadingHierarchyLevel,
  } = useHierarchyLevel();

  // LegalEntities
  const {
    legalEntities, isLoading: isLoadingLegalEntities,
  } = useLegalEntity();

  // Positions
  const {
    positions, isLoading: isLoadingPositions,
  } = usePosition();

  const onAutocompleteChangeOrgUnits = (nameOfAttr, orgUnit) => (
    prop,
    event,
    newValue,
  ) => {
    const orgUnitName = getAutocompleteAttr(newValue, nameOfAttr);

    if (isEqual(orgUnit, OBJECT_KEYS.division)) {
      setValue(
        "collaborator.job_position_attributes.division_name",
        getElementNewValue(newValue, orgUnitName),
      );
      setValue("collaborator.job_position_attributes.area_name", "");
      setAreas(getAreas(organizationUnits, newValue?.id));
    } else {
      setValue(
        "collaborator.job_position_attributes.area_name",
        getElementNewValue(newValue, orgUnitName),
      );
      setAreaSelected(getElementNewValue(newValue, orgUnitName));
    }
  };

  const onAutocompleteChange = () => (prop, event, newValue) => {
    if (isEqual(prop, ENTITY_OPTION.country)) {
      setCountrySelected(newValue?.id);
      setDefaultValue({
        ...defaultValue,
        [prop]: newValue,
        city: null,
      });
      if (allCities) {
        setCities(getCitiesByCountry(newValue?.id, allCities));
      }
    } else {
      setDefaultValue({
        ...defaultValue,
        [prop]: newValue,
      });
    }
  };

  const handleDialog = (option) => {
    setDialog(option);
  };

  const handleDialogSubmit = (text, prop) => {
    setInputTextValue(text);
    setNewCreation(prop);
    handleDialog(true);
  };

  const creations = {
    city: {
      list: cities,
      data: {
        country_id: countrySelected,
        name: inputTextValue,
      },
      create: createCity,
      queryName: "cities",
    },
    position: {
      data: {
        name: inputTextValue,
      },
      create: createPosition,
      queryName: "positions",
    },
    level: {
      data: {
        name: inputTextValue,
      },
      create: createLevel,
      queryName: "hierarchyLevels",
    },
    legalEntity: {
      data: {
        name: inputTextValue,
        code: inputTextValue,
      },
      create: createEntity,
      queryName: "legalEntities",
    },
  };

  const handleNew = async () => {
    const newItem = creations[newCreation];
    const createdElement = await dispatch(newItem.create(newItem.data));

    if (!isEmpty(createdElement)) {
      //  error handle
      if (createdElement.error) {
        toast(MESSAGE_TYPES.error, createdElement, t);
      } else {
        if (newItem?.list) {
          newItem.list.push(createdElement);
        }
        setDefaultValue({
          ...defaultValue,
          [newCreation]: createdElement,
        });

        if (newItem?.queryName) {
          queryClient.setQueryData(newItem.queryName, (old) => [...old, createdElement]);
        }
      }

      handleDialog(false);
    }
  };

  return (
    <>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } md={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.name") }
            control={ control }
            rules={ { required: true } }
            name={ `${personAttributes}.full_name` }
            required
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.text }
            label={ t("common.id") }
            control={ control }
            rules={ { required: true } }
            name={ `${personAttributes}.personal_id` }
            required
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <InputTextController
            type={ TYPES.email }
            label={ t("common.personal_mail") }
            control={ control }
            rules={ { required: true } }
            name={ `${personAttributes}.personal_email` }
            required
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <ManagerSelect name={ "candidate.manager_id" } control={ control } />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <AutoCompleteCreable
            id={ "position" }
            options={ positions }
            label={ t("common:common.position") }
            register={ register }
            value={ defaultValue.position && defaultValue.position?.name }
            onChange={ onAutocompleteChange() }
            name={ `${jobPositionAttributes}.position_id` }
            nameOfAttr={ "name" }
            addNew={ {
              handleNew: handleDialogSubmit,
              text: t("common.add_new_position"),
              prop: OBJECT_KEYS.position,
            } }
            isRequired
            loading={ isLoadingPositions }
            hasError={ !validation.position && sendValidation }
            checkIsValid={ checkIsValid }
          />
          {!validation.position && sendValidation && (
            <FormHelperText isError>{t("common.noEmpty")}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <AutoCompleteCreable
            id={ "division" }
            options={ divisions }
            label={ t("common.division") }
            register={ register }
            onChange={ onAutocompleteChangeOrgUnits(
              OBJECT_KEYS.name,
              OBJECT_KEYS.division,
            ) }
            name={ `${jobPositionAttributes}.division_name` }
            nameOfAttr={ OBJECT_KEYS.name }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <AutoCompleteCreable
            id={ "area" }
            options={ areas }
            label={ t("common.area") }
            register={ register }
            onChange={ onAutocompleteChangeOrgUnits(
              OBJECT_KEYS.name,
              OBJECT_KEYS.area,
            ) }
            value={ areaSelected }
            name={ `${jobPositionAttributes}.area_name` }
            nameOfAttr={ OBJECT_KEYS.name }
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <AutoCompleteCreable
            id={ "level" }
            options={ hierarchyLevels }
            label={ t("common:common.hierarchyLevel") }
            register={ register }
            value={ defaultValue.level && defaultValue.level?.name }
            onChange={ onAutocompleteChange() }
            name={ `${jobPositionAttributes}.hierarchy_level_id` }
            nameOfAttr={ OBJECT_KEYS.name }
            addNew={ {
              handleNew: handleDialogSubmit,
              text: t("common.add_new_level"),
              prop: OBJECT_KEYS.level,
            } }
            loading={ isLoadingHierarchyLevel }
          />
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <AutoCompleteCreable
            id={ "legalEntity" }
            options={ legalEntities }
            label={ t("common:common.legalEntity") }
            register={ register }
            value={ defaultValue.legalEntity && defaultValue.legalEntity?.name }
            onChange={ onAutocompleteChange() }
            name={ "candidate.legal_entity_id" }
            nameOfAttr={ OBJECT_KEYS.name }
            addNew={ {
              handleNew: handleDialogSubmit,
              text: t("common.add_new_legalEntity"),
              prop: OBJECT_KEYS.legalEntity,
            } }
            loading={ isLoadingLegalEntities }
          />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <AutoCompleteCreable
            id={ "country" }
            options={ countries }
            label={ t("common.country") }
            register={ register }
            value={ defaultValue.country && defaultValue.country?.name }
            onChange={ onAutocompleteChange() }
            name={ `${jobPositionAttributes}.country_id` }
            nameOfAttr={ OBJECT_KEYS.name }
            isRequired
            hasError={ !validation.country && sendValidation }
            checkIsValid={ checkIsValid }
          />
          {!validation.country && sendValidation && (
            <FormHelperText isError>{t("common.noEmpty")}</FormHelperText>
          )}
        </Grid>
        <Grid item xs={ 12 } sm={ 6 }>
          <AutoCompleteCreable
            id={ "city" }
            options={ cities }
            label={ t("common.city") }
            register={ register }
            value={ defaultValue.city && defaultValue.city?.name }
            onChange={ onAutocompleteChange() }
            name={ `${jobPositionAttributes}.city_id` }
            nameOfAttr={ OBJECT_KEYS.name }
            addNew={ {
              handleNew: handleDialogSubmit,
              text: t("common.add_new_city"),
              prop: OBJECT_KEYS.city,
            } }
            disabled={ isUndefined(countrySelected) }
          />
        </Grid>
      </Grid>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ `${t(`common.add_new_${newCreation}`)}: ${inputTextValue}` }
        message={ t("common.modal_messages.sure_text") }
        onSubmit={ handleNew }
        buttons={ {
          isLoading: false,
        } }
      />
    </>
  );
};

CandidateInformation.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  checkIsValid: PropTypes.func.isRequired,
  sendValidation: PropTypes.bool.isRequired,
  validation: PropTypes.object.isRequired,
  defaultValue: PropTypes.object.isRequired,
  setDefaultValue: PropTypes.func.isRequired,
};

export default CandidateInformation;
