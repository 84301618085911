import { useState, useContext } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import GroupIcon from "@mui/icons-material/Group";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import AlertDialog from "components/AlertDialog";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { SessionContext } from "modules/session/context";
import {
  deleteItem as deleteSurveyProcess,
  createOrUpdateAsync,
} from "redux/actions/surveyProcessesActions";
import {
  VARIANT,
  ROLES,
  STATE,
  SURVEY_PROCESS_TYPE,
} from "common/constants";
import { isAdmin, isValidRole } from "common/utils";
import { getTextByDays } from "../../functions";
import { StyledActions } from "./styles";

// TODO: Add participation
const Events = (props) => {
  const {
    data, handleItemSelected, t, dispatch,
    isMobile, handleOpenModal, handleChangeState,
  } = props;
  const [dialog, setDialog] = useState(false);
  const [stateDialog, setStateDialog] = useState(false);
  const { loadingProcess } = useSelector(
    ({ surveysReducer }) => surveysReducer,
  );
  const {
    state: { user },
  } = useContext(SessionContext);

  const processState = data?.process?.state;
  const { name } = data;
  const processType = data?.processType?.value;
  const processKey = data?.processType?.key;
  const isTest = data?.process?.is_test;

  const deleteProcess = () => {
    dispatch(deleteSurveyProcess(processType, data.id));
    setDialog(false);
  };

  const setEventToUpdate = () => {
    handleItemSelected(data);
  };

  const handleFinishProcess = async () => {
    const transition = {
      state_transition: STATE.finish,
    };
    const processData = {
      end_date: moment.utc(),
      type: processType,
    };
    const process = await dispatch(
      createOrUpdateAsync(processData, processType, data.id, t, transition),
    );
    if (process === true) {
      const message = t("planning:calendar.actions.finish.success");
      toast(
        MESSAGE_TYPES.info,
        handleMessages(MESSAGE_TYPES.info, HTTP_STATUS_RESPONSE.ok, t, message),
      );
      data.process.state = STATE.finished;
      handleChangeState(data);
      setStateDialog(false);
    }
  };

  return (
    <Grid container spacing={ 2 } data-testid={ "events-view-component" }>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          { `${t("surveys:form.fields.start_date")}:` }
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography variant={ VARIANT.h6 }>
          {data.defaultStartDate}
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          { `${t("surveys:form.fields.min_worked")}:` }
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography variant={ VARIANT.h6 }>
          { getTextByDays(data.minTime, t)}
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography>
          { `${t("planning:calendar.reminder")}:` }
        </Typography>
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography variant={ VARIANT.h6 }>
          { getTextByDays(data.reminderTime, t)}
        </Typography>
      </Grid>
      {/* actions */}
      {!isMobile
      && (isAdmin(user?.userCookies) || isValidRole(user?.userCookies, ROLES.TALENT_MANAGER))
      && (
        <StyledActions item xs={ 12 }>
          <Typography variant={ VARIANT.h6 }>
            { `${t("common:common.actions")}:`}
          </Typography>
          {(processKey !== SURVEY_PROCESS_TYPE.engagement.key)
            && (
              <Tooltip title={ t("participation:actions.previewParticipation") }>
                <IconButton onClick={ () => {
                  handleOpenModal("/planning", data.process.id, data.process);
                } }
                >
                  <GroupIcon />
                </IconButton>
              </Tooltip>
            )}
          {/* TODO: reopen and edit dates */}
          {(processState === STATE.running)
           && (
             <Tooltip title={ processState === STATE.finished ? "" : t("planning:calendar.actions.finish.title") }>
               <IconButton onClick={ () => setStateDialog(true) }>
                 {processState === STATE.finished ? <EventRepeatIcon /> : <EventBusyIcon />}
               </IconButton>
             </Tooltip>
           )}
          <Tooltip title={ t("common:common.edit") }>
            <IconButton onClick={ setEventToUpdate }>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {(processState === STATE.drafted || processState === STATE.programmed || isTest)
          && (
            <Tooltip title={ t("common:common.delete") }>
              <IconButton
                onClick={ () => setDialog(true) }
                disabled={ isValidRole(user?.userCookies, ROLES.TALENT_MANAGER) }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </StyledActions>
      )}
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("planning:alertMessage.title_delete") }
        message={ `${t("planning:alertMessage.text_delete")} - ${name}` }
        onSubmit={ deleteProcess }
        buttons={ { isLoading: loadingProcess } }
      />
      <AlertDialog
        isOpen={ stateDialog }
        onClose={ () => setStateDialog(false) }
        title={ t("planning:calendar.actions.finish.title") }
        message={ t("planning:calendar.actions.finish.text", { name }) }
        onSubmit={ handleFinishProcess }
        buttons={ { isLoading: loadingProcess } }
      />
    </Grid>
  );
};

Events.propTypes = {
  data: PropTypes.object.isRequired,
  handleItemSelected: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func.isRequired,
};

export default Events;
