import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import dataLoading from "assets/images/general/data_loading.svg";
import dataLoadingGreen from "assets/images/general/data_loading_green.svg";
import { StyledContainer, StyledMessage, StyledContent } from "./styles";

const NoDataResult = ({ title, message, color }) => {
  const { t } = useTranslation("common");

  const colors = {
    default: "#263238",
    green: "#0CB2A8",
  };

  const icon = {
    default: dataLoading,
    green: dataLoadingGreen,
  };

  return (
    <StyledContainer>
      <StyledContent>
        <Box
          display={ "flex" }
          justifyContent={ "center" }
        >
          <img
            src={ icon[color] }
            alt={ "No info to load" }
          />
        </Box>
        <StyledMessage color={ colors[color] } >
          <h4>{ title === "" ? t("surveys:not_published.title") : title }</h4>
          { message === "" ? t("surveys:not_published.message") : message }
        </StyledMessage>
      </StyledContent>
    </StyledContainer>
  );
};

NoDataResult.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  color: PropTypes.string,
};

NoDataResult.defaultProps = {
  title: "",
  message: "",
  color: "default",
};

export default NoDataResult;
