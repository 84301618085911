import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "components/Button";
import Alert from "components_refactor/Alert";
import { VARIANT, BUTTON_STYLE_TYPES } from "common/constants";
import useStyles from "./styles";

const AlertDialog = (props) => {
  const {
    isOpen, onClose, title, message,
    onSubmit, buttons, alertMessage,
    additionalInfo,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");
  return (
    <div data-testid={ "alert-dialog-component" }>
      <Dialog
        open={ isOpen }
        onClose={ onClose }
        className={ classes.root }
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {message}
            {alertMessage && (
              <Alert
                title={ alertMessage?.title }
                message={ alertMessage?.message }
                type={ "info" }
              />
            )}
            {additionalInfo
            && <Box pt={ 2 }>{ additionalInfo }</Box>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={ classes.buttonActionsContainer }>
            <Button
              onClick={ onClose }
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
              disabled={ buttons?.isLoading }
              cs={ classes.buttonActions }
            >
              {buttons?.disagree ?? t("common.modal_messages.no_cancel")}
            </Button>
            <Button
              onClick={ onSubmit }
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              disabled={ buttons?.isLoading }
              cs={ classes.buttonActions }
              autoFocus
            >
              {buttons?.agree ?? t("common.modal_messages.yes_confirm")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AlertDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  onSubmit: PropTypes.func,
  buttons: PropTypes.object,
  alertMessage: PropTypes.object,
  additionalInfo: PropTypes.node,
};

AlertDialog.defaultProps = {
  message: null,
  buttons: null,
  onSubmit: null,
  alertMessage: null,
  additionalInfo: null,
};

export default AlertDialog;
