import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "components/Modal";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import { OBJECT_KEYS, VARIANT } from "common/constants";
import FeedbackIcon from "assets/images/feedback/feedback.svg";
import {
  INDEX_TABS_SEND,
  TABS_SEND_FORM,
} from "views_refactor/Profile/functions";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import FeedbackStartStopContinue from "../FeedbackSStartStopContinue";
import FeedbackSimple from "../FeedbackSimple";
import { StyledGrid, StyledLabel } from "./styles";

const FeedbackFormModal = (props) => {
  const {
    t,
    collaborator,
    feedbackStates,
    hookFeedbackForm,
    collaboratorsList,
    handleValueChanged,
    handleInputText,
    onFeedbackSumit,
    onClose,
  } = props;

  const {
    isModalOpen, feedbackTab, typeSelected, hasEmployee,
  } = feedbackStates;
  const { control, handleSubmit } = hookFeedbackForm;

  const employeeRender = hasEmployee ? (
    <Typography variant={ VARIANT.h5 }>{collaborator?.name}</Typography>
  ) : (
    <AutoCompleteCreableController
      options={ collaboratorsList || [] }
      label={ t("feedback:select_collaborator") }
      control={ control }
      rules={ { required: true } }
      name={ `${typeSelected}.feedback_attributes.employee_to_id` }
      nameOfAttr={ OBJECT_KEYS.fullname }
      isWithHelperText={ false }
      isRequired
      onInputTextChange={ handleInputText }
    />
  );

  return (
    <div data-testid={ "feedback-modal-view" }>
      <Modal
        isOpen={ isModalOpen.addFeedback }
        onClose={ onClose }
        title={ t("feedback:title_send_feedback") }
        iconTitle={ FeedbackIcon }
      >
        <StyledScrollBar maxHeight={ "500px" } padding={ "10px" }>
          <StyledGrid>
            <form onSubmit={ handleSubmit(onFeedbackSumit) }>
              <Grid item xs={ 8 }>
                { employeeRender }
              </Grid>
              <Grid item xs={ 12 }>
                <StyledLabel variant={ VARIANT.bodyOne }>
                  {t("feedback:select_type_of_feedback")}
                </StyledLabel>
                <Tab
                  tabs={ TABS_SEND_FORM(t) }
                  onChange={ handleValueChanged }
                  tabValue={ feedbackTab }
                />
                <TabPanel
                  value={ feedbackTab }
                  index={ INDEX_TABS_SEND.simple }
                >
                  <FeedbackSimple { ...props } />
                </TabPanel>
                <TabPanel
                  value={ feedbackTab }
                  index={ INDEX_TABS_SEND.stop_start_continue }
                >
                  <FeedbackStartStopContinue { ...props } />
                </TabPanel>
                <TabPanel
                  value={ feedbackTab }
                  index={ INDEX_TABS_SEND.stop_start_continue }
                />
              </Grid>
            </form>
          </StyledGrid>
        </StyledScrollBar>
      </Modal>
    </div>
  );
};

FeedbackFormModal.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  feedbackStates: PropTypes.object.isRequired,
  hookFeedbackForm: PropTypes.object.isRequired,
  collaboratorsList: PropTypes.array,
  handleValueChanged: PropTypes.func.isRequired,
  handleInputText: PropTypes.func.isRequired,
  onFeedbackSumit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

FeedbackFormModal.defaultProps = {
  collaborator: null,
  collaboratorsList: null,
};

export default FeedbackFormModal;
