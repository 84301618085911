import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";

const NineBoxQuadrant = ({
  quad, isActive,
}) => (
  <>
    {/* quadrant number */}
    {/* <Typography
      variant={ "h3" }
      sx={ {
        color: "rgba(255,255,255,0.2)",
        position: "absolute",
        zIndex: 1,
        userSelect: "none",
        fontSize: 60,
      } }
    >
      {quad.number}
    </Typography> */}

    {/* quadrant name */}
    {isActive && (
      <Typography
        variant={ "h6" }
        sx={ {
          position: "absolute",
          color: "#fff",
          fontWeight: "bold",
          backgroundColor: "rgba(0,0,0,0.4)",
          padding: "4px 8px",
          borderRadius: "4px",
          zIndex: 2,
        } }
      >
        {`${quad.number}. ${quad.name}`}
      </Typography>
    )}
  </>
);

NineBoxQuadrant.propTypes = {
  quad: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    fillColor: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default NineBoxQuadrant;
