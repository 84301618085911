import { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { SessionContext } from "modules/session/context";
import Modal from "components/Modal";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import { StyledTabsContainer } from "styledComponents/Tabs";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import FeedbackIcon from "assets/images/feedback/feedback.svg";
import { getListSearchPaginated } from "redux/actions/collaboratorActions";
import { getList as getFeedbacksByEmployeeIdAndType, sendFeedback, resetStateProcess } from "redux/actions/feedbackActions";
import {
  FEEDBACK_TYPE,
  FORM_MODE, OBJECT_KEYS, VARIANT,
} from "common/constants";
import { isObject } from "common/helpers";
import { getEmployeeId, getValueByProp } from "common/utils";
import FeedbackStartStopContinue from "../FeedbackSStartStopContinue";
import FeedbackSimple from "../FeedbackSimple";
import {
  INDEX_TABS_SEND,
  TABS_SEND_FORM,
} from "../../functions";
import { useStyles } from "./styles";

const FeedbackFormModal = (props) => {
  const {
    companyId,
    employeeId,
    isOpen,
    onSend = () => {},
    onClose = () => {},
  } = props;
  const { t } = useTranslation("feedback");
  const [tabHandler, setTabHandler] = useState(0);
  const [searchFilter, setSearchFilter] = useState();
  const [typeSelected, setTypeSelected] = useState(FEEDBACK_TYPE.feedbackSimpleSN);
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    reValidateMode: FORM_MODE.onChange,
    mode: FORM_MODE.onChange,
  });

  const {
    state: { user },
  } = useContext(SessionContext);

  const {
    searchList: collaboratorsList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    successProcess: sendFeedbackData,
    isLoadingProcess: isLoadingSendFeedback,
    errorProcess,
  } = useSelector(({ feedbackReducer }) => feedbackReducer);

  const employee = employeeId;

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
        id_not_in: [employeeId],
      },
    };
    if (isObject(employeeId)) {
      query.q.id_not_in = [employeeId.id];
    }
    dispatch(getListSearchPaginated(query));
  }, [searchFilter, employeeId, dispatch]);

  const handleValueChanged = (value) => {
    setTabHandler(value);
    setTypeSelected(value === INDEX_TABS_SEND.simple ? FEEDBACK_TYPE.feedbackSimpleSN : FEEDBACK_TYPE.feedbackSSCSN);
  };

  const onSubmit = (formData) => {
    if (formData) {
      if (companyId) {
        formData[typeSelected].feedback_attributes.employee_from_id = employee;
        formData[typeSelected].feedback_attributes.employee_to_id = getValueByProp(
          formData[typeSelected].feedback_attributes.employee_to_id,
          OBJECT_KEYS.id,
        );
      } else {
        const fromId = getEmployeeId(user);
        formData[typeSelected].feedback_attributes = {
          employee_from_id: fromId,
        };
        formData[typeSelected].feedback_attributes.employee_to_id = employee?.id;
      }
      dispatch(sendFeedback(formData, typeSelected));
      setSearchFilter("");
    }
  };

  useEffect(() => {
    if (!isLoadingSendFeedback && sendFeedbackData && isOpen) {
      onSend(false);
      onClose();
      dispatch(resetStateProcess());
      dispatch(getFeedbacksByEmployeeIdAndType(companyId ? employee : employee?.id, true));
    } else if (!isLoadingSendFeedback && errorProcess && isOpen) {
      onSend(true);
    }
  }, [isLoadingSendFeedback, sendFeedbackData, onSend, onClose, dispatch, isOpen, companyId, employee, errorProcess]);

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  return (
    <div data-testid={ "feedback-modal-view" }>
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
        title={ t("title_send_feedback") }
        iconTitle={ FeedbackIcon }
      >
        <Grid className={ classes.root }>
          <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid item xs={ 8 }>
              {companyId ? (
                <AutoCompleteCreableController
                  options={ collaboratorsList || [] }
                  label={ t("select_collaborator") }
                  control={ control }
                  rules={ { required: true } }
                  name={ `${typeSelected}.feedback_attributes.employee_to_id` }
                  nameOfAttr={ OBJECT_KEYS.fullname }
                  isWithHelperText={ false }
                  isRequired
                  onInputTextChange={ handleInputText }
                />
              ) : (
                <Typography variant={ "h5" }>{employee?.name}</Typography>
              )}

            </Grid>
            <Grid item xs={ 12 } className={ classes.content }>
              <Typography
                variant={ VARIANT.bodyOne }
                className={ classes.labelType }
              >
                {t("select_type_of_feedback")}
              </Typography>
              <StyledTabsContainer
                spacing={ 4 }
                fontsize={ 12 }
                normaltext
                withbackground
              >
                <Tab
                  tabs={ TABS_SEND_FORM(t) }
                  onChange={ handleValueChanged }
                  cs={ classes.tabStyle }
                  tabValue={ tabHandler }
                />
                <TabPanel
                  value={ tabHandler }
                  index={ INDEX_TABS_SEND.simple }
                >
                  <FeedbackSimple
                    control={ control }
                    isSubmitDisabled={ !isValid }
                    isLoading={ isLoadingSendFeedback }
                  />
                </TabPanel>
                <TabPanel
                  value={ tabHandler }
                  index={ INDEX_TABS_SEND.stop_start_continue }
                >
                  <FeedbackStartStopContinue
                    control={ control }
                    isSubmitDisabled={ !isValid }
                    isLoading={ isLoadingSendFeedback }
                  />
                </TabPanel>
                <TabPanel
                  value={ tabHandler }
                  index={ INDEX_TABS_SEND.stop_start_continue }
                />
              </StyledTabsContainer>
            </Grid>
          </form>
        </Grid>
      </Modal>

    </div>
  );
};

FeedbackFormModal.propTypes = {
  employeeId: PropTypes.number,
  companyId: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSend: PropTypes.func,
};

export default FeedbackFormModal;
