import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";

export const StyledBoxContainer = styled.div`
  padding: 10px 20px 0 0;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: visible;
`;

export const StyledQuadrantBox = styled.div`
  width: 100%;
  position: relative;
  transition: all 0.4s ease-in-out;
  aspect-ratio: 1 / 1;
  overflow: visible;
`;

export const StyledExpandedQuadrant = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  overflow: visible;

  .avatar-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export const StyledCloseButton = styled(IconButton)`
  position: absolute !important;
  top: 10px;
  right: 10px;
  z-index: 12;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.3) !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
`;

export const StyledLabel = styled(Typography)`
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
  color: #858585;
  line-height: 16px;

  span {
    font-size: 10px;
    font-weight: 400;
  }

  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 12px;
    span {
      font-size: 8px;
    }
  }
`;

export const StyledAxisLabel = styled(Typography)`
  font-weight: 700;
  font-size: 28px;

  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const StyledLink = styled(Typography)`
  color: ${palette.primaryApp};
  font-size: 16px;
  font-weight: 500;
`;
