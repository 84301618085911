import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledImage = styled.img`
  width: 60px;
  align-items: center;
`;

export const StyledList = styled(Grid)`
  text-align: left;
  display: flex;
  align-items: center;
  gap: 8px;

  h4 {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  h4, svg {
    color: #00059E;
  }
`;
