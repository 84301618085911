import React, { useState } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const SelectSearch = (props) => {
  const {
    id,
    label,
    placeholder,
    onChange,
    data,
    disabled,
    onInputTextChange,
    isLoading,
    size,
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(null);

  const handleInputTextChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    onInputTextChange(newInputValue);
  };

  const resetValue = () => {
    setTimeout(() => {
      setInputValue("");
    }, 10);
  };

  const handleSelect = (event, newValue) => {
    setValue(null);
    onChange(newValue);
    resetValue();
  };

  return (
    <Autocomplete
      id={ id }
      options={ data || [] }
      getOptionLabel={ (option) => option.label || "" }
      value={ value }
      onChange={ handleSelect }
      inputValue={ inputValue }
      onInputChange={ handleInputTextChange }
      disabled={ disabled }
      isOptionEqualToValue={ (option) => option.id === value.id }
      size={ size }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          placeholder={ placeholder }
          variant={ "outlined" }
          InputProps={ {
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color={ "inherit" } size={ 20 } />}
                {params.InputProps.endAdornment}
              </>
            ),
          } }
        />
      ) }
    />
  );
};

SelectSearch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  onInputTextChange: PropTypes.func,
  isLoading: PropTypes.bool,
  size: PropTypes.string,
};

SelectSearch.defaultProps = {
  label: "",
  placeholder: "",
  disabled: false,
  onInputTextChange: () => {},
  isLoading: false,
  data: [],
  size: "",
};

export default SelectSearch;
