import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import PeopleIcon from "@mui/icons-material/People";
import SimpleLeadershipMatrix from "components/SimpleLeadershipMatrix";
import SkeletonLoader from "components/SkeletonLoader";
import SectionContainer from "components_refactor/SectionContainer";
import constants from "styledComponents/constants";
import { replacePath, getPercent } from "common/utils";
import { SKELETONS_NUMBER } from "common/constants";
import localRoutes from "../../functions/routes";
import { StyledCardTitle, StyledFixedContainer, StyledLink } from "../../styles";
import {
  StyledScore,
  StyledResult,
  StyledInfoTitle,
  StyledTeam,
  StyledTeamResult,
} from "./styles";

const LeadershipInfo = (props) => {
  const {
    t, history, collaborator, language,
  } = props;

  const leadershipDetail = collaborator?.leadership_details;
  const quadrantSelected = leadershipDetail
    && leadershipDetail?.leadership_type_result?.leadership_type?.slug;
  const leadershipScore = collaborator?.leadership_details?.leadership_score;
  const isValidToShowNps = leadershipScore?.nps_total_response >= 3;

  return (
    <SectionContainer>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <StyledCardTitle weight={ constants.fontConstants.bold }>
            {quadrantSelected ? t("common:common.report") : t("profile:goodLeader")}
          </StyledCardTitle>
          {quadrantSelected
            && (
              <Grid container justifyContent={ "center" } mt={ 2 } alignItems={ "center" }>
                <Grid item xs={ 8 } md={ 6 }>
                  <SimpleLeadershipMatrix
                    quadrantSelected={ quadrantSelected }
                  />
                </Grid>
                {leadershipScore?.score
                 && (
                   <Grid item xs={ 4 } md={ 6 } pl={ 2 }>
                     <StyledScore>{ getPercent(leadershipScore.score, true) }</StyledScore>
                     <StyledResult>{ leadershipDetail?.leadership_type_result?.leadership_type[`name_${language}`] }</StyledResult>
                   </Grid>
                 )}
              </Grid>
            )}
          {leadershipDetail
            ? (
              <Grid container justifyContent={ "center" } mt={ 2 } alignItems={ "center" }>
                <Grid item xs={ 12 }>
                  <StyledInfoTitle>{t("profile:leadership.team_in_charge")}</StyledInfoTitle>
                </Grid>
                {leadershipDetail?.active_children_count >= 0
                && (
                  <Grid item xs={ 12 }>
                    <StyledTeam>
                      <StyledInfoTitle>{ leadershipDetail.active_children_count }</StyledInfoTitle>
                      <PeopleIcon />
                    </StyledTeam>
                  </Grid>
                )}
                {leadershipScore !== null
                && (
                  <>
                    <StyledTeamResult item xs={ 6 } md={ isValidToShowNps ? 4 : 6 }>
                      <StyledInfoTitle>{t("profile:leadership.results.performance")}</StyledInfoTitle>
                      <p>{getPercent(leadershipScore?.children_performance, true)}</p>
                    </StyledTeamResult>
                    {isValidToShowNps
                    && (
                      <StyledTeamResult item xs={ 6 } md={ 3 }>
                        <StyledInfoTitle>{t("profile:leadership.results.enps")}</StyledInfoTitle>
                        <p>{getPercent(leadershipScore.children_nps)}</p>
                      </StyledTeamResult>
                    )}
                    <StyledTeamResult item xs={ 12 } md={ isValidToShowNps ? 5 : 6 }>
                      <StyledInfoTitle>{t("profile:leadership.results.attrition")}</StyledInfoTitle>
                      <p>{getPercent(leadershipScore?.children_turnover, true)}</p>
                    </StyledTeamResult>
                  </>
                )}
              </Grid>
            ) : <SkeletonLoader num={ SKELETONS_NUMBER.TWO } />}
          <StyledFixedContainer>
            <StyledLink onClick={ () => replacePath(history, localRoutes.goodLeader) }>
              { t("common:common.viewMore") }
            </StyledLink>
          </StyledFixedContainer>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

LeadershipInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  collaborator: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
};

export default LeadershipInfo;
