import styled from "styled-components";
import theme from "theme/palette";
import { ALIGN_ITEMS } from "common/constants";
import constants from "styledComponents/constants";

export const StyledRecommendationTitle = styled.p`
  color: ${theme.text.secondaryText};
  font-size: ${constants.fontSize.standar};
  font-weight: ${constants.fontWeight.bold};
  display: ${ALIGN_ITEMS.flex};
  align-items: ${ALIGN_ITEMS.center};
  svg {
    margin-right: 10px;
  }
`;

export const StyledRecommendationDescription = styled.p`
  font-size: ${constants.fontSize.standar};
  font-weight: ${constants.fontWeight.normal};
  color: ${theme.text.secondaryText};
  margin-top: 15px;
  white-space: pre-wrap;
`;

export const StyledRecommendationContainer = styled.div`
  padding-bottom: 60px;
`;
