import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import Select from "components/Select";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { isEmpty } from "common/helpers";
import { getList as getPotentialProcess } from "redux/actions/potential/potentialActions";
import TableCollaborators from "./components/TableCollaborators";
import { StyledDashboardContainer } from "./styles";

const Potential = (props) => {
  const { dispatch } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const { t, i18n } = useTranslation(["performance", "common", "potential"]);
  const { language } = i18n;

  const {
    list: potentialProcessesList,
    isLoadingList: isLoadingPotentialProcessesList,
  } = useSelector(({ potentialReducer }) => potentialReducer);

  const [values, setValues] = useState({
    evaluation: "",
  });

  const getEvaluations = useCallback(() => {
    let evaluations = [];
    if (!isLoadingPotentialProcessesList && potentialProcessesList) {
      const evaluationsWithoutFollow = potentialProcessesList
        ?.filter((item) => item.has_results && !item.follow_up_process_id)
        .sort((a, b) => Date(a.end_date) - Date(b.end_date))
        .map((item) => ({
          value: item.id,
          label: item[`name_${language}`],
        }));
      const evaluationsWithFollow = potentialProcessesList
        ?.filter((item) => item.has_results && item.follow_up_process_id)
        .sort((a, b) => Date(a.end_date) - Date(b.end_date))
        .map((item) => ({
          value: item.id,
          label: item.name,
        }));
      evaluations = evaluationsWithoutFollow.concat(evaluationsWithFollow);
    }

    return evaluations;
  }, [potentialProcessesList, isLoadingPotentialProcessesList, language]);

  useEffect(() => {
    const EVAL = getEvaluations();
    if (!isEmpty(potentialProcessesList) && EVAL[0]?.value) {
      setValues({ evaluation: EVAL[0]?.value });
    }
  }, [potentialProcessesList, getEvaluations, dispatch]);

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  useEffect(() => {
    if (!potentialProcessesList) {
      dispatch(getPotentialProcess());
    }
  }, [dispatch, potentialProcessesList]);

  return (
    <StyledDashboardContainer data-testid={ "potentialView" }>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } sm={ 6 } md={ 8 } lg={ 9 }>
          <ViewHeaderTitle title={ t("common:sidebar.potential") } />
        </Grid>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
          <Select
            id={ "evaluation" }
            label={ t("performance:dashboard.filter_evaluation") }
            menuItems={ getEvaluations() }
            value={ values.evaluation }
            onChange={ handleChange }
            className={ "custom-select" }
            disabled={ isLoadingPotentialProcessesList || isEmpty(potentialProcessesList) }
          />
        </Grid>
      </Grid>
      <TableCollaborators
        processId={ values.evaluation }
        isMobile={ isMobile }
        isActive={ getEvaluations()[0]?.value === values.evaluation }
      />
    </StyledDashboardContainer>
  );
};

Potential.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default Potential;
