import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BounceLoader from "components/BounceLoader";
import { StyledTitle } from "styledComponents/View";

const ExpiredToken = () => {
  const { t } = useTranslation("common");
  const [isRedirect, setIsRedirect] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsRedirect(true);
    }, 2000);
  }, []);

  if (isRedirect) {
    return <Redirect to={ "/sign-in" } />;
  }
  return (
    <>
      <BounceLoader align={ "centered" } />
      <StyledTitle size={ 20 } textAlign={ "center" }>
        {t("sign-in.beenRedirect")}
      </StyledTitle>
    </>
  );
};
export default ExpiredToken;
