import Grid from "@mui/material/Grid";
import {
  CALENDAR_MODULE,
  TOP_ACKNOWLEDGEMENTS_MODULE,
} from "partial_refactor/ModuleManager/container.constants";
import ContainerManager from "partial_refactor/ModuleManager/ContainerManager";
import { StyledContainer, StyledTypographyTitle } from "./styles";

const HomeView = (props) => {
  const { t } = props;

  return (
    <StyledContainer>
      <StyledTypographyTitle>{t("home:title")}</StyledTypographyTitle>
      <Grid container spacing={ 3 }>
        <Grid item xs={ 12 } md={ 5 }>
          <ContainerManager
            containerName={ "HOME_PAGE" }
            modules={ [CALENDAR_MODULE, TOP_ACKNOWLEDGEMENTS_MODULE] }
            moduleToShow={ [CALENDAR_MODULE] }
            dispatchs={ props.dispatchs }
            extraAttributes={ { ...props } }
            isLoading={ props.surveysReducerStates.loadingList }
          />
        </Grid>
        <Grid item xs={ 12 } md={ 7 }>
          {/* <ContainerManager
            containerName="HOME_PAGE"
            modules={[TOP_ACKNOWLEDGEMENTS_MODULE]}
            extraAttributes={{ ...props }}
            /> */}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default HomeView;
