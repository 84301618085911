import PropTypes from "prop-types";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { INPUT_TYPE } from "common/constants";
import FormHelperText from "components_refactor/FormHelperText";
import InputCheckbox from "components/InputCheckbox";
import InputFile from "components/InputFile";
import PasswordInput from "components/PasswordInputController";
import InputTextController from "components/InputTextController";
import DateInputController from "components/DateInputController";
import InputNumberController from "components/InputNumberController";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import SelectController from "components/SelectController";
import CurrencyInputTextController from "components/CurrencyInputTextController";

const InputForm = (props) => {
  const {
    disabled, error, type, displayType, isRequired, className,
  } = props;

  const createInput = () => {
    switch (type) {
    case INPUT_TYPE.select:
      return <SelectController { ...props } />;

    case INPUT_TYPE.password:
      return <PasswordInput { ...props } />;

    case INPUT_TYPE.text:
      return <InputTextController { ...props } isError={ error } />;

    case INPUT_TYPE.email:
      return <InputTextController { ...props } isError={ error } />;

    case INPUT_TYPE.checkbox:
      return <InputCheckbox { ...props } />;

    case INPUT_TYPE.file:
      return <InputFile { ...props } />;

    case INPUT_TYPE.date:
      return <DateInputController { ...props } />;

    case INPUT_TYPE.number:
      return <InputNumberController { ...props } />;

    case INPUT_TYPE.currency:
      return <CurrencyInputTextController { ...props } />;

    case INPUT_TYPE.autocomplete:
      return <AutoCompleteCreableController { ...props } disabled={ disabled } />;

    default:
      return "";
      // TODO: implement this later
      // return (
      //   <InputSimpleText {...props} />
      // );
    }
  };

  if (displayType) {
    return createInput();
  }

  return (
    <FormGroup data-testid={ "input-form-component" } className={ className }>
      <FormControl>{createInput()}</FormControl>
      {!disabled && isRequired && !error && <FormHelperText />}
      {error && <FormHelperText isError>{error}</FormHelperText>}
    </FormGroup>
  );
};

InputForm.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  displayType: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
};

InputForm.defaultProps = {
  disabled: false,
  error: "",
  type: null,
  displayType: null,
  isRequired: false,
  className: null,
};

export default InputForm;
