import { useQuery } from "react-query";
import {
  getOrganizationUnits,
  getManagers,
  getCountries,
  getCities,
  getStates,
} from "redux/actions/filtersApi";
import { toast } from "components/Toast/functions";
import { handleGenericError } from "common/handleGenericError";

const handleQueryError = (error, t) => {
  const { title, message, type } = handleGenericError(error, t);
  toast(type, { title, message });
};

export const useOrganizationUnits = (params, t) => useQuery(["organizationUnits", params], getOrganizationUnits, {
  staleTime: Infinity,
  cacheTime: 1000 * 60 * 5,
  onError: (error) => handleQueryError(error, t),
});

export const useManagers = (params, t) => useQuery(["managers", params], getManagers, {
  staleTime: Infinity,
  cacheTime: 1000 * 60 * 5,
  onError: (error) => handleQueryError(error, t),
});

export const useCountries = (params, t) => useQuery(["countries", params], getCountries, {
  staleTime: Infinity,
  cacheTime: 1000 * 60 * 5,
  onError: (error) => handleQueryError(error, t),
});

export const useCities = (params, t) => useQuery(["cities", params], getCities, {
  staleTime: Infinity,
  cacheTime: 1000 * 60 * 5,
  onError: (error) => handleQueryError(error, t),
});

export const useStates = (params, t) => useQuery(["states", params], getStates, {
  staleTime: Infinity,
  cacheTime: 1000 * 60 * 5,
  onError: (error) => handleQueryError(error, t),
});
