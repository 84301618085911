import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import { update as updateItem, create as createItem } from "redux/actions/sections/sectionItemActions";
import {
  BUTTON_STYLE_TYPES,
  INDEX,
  TYPES,
  VARIANT,
  ACCEPTED_SECTION_ITEMS_FORMATS,
  COMPONENT,
  TARGET,
  MAX_FILE_WEIGHT,
  FILE_TYPE,
  OBJECT_KEYS,
  INPUT_TYPE,
} from "common/constants";
import { isEqual } from "common/helpers";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import { StyledIconButton } from "views/Onboarding/components/DocumentsList/styles";
import { resetFormData } from "../functions";
import {
  StyledTypographyItem,
  StyledUpload,
  StyledItemDetail,
  StyledDialogItemActions,
  StyledUploadFileContainer,
  StyledButtonFile,
} from "../styles";

const FormItem = (props) => {
  const {
    data,
    onClose,
    setNewSectionItem,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(["templates", "tables", "common"]);
  const [file, setFile] = useState();

  const {
    control, reset, watch,
  } = useForm();

  useEffect(() => {
    if (data) {
      const editData = resetFormData(data);
      setFile({ name: "", url: "" });
      if (data.file_url && !data.url) {
        setFile(
          {
            name: data?.filename,
            url: data?.file_url,
            type: data?.file_type,
          },
        );
      }
      if (editData) reset(editData);
    }
    // eslint-disable-next-line
  }, [data, reset]);

  const onSubmit = async () => {
    const formData = new FormData();
    const itemData = control.getValues();
    if (file?.name && !isEqual(data?.file_url, file?.url)) {
      formData.append("section_item[file]", file);
    }

    formData.append("section_item[section_id]", data.section_id);
    formData.append("section_item[name]", itemData.name);
    formData.append("section_item[description]", itemData.description);
    formData.append("section_item[url]", itemData.url);

    const newItem = await dispatch(data?.id ? updateItem(data.id, formData) : createItem(formData));
    if (newItem) {
      setNewSectionItem(newItem);
    }
  };

  const handleUpload = (event) => {
    const fileData = event.target?.files[INDEX.zero];
    const itemData = control.getValues();
    fileData.url = URL.createObjectURL(fileData);
    itemData.url = "";
    if (fileData.size > MAX_FILE_WEIGHT) {
      setFile(null);
      return toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.exceedSizeLimit"),
      });
    }
    setFile(fileData);
  };

  return (
    <form data-testid={ "form-item-view-component" }>
      <StyledItemDetail>
        <Grid container spacing={ 3 }>
          <Grid item xs={ 12 }>
            <StyledTypographyItem variant={ VARIANT.h6 } >{ t("sections.form.title") }</StyledTypographyItem>
            <InputTextController
              type={ TYPES.text }
              label={ t("template_title.label") }
              control={ control }
              rules={ { required: true } }
              name={ OBJECT_KEYS.name }
              required
            />
          </Grid>
          <Grid item xs={ 12 }>
            <InputTextController
              type={ TYPES.text }
              label={ t("tables:headers.description") }
              control={ control }
              name={ OBJECT_KEYS.description }
              multiline
            />
          </Grid>
          <Grid item xs={ 12 }>
            <StyledUpload>
              <Box pr={ INDEX.one }>
                <img alt={ t("sections.form.upload_file") } src={ uploadImg } />
              </Box>
              <Box flexGrow={ INDEX.one }>
                <Typography variant={ VARIANT.h6 }>
                  {t("sections.form.upload_file_title")}
                </Typography>
                <Typography variant={ VARIANT.bodyTwo }>
                  {t("sections.form.upload_file_description")}
                </Typography>
              </Box>
            </StyledUpload>
            <InputTextController
              type={ TYPES.text }
              label={ t("sections.form.url") }
              control={ control }
              name={ OBJECT_KEYS.url }
              disabled={ file?.name }
            />
            <StyledUploadFileContainer>
              <StyledButtonFile
                variant={ VARIANT.outlined }
                typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                component={ COMPONENT.label }
                disabled={ watch(OBJECT_KEYS.url) }
              >
                {t("sections.form.upload_file")}
                <input
                  accept={ ACCEPTED_SECTION_ITEMS_FORMATS }
                  type={ INPUT_TYPE.file }
                  onChange={ handleUpload }
                />
              </StyledButtonFile>
              {file?.name
              && (
                <>
                  <Box>
                    <Typography
                      variant={ VARIANT.bodyTwo }
                      component={ COMPONENT.span }
                    >
                      <Link href={ file?.url } target={ TARGET.blank }>
                        {file?.name}
                      </Link>
                    </Typography>
                  </Box>
                  <Box>
                    <StyledIconButton
                      onClick={ () => setFile(null) }
                    >
                      <DeleteIcon />
                    </StyledIconButton>
                  </Box>
                </>
              )}
            </StyledUploadFileContainer>
            {isEqual(file?.type, FILE_TYPE.video)
            && (
              <video
                controls
                src={ file?.url }
              >
                <track
                  default
                  kind={ "captions" }
                />
              </video>
            )}
          </Grid>
          <StyledDialogItemActions>
            <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ () => onClose(null) }>
              { t("Onboarding:cancelButton") }
            </Button>
            <Button
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              onClick={ onSubmit }
            >
              { t("common:common.save") }
            </Button>
          </StyledDialogItemActions>
        </Grid>
      </StyledItemDetail>
    </form>
  );
};

FormItem.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  setNewSectionItem: PropTypes.func.isRequired,
};

export default FormItem;
