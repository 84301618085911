import { useState } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormHelperText from "components_refactor/FormHelperText";

const PasswordInput = (props) => {
  const {
    id,
    name,
    isRequired,
    control,
    rules,
    customStyles,
    label,
    helperText,
    defaultValue,
    error,
  } = props;
  const { t } = useTranslation("authentication");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <FormControl
      variant={ "outlined" }
      required={ isRequired }
      fullWidth
      error={ !!error }
      className={ customStyles }
    >
      <InputLabel htmlFor={ id }>{label || t("password")}</InputLabel>
      <Controller
        data-testid={ "password-input-controller" }
        name={ name }
        control={ control }
        rules={ rules }
        defaultValue={ defaultValue }
        as={ (
          <OutlinedInput
            id={ id }
            type={ showPassword ? "text" : "password" }
            fullWidth
            endAdornment={ (
              <InputAdornment position={ "end" }>
                <IconButton
                  aria-label={ "toggle password visibility" }
                  onClick={ handleClickShowPassword }
                  edge={ "end" }
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) }
            label={ label || t("password") }
          />
        ) }
      />
      {(helperText) && (
        <FormHelperText>{ helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

PasswordInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  control: PropTypes.shape({
    setValue: PropTypes.func,
    register: PropTypes.func,
    getValues: PropTypes.func,
  }),
  rules: PropTypes.object,
  isRequired: PropTypes.bool,
  customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

PasswordInput.defaultProps = {
  id: "",
  name: "",
  defaultValue: "",
  control: null,
  rules: {},
  isRequired: false,
  customStyles: "",
  label: "",
  helperText: "",
  error: false,
};

export default PasswordInput;
