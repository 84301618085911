import styled from "styled-components";
import palette from "theme/palette";

export const FilterButton = styled.button`
  background-color: ${
    (props) => {
      if (props.active) return "#E1E2FF";
      return "#D9D9D9";
    }};

  color: ${(props) => {
      if (props.active) return "#2930FF";
      return "#525252";
    }};

  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 14px;
  font-weight: 600;
  transition: background-color 0.3s, opacity 0.3s;
  text-decoration: underline;
  text-align: left;
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "inherit" : "#E1E2FF")};
    color: ${(props) => (props.disabled ? "inherit" : "#2930FF")};
  }
`;

export const FilterModal = styled.div`
  width: 500px;
  max-width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);

  .MuiListItemIcon-root {
    min-width: 20px;
  }

  @media (max-width: 600px) {
    width: 90%;
    max-width: 100%;
    top: 10%;
    transform: translate(-50%, 0);
    padding: 16px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FilterActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ApplyButton = styled.button`
  background-color: ${palette.text.link};
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    background-color: #4e46c5;
  }
`;

export const CancelButton = styled.button`
  background-color: transparent;
  border: 1px solid ${palette.text.link};
  color: ${palette.text.link};
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    background-color: #eadeff;
  }
`;

export const ClearButton = styled.button`
  background: transparent;
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-right: 12px;

  &:hover {
    background: #ff4d4f;
    color: white;
  }

  &:focus {
    outline: none;
  }
`;

export const RightActions = styled.div`
  display: flex;
  gap: 12px;
  margin-left: auto;
`;
