import PropTypes from "prop-types";
import charts from "theme/charts";
import Radio from "@mui/material/Radio";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ALIGN_ITEMS } from "common/constants";
import {
  StyledFormControlLabel, StyledTooltip,
  StyledHelpIcon } from "./styles";

const RadioButton = (props) => {
  const {
    label, value, disabled, labelPlacement,
    className, isRequired, tooltip,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const labelWithTooltip = (
    <>
      {label}
      {tooltip && (
        <StyledTooltip title={ tooltip }>
          <StyledHelpIcon />
        </StyledTooltip>
      )}
    </>
  );

  return (
    <StyledFormControlLabel
      value={ value }
      disabled={ disabled }
      control={ <Radio required={ isRequired } /> }
      label={ labelWithTooltip }
      labelPlacement={ isMobile ? ALIGN_ITEMS.left : labelPlacement }
      isMobile={ isMobile }
      className={ className }
    />
  );
};

RadioButton.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  labelPlacement: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  tooltip: PropTypes.string,
};

RadioButton.defaultProps = {
  label: "",
  value: "",
  disabled: false,
  labelPlacement: "",
  className: "",
  isRequired: false,
  tooltip: null,
};

export default RadioButton;
