import CircularProgress from "@mui/material/CircularProgress";
import { CIRCULAR_PROGRESS } from "common/constants";

// NOTE: simple loader for inputs.
const LoaderInput = () => (
  <div data-testid={ "loader-input" }>
    <CircularProgress size={ CIRCULAR_PROGRESS.small } />
  </div>
);

export default LoaderInput;
