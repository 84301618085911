import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import NoDataMessage from "components_refactor/NoDataMessage";
import { ALIGN_ITEMS, SIZE, VARIANT } from "common/constants";
import { StyledSubtitle } from "../../styledComponents";
import {
  StyledTableRow,
  StyledIcon,
  StyledTableCell,
  StyledAddCircleIcon,
  StyledRemoveCircleIcon,
} from "./styles";

const FavorabilityRankingTable = (props) => {
  const { values } = props;
  const { t } = useTranslation("engagement");
  const [isHidden, setIsHidden] = useState({});

  const activeOrInactiveData = (parentId, disabled) => {
    setIsHidden((prevValues) => (
      {
        ...prevValues,
        [parentId]: disabled,
      }
    ));
  };

  const setParents = useCallback(() => {
    const parentsData = {};
    const parents = values?.header?.filter((item) => item.isParent);
    if (parents) {
      parents.forEach((element) => { parentsData[element.id] = true; });
    }
    setIsHidden(parentsData);
  }, [values]);

  useEffect(() => {
    setParents();
  }, [values, setParents]);

  return (
    <Box data-testid={ "nps-and-satisfaction-table-view-component" } mt={ 2 } >
      <StyledSubtitle variant={ VARIANT.h4 }>{ t("generalSatisfaction") }</StyledSubtitle>
      <TableContainer component={ Paper }>
        <Table aria-label={ "favorability ranking table" } size={ SIZE.small }>
          <TableHead>
            <StyledTableRow>
              {values?.header?.map((headCell) => (
                <StyledTableCell
                  key={ headCell.id }
                  disabled={ headCell.key && isHidden[headCell.key] }
                >
                  <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
                    <Box>{headCell.label}</Box>
                    {headCell.icon && (
                      <Box>
                        <StyledIcon src={ headCell.icon } alt={ headCell.label } />
                      </Box>
                    )}
                    {headCell.isParent && (
                      <Box>
                        <IconButton
                          onClick={ () => (
                            activeOrInactiveData(headCell.id, !isHidden[headCell.id])
                          ) }
                        >
                          {isHidden[headCell.id]
                            ? <StyledAddCircleIcon />
                            : <StyledRemoveCircleIcon /> }
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {values?.data?.map((item) => (
              <StyledTableRow key={ item.id }>
                {values?.header?.map((element) => (
                  <StyledTableCell
                    key={ `result${element.id}` }
                    color={ element.color }
                    disabled={ element.key && isHidden[element.key] }
                  >
                    { item[element.id] }
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        { values?.data?.length === 0 && <NoDataMessage />}
      </TableContainer>
    </Box>
  );
};

FavorabilityRankingTable.propTypes = {
  values: PropTypes.object.isRequired,
};

export default FavorabilityRankingTable;
