import styled from "styled-components";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import Skeleton from "@mui/material/Skeleton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "components/Button";
import theme from "theme/palette";

export const StyledTable = styled.div`
  width: 100%;
  color: ${theme.text.secondaryTitle};
  font-size: 14px;
  font-weight: 500;
  .MuiTableCell-head {
    color: ${theme.white};
  }
`;

export const StyledTableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  th:first-child: {
    borderRadius: 8px 0 0 8px;
  },
  th:last-child: {
    borderRadius: 0 8px 8px 0;
  }
`;

export const StyledTableBody = styled(TableBody)`
  width: 100%;
  box-shadow: ${(props) => (props.hasBorder ? `inset 0 0 0 1px ${theme.table.disabled}` : "none")};
  background-color: ${theme.table.white};
  &.content{
    .noData{
      min-height: 200px;
    }
    .rowContent{
      height: 70px;
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      &.odd{
        background-color: ${theme.table.oddRow} ;
      }
      > div {
        width: 100%;
        padding: 10px 25px;
        &:first-child{
          width: 200%;
        }
        &:last-child{
          width: 15%;
        }
      }
    }
  }
`;

export const StyledTitleWithSubtitle = styled.div`
  display: inline;
  text-align: ${(props) => (props.isMobile ? "center" : "left") };
  padding-left: 5px;
  .subtitle{
    font-weight: 400;
    font-size: 12px;
    color: ${theme.text.content};
    display: flex;
    align-items: center;
  }
  svg{
    margin-right: 5px;
    width: 14px;
  }
  .title{
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledActionItem = styled.div`
  &.error{
    color: ${theme.table.red}
  }
`;

export const StyledActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.onlyOne ? "flex-end" : "space-between")};
  background-color: ${theme.white};
  border-radius: 0 0 8px 8px;
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${theme.table.paginationIcon};
  font-size: 12px;

`;

export const StyledAddButton = styled.div`
  padding: 5px 8px 12px;
`;

export const StyledButtonPaginator = styled(Button)`
  background-color: ${theme.white};
  margin: 5px 2px;
  svg{
    color: ${theme.table.paginationIcon};
  }
  &.Mui-disabled{
    svg{
      color: ${theme.table.disabled};
    }
  }
`;

export const StyledSearchContainer = styled.div`
  display: ${(props) => (props.isMobile ? "inline-block" : "flex")};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .searchInput{
    margin-bottom: 15px;
    input{
      width: 250px;
      @media (max-width: 600px) {
        width: 100%
      };
    }
  }
`;

export const StyledDownLoad = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

export const StyledTableCard = styled(Paper)`
  margin: 10px 1px;
`;

export const StyledButtonActionsContainer = styled.div`
  margin-top: 25px;
  text-align: center;
`;
export const StyledLink = styled.div`
  color: ${theme.text.link};
  &:hover {
    cursor: pointer;
  }
`;

export const StyledTableRow = styled(TableRow)`
  justify-content: space-evenly;
  height: 70px;
  .MuiTableCell-root{
    font-weight: 500;
    border-bottom: 0;
  }
  .MuiTableCell-stickyHeader {
    background: ${theme.table.header};
  }
  &.content{
    text-align: left;
  }
  &.header{
    height: 48px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-size: 14px;
  }
`;

export const StyledCollapseContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSkeleton = styled(Skeleton)`
  margin: 0 15px;
`;

export const StyledTableAddCell = styled(TableCell)`
  background-color: ${theme.background.default};
`;

export const StyledLegend = styled.div`
  text-align: center;
  color: ${theme.text.gray};
`;

export const StyledTableContainer = styled(TableContainer)`
  max-height: 300px;
  @media (min-width: 600px) {
    ::-webkit-scrollbar {
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.background.mediumGrey};
      border-radius: 3px;
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: ${theme.scroll.thumbActive};
    }
    ::-webkit-scrollbar-thumb:hover: {
      background: ${theme.scroll.thumbHover};
      box-shadow: ${theme.scroll.boxShadow};
    }
    ::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    ::-webkit-scrollbar-track:hover,::-webkit-scrollbar-track:active {}
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: ${theme.scroll.firefoxColor};
  }
  @media (max-width: 600px) {
    max-height: ${(props) => props.height || 400 }px;
  }
`;

export const StyledOrder = styled.div`
  .MuiIconButton-root {
    color: ${theme.white};
    padding: 5px;
    svg {
      font-size: 16px;
    }
  }
`;

export const StyledArrowUpwardIcon = styled(ArrowUpwardIcon)`
  opacity: ${(props) => props.disabled && 0.4};
`;
