import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import {
  StyledDashboardContainer,
  StyledPaperContainer,
} from "styledComponents/View";
import SkeletonLoader from "components/SkeletonLoader";
import { useNotificationsSettings, useToggleNotificationSetting } from "hooks/useNotificationsSettings";

import InputToggle from "../InputToggle";

const ToggleList = () => {
  const { t } = useTranslation();
  const { notificationsSettings, isLoading } = useNotificationsSettings(t);
  const { mutate: toggleNotificationSetting } = useToggleNotificationSetting(t);

  const notificationsLeft = [
    { name: "welcome_notification" },
    { name: "birthday_notification" },
    { name: "anniversary_notification" },
  ];

  const notificationsRight = [
    { name: "new_hires_notification" },
  ];

  if (isLoading) {
    return (<SkeletonLoader />);
  }

  const handleToggleChange = (notificationName) => {
    toggleNotificationSetting(notificationName);
  };

  return (
    <StyledPaperContainer mt={ "0px" }>
      <StyledDashboardContainer>
        <Stack direction={ "row" } sx={ { justifyContent: "space-between" } } spacing={ 8 }>
          <Stack direction={ "column" } spacing={ 4 } flexGrow={ "1" }>
            {notificationsLeft.map((notification) => (
              <InputToggle
                key={ notification.name }
                name={ notification.name }
                isChecked={ notificationsSettings[notification.name] }
                onChange={ () => handleToggleChange(notification.name) }
              />
            ))}
          </Stack>
          <Stack direction={ "column" } spacing={ 4 } flexGrow={ "1" }>
            {notificationsRight.map((notification) => (
              <InputToggle
                key={ notification.name }
                name={ notification.name }
                isChecked={ notificationsSettings[notification.name] }
                onChange={ () => handleToggleChange(notification.name) }
              />
            ))}
          </Stack>
        </Stack>
      </StyledDashboardContainer>
    </StyledPaperContainer>
  );
};

export default ToggleList;
