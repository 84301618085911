import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { TARGET } from "common/constants";
import { StyledLinkMore } from "./styles";

const CustomLink = (props) => {
  const {
    t, data, setShowAll, showMore, isPeerEvaluation, isTeamEvaluation, isInternalClientEvaluation, handleModal, canAddPeers,
  } = props;

  if (showMore) {
    return (
      <StyledLinkMore onClick={ () => setShowAll(data.type) } data-testid={ "custom-link-show-more" }>
        {t("common:common.viewMore")}
      </StyledLinkMore>
    );
  }

  if (isPeerEvaluation && canAddPeers) {
    return (
      <StyledLinkMore onClick={ () => handleModal() } data-testid={ "custom-link-peer-evaluation" }>
        {t("surveys:addPeer")}
      </StyledLinkMore>
    );
  }

  if (!isPeerEvaluation && !data?.isCompleted && data.link ) {
    return (
      <Link href={ data?.link } target={ TARGET.self }>
        { (isTeamEvaluation || isInternalClientEvaluation) ? t("surveys:evaluateAll") : t("common:common.evaluate")}
      </Link>
    );
  }
  return "";
};

CustomLink.propTypes = {
  t: PropTypes.func.isRequired,
  data: PropTypes.object,
  setShowAll: PropTypes.func,
  showMore: PropTypes.bool,
  isPeerEvaluation: PropTypes.bool,
  isTeamEvaluation: PropTypes.bool,
  isInternalClientEvaluation: PropTypes.bool,
  handleModal: PropTypes.func,
  canAddPeers: PropTypes.bool,
};

CustomLink.defaultProps = {
  data: {},
  setShowAll: null,
  showMore: false,
  isPeerEvaluation: false,
  isTeamEvaluation: false,
  isInternalClientEvaluation: false,
  handleModal: null,
  canAddPeers: true,
};

export default CustomLink;
