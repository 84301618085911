import { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SIZE } from "common/constants";
import { getRowClass, isNewElementSelected } from "../../functions";
import { useStyles, StyledTableCell, StyledTableCellCollapse, StyledTableCellDragable } from "../../styles";

const TableRowCustom = (props) => {
  const { item, keys, colspan, getCollapseData, isNotHeader, isDragable, actions } = props;
  const classes = useStyles();
  const [tbodyElements] = useState(keys);
  const [open, setOpen] = useState(false);

  const handleAddCancelNew = (id) => {
    setOpen(!open);
    actions.addNewNoChild(actions.module, actions.parentId, id, !open);
  };

  const getValueToRender = (element, valueToRender) => {
    return element.customRender
      ? element.customRender(item)
      : actions.editableRow && item.id === actions.editableRow.rowData.id
      ? actions.editableRow.action.getEditableRow(actions.editableRow.rowData)
      : valueToRender;
  };

  const getCollapseCell = (index, element, valueToRender, actions) => {
    let disabled = !element.collapseCell.isActive || (item.children && isEmpty(item.children));

    return (
      <StyledTableCell key={index} width={element.width} colSpan={actions?.editableRow?.action?.getEditableRow ? 2 : 1}>
        <Box className={classes.collapseData}>
          {element.collapseCell.isActive && (
            <IconButton size={SIZE.small} onClick={() => setOpen(!open)} disabled={disabled}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}

          {getValueToRender(element, valueToRender)}
          {actions?.addNew &&
            disabled &&
            (isNull(actions.newIdElement) && element.collapseCell.isActive ? (
              <AddCircleOutlineIcon
                onClick={() => handleAddCancelNew(item.id)}
                className={clsx(classes.addNew, classes.iconNewElement)}
              />
            ) : (
              element.collapseCell.isActive && (
                <HighlightOffIcon
                  onClick={() => isNewElementSelected(item, actions) && handleAddCancelNew(item.id)}
                  className={clsx(
                    classes.cancelNew,
                    classes.iconNewElement,
                    !isNewElementSelected(item, actions) && classes.iconDisabled
                  )}
                />
              )
            ))}
        </Box>
      </StyledTableCell>
    );
  };

  return (
    <>
      <TableRow
        data-testid="table-row-custom"
        className={clsx(getRowClass(item, classes, open, isNotHeader, isDragable), classes.fadeIn)}
      >
        {tbodyElements?.map((element, index) => {
          const valueToRender = get(item, element.id);
          return element.dragable ? (
            <StyledTableCellDragable key={index} width={element.width} align={element.align}>
              {element.customRender(item)}
            </StyledTableCellDragable>
          ) : element.collapseCell ? (
            getCollapseCell(index, element, valueToRender, actions)
          ) : element.customRender ? (
            <StyledTableCell key={index} width={element.width} align={element.align}>
              {element.customRender(item)}
            </StyledTableCell>
          ) : (
            <StyledTableCell key={index} width={element.width} align={element.align}>
              {valueToRender}
            </StyledTableCell>
          );
        })}
      </TableRow>
      {getCollapseData && (
        <TableRow>
          <StyledTableCellCollapse colSpan={colspan}>
            <Collapse in={open} unmountOnExit className={classes.collapseComponent}>
              {getCollapseData(item)}
            </Collapse>
          </StyledTableCellCollapse>
        </TableRow>
      )}
    </>
  );
};

TableRowCustom.propTypes = {
  item: PropTypes.object,
  keys: PropTypes.array,
  colspan: PropTypes.number,
  getCollapseData: PropTypes.func,
  isNotHeader: PropTypes.bool,
  isDragable: PropTypes.bool,
  actions: PropTypes.object,
};

export default TableRowCustom;
