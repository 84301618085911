import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import noInfoFace from "assets/images/tables/noInfoFace.svg";
import noInfoHappyFace from "assets/images/tables/noInfoHappyFace.svg";
import { StyledContainer, StyledMessage, StyledContent } from "./styles";

const NoDataMessage = ({
  message, customStyles, isHappyFace, icon, iconImage,
}) => {
  const { t } = useTranslation("common");

  return (
    <StyledContainer className={ customStyles } data-testid={ "noDataMessage" }>
      <StyledContent>
        <Box display={ "flex" } justifyContent={ "center" }>
          {icon || (
            <img
              src={ iconImage || (isHappyFace ? noInfoHappyFace : noInfoFace) }
              alt={ "No info to load" }
            />
          )}
        </Box>
        <StyledMessage>
          {message || t("common.no_records")}
        </StyledMessage>
      </StyledContent>
    </StyledContainer>
  );
};

NoDataMessage.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isHappyFace: PropTypes.bool,
  icon: PropTypes.node,
  iconImage: PropTypes.string,
};

NoDataMessage.defaultProps = {
  message: "",
  customStyles: "",
  isHappyFace: false,
  icon: null,
  iconImage: null,
};

export default NoDataMessage;
