import { useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components_refactor/NoDataMessage";
import {
  ASC, DESC, COMPONENT, PAGINATION,
} from "common/constants";
import EnhancedTableHead from "./components/TableHead";
import {
  StyledRoundTableContainer, StyledRoundPaper, StyledTableRow, StyledTableCell,
} from "./styles";

const PaginatedTable = (props) => {
  const {
    data, headers, sortAction, pagination, isLoading, rows, total,
  } = props;
  const [direction, setDirection] = useState(ASC);
  const [active, setActive] = useState("");
  const start = data && data.length - 1 <= PAGINATION.maxPerPage
    ? 0 : pagination.page * pagination.pageSize;
  const rowsByPage = rows?.slice(start, start + pagination.pageSize);
  const handleSort = (title) => {
    setActive(title);
    setDirection(direction === ASC ? DESC : ASC);
    sortAction(title, direction);
  };

  return (
    <StyledRoundPaper>
      <StyledRoundTableContainer>
        <Table>
          <EnhancedTableHead
            headers={ headers }
            direction={ direction }
            active={ active }
            handleSort={ handleSort }
          />
          {!isLoading && (
            <TableBody>
              {rowsByPage?.map((item, index) => (
                <StyledTableRow key={ index } even={ (index % 2) ? undefined : "true" }>
                  {item?.map((cell, cellIndex) => <StyledTableCell key={ cellIndex }>{cell.isVisible ? cell.content : ""}</StyledTableCell>)}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {isLoading && <SkeletonLoader />}
        {!isLoading && (isEmpty(data) ? <NoDataMessage /> : (
          <TablePagination
            component={ COMPONENT.div }
            count={ Number(total) }
            rowsPerPage={ pagination.pageSize }
            page={ pagination.page }
            onPageChange={ pagination.handlePageChange }
            rowsPerPageOptions={ [] }
          />
        ))}
      </StyledRoundTableContainer>
    </StyledRoundPaper>
  );
};

PaginatedTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array,
  sortAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    handlePageChange: PropTypes.func,
    pageSize: PropTypes.number,
    page: PropTypes.number,
    count: PropTypes.number,
  }),
  total: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

PaginatedTable.defaultProps = {
  data: [],
  rows: [],
  pagination: {},
};

export default PaginatedTable;
