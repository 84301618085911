import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NoDataMessage from "components_refactor/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import StaticCustomProgressBar from "components/StaticCustomProgressBar";
import { SKELETONS_NUMBER } from "common/constants";
import { isEmpty } from "common/helpers";
import NalaBot from "assets/images/general/nala_bot_normal.svg";
import { calculateTimePassedPercentageAndRemainingTime } from "views/Successions/functions";
import palette from "theme/palette";
import {
  StyledDivider,
  StyledGoalContainer,
  StyledSubTitle,
  StyledFixedContainer,
  StyledContentText,
  StyledLink,
} from "../GoalsInfo/styles";
import {
  StyledAgreementTitle,
  StyledAgreementSubTitle,
  StyledSectionContainer,
} from "./styles";

const AgreementsInfo = (props) => {
  const {
    t, isOwnProfile, successionPlan, agreements, collaborator,
  } = props;
  const { successionPlansByEmployee, isLoadingSuccessionPlans } = successionPlan;
  const {
    agreementsCollaboratorList,
    isLoadingAgreementsCollaboratorList,
  } = agreements;

  const successionPlanContent = !isLoadingSuccessionPlans
    && successionPlansByEmployee?.length > 0
      && (
        <Grid item xs={ 12 }>
          <StyledSubTitle>
            {t("profile:development-plan-card.progress_succession_plan")}
          </StyledSubTitle>
          {successionPlansByEmployee.slice(0, 2).map((item) => {
            const {
              percentage,
              remainingTimeKey,
              remainingTimeValue,
            } = calculateTimePassedPercentageAndRemainingTime(item);
            const description = t(`successions:plan.${remainingTimeKey}`, { months: remainingTimeValue, years: remainingTimeValue });
            return (
              <StyledGoalContainer
                key={ item.id }
              >
                <StaticCustomProgressBar
                  title={ item.name }
                  percentage={ percentage }
                  color={ palette.background.purpleBox }
                  description={ description }
                  hasOpacity={ item?.total_agreements === 0 }
                  t={ t }
                  name={ collaborator.full_name }
                />
                <StyledDivider />
              </StyledGoalContainer>
            );
          })}
        </Grid>
      );

  const agreementsContent = isEmpty(agreementsCollaboratorList) ? (
    <NoDataMessage
      iconImage={ NalaBot }
      message={ (
        <StyledContentText>
          <Typography>{ t(`profile:development-plan-card.${isOwnProfile ? "no-agreements" : "start-title-for-manager"}`) }</Typography>
          <StyledLink to={ `/development-plan?employee-name=${collaborator.full_name}` }>
            { t("profile:goalsCard.startNow") }
          </StyledLink>
        </StyledContentText>
      ) }
    />
  ) : (
    <Grid item xs={ 12 }>
      {agreementsCollaboratorList?.map((item) => (
        <StyledAgreementTitle key={ item.id } isFinished={ item.state === "finished" }>
          <CheckCircleIcon />
          {item.title}
        </StyledAgreementTitle>
      ))}
      <StyledFixedContainer>
        <StyledLink to={ `/development-plan?employee-name=${collaborator.full_name}` }>
          { t("common:common.viewMore") }
        </StyledLink>
      </StyledFixedContainer>
    </Grid>
  );

  return (
    <StyledSectionContainer>
      <Grid container>
        { successionPlanContent }
        <StyledAgreementSubTitle>
          {t("common:sidebar.development-plan")}
        </StyledAgreementSubTitle>
        {isLoadingAgreementsCollaboratorList ? (
          <SkeletonLoader num={ SKELETONS_NUMBER.SIX } isInline={ false } />
        ) : agreementsContent }
      </Grid>
    </StyledSectionContainer>
  );
};

AgreementsInfo.propTypes = {
  t: PropTypes.func.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  successionPlan: PropTypes.shape({
    successionPlansByEmployee: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      organization_unit_complete_path: PropTypes.string,
    })),
    isLoadingSuccessionPlans: PropTypes.bool.isRequired,
  }).isRequired,
  agreements: PropTypes.shape({
    agreementsCollaboratorList: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    })),
    isLoadingAgreementsCollaboratorList: PropTypes.bool.isRequired,
  }).isRequired,
  handleViewMore: PropTypes.func.isRequired,
  collaborator: PropTypes.shape({
    full_name: PropTypes.string,
  }).isRequired,
};

export default AgreementsInfo;
