import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "components_refactor/FormHelperText";
import { DEFAULT_MIN_DECIMAL, MAX_LENGTH_INPUT } from "common/constants";
import { StyledTextField } from "./styles";

// FIXME
const CurrencyTextFieldController = (props) => {
  const {
    label,
    isRequired,
    disabled,
    defaultValue,
    control,
    name,
    maxLength = MAX_LENGTH_INPUT.currency,
    cs,
  } = props;

  const CustomNumberFormat = (props) => {
    const { inputRef, ...other } = props;
    return (
      <NumberFormat
        { ...other }
        getInputRef={ inputRef }
        allowNegative={ false }
        thousandSeparator
        isNumericString
        fixedDecimalScale
        decimalScale={ DEFAULT_MIN_DECIMAL }
      />
    );
  };

  return (
    <>
      <Controller
        data-testid={ "currency-input-text-controller" }
        as={ (
          <StyledTextField
            label={ label }
            required={ isRequired }
            disabled={ disabled }
            className={ cs }
            variant={ "outlined" }
            fullWidth
            margin={ "dense" }
            InputProps={ {
              inputComponent: CustomNumberFormat,
              startAdornment: <InputAdornment>{"$"}</InputAdornment>,
            } }
            InputLabelProps={ { required: false } }
            inputProps={ { maxLength: maxLength } }
          />
        ) }
        defaultValue={ defaultValue }
        control={ control }
        name={ name }
      />
      {!disabled && isRequired && <FormHelperText />}
    </>
  );
};

CurrencyTextFieldController.propTypes = {
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  control: PropTypes.object,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  cs: PropTypes.string,
};

export default CurrencyTextFieldController;
