import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import palette from "theme/palette";

export const StyledPaperContainer = styled(Paper)`
  margin-bottom: 16px;
`;

export const StyledSelect = styled(Grid)`
  justify-content: flex-end;
  margin-top: 20px;
`;

export const StyledLink = styled(Link)`
  color: ${palette.text.link};
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
`;

export const StyledGridContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledSubtitleText = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${palette.subtitle};
`;

export const StyledGridEnd = styled(Grid)`
  text-align: right;
`;

export const StyledGridComments = styled(Grid)`
  padding: 20px;
  width: 100%;
`;

export const StyledGridComment = styled(Grid)`
  display: Grid;
  p {
    background: ${palette.background.lightGrey};
    border-radius: 5px;
    padding: 10px;
  }
`;

export const StyledNoResultContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 6px;
    width: 25px;
    height: 25px;
  }
`;

export const StyledNoResult = styled.div`
  color: ${palette.primary.light};
`;

export const StyledCollaboratorName = styled.div`
  width: 180px;
`;

export const StyledGridCard = styled(Grid)`
  margin-top: 5px;
`;

export const StyledError = styled.span`
  color: ${palette.table.red};
`;

export const StyledContainer = styled(Grid)`
  margin-top: 10px;
`;
