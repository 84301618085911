import {
  useState, useEffect, useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SkeletonLoader from "components/SkeletonLoader";
import DownloadExcelButton from "components/DownloadExcelButton";
import HalfScreenDialog from "components/HalfScreenDialog";
import {
  PAGINATION,
  // ROLES,
  SURVEY_PROCESS_TYPE,
  // DAYS_TO_FINISH_ONBOARDING,
  // DATE_FORMATS,
} from "common/constants";
import { useFollowUpResult } from "hooks/useFollowUpProcess";
import { getCollaboratorsFollowUpProcessesToDownload } from "redux/actions/followUpActions";
import { getList as getSurveyProcesses } from "redux/actions/surveyProcessesActions";
import {
  getExcelData, getHeader, getRows,
} from "views/CollaboratorsFollowUp/functions";
import SatisfactionByCollaborator from "../SatisfactionByCollaborator";
import FollowUpTable from "../FollowUpTable";
import { StyledContainer } from "./styles";

const OverView = (props) => {
  const { filterQuery, handleTotal } = props;
  const { t, i18n } = useTranslation(["onboardingFollowUp", "engagement"]);
  const { language } = i18n;
  const history = useHistory();
  const [header, setHeader] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tableSort, setTableSort] = useState();
  const [dialog, setDialog] = useState(false);
  const [selectedCollaborator, setSelectedCollaborator] = useState();
  const [pageFilter, setPageFilter] = useState({
    number: 0,
    size: PAGINATION.maxPerPage,
  });
  const {
    list: followUpList,
  } = useSelector(({ followUpReducer }) => followUpReducer);

  const {
    followList: surveyProcessList,
    loadingList: isLoadingSurveyProcessList,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const dispatch = useDispatch();

  const pageHandler = async (event, newPage) => {
    window.scroll(0, 0);
    setPageFilter({ size: PAGINATION.maxPerPage, number: newPage });
  };

  const getSurveyResults = useCallback(() => {
    const performanceProcesses = surveyProcessList.filter(
      (process) => process.type === SURVEY_PROCESS_TYPE.performance.key,
    );
    const potentialProcesses = surveyProcessList.filter(
      (process) => process.type === SURVEY_PROCESS_TYPE.engagement.key,
    );
    setHeader(getHeader(performanceProcesses, potentialProcesses, t, followUpList));
    setIsLoading(false);
  }, [surveyProcessList, t, followUpList]);

  useEffect(() => {
    if (!isLoadingSurveyProcessList && surveyProcessList !== null) {
      setIsLoading(true);
      getSurveyResults();
    }
  }, [isLoadingSurveyProcessList, surveyProcessList, getSurveyResults]);

  // TODO: REMOVE
  useEffect(() => {
    dispatch(getSurveyProcesses(true));
  }, [dispatch]);

  const handleSatisfaction = (collaborator) => {
    setSelectedCollaborator(collaborator);
    setDialog(true);
  };

  const {
    data: followUpResult,
    isLoading: isLoadingFollowUpResult,
  } = useFollowUpResult(filterQuery, pageFilter, tableSort);

  const followUpCollaboratorTotal = followUpResult?.total || 0;

  const rows = getRows(
    followUpResult?.employees || [],
    header,
    history,
    handleSatisfaction,
    t,
    language,
  );

  const onDownloadExcel = async () => {
    const downloadData = await dispatch(
      getCollaboratorsFollowUpProcessesToDownload(filterQuery, followUpCollaboratorTotal),
    );
    if (downloadData) {
      const data = getExcelData(downloadData, t, surveyProcessList, followUpList, language);
      return [data, t("downloadTitle"), false, true];
    }
    return null;
  };

  useEffect(() => {
    if (!isLoadingFollowUpResult) {
      handleTotal(followUpCollaboratorTotal);
    }
  }, [followUpCollaboratorTotal, isLoadingFollowUpResult]);

  const followTable = (isLoading || isLoadingSurveyProcessList ? (
    <SkeletonLoader num={ 9 } />
  ) : (
    <FollowUpTable
      header={ header }
      data={ rows }
      isLoading={ isLoadingFollowUpResult }
      setTableSort={ setTableSort }
      pagination={ {
        page: pageFilter.number,
        pageSize: pageFilter.size,
        handlePageChange: pageHandler,
      } }
      total={ followUpCollaboratorTotal }
    />
  ));

  return (
    <StyledContainer data-testid={ "overview-follow-up-view" }>
      <Grid container>
        <Grid item xs={ 12 } md={ 8 }>
          <Typography variant={ "h4" }>
            {t("collaboratorsInFollowUp")}
          </Typography>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <DownloadExcelButton
            onClick={ onDownloadExcel }
            disabled={ followUpCollaboratorTotal === 0 }
          />
        </Grid>
      </Grid>
      {followTable}
      <HalfScreenDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("modalTitle") }
      >
        <SatisfactionByCollaborator employee={ selectedCollaborator } />
      </HalfScreenDialog>
    </StyledContainer>
  );
};

OverView.propTypes = {
  filterQuery: PropTypes.object,
  handleTotal: PropTypes.func,
};

OverView.defaultProps = {
  filterQuery: {},
  handleTotal: () => {},
};

export default OverView;
