import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import ReplayIcon from "@mui/icons-material/Replay";
import Tooltip from "@mui/material/Tooltip";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components_refactor/Filter";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import TableGrid from "components/TableGrid";
import { StyledDashboardContainer } from "styledComponents/View";
import { useQuery, useMutation } from "react-query";
import { ROWS_RANGE_PER_PAGE } from "common/constants";
import NineBox from "./components/NineBox";
import List from "./components/List";
import ProfileInfo from "./components/ProfileInfo";
import {
  StyledMatrixContainer, StyledCard,
} from "./styles";

const fetchNineBoxData = () => JSON.parse(localStorage.getItem("nineBoxData")) || {};

const getTableData = (nineBoxData, t) => {
  if (!nineBoxData?.results?.length) return { rows: [], headers: [] };

  const excludedHeaderKeys = ["quadrant_x", "quadrant_y", "full_name", "profile_img", "name", "x", "y", "id", "quadrant", "personal_id"];
  const excludedKeys = ["quadrant_x", "quadrant_y"];

  const headers = [
    {
      id: "profile",
      label: t("tables:headers.name"),
      align: "left",
      customRender: (rowData) => (
        <ProfileInfo name={ rowData.full_name } img={ rowData.profile_img } />
      ),
    },
    {
      id: "id",
      label: t("common:common.id"),
      customRender: (rowData) => rowData.personal_id,
    },
    {
      id: "x",
      label: `${nineBoxData?.xAxisLabel} (x)`,
    },
    {
      id: "y",
      label: `${nineBoxData?.yAxisLabel} (y)`,
    },
    {
      id: "quadrant",
      label: t("ninebox:quadrant"),
    },
    ...Object.keys(nineBoxData.results[0])
      .filter((key) => !excludedHeaderKeys.includes(key))
      .map((key) => ({
        id: key,
        label: key.replace(/_/g, " "),
      })),
  ];

  const rows = nineBoxData.results.map((row) => {
    const filteredData = Object.fromEntries(
      Object.entries(row).filter(([key]) => !excludedKeys.includes(key)),
    );

    return {
      ...filteredData,
    };
  });

  return { headers, rows };
};

const NineBoxMatrixView = (props) => {
  const { t } = props;
  const [hasAvatar, setHasAvatar] = useState(true);

  const handleExternalQuery = (query) => {
    console.log("query", query);
  };

  const { data: nineBoxData, refetch } = useQuery("nineBoxData", fetchNineBoxData, { staleTime: Infinity });

  const mutation = useMutation((newData) => {
    localStorage.setItem("nineBoxData", JSON.stringify(newData));
    refetch();
  });

  const options = [
    { label: t("ninebox:photo_filter"), value: 0 },
    { label: t("ninebox:name_filter"), value: 1 },
  ];

  const handleChangeSwitchOption = (option) => {
    setHasAvatar(option === 0);
  };

  const tableData = useMemo(() => getTableData(nineBoxData, t), [nineBoxData, t]);

  const handleClearNineBoxData = () => {
    localStorage.removeItem("nineBoxData");
    refetch();
  };

  return (
    <StyledDashboardContainer>
      <ViewHeaderTitle title={ t("common:sidebar:ninebox") } />
      <Grid container>
        <Grid item xs={ 10 }>
          <Filter
            onApplyFilters={ handleExternalQuery }
            hasDefaultFilter={ false }
          />
        </Grid>
        <Grid item xs={ 2 } display={ "flex" } justifyContent={ "flex-end" }>
          { nineBoxData?.results?.length > 0
          && (
            <Tooltip title={ t("ninebox:reset") }>
              <IconButton
                onClick={ handleClearNineBoxData }
              >
                <ReplayIcon />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={ 2 } mt={ 2 }>
        <Grid item xs={ 12 } md={ 7 }>
          <StyledCard>
            <StyledMatrixContainer>
              <Grid container>
                <Grid item xs={ 12 } md={ 5 } />
                <Grid item xs={ 12 } md={ 7 } mb={ 2 }>
                  <SwitchSelectorContent
                    options={ options }
                    hasTitle={ false }
                    onChangeOption={ handleChangeSwitchOption }
                    isFull
                  />
                </Grid>
              </Grid>

              <NineBox
                axisXLabel={ nineBoxData?.xAxisLabel || "Potential" }
                axisYLabel={ nineBoxData?.yAxisLabel || "Performance" }
                people={ nineBoxData?.results || [] }
                t={ t }
                onSubmit={ (data) => mutation.mutate(data) }
                hasAvatar={ hasAvatar }
              />
            </StyledMatrixContainer>
          </StyledCard>
        </Grid>
        <Grid item xs={ 12 } md={ 5 }>
          <StyledCard>
            <StyledMatrixContainer>
              <List nineBoxData={ nineBoxData } t={ t } />
            </StyledMatrixContainer>
          </StyledCard>
        </Grid>
      </Grid>
      <Grid container spacing={ 2 } mt={ 0.5 }>
        <Grid item xs={ 12 }>
          <StyledCard>
            <StyledMatrixContainer>
              <TableGrid
                rows={ tableData?.rows }
                header={ tableData?.headers }
                hasPagination
                paginationOptions={ {
                  maxPerPage: ROWS_RANGE_PER_PAGE[0],
                  rowsRange: ROWS_RANGE_PER_PAGE,
                } }
                hasSearch
                isLoading={ false }
                searchBy={ "full_name" }
              />
            </StyledMatrixContainer>
          </StyledCard>
        </Grid>
      </Grid>
    </StyledDashboardContainer>
  );
};

NineBoxMatrixView.propTypes = {
  t: PropTypes.func.isRequired,
};

export default NineBoxMatrixView;
