import PropTypes from "prop-types";
import moment from "moment";
import forEach from "lodash/forEach";
import isNull from "lodash/isNull";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import SelectController from "components/SelectController";
import FormHelperText from "components_refactor/FormHelperText";
import CurrencyTextFieldController from "components/CurrencyInputTextController";
import DateInputController from "components/DateInputController";
import AutoCompleteCreable from "components/AutocompleteCreable";
import { LOCAL_STORAGE_NAMES } from "common/constants";
import { getItemFromLocalStorage, getAutocompleteAttr } from "common/utils";
import { StyledSalaryContainer } from "../../styles";

const Salary = (props) => {
  const {
    register, control, setValue, sendValidation, checkIsValid, validation,
  } = props;
  const { t } = useTranslation(["common", "account"]);
  const typesOfContract = getItemFromLocalStorage(
    LOCAL_STORAGE_NAMES.typeOfContract,
  );
  const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);

  const onAutocompleteChange = (nameOfAttr) => (prop, event, newValue) => {
    const autocompleteAttr = getAutocompleteAttr(newValue, nameOfAttr);
    setValue(
      "candidate.salary_attributes.currency",
      !isNull(newValue) ? autocompleteAttr : "",
    );
  };

  const getTypeOfContract = () => {
    const typeOfContract = [];
    typesOfContract
      && forEach(typesOfContract, (contract) => {
        typeOfContract.push({
          value: contract.id,
          label: contract.name,
        });
      });
    return typeOfContract;
  };

  return (
    <StyledSalaryContainer container spacing={ 3 }>
      <Grid item xs={ 12 } md={ 6 }>
        <DateInputController
          id={ "startingDate" }
          label={ t("common.startingDate") }
          control={ control }
          name={ "candidate.starting_date" }
          openTo={ "year" }
          value={ moment() }
          views={ ["year", "month", "day"] }
          required
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <SelectController
          id={ "typeOfContract" }
          label={ t("common.typeOfContract") }
          control={ control }
          menuItems={ getTypeOfContract() }
          name={ "candidate.job_position_attributes.type_of_contract_id" }
          defaultValue={ "" }
          required
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <CurrencyTextFieldController
          id={ "salary" }
          label={ t("common.salary") }
          control={ control }
          name={ "candidate.salary_attributes.gross_salary" }
          isRequired
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <AutoCompleteCreable
          id={ "currency" }
          options={ currencies }
          label={ t("common:common.currency.main") }
          register={ register }
          onChange={ onAutocompleteChange("name_with_code") }
          name={ "candidate.salary_attributes.currency" }
          nameOfAttr={ "name_with_code" }
          isRequired
          hasError={ !validation.currency && sendValidation }
          checkIsValid={ checkIsValid }
        />
        { !validation.currency && sendValidation && <FormHelperText isError >{ t("common.noEmpty") }</FormHelperText> }
      </Grid>
    </StyledSalaryContainer>
  );
};

Salary.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  checkIsValid: PropTypes.func.isRequired,
  sendValidation: PropTypes.bool.isRequired,
  validation: PropTypes.object.isRequired,
};

export default Salary;
