import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "components/Button";
import ManagerSelect from "components/commons/ManagerSelect";
import {
  ALIGN_ITEMS, BUTTON_STYLE_TYPES, COLORS_NAME, VARIANT,
} from "common/constants";
import { StyledInfo } from "../../styles";

const EditManager = (props) => {
  const {
    t,
    collaborator,
    hookManagerForm,
    onManagerSubmit,
    handleEditManager,
    process,
  } = props;
  const { control, isLoadingProcess } = hookManagerForm;

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <ManagerSelect
          name={ "collaborator.manager_id" }
          control={ control }
          employee={ collaborator }
        />
        <StyledInfo>
          {t("profile:lateral_movement_on_leadership_change")}
        </StyledInfo>
        <Box display={ ALIGN_ITEMS.flex } justifyContent={ ALIGN_ITEMS.flexEnd }>
          <Box mt={ 2 } ml={ 2 }>
            <Button
              onClick={ () => handleEditManager() }
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
            >
              {t("common:common.cancel")}
            </Button>
          </Box>
          <Box mt={ 2 } ml={ 2 }>
            <Button
              onClick={ hookManagerForm.handleSubmit(onManagerSubmit) }
              variant={ VARIANT.contained }
              color={ COLORS_NAME.primary }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              isLoading={ process?.isLoadingProcess || isLoadingProcess }
              disabled={ process?.isLoadingProcess || isLoadingProcess }
            >
              {t("common:common.save")}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

EditManager.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  hookManagerForm: PropTypes.object.isRequired,
  onManagerSubmit: PropTypes.func.isRequired,
  handleEditManager: PropTypes.func.isRequired,
  process: PropTypes.object,
};

EditManager.defaultProps = {
  process: null,
};

export default EditManager;
