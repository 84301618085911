import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import MultiSelect from "./MultiSelect";

const MultiSelectController = (props) => {
  const {
    name, data, control, defaultValue,
  } = props;
  const handleOnChange = (selectedValue) => {
    control.setValue(name, selectedValue);
  };

  return (
    <Controller
      name={ name }
      control={ control }
      render={ ({ field }) => (
        <MultiSelect
          data={ data }
          { ...field }
          onChange={ handleOnChange }
          defaultValue={ defaultValue }
        />
      ) }
    />
  );
};

MultiSelectController.propTypes = {
  name: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          label: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  control: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
    register: PropTypes.func,
    getValues: PropTypes.func,
  }).isRequired,
  defaultValue: PropTypes.shape({
    parent: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
    child: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  }),
};

MultiSelectController.defaultProps = {
  defaultValue: null,
};

export default MultiSelectController;
