import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import NineBoxAvatar from "../NineBoxAvatar";
import NineBoxQuadrant from "../NineBoxQuadrant";
import UploadDataModal from "../UploadDataModal";
import { quadrantConfigs } from "../../functions";
import {
  StyledBoxContainer,
  StyledExpandedQuadrant,
  StyledCloseButton,
  StyledQuadrantBox,
  StyledLabel,
  StyledAxisLabel,
  StyledLink,
} from "./styles";

const yLabels = [
  { label: "High", range: "(71-100)" },
  { label: "Mid", range: "(35-70)" },
  { label: "Low", range: "(0-34)" },
];

const xLabels = [
  { label: "Low", range: "(0-34)" },
  { label: "Mid", range: "(35-70)" },
  { label: "High", range: "(70-100)" },
];

const NineBox = ({
  axisXLabel, axisYLabel, people, t, onSubmit, hasAvatar,
}) => {
  const [expandedQuadrant, setExpandedQuadrant] = useState(null);
  const [hoveredQuadrant, setHoveredQuadrant] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const groupedByQuadrant = useMemo(() => {
    const groups = Array.from({ length: 9 }, () => []);

    people.forEach((p) => {
      const index = quadrantConfigs.findIndex((q) => q.number === p.quadrant);
      if (index !== -1) {
        groups[index].push(p);
      }
    });

    return groups;
  }, [people]);

  const quadrantRow = expandedQuadrant !== null ? Math.floor(expandedQuadrant / 3) : null;
  const quadrantCol = expandedQuadrant !== null ? expandedQuadrant % 3 : null;

  return (
    <StyledBoxContainer>
      <Grid container>
        <Grid
          item
          xs={ 1 }
          sx={ { display: "flex", alignItems: "center", justifyContent: "center" } }
        >
          <StyledAxisLabel
            variant={ "h4" }
            sx={ { transform: "rotate(-90deg)", whiteSpace: "nowrap" } }
          >
            {axisYLabel}
          </StyledAxisLabel>
        </Grid>

        <Grid
          item
          xs={ 1 }
          sx={ {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
            pr: 1,
          } }
        >
          {expandedQuadrant === null
            ? yLabels.map((yLabel) => (
              <StyledLabel
                key={ yLabel.label }
                variant={ "body1" }
                sx={ {
                  textAlign: "center",
                  transform: "rotate(-90deg)",
                  whiteSpace: "nowrap",
                } }
              >
                {yLabel.label}
                <br />
                <span>{yLabel.range}</span>
              </StyledLabel>
            ))
            : (
              <StyledLabel
                key={ yLabels[quadrantRow].label }
                variant={ "body1" }
                sx={ {
                  textAlign: "center",
                  transform: "rotate(-90deg)",
                  whiteSpace: "nowrap",
                } }
              >
                {yLabels[quadrantRow].label}
                <br />
                <span>{yLabels[quadrantRow].range}</span>
              </StyledLabel>
            )}
        </Grid>

        <Grid item xs={ 10 }>
          <StyledQuadrantBox>
            {expandedQuadrant === null ? (
              <Grid container sx={ { width: "100%", height: "100%" } }>
                {quadrantConfigs.map((quad, i) => (
                  <Grid
                    key={ quad.id }
                    item
                    xs={ 4 }
                    sx={ {
                      border: "3px solid #fff",
                      backgroundColor: quad.fillColor || "#fff",
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      aspectRatio: "1 / 1",
                      cursor: "pointer",
                      transition: "transform 0.3s ease-in-out",
                      overflow: "visible",
                    } }
                    onClick={ () => setExpandedQuadrant(i) }
                    onMouseEnter={ () => setHoveredQuadrant(i) }
                    onMouseLeave={ () => setHoveredQuadrant(null) }
                  >
                    {!isMobile && (
                      <NineBoxQuadrant quad={ quad } isActive={ hoveredQuadrant === i } />
                    )}
                    {isMobile ? (
                      <Typography
                        variant={ "h5" }
                        sx={ {
                          color: "#fff",
                          fontWeight: "bold",
                          position: "absolute",
                          zIndex: 2,
                        } }
                      >
                        {`${groupedByQuadrant[i]?.length} ${t(
                          "common:abbreviations.employees",
                        )}`}
                      </Typography>
                    ) : (
                      <div className={ "avatar-container" }>
                        {groupedByQuadrant[i]?.map((person) => (
                          <NineBoxAvatar
                            key={ person.id }
                            person={ person }
                            hasAvatar={ hasAvatar }
                          />
                        ))}
                      </div>
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <StyledExpandedQuadrant
                backgroundColor={ quadrantConfigs[expandedQuadrant].fillColor }
              >
                <StyledCloseButton onClick={ () => setExpandedQuadrant(null) }>
                  <CloseIcon fontSize={ "small" } />
                </StyledCloseButton>
                <Typography variant={ "h5" } sx={ { fontWeight: "bold", color: "#fff" } }>
                  {`${quadrantConfigs[expandedQuadrant].number}. ${quadrantConfigs[expandedQuadrant].name}`}
                </Typography>
                <div className={ "avatar-container" }>
                  {groupedByQuadrant[expandedQuadrant]?.map((person) => (
                    <NineBoxAvatar
                      key={ `expanded-${person.id}` }
                      person={ person }
                      size={ isMobile ? 30 : 70 }
                      hasAvatar={ hasAvatar }
                    />
                  ))}
                </div>
              </StyledExpandedQuadrant>
            )}
            {people.length === 0 && (
              <Box
                sx={ {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  zIndex: 10,
                } }
              >
                <Typography variant={ "h6" } sx={ { mb: 1 } }>{t("ninebox:no_data.title")}</Typography>
                { !isMobile
                && (
                  <StyledLink variant={ "body2" } color={ "primary" } sx={ { cursor: "pointer", textDecoration: "underline" } } onClick={ () => setOpenModal(true) }>
                    {t("ninebox:no_data.link")}
                  </StyledLink>
                )}
              </Box>
            )}
          </StyledQuadrantBox>
        </Grid>
      </Grid>

      <UploadDataModal
        open={ openModal }
        onClose={ () => setOpenModal(false) }
        onSubmit={ onSubmit }
      />

      <Grid container>
        <Grid item xs={ 2 } />
        <Grid item xs={ 10 }>
          <Grid container>
            {expandedQuadrant === null
              ? xLabels.map((xLabel) => (
                <Grid
                  item
                  xs={ 4 }
                  key={ xLabel.label }
                  sx={ {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  } }
                >
                  <StyledLabel variant={ "body1" } sx={ { textAlign: "center" } }>
                    {xLabel.label}
                    <br />
                    <span>{xLabel.range}</span>
                  </StyledLabel>
                </Grid>
              ))
              : (
                <Grid
                  item
                  xs={ 12 }
                  key={ xLabels[quadrantCol].label }
                  sx={ {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  } }
                >
                  <StyledLabel variant={ "body1" } sx={ { textAlign: "center" } }>
                    {xLabels[quadrantCol].label}
                    <br />
                    <span>{xLabels[quadrantCol].range}</span>
                  </StyledLabel>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={ 2 } />
        <Grid item xs={ 10 }>
          <StyledAxisLabel variant={ "h4" } sx={ { textAlign: "center", mt: 2 } }>
            {axisXLabel}
          </StyledAxisLabel>
        </Grid>
      </Grid>
    </StyledBoxContainer>
  );
};

NineBox.propTypes = {
  axisXLabel: PropTypes.string.isRequired,
  axisYLabel: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      full_name: PropTypes.string.isRequired,
      quadrant: PropTypes.number.isRequired,
      quadrant_x: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      quadrant_y: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      profile_img: PropTypes.string.isRequired,
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasAvatar: PropTypes.bool.isRequired,
};

export default NineBox;
