import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import InputTextController from "components/InputTextController";
import SelectController from "components/SelectController";
import SkeletonLoader from "components/SkeletonLoader";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import Button from "components/Button";
import {
  TYPES, LOCAL_STORAGE_NAMES, OBJECT_KEYS, VARIANT, COLORS_NAME,
} from "common/constants";
import { getFormattedItems } from "common/utils";
import { isNull, isEmpty } from "common/helpers";
import { CONTRACT_TYPES, CITIES } from "views/GeneralAdministrator/functions";
import { getList as getEmploymentRelationship } from "redux/actions/common/employmentRelationshipActions";
import {
  StyledContent, StyledInputs, StyledActions, StyledSelectContainer,
} from "views/GeneralAdministrator/styles";
import { getPulseValidation } from "../../functions";

// TODO: refactor all file
const InputRow = (props) => {
  const {
    module,
    control,
    parentId,
    classes,
    onCancel = () => {},
    onAdd,
    isLoading,
    disabled,
    isNewId,
    defaultValues,
  } = props;

  const { t } = useTranslation("common");
  const [disabledButton, setIsDisabledButton] = useState(false);
  const [pulse, setPulse] = useState({
    name: false,
    employment_relationship: false,
  });

  const dispatch = useDispatch();
  const {
    list: listEmploymentRelationship,
    isLoadingList: isLoadingListEmploymentRelationship,
  } = useSelector(
    ({ employmentRelationshipReducer }) => employmentRelationshipReducer,
  );

  useEffect(() => {
    dispatch(getEmploymentRelationship());
  }, [dispatch]);

  const getCustomInputs = () => {
    let customRender;
    switch (module) {
    case CONTRACT_TYPES:
      customRender = (
        <StyledSelectContainer simple>
          <SelectController
            id={ `${module}-relationship` }
            label={ t("common.employment_relationship") }
            control={ control }
            menuItems={ getFormattedItems(null, listEmploymentRelationship) }
            name={ `${module}.employment_relationship` }
            customStyles={ clsx(classes?.inputRow, pulse.employment_relationship ? classes.pulse : "") }
            required
            defaultValue={ defaultValues?.employment_relationship }
          />
        </StyledSelectContainer>
      );
      break;
    case CITIES:
      customRender = (
        <StyledSelectContainer>
          <AutoCompleteCreableController
            options={ getFormattedItems(LOCAL_STORAGE_NAMES.countries) }
            label={ t("common.country") }
            control={ control }
            rules={ { required: true } }
            name={ `${module}.country_id` }
            nameOfAttr={ OBJECT_KEYS.label }
            fieldValue={ OBJECT_KEYS.value }
            isWithHelperText
            isRequired
            customStyles={ clsx(classes?.inputRow, pulse.country ? classes.pulse : "") }
            defaultValue={ defaultValues?.country_id }
          />
        </StyledSelectContainer>

        // <SelectController
        //   id={ `${module}-country` }
        //   label={ t("common.country") }
        //   control={ control }
        //   menuItems={ getFormattedItems(LOCAL_STORAGE_NAMES.countries) }
        //   name={ `${module}.country_id` }
        //   customStyles={ clsx(classes?.inputRow, pulse.country ? classes.pulse : "") }
        //   required
        // />
      );
      break;

    default:
      break;
    }
    return customRender;
  };

  const setPulseValidation = (pulseValidation) => {
    switch (module) {
    case CONTRACT_TYPES:
      setPulse({
        name: pulseValidation.name,
        employment_relationship: pulseValidation.employment_relationship,
      });
      break;

    default:
      setPulse({
        name: pulseValidation.name,
      });
      break;
    }
  };

  const handleSubmit = () => {
    const pulseValidation = getPulseValidation(module, control.getValues());
    setPulseValidation(pulseValidation);
    const validArray = Object.keys(pulseValidation).filter((key) => pulseValidation[key]);
    if (isEmpty(validArray)) {
      setIsDisabledButton(true);
      if (!disabledButton) {
        onAdd();
      }
    } else {
      setIsDisabledButton(false);
    }
  };

  return isLoadingListEmploymentRelationship && isNull(listEmploymentRelationship)
    ? <SkeletonLoader /> : (
      <StyledContent data-testid={ "general-administrator-input-row" }>
        <StyledInputs>
          <InputTextController
            id={ `${module}-${parentId}` }
            type={ TYPES.text }
            label={ t("common.name") }
            required
            control={ control }
            name={ `${module}.name` }
            customStyles={ clsx(classes?.inputRow, pulse.name ? classes.pulse : "") }
            isLoading={ isLoading }
            disabled={ disabled }
            defaultValue={ defaultValues?.name }
          />
          {getCustomInputs(module)}
        </StyledInputs>
        <StyledActions>
          <Button
            onClick={ onCancel }
            variant={ VARIANT.outlined }
            color={ COLORS_NAME.primary }
          >
            { t("common.cancel") }
          </Button>
          <Button
            onClick={ handleSubmit }
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
          >
            { isNewId ? t("common.add") : t("common.edit") }
          </Button>
        </StyledActions>
      </StyledContent>
    );
};

InputRow.propTypes = {
  module: PropTypes.string,
  control: PropTypes.object,
  parentId: PropTypes.number,
  classes: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isNewId: PropTypes.bool,
};

export default InputRow;
