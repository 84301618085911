import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Modal from "components/Modal";
import InputForm from "components/InputForm";
import {
  DATE_PARTS, INDEX, INPUT_RULES, INPUT_TYPE, OBJECT_KEYS,
} from "common/constants";
import { getErrorMessage } from "common/formValidators";
import { isEqual } from "common/helpers";
import { replacePath } from "common/utils";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import {
  getMaritalStatusList,
} from "../../../../../../functions/profile";
import localRoutes from "../../../../../../functions/routes";
import { StyledInputForm } from "./styles";

const EditFormModal = (props) => {
  const {
    t,
    history,
    profileStates,
    hookForm,
    genderList,
    modalButtons,
    onSubmit,
    errors,
    process,
    onAutocompleteGenderChange,
  } = props;

  const { isModalOpen, isDisableInputOther } = profileStates;
  const { control } = hookForm;

  return (
    <Modal
      isOpen={ isModalOpen.personalInfo }
      onClose={ () => replacePath(history, localRoutes.root) }
      title={ t("profile:editProfile") }
      onSubmit={ hookForm.handleSubmit(onSubmit) }
      buttons={ modalButtons }
      disabled={ !isEqual(Object.entries(errors)?.length, INDEX.zero) }
      isLoading={ process.isLoadingProcess }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 6 }>
            <StyledInputForm
              type={ INPUT_TYPE.date }
              label={ t("profile:form.dateOfBirth") }
              control={ control }
              name={ "collaborator.person_attributes.birth_date" } // TODO: do it for candidate as well
              openTo={ DATE_PARTS.year }
              value={ null }
              views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
              maxDate={ new Date() }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("profile:form.dni") }
              control={ control }
              name={ "collaborator.person_attributes.personal_id" } // TODO: do it for candidate as well
              rules={ INPUT_RULES.required }
              error={ getErrorMessage(errors, "collaborator.person_attributes.personal_id", "dni") }
              isRequired
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.email }
              label={ t("profile:form.personalEmail") }
              control={ control }
              name={ "collaborator.person_attributes.personal_email" } // TODO: do it for candidate as well
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("profile:form.nationality") }
              control={ control }
              name={ "collaborator.person_attributes.nationality" } // TODO: do it for candidate as well
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              id={ OBJECT_KEYS.gender }
              type={ INPUT_TYPE.autocomplete }
              label={ t("profile:form.gender") }
              control={ control }
              options={ genderList }
              name={ "collaborator.person_attributes.gender" } // TODO: do it for candidate as well
              nameOfAttr={ OBJECT_KEYS.label }
              fieldValue={ OBJECT_KEYS.id }
              onChangeValue={ onAutocompleteGenderChange() }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("profile:form.other") }
              control={ control }
              name={ "collaborator.person_attributes.gender_other" } // TODO: do it for candidate as well
              disabled={ isDisableInputOther }
              error={ getErrorMessage(errors, "collaborator.person_attributes.gender_other", "gender_other") }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("profile:form.address") }
              control={ control }
              name={ "collaborator.person_attributes.address" } // TODO: do it for candidate as well
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.select }
              id={ OBJECT_KEYS.maritalStatus }
              label={ t("profile:form.maritalStatus") }
              control={ control }
              menuItems={ getMaritalStatusList(t) }
              name={ "collaborator.person_attributes.marital_status" } // TODO: do it for candidate as well
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("profile:form.linkedinProfile") }
              control={ control }
              name={ "collaborator.person_attributes.linkedin_profile" } // TODO: do it for candidate as well
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("profile:form.phoneNumber") }
              control={ control }
              name={ "collaborator.person_attributes.phone_number" } // TODO: do it for candidate as well
            />
          </Grid>
        </Grid>
      </StyledScrollBar>
    </Modal>
  );
};

EditFormModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  profileStates: PropTypes.object.isRequired,
  hookForm: PropTypes.object.isRequired,
  genderList: PropTypes.array.isRequired,
  modalButtons: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onAutocompleteGenderChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  process: PropTypes.object,
};

EditFormModal.defaultProps = {
  errors: null,
  process: null,
};

export default EditFormModal;
