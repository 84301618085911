import styled from "styled-components";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { isEqual } from "common/helpers";
import palette from "theme/palette";
import Grid from "@mui/material/Grid";

export const StyledViewHeaderTitle = styled(ViewHeaderTitle)`
  margin-bottom: 10px;
`;

export const StyledCircle = styled.circle`
  stroke-width: ${(props) => (props.focus ? "0.3px" : "0.1px")};
  cursor: pointer;
  :hover {
    stroke: ${(props) => (isEqual(props.order, 0) ? palette.white : palette.background.purple)}
  }
`;

export const StyledGroupNumber = styled.text`
  font-size: 2px;
  fill: ${palette.white};
`;

export const StyledFocusRect = styled.rect`
  opacity: 0.5;
  fill: ${palette.white};
`;

export const StyledTooltipRect = styled.rect`
  fill: ${palette.background.purple};
`;

export const StyledTooltipText = styled.text`
  font-size: 1.6px;
  fill: ${palette.white};
`;

export const StyledListItemAvatar = styled(ListItemAvatar)`
  min-width: 32px !important;
`;

export const StyledSmallAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`;

export const StyledContainer = styled(Grid)`
  margin-top: 10px;
`;
