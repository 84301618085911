import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputForm from "components/InputForm";
import ManagerSelect from "components/commons/ManagerSelect";
import AlertDialog from "components/AlertDialog";
import AutoCompleteController from "components_refactor/AutocompleteController";
import {
  DATE_PARTS,
  INPUT_RULES,
  INPUT_TYPE,
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
} from "common/constants";
import { getErrorMessage } from "common/formValidators";
import { getFormattedItems } from "common/utils";
import { getMovementsList } from "views_refactor/Profile/functions/profile";
import { getMinDate } from "views_refactor/Profile/functions/movements";
import { StyledGrid } from "../../styles";

const MovementForm = (props) => {
  const {
    t,
    collaborator,
    movementStates,
    movementsHistoryListState,
    hookMovementForm,
    employeeInfoStates,
    onAutocomplete,
    otherFields,
    handleFunctions,
    salaryInfoStates,
    employeeErrors,
    salaryErrors,
    isLoadingMovement,
  } = props;

  const { movementTypes, typeSelected } = movementStates;
  const { control } = hookMovementForm;
  const { positions, divisions } = employeeInfoStates.optionsList;
  const { areas } = otherFields;
  const { handleNew, handleDialog, handleDialogSubmit } = handleFunctions;
  const { onAutocompleteChange, onChangeDivision } = onAutocomplete;
  const { optionsList } = salaryInfoStates;
  const { list } = movementsHistoryListState;
  const { inputTextValue, newCreation, isOpenDialog } = employeeInfoStates;

  const handleDivision = (divisionId) => {
    onChangeDivision(divisionId, hookMovementForm.setValue);
  };

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          type={ INPUT_TYPE.date }
          label={ t("profile:form.movementDate") }
          control={ control }
          name={ "collaborator.date" } // TODO: do it for candidate as well
          openTo={ DATE_PARTS.year }
          views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
          minDate={ getMinDate(list) }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputForm
          type={ INPUT_TYPE.select }
          id={ OBJECT_KEYS.type }
          label={ t("profile:form.movementType") }
          control={ control }
          menuItems={ getMovementsList(t, movementTypes) }
          name={ "collaborator.type" } // TODO: do it for candidate as well
        />
      </Grid>
      { ((typeSelected === OBJECT_KEYS.lateralMovement) || (typeSelected === OBJECT_KEYS.promotion))
        && (
          <>
            <Grid item xs={ 12 } md={ 6 }>
              <InputForm
                id={ OBJECT_KEYS.position }
                options={ positions }
                type={ INPUT_TYPE.autocomplete }
                label={ t("common:common.position") }
                control={ control }
                name={ "collaborator.job_position_attributes.position_id" } // TODO: do it for candidate as well
                nameOfAttr={ OBJECT_KEYS.name }
                fieldValue={ OBJECT_KEYS.id }
                onChangeValue={ onAutocompleteChange() }
                addNew={ {
                  handleNew: handleDialogSubmit,
                  text: t("common:common.add_new_position"),
                  prop: OBJECT_KEYS.position,
                } }
                rules={ INPUT_RULES.required }
                error={ getErrorMessage(employeeErrors, "collaborator.job_position_attributes.position_id", "position") }
                isRequired
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteController
                id={ "division" }
                options={ divisions }
                name={ "collaborator.job_position_attributes.division_id" }
                label={ t("common:common.division") }
                control={ control }
                onChangeValue={ handleDivision }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <AutoCompleteController
                id={ "area" }
                options={ areas }
                name={ "collaborator.job_position_attributes.area_id" }
                label={ t("common:common.area") }
                control={ control }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <ManagerSelect
                name={ "collaborator.job_position_attributes.manager_id" }
                control={ control }
                employee={ collaborator }
              />
            </Grid>
          </>
        )}
      { ((typeSelected === OBJECT_KEYS.promotion) || (typeSelected === OBJECT_KEYS.salaryRaise))
        && (
          <>
            <Grid item xs={ 12 } md={ 3 }>
              <InputForm
                type={ INPUT_TYPE.currency }
                id={ OBJECT_KEYS.salary }
                label={ t("common:common.salary") }
                control={ control }
                name={ "collaborator.salary_attributes.gross_salary" } // TODO: do it for candidate as well
              />
            </Grid>
            <Grid item xs={ 12 } md={ 3 }>
              <InputForm
                type={ INPUT_TYPE.autocomplete }
                id={ OBJECT_KEYS.currency }
                options={ optionsList.currencies }
                label={ t("common:common.currency.main") }
                control={ control }
                name={ "collaborator.salary_attributes.currency" } // TODO: do it for candidate as well
                nameOfAttr={ OBJECT_KEYS.nameWithCode }
                rules={ INPUT_RULES.required }
                error={ getErrorMessage(salaryErrors, "collaborator.salary_attributes.currency_id", "currency") }
                isRequired
              />
            </Grid>
            <StyledGrid item xs={ 12 } md={ 6 }>
              <InputForm
                type={ INPUT_TYPE.select }
                id={ OBJECT_KEYS.typeOfContract }
                label={ t("common:common.typeOfContract") }
                control={ control }
                name={ "collaborator.job_position_attributes.type_of_contract_id" } // TODO: do it for candidate as well
                menuItems={ getFormattedItems(LOCAL_STORAGE_NAMES.typeOfContract) }
              />
            </StyledGrid>
          </>
        )}
      <AlertDialog
        isOpen={ isOpenDialog }
        onClose={ () => handleDialog(false) }
        title={ `${t(`common:common.add_new_${newCreation}`)}: ${inputTextValue}` }
        message={ t("common:common.modal_messages.sure_text") }
        // True is sent to update the movements form
        onSubmit={ () => handleNew(true) }
        buttons={ { isLoading: isLoadingMovement } }
      />
    </Grid>
  );
};

MovementForm.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  movementStates: PropTypes.object.isRequired,
  hookMovementForm: PropTypes.object.isRequired,
  employeeInfoStates: PropTypes.object.isRequired,
  onAutocomplete: PropTypes.object.isRequired,
  otherFields: PropTypes.object.isRequired,
  handleFunctions: PropTypes.object.isRequired,
  salaryInfoStates: PropTypes.object.isRequired,
  movementsHistoryListState: PropTypes.object.isRequired,
  isLoadingMovement: PropTypes.bool.isRequired,
  employeeErrors: PropTypes.object,
  salaryErrors: PropTypes.object,
};

MovementForm.defaultProps = {
  collaborator: null,
  employeeErrors: null,
  salaryErrors: null,
};

export default MovementForm;
