import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "components/Button";
import SkeletonLoader from "components/SkeletonLoader";
import { BUTTON_STYLE_TYPES, SCROLL_BEHAVIOR, SKELETONS_NUMBER } from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import { getAllStates, STATES } from "./functions";
import {
  StyledContainer, StyledActions, StepState, StyledStepLabel, StyledStepper,
} from "./styles";

const StepperComponent = (props) => {
  const {
    steps, onClick, end, lastStep, sent, noShowState,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation(["candidates", "preboarding"]);
  const allStates = getAllStates(t);

  useEffect(() => {
    if (end) setActiveStep(steps.length);
  }, [end, steps]);

  const handleNext = () => {
    if (steps[activeStep]?.additionalInNext) {
      steps[activeStep].additionalInNext();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => (steps[step]?.isLoading
    ? (<SkeletonLoader num={ SKELETONS_NUMBER.FOUR } />)
    : steps[step]?.content);

  const getState = (index, item) => {
    let state = STATES.pending;
    if (isEqual(activeStep, index) && !isEqual(index, steps.length)) {
      state = STATES.inProgress;
    } else if (sent && isEqual(index, steps.length)) {
      state = STATES.sent;
    } else if ((!item?.disabled && index < activeStep && !isEqual(index, steps.length)) || (sent && index > activeStep)) {
      state = STATES.completed;
    } else if (isEqual(index, steps.length)) {
      state = null;
    }
    return state && (
      <StepState
        color={ allStates[state].color }
        background={ allStates[state].background }
      >
        { allStates[state].name }
      </StepState>
    );
  };

  useEffect(() => {
    const element = document.getElementById(`step-${activeStep}`);
    if (element) {
      element.scrollIntoView({ behavior: SCROLL_BEHAVIOR.smooth });
    }

    if (steps[activeStep]?.sendNext && !isEqual(activeStep, steps.length - 1)) {
      handleNext();
      steps[activeStep].setNext(false);
    }
    // eslint-disable-next-line
  }, [steps, activeStep]);

  const buttonContent = steps[activeStep]?.idForm ? (
    <Button
      form={ steps[activeStep]?.idForm }
      variant={ "contained" }
      type={ BUTTON_STYLE_TYPES.SUBMIT }
      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
      disabled={ steps[activeStep]?.disabled }
    >
      { t("induction.next") }
    </Button>
  ) : (
    <Button
      variant={ "contained" }
      onClick={ isEqual(activeStep, steps.length - 1)
        ? onClick
        : handleNext }
      type={ BUTTON_STYLE_TYPES.SUBMIT }
      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
      disabled={ steps[activeStep]?.disabled }
      isLoading={ steps[activeStep]?.isLoading }
    >
      {isEqual(activeStep, steps.length - 1) && !end
        ? t("induction.send")
        : t("induction.next")}
    </Button>
  );

  return (
    <StyledContainer data-testid={ "stepper" }>
      <StyledStepper alternativeLabel activeStep={ activeStep }>
        {steps
          && steps.map((item, index) => (
            <Step key={ index } id={ `step-${index}` }>
              <StyledStepLabel onClick={ () => setActiveStep(index) }>
                <Typography>{item.label}</Typography>
                { !noShowState && getState(index, item) }
              </StyledStepLabel>
            </Step>
          ))}
        {lastStep && !isEmpty(lastStep) && (
          <Step id={ `step-${steps.length}` }>
            <StyledStepLabel completed={ isEqual(activeStep, steps.length) } onClick={ () => setActiveStep(steps.length) }>
              <Typography>{lastStep.label}</Typography>
              { !noShowState && getState(steps.length, lastStep)}
            </StyledStepLabel>
          </Step>
        )}
      </StyledStepper>
      {steps && (
        <div>
          {isEqual(activeStep, steps.length) ? (
            <>
              {lastStep && lastStep.content && lastStep.content}
              <StyledActions>
                <Button
                  disabled={ isEqual(activeStep, 0) }
                  onClick={ handleBack }
                  typeStyle={ BUTTON_STYLE_TYPES.BACK }
                >
                  {t("induction.back")}
                </Button>
              </StyledActions>
            </>
          ) : (
            <>
              <Box mt={ 2 } mb={ 2 }>
                <Typography variant={ "h5" }>
                  {steps[activeStep]?.label}
                </Typography>
                {getStepContent(activeStep)}
              </Box>
              <StyledActions>
                <Button
                  disabled={ isEqual(activeStep, 0) }
                  onClick={ handleBack }
                  typeStyle={ BUTTON_STYLE_TYPES.BACK }
                >
                  {t("induction.back")}
                </Button>
                { buttonContent }
              </StyledActions>
            </>
          )}
        </div>
      )}
    </StyledContainer>
  );
};

StepperComponent.propTypes = {
  steps: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  end: PropTypes.bool,
  lastStep: PropTypes.object,
  sent: PropTypes.bool,
  noShowState: PropTypes.bool,
};

StepperComponent.defaultProps = {
  end: false,
  lastStep: null,
  sent: false,
  noShowState: false,
};

export default StepperComponent;
