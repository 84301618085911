import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledGrid = styled(Grid).withConfig({
  shouldForwardProp: (prop) => !["hasPadding", "padding"].includes(prop),
})`
  padding: ${(props) => (props.hasPadding ? props.padding : "0px")};

  .MuiSkeleton-root {
    border-radius: 5px;
  }
`;
