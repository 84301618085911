import isEmpty from "lodash/isEmpty";
import { getTooltipIcon, getTooltipTitle, getTooltipRecommendation } from "components/TalentSpiderChart/functions/dotTooltip";
import { getVerticesLabelIcon } from "components/TalentSpiderChart/functions";
import { INDEX } from "common/constants";
import { getPercent } from "common/utils";

export const TABS = (t) => [
  { label: t("map") },
  { label: t("recommendations") },
];

export const INDEX_TABS = {
  map: INDEX.zero,
  recommendations: INDEX.one,
};

// TODO: remove
export const getRecommendations = (data, t) => {
  if (data && !isEmpty(data)) {
    const recommendations = data[INDEX.zero].attrition_axis_values
      .filter((item) => item.value !== null)
      .map((item) => {
        return {
          icon: getVerticesLabelIcon(item.slug).icon,
          title: t(`chart.labels.${item.slug}`),
          percentage: getPercent(item.value, true),
          recommendation: {
            icon: getTooltipIcon(getPercent(item.value)),
            title: getTooltipTitle(getPercent(item.value), t),
            text: getTooltipRecommendation(getPercent(item.value), item.slug, t),
          },
        };
      });
    return recommendations;
  }
};
