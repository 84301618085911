import {
  useEffect, useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "components/Button";
import InputTag from "components/InputTag";
import InputCodeEditor from "components/InputCodeEditor";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import { isEmpty, isEqual, isBlank } from "common/helpers";
import { getFormattedTags, mergeListsOfObjects, removeById } from "common/utils";
import {
  BUTTON_STYLE_TYPES, MAX_EMPLOYEES_QUERY, OBJECT_KEYS, SIZE,
} from "common/constants";
import { getCollaboratorByEmails, getListSearchPaginated } from "redux/actions/collaboratorActions";
import { getCollaboratorByParts } from "views/Collaborators/functions";
import EmployeeValidator from "views/Collaborators/components/EmployeeValidator";
import { useCreateInternalClient, useInternalClientsByEmployee } from "hooks/useInternalClient";
import { StyledDialogActions, StyledTitleBox, StyledDialogContent } from "./styles";

const InternalClientModal = (props) => {
  const { isOpen, onClose, collaborator } = props;
  const { t } = useTranslation(["common", "audiences"]);
  const { handleSubmit, register } = useForm();

  const [searchFilter, setSearchFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inputTagsSelected, setInputTagsSelected] = useState([]);
  const [collaboratorsSelectedList, setCollaboratorsSelectedList] = useState([]);
  const [collaboratorsBySearchList, setCollaboratorsBySearchList] = useState([]);
  const [collaboratorsByEmailList, setCollaboratorsByEmailList] = useState([]);

  const { searchList: collaboratorList } = useSelector(
    ({ collaboratorReducer }) => collaboratorReducer,
  );
  const dispatch = useDispatch();

  const {
    internalClients,
    isLoading: isLoadingInternalClients,
  } = useInternalClientsByEmployee(collaborator?.id, t, true);

  const { mutate: createInternalClient, isLoading: isCreating } = useCreateInternalClient(t);

  const collaboratorsIds = [
    ...new Set([
      ...(internalClients?.map((col) => col.internal_client_id) || []),
      ...(collaborator?.id ? [collaborator.id] : []),
    ]),
  ];

  useEffect(() => {
    const query = {
      q: {
        person_full_name_cont: searchFilter,
        active_in: [true],
        id_not_in: collaboratorsIds,
      },
    };
    dispatch(getListSearchPaginated(query));
  }, [searchFilter, dispatch]);

  const handleInputText = (text) => {
    setSearchFilter(text);
  };

  const handleTags = (tags) => {
    setInputTagsSelected([]);
    setCollaboratorsBySearchList(mergeListsOfObjects(collaboratorsBySearchList, tags));
  };

  const removeItem = (id) => {
    const itemInSearchList = collaboratorsBySearchList.filter((item) => item.id === id);
    if (isEmpty(itemInSearchList)) {
      setCollaboratorsByEmailList(removeById(collaboratorsByEmailList, id));
    } else {
      setCollaboratorsBySearchList(removeById(collaboratorsBySearchList, id));
    }
  };

  const getEmployees = async (emails) => {
    let allResponse = [];
    if (emails.length > MAX_EMPLOYEES_QUERY) {
      allResponse = await getCollaboratorByParts(emails);
    } else {
      allResponse = await getCollaboratorByEmails(emails);
    }
    return allResponse;
  };

  const removeCollaboratorsList = () => {
    setCollaboratorsSelectedList([]);
    setCollaboratorsBySearchList([]);
    setCollaboratorsByEmailList([]);
  };

  const onChangeEditor = (newValue) => {
    const emails = [...new Set(newValue.split("\n").filter((e) => !isBlank(e)))];
    if (isEmpty(emails)) {
      removeCollaboratorsList();
    } else {
      setIsLoading(true);
      getEmployees(emails).then((allResponse) => {
        const orderByEmail = emails.map((email) => {
          const employee = allResponse?.find((item) => isEqual(item.email.trim(), email.trim()));
          if (employee) {
            return {
              id: employee.id,
              value: employee.full_name,
              label: employee.full_name,
              email: employee.email,
              isActive: employee.is_active,
            };
          }
          return { email, noExist: true };
        });
        setCollaboratorsByEmailList(orderByEmail);
      }).catch(() => {
        toast(MESSAGE_TYPES.error, { title: t("common:common.api_responses.error.title") });
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (collaboratorsByEmailList && !isEmpty(collaboratorsByEmailList)) {
      setIsLoading(false);
    }
  }, [collaboratorsByEmailList]);

  useEffect(() => {
    setCollaboratorsSelectedList(mergeListsOfObjects(collaboratorsBySearchList, collaboratorsByEmailList));
  }, [collaboratorsBySearchList, collaboratorsByEmailList]);

  const handleOnClose = () => {
    removeCollaboratorsList();
    onClose();
  };

  const everyoneIsActive = !isEmpty(collaboratorsSelectedList) && isEqual(
    collaboratorsSelectedList.length,
    collaboratorsSelectedList.filter((item) => item.isActive).length,
  );

  // Add internal clients
  const onSubmit = () => {
    const internalClientIds = collaboratorsSelectedList.map((item) => item.id);
    const data = {
      employees_internal_client: {
        employee_id: collaborator.id,
        internal_client_ids: internalClientIds,
      },
    };
    createInternalClient(data, {
      onSuccess: () => {
        handleOnClose();
      },
    });
  };

  const filteredCollaboratorList = collaborator?.id ? collaboratorList?.filter((col) => {
    if (col.id === collaborator.manager?.id) return false;
    if (col.manager_name === collaborator.manager?.name) return false;

    if (internalClients && internalClients.some(
      (client) => client.internal_client_id === col.id,
    )) {
      return false;
    }

    return true;
  }) : [];

  useEffect(() => {
    removeCollaboratorsList();
  }, []);

  return (
    <Dialog open={ isOpen } onClose={ handleOnClose } maxWidth={ "md" } fullWidth>
      <form onSubmit={ handleSubmit(onSubmit) }>
        <DialogTitle disableTypography>
          <Box display={ "flex" } mb={ 2 }>
            <StyledTitleBox flexGrow={ 1 }>{t("common:internal_clients.modal.title")}</StyledTitleBox>
            <Box>
              <IconButton onClick={ onClose }>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <StyledDialogContent>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 6 }>
              <Box mt={ 2 }>
                <InputTag
                  id={ "employee_ids" }
                  size={ SIZE.small }
                  itemsSelected={ inputTagsSelected }
                  placeholder={ t("common:common.searchCollaborators") }
                  register={ register }
                  onInputTextChange={ handleInputText }
                  onChange={ handleTags }
                  data={ getFormattedTags(filteredCollaboratorList, OBJECT_KEYS.fullname, true) }
                  withoutTags
                  disabled={ isLoadingInternalClients }
                />
              </Box>
              <Box mt={ 2 }>
                <InputCodeEditor
                  label={ t("audiences:copyCollaborators") }
                  placeholder={ t("common:common.email") }
                  onChange={ onChangeEditor }
                />
              </Box>
            </Grid>
            <Grid item xs={ 12 } md={ 6 } mt={ 2 }>
              <EmployeeValidator
                collaborators={ collaboratorsSelectedList }
                removeItem={ removeItem }
                isLoading={ isLoading }
              />
            </Grid>
          </Grid>
        </StyledDialogContent>
        <StyledDialogActions>
          <Button typeStyle={ BUTTON_STYLE_TYPES.CANCEL } onClick={ handleOnClose }>
            {t("Onboarding:cancelButton")}
          </Button>
          <Button typeStyle={ BUTTON_STYLE_TYPES.SUBMIT } type={ "submit" } disabled={ !everyoneIsActive || isCreating }>
            {isCreating ? t("common:common.loading") : t("common:common.save")}
          </Button>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
};

InternalClientModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
};

InternalClientModal.defaultProps = {
  collaborator: null,
};

export default InternalClientModal;
