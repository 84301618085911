import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import { SessionContext } from "modules/session/context";
import SkeletonLoader from "components/SkeletonLoader";
import InputTextController from "components/InputTextController";
import {
  toast, handleMessages, MESSAGE_TYPES, HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import AutoCompleteCreable from "components/AutocompleteCreable";
import AlertDialog from "components/AlertDialog";
import Button from "components/Button";
import { isNullOrUndefined } from "common/helpers";
import {
  SKELETONS_NUMBER,
  INPUT_TYPE,
  ALIGN_ITEMS,
  INDEX,
  ROLES,
  OBJECT_KEYS,
  VARIANT,
  TYPES,
  ACCEPTED_FILE_FORMATS,
  COMPONENT,
  TARGET,
  BUTTON_STYLE_TYPES,
  MAX_FILE_WEIGHT,
  MODULES,
  SIZE,
} from "common/constants";
import {
  isAdmin,
  isCandidate,
  getParamEmployeeId,
  canAccessModule,
} from "common/utils";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import {
  getList as getDocumentList,
  resetState as resetStateDocumentList,
  resetStateProcess,
  create as createDocument,
  deleteItem as deleteDocument,
  update as updateDocument,
} from "redux/actions/collaborators/documentActions";
import {
  getList as getDocumentTypes,
  create as createDocumentType,
  resetState as resetStateDocumentTypes,
} from "redux/actions/documentTypeActions";

import TablePagination from "components/TablePagination";
import {
  getDocumentsByCandidate,
  HEADER,
  getRowsMobile,
  getRowsDesktop,
} from "../../functions/documents";
import {
  StyledCardContent,
  StyledTableContainer,
  StyledUploadFileContainer,
  StyledButtonFile,
  StyledIconButton,
  StyledUpload,
  StyledDialogItemActions,
} from "./styles";

const DocumentsComponent = (props) => {
  const { params } = props;
  const { t } = useTranslation(["common", "account", "templates"]);
  const [currentCollaboratorId, setCurrentCollaboratorId] = useState();
  const [selectDocument, setSelectDocument] = useState();
  const [dialog, setDialog] = useState(false);
  const [newCreation, setNewCreation] = useState();
  const [defaultValue, setDefaultValue] = useState({
    name: null,
  });

  const dispatch = useDispatch();
  const {
    list,
    loadingList,
    successProcess,
    isLoadingProcess,
  } = useSelector(({ collaboratorDocumentReducer }) => collaboratorDocumentReducer);

  const {
    one: candidate,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const {
    list: documentTypesList,
    isLoadingList: isLoadingDocumentTypesList,
    newDocument: newDocumentType,
  } = useSelector(({ documentTypesReducer }) => documentTypesReducer);
  const {
    state: { user },
  } = useContext(SessionContext);

  const candidateValidation = canAccessModule(
    user?.userCookies, MODULES.candidate,
  ) && ROLES.CANDIDATE in params;

  useEffect(() => {
    const collaboratorId = getParamEmployeeId(user, params);
    setCurrentCollaboratorId(collaboratorId);
    if (!isCandidate(user?.userCookies) && !candidateValidation) {
      dispatch(getDocumentList(collaboratorId));
    }

    dispatch(getDocumentTypes());
  }, [dispatch, params, user, candidateValidation, newDocumentType]);

  useEffect(() => {
    if ((successProcess && !isCandidate(user?.userCookies)) || newDocumentType) {
      if (newDocumentType) {
        setSelectDocument(newDocumentType.document_type);
      }
      dispatch(resetStateProcess());
      dispatch(resetStateDocumentList());
      dispatch(resetStateDocumentTypes());
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      dispatch(getDocumentList(currentCollaboratorId));
      dispatch(getDocumentTypes());
    }
  }, [successProcess, dispatch, currentCollaboratorId, t, user, newDocumentType]);

  const handleSelect = (prop, event, newValue) => {
    setSelectDocument(newValue);
    setDefaultValue({
      ...defaultValue,
      name: newValue?.name,
    });
  };

  const handleDialog = (option) => {
    setDialog(option);
  };

  const handleSubmitDocumentType = (document) => {
    setNewCreation({ name: document });
    handleDialog(true);
  };

  const handleNew = async () => {
    await dispatch(createDocumentType(JSON.stringify(newCreation)));
    setDefaultValue({
      ...defaultValue,
      name: newCreation?.name,
    });
    handleDialog(false);
  };

  const handleDeleteDocument = (itemSelectedId) => {
    dispatch(deleteDocument(itemSelectedId));
  };

  const handleUpdateDocument = (formData, itemSelectedId) => {
    dispatch(updateDocument(formData, itemSelectedId));
  };

  const [file, setFile] = useState();

  const {
    control, watch, reset,
  } = useForm();

  const handleUpload = (event) => {
    const fileData = event.target?.files[INDEX.zero];
    const itemData = control.getValues();
    fileData.url = URL.createObjectURL(fileData);
    itemData.url = "";
    if (fileData.size > MAX_FILE_WEIGHT) {
      setFile(null);
      toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.exceedSizeLimit"),
      });
    } else {
      setFile(fileData);
    }
  };

  const onSubmit = async () => {
    const itemData = control.getValues();
    if (itemData.url || file) {
      dispatch(createDocument(file, selectDocument.id, currentCollaboratorId, itemData.url));
      setFile(null);
      setDefaultValue({
        ...defaultValue,
        name: null,
      });
      reset({ url: "" });
    } else {
      toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.emptyFile"),
      });
    }
  };

  const fileName = file?.name && (
    <>
      <Box>
        <Typography
          variant={ VARIANT.bodyTwo }
          component={ COMPONENT.span }
        >
          <Link href={ file?.url } target={ TARGET.blank }>
            {file?.name}
          </Link>
        </Typography>
      </Box>
      <Box>
        <StyledIconButton
          onClick={ () => setFile(null) }
        >
          <DeleteIcon />
        </StyledIconButton>
      </Box>
    </>
  );

  return (
    <>
      <Card data-testid={ "documents-tab-view-component" }>
        <StyledCardContent>
          <Grid container alignItems={ ALIGN_ITEMS.center } spacing={ 2 }>
            {isAdmin(user?.userCookies) && (
              <>
                <Grid item xs={ 12 }>
                  <StyledUpload>
                    <Box pr={ INDEX.one }>
                      <img alt={ t("account:accountDocuments.general_title") } src={ uploadImg } />
                    </Box>
                    <Box flexGrow={ INDEX.one }>
                      <Typography variant={ VARIANT.h6 }>
                        {t("account:accountDocuments.general_title")}
                      </Typography>
                      <Typography variant={ VARIANT.bodyTwo }>
                        {t("account:accountDocuments.upload_file_description")}
                      </Typography>
                    </Box>
                  </StyledUpload>
                </Grid>
                <Grid item xs={ 12 } md={ 3 }>
                  <AutoCompleteCreable
                    id={ OBJECT_KEYS.documentType }
                    options={ documentTypesList || [] }
                    label={ t("account:accountDocuments.select_document_type") }
                    onChange={ handleSelect }
                    value={ defaultValue.name }
                    disabled={ isLoadingDocumentTypesList }
                    nameOfAttr={ "name" }
                    addNew={ {
                      handleNew: handleSubmitDocumentType,
                      text: t("account:accountDocuments.add_new_document_type"),
                      prop: OBJECT_KEYS.documentType,
                    } }
                    size={ SIZE.small }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 9 }>
                  <StyledUploadFileContainer>
                    <StyledButtonFile
                      variant={ VARIANT.outlined }
                      typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                      component={ COMPONENT.label }
                      disabled={ watch(OBJECT_KEYS.url) }
                    >
                      {t("templates:sections.form.upload_file")}
                      <input
                        accept={ ACCEPTED_FILE_FORMATS }
                        type={ INPUT_TYPE.file }
                        onChange={ handleUpload }
                      />
                    </StyledButtonFile>
                    { fileName }
                  </StyledUploadFileContainer>
                </Grid>
                <Grid item xs={ 12 } md={ 10 }>
                  <InputTextController
                    type={ TYPES.text }
                    label={ t("templates:sections.form.url") }
                    control={ control }
                    name={ OBJECT_KEYS.url }
                    disabled={ file?.name }
                    size={ SIZE.small }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 2 }>
                  <StyledDialogItemActions>
                    <Button
                      typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                      onClick={ onSubmit }
                      disabled={ isNullOrUndefined(selectDocument) }
                    >
                      { t("common:common.save") }
                    </Button>
                  </StyledDialogItemActions>
                </Grid>
              </>
            )}
            <Grid item xs={ 12 }>
              {loadingList ? (
                <SkeletonLoader num={ SKELETONS_NUMBER.NINE } />
              ) : (
                <StyledTableContainer>
                  <TablePagination
                    header={ HEADER }
                    list={ isCandidate(user?.userCookies) || candidateValidation
                      ? getDocumentsByCandidate(candidate.requested_documents)
                      : list }
                    isLoading={ isLoadingProcess }
                    getRows={ {
                      mobile: getRowsMobile,
                      desktop: getRowsDesktop,
                    } }
                    extraParam={ user }
                    modalOptions={ [handleDeleteDocument, handleUpdateDocument, currentCollaboratorId] }
                  />
                </StyledTableContainer>
              )}
            </Grid>
          </Grid>
        </StyledCardContent>
      </Card>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => handleDialog(false) }
        title={ t("account:accountDocuments.add_new_document_type") }
        message={ t("common.modal_messages.sure_text") }
        onSubmit={ handleNew }
        buttons={ {
          isLoading: false,
        } }
      />
    </>
  );
};

DocumentsComponent.propTypes = {
  params: PropTypes.object.isRequired,
};

export default DocumentsComponent;
