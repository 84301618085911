import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import Grid from "@mui/material/Grid";
import InputTextController from "components/InputTextController";
import InputNumberController from "components/InputNumberController";
import DateInputController from "components/DateInputController";
import SelectController from "components/SelectController";
import SkeletonLoader from "components/SkeletonLoader";
import {
  OBJECT_KEYS, DATE_PARTS, TYPES, INDEX,
} from "common/constants";
import { isEmpty, isNull } from "common/helpers";
import { getList as getFollowUpList } from "redux/actions/followUpActions";
import { getProcessTypes, getFollowUp } from "../../functions";
import { StyledMainContainer, StyledGrid } from "./styles";

const ProcessInformation = (props) => {
  const { control, isEdit, watch } = props;
  const { t } = useTranslation(["surveys", "common"]);

  const {
    list: followUpList,
    isLoadingList: isLoadingFollowUpList,
  } = useSelector(({ followUpReducer }) => followUpReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFollowUpList());
  }, [dispatch]);

  if (isLoadingFollowUpList && isNull(followUpList)) {
    return (<SkeletonLoader />);
  }
  return (
    <StyledMainContainer container spacing={ 3 } data-testid={ "process-information" }>
      <Grid item xs={ 12 } md={ 6 }>
        <SelectController
          label={ t("surveys:form.fields.process_type") }
          control={ control }
          menuItems={ getProcessTypes(t) }
          name={ OBJECT_KEYS.type }
          disabled={ isEdit }
          required
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <SelectController
          label={ t("surveys:form.fields.follow_up") }
          control={ control }
          menuItems={ getFollowUp(followUpList) }
          name={ "follow_up_process_id" }
          disabled={ isEmpty(followUpList) }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <InputTextController
          type={ TYPES.text }
          label={ `${t("surveys:form.fields.process_name")} (${t("common:locales:es")})` }
          control={ control }
          required
          name={ OBJECT_KEYS.nameEs }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <InputTextController
          type={ TYPES.text }
          label={ `${t("surveys:form.fields.process_name")} (${t("common:locales:en")})` }
          control={ control }
          required
          name={ OBJECT_KEYS.nameEn }
        />
      </Grid>
      <Grid item xs={ 12 }>
        <InputTextController
          type={ TYPES.text }
          label={ `${t("surveys:form.fields.process_name")} (${t("common:locales:pt")})` }
          control={ control }
          required
          name={ OBJECT_KEYS.namePt }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <InputNumberController
          label={ t("surveys:form.fields.min_worked_days") }
          control={ control }
          name={ OBJECT_KEYS.minWorkedDays }
          isInteger
          disabled={ watch("follow_up_process_id") > INDEX.zero }
        />
      </Grid>
      <StyledGrid item xs={ 12 } md={ 3 }>
        <DateInputController
          label={ t("surveys:form.fields.start_date") }
          control={ control }
          name={ OBJECT_KEYS.start_date }
          openTo={ DATE_PARTS.year }
          value={ moment() }
          views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
          required
          disabled={ watch("follow_up_process_id") > INDEX.zero }
        />
      </StyledGrid>
      <StyledGrid item xs={ 12 } md={ 3 }>
        <DateInputController
          label={ t("surveys:form.fields.end_date") }
          control={ control }
          name={ OBJECT_KEYS.end_date }
          openTo={ DATE_PARTS.year }
          value={ moment() }
          views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
          required
          disabled={ watch("follow_up_process_id") > INDEX.zero }
        />
      </StyledGrid>
    </StyledMainContainer>
  );
};

ProcessInformation.propTypes = {
  control: PropTypes.object,
  isEdit: PropTypes.bool,
  watch: PropTypes.object,
};

export default ProcessInformation;
