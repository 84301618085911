import styled from "styled-components";
import makeStyles from "@mui/styles/makeStyles";
import ListItem from "@mui/material/ListItem";
import Button from "components/Button";
import palette from "theme/palette";
import typography from "theme/typography";

export const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `${theme.spacing(1, 1.5)}!important`,
    color: `${theme.palette.divider}!important`,
    border: `${theme.palette.border.divider}!important`,
  },
  nav: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.darkBlue,
  },
}));

export const StyledItem = styled(ListItem)`
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: ${(props) => (props.sub ? "30" : "0")}px;

  .MuiButton-root.Mui-disabled {
    color: ${palette.text.darkBlue};
    opacity: 0.4;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => (props.active ? palette.background.lightGrey : "transparent")};
  color: ${palette.text.darkBlue};
  padding: 10px 8px;
  justify-content: flex-start;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
  font-family: ${(props) => (props.mobile ? typography.fonts.primary : typography.fonts.secondary)};
  font-weight: 500;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    border-left: ${palette.border.sidebar};
    background-color: ${palette.background.lightGrey};
    border-radius: inherit;

    .MuiSvgIcon-root {
      color: ${palette.text.link};
    }
  }

  ${(props) => (props.active
  ? `{
      background-color: ${palette.background.lightGrey};
    border-left: ${palette.border.sidebar};
    border-radius: inherit;

    svg:hover path {
      fill: ${palette.text.link};
    }`
  : "")}
`;

export const StyledIcon = styled.div`
  color: ${(props) => (props.active ? palette.text.link : palette.text.darkBlue)};
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
`;
