import styled from "styled-components";

export const StyledProcessColor = styled.span`
  width: 5px;
  min-height: 50px;
  background-color: ${(props) => props.color || "#fff"};
  display: inline-block;
  margin-right: 5px;
  flex-shrink: 0;
`;
