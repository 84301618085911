import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePaginationInfinityScroll from "components/TablePaginationInfinityScroll";
import SkeletonLoader from "components/SkeletonLoader";
import {
  ALIGN_ITEMS,
  SKELETONS_NUMBER,
  VARIANT,
} from "common/constants";
import { RISK_CATEGORY } from "views/TalentDrain/functions";
import UserIcon from "assets/images/icons/user.svg";
import { useDataCollapse } from "views/TalentDrain/hooks/useDataCollapse";
import { useDataTable } from "views/TalentDrain/hooks/useDataTable";
import {
  StyledCard,
  StyledCardContent,
  StyledCollapseArrow,
  StyledCollapseContainer,
  StyledPercent,
  StyledRight,
  StyledRisk,
} from "./styles";

// FIXME: the first get are executed 2 times
const RiskCardCollapse = (props) => {
  const {
    type,
    totalByCategory,
    isLoading,
    isOpenCollapse,
    handleCollapseState,
    isShortVersion,
    handleOpenModal,
  } = props;
  const { t } = useTranslation(["common", "talentDrain"]);

  const {
    allData,
    pagination,
    percent,
    handleSort,
    handlePagination,
  } = useDataCollapse({ ...props });

  const {
    HEADER,
    ORDER,
    SHORT_HEADER,
    getRowsDesktop,
    getShortRowsDesktop,
    getShortRowsMobile,
  } = useDataTable();

  return (
    <>
      <StyledCard onClick={ () => handleCollapseState(type) }>
        <StyledCardContent>
          <Grid container alignItems={ ALIGN_ITEMS.center }>
            <Grid item xs={ 12 } sm={ 7 }>
              {isLoading && (pagination === 1) ? (
                <SkeletonLoader
                  num={ SKELETONS_NUMBER.ONE }
                />
              ) : (
                <StyledCollapseContainer>
                  <StyledPercent>{ `${percent}%` }</StyledPercent>
                  <StyledRisk color={ RISK_CATEGORY[type].color }>
                    <Typography variant={ VARIANT.h4 }>{t(`talentDrain:${type}Risk`)}</Typography>
                    <LinearProgress
                      variant={ VARIANT.determinate }
                      value={ percent }
                    />
                  </StyledRisk>
                </StyledCollapseContainer>
              )}
            </Grid>
            <Grid item xs={ 12 } sm={ 5 }>
              <StyledRight>
                <img src={ UserIcon } alt={ "Icon" } />
                {isLoading && (pagination === 1) ? (
                  <SkeletonLoader
                    num={ SKELETONS_NUMBER.ONE }
                    isInline={ false }
                  />
                ) : (
                  <Typography variant={ VARIANT.bodyOne }>

                    { `${totalByCategory} ${t("common:common.collaborators").toLowerCase()}` }
                  </Typography>
                )}
                <StyledCollapseArrow>
                  {isOpenCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </StyledCollapseArrow>
              </StyledRight>
            </Grid>
          </Grid>
        </StyledCardContent>
      </StyledCard>
      <Collapse in={ isOpenCollapse } unmountOnExit>
        <TablePaginationInfinityScroll
          header={ isShortVersion ? SHORT_HEADER : HEADER }
          list={ allData }
          isLoading={ isLoading }
          getRows={ {
            desktop: isShortVersion ? getShortRowsDesktop : getRowsDesktop,
            mobile: isShortVersion && getShortRowsMobile,
          } }
          handlePagination={ handlePagination }
          total={ totalByCategory }
          order={ ORDER }
          sortAction={ handleSort }
          extraParam={ handleOpenModal }
        />
      </Collapse>
    </>
  );
};

RiskCardCollapse.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  handleCollapseState: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  total: PropTypes.number,
  totalByCategory: PropTypes.number,
  isOpenCollapse: PropTypes.bool,
  isLoading: PropTypes.bool,
  query: PropTypes.object,
  searchFilter: PropTypes.string,
  isShortVersion: PropTypes.bool,
};

RiskCardCollapse.defaultProps = {
  data: [],
  total: 0,
  totalByCategory: 0,
  isOpenCollapse: false,
  isLoading: false,
  query: null,
  searchFilter: null,
  isShortVersion: false,
};

export default RiskCardCollapse;
