import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { getDataNineBoxQuadrant } from "views/MatrixNineBox/functions";
import StaticCustomProgressBar from "components/StaticCustomProgressBar";
import InputForm from "components/InputForm";
import Button from "components/Button";
import TooltipIcon from "components/TooltipIcon";
import SkeletonLoader from "components/SkeletonLoader";
import AlertDialog from "components/AlertDialog";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import CollaboratorProfile from "components_refactor/CollaboratorProfileSummaryCard/components/CollaboratorProfile";
import Results from "components_refactor/CollaboratorProfileSummary/Results";
import {
  updateSuccessionPlanByCollaborator,
  deleteSuccessionPlanByCollaborator,
} from "redux/actions/collaboratorActions";
import {
  getSuccessionPlansById,
} from "redux/actions/position/positionActions";
import { getCurrentTalentScoreByEmployee } from "redux/actions/talentScore/talentScoreActions";
import {
  INPUT_RULES, INPUT_TYPE,
  BUTTON_STYLE_TYPES, SIZE,
  SKELETONS_NUMBER, NINEBOX,
} from "common/constants";
import { formatDate } from "common/utils";
import palette from "theme/palette";
import { calculateTimePassedPercentageAndRemainingTime, getEstimatedTime } from "views/Successions/functions";
import {
  StyledButtons, StyledSuccessionItem,
  StyledSuccessionContainer,
} from "../CollaboratorInfo/styles";
import { StyledSuccessionPlan } from "./styles";

const SuccessionInfo = React.memo((props) => {
  const {
    succession,
    t,
    estimatedTime,
    handleDeleteSuccession,
  } = props;

  const {
    control, setValue, getValues,
  } = useForm();
  const dispatch = useDispatch();

  const {
    list: resultScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const successionPlanId = succession.id;
  const [successionPlans, setSuccessionPlans] = useState([]);
  const [editingSuccessionId, setEditingSuccessionId] = useState(null);
  const [dialog, setDialog] = useState({ isOpen: false, selectedId: null });
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: successionPlanByEmployee,
    isLoading: isLoadingSuccessionPlan,
    refetch,
    // error,
  } = useQuery(["successionPlanByPositionEmployee", successionPlanId], () => getSuccessionPlansById(successionPlanId), {
    staleTime: Infinity,
  });

  const { nineBoxTypeList, isLoading: isLoadingType } = useNineBoxTypes(t);

  const getNineboxResult = async (selectedEmployee) => {
    let nineboxResult = null;
    let quadrant = null;
    const currentTalentScore = await getCurrentTalentScoreByEmployee(selectedEmployee);
    if (currentTalentScore?.performance && currentTalentScore?.potential) {
      const results = getDataNineBoxQuadrant(currentTalentScore, resultScaleList, t, nineBoxTypeList);
      if (results.length > 0) {
        quadrant = results[0].quadrantData[NINEBOX.quadrantData.quadrant];
      }
      const performanceDate = new Date(currentTalentScore.performance.score_updated_at);
      const potentialDate = new Date(currentTalentScore.potential.score_updated_at);
      const latestDate = performanceDate > potentialDate ? performanceDate : potentialDate;
      nineboxResult = {
        nineboxQuadrant: {
          quadrant,
          updatedAt: formatDate(latestDate),
        },
      };
    }
    return nineboxResult;
  };

  const getDataByEmployee = useCallback(async () => {
    setIsLoading(true);
    try {
      const nineboxResult = await getNineboxResult(successionPlanByEmployee.employee.id);
      succession.collaborator.results = nineboxResult;
      setSuccessionPlans([successionPlanByEmployee]);
      const matchingOption = getEstimatedTime(successionPlanByEmployee, estimatedTime);
      setValue(`estimated_time_${successionPlanByEmployee.id}`, matchingOption);
    } catch (error) {
      setSuccessionPlans([successionPlanByEmployee]);
    } finally {
      setIsLoading(false);
    }
  }, [successionPlanByEmployee, dispatch, setValue, setIsLoading, getEstimatedTime]);

  useEffect(() => {
    if (successionPlanByEmployee && !isLoadingType) {
      getDataByEmployee();
    }
    // eslint-disable-next-line
  }, [successionPlanByEmployee]);

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateSuccession = (successionId) => {
    setEditingSuccessionId(successionId);
  };

  const handleUpdateSuccessionPlan = async (successionId) => {
    const fieldName = `estimated_time_${successionId}`;
    const newEstimatedTime = getValues(fieldName);

    if (typeof newEstimatedTime === "string") {
      const dataToSend = {
        succession_plan: {
          estimated_time: newEstimatedTime,
        },
      };
      const successionPlan = await dispatch(
        updateSuccessionPlanByCollaborator(successionId, dataToSend),
      );
      setSuccessionPlans((currentPlans) => currentPlans.map(
        (plan) => (plan.id === successionId ? successionPlan : plan),
      ));
    }
    setEditingSuccessionId(null);
  };

  const handleCloseEditForm = () => {
    setEditingSuccessionId(null);
  };

  const handleOpenDeleteDialog = (id) => {
    setDialog({ isOpen: true, selectedId: id });
  };

  const handleCloseDialog = () => {
    setDialog({ isOpen: false, selectedId: null });
  };

  const handleDelete = async (successionId) => {
    await dispatch(deleteSuccessionPlanByCollaborator(successionId));
    setSuccessionPlans((currentPlans) => currentPlans.filter((plan) => plan.id !== successionId));
    setDialog({ isOpen: false, selectedId: null });
    handleDeleteSuccession(successionId);
  };

  const getSuccessionContent = (successionPlan) => {
    let defaultValue = null;
    if (editingSuccessionId) {
      defaultValue = getEstimatedTime(successionPlan, estimatedTime) || null;
    }

    const {
      percentage,
      remainingTimeKey,
      remainingTimeValue,
    } = calculateTimePassedPercentageAndRemainingTime(successionPlan);
    const description = t(`successions:plan.${remainingTimeKey}`, { months: remainingTimeValue, years: remainingTimeValue });

    return (
      <Grid item xs={ 12 } md={ 6 }>
        {editingSuccessionId === successionPlan.id ? (
          <Controller
            control={ control }
            name={ `estimated_time_${successionPlan.id}` }
            rules={ INPUT_RULES.required }
            render={ () => (
              <InputForm
                id={ `estimated_time_${successionPlan.id}` }
                options={ estimatedTime }
                type={ INPUT_TYPE.autocomplete }
                label={ t("successions:estimated_time") }
                control={ control }
                name={ `estimated_time_${successionPlan.id}` }
                nameOfAttr={ "name" }
                fieldValue={ "value" }
                size={ SIZE.small }
                defaultValue={ defaultValue }
              />
            ) }
          />
        ) : (
          <StaticCustomProgressBar
            title={ t("successions:plan.progress") }
            percentage={ percentage }
            color={ palette.background.purpleBox }
            description={ description }
            hasOpacity={ successionPlan?.total_agreements === 0 }
            t={ t }
            name={ succession?.collaborator?.full_name }
          />
        )}
      </Grid>
    );
  };

  const successionInfo = (
    <>
      {(isLoadingSuccessionPlan || isLoading)
  && <SkeletonLoader num={ SKELETONS_NUMBER.SIX } />}
      {!isLoadingSuccessionPlan && successionPlans?.length > 0
  && (
    <StyledSuccessionPlan>
      {successionPlans.map((successionPlan) => (
        <StyledSuccessionItem key={ successionPlan.id }>
          <StyledSuccessionContainer container spacing={ 3 } alignItems={ "center" }>
            <Grid item xs={ 12 } md={ 4 }>
              <CollaboratorProfile collaborator={ successionPlan.employee } />
            </Grid>
            {getSuccessionContent(successionPlan)}
            {editingSuccessionId === successionPlan.id
              ? (
                <StyledButtons item xs={ 12 } md={ 2 }>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    size={ SIZE.small }
                    onClick={ () => handleUpdateSuccessionPlan(successionPlan.id) }
                  >
                    <TooltipIcon title={ t("common:common.save") } element={ <SaveIcon /> } />
                  </Button>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    onClick={ handleCloseEditForm }
                    size={ SIZE.small }
                  >
                    <TooltipIcon title={ t("common:common.cancel") } element={ <CloseIcon /> } />
                  </Button>
                </StyledButtons>
              ) : (
                <StyledButtons item xs={ 12 } md={ 2 }>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                    size={ SIZE.small }
                    onClick={ () => handleUpdateSuccession(successionPlan.id) }
                    disabled={ editingSuccessionId !== null }
                  >
                    <TooltipIcon title={ t("common:common.edit") } element={ <EditIcon /> } />
                  </Button>
                  <Button
                    typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                    size={ SIZE.small }
                    disabled={ editingSuccessionId !== null }
                    onClick={ () => handleOpenDeleteDialog(successionPlan.id) }
                  >
                    <TooltipIcon title={ t("common:common.delete") } element={ <DeleteIcon /> } />
                  </Button>
                </StyledButtons>
              )}
            <Grid item xs={ 12 } md={ 12 }>
              <Results t={ t } collaborator={ succession.collaborator } />
            </Grid>
          </StyledSuccessionContainer>
        </StyledSuccessionItem>
      ))}
    </StyledSuccessionPlan>
  )}
    </>
  );

  return (
    <>
      {successionInfo}
      <AlertDialog
        isOpen={ dialog.isOpen }
        onClose={ handleCloseDialog }
        title={ t("successions:actions.delete.title") }
        message={ t("successions:actions.delete.description") }
        onSubmit={ () => handleDelete(dialog.selectedId) }
      />
    </>
  );
});

SuccessionInfo.propTypes = {
  succession: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  estimatedTime: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  handleDeleteSuccession: PropTypes.func.isRequired,
};

export default SuccessionInfo;
