import Grid from "@mui/material/Grid";
import TaskStatusCard from "components/TaskStatusCard";
import NoDataMessage from "components_refactor/NoDataMessage";
import {
  isEqual, isNotValid,
} from "common/helpers";
import {
  FOLLOW_UP_STATUS,
  SURVEY_PROCESS_TYPE,
} from "common/constants";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import {
  sumDaysFromDate,
  getDaysFromToday,
  getMaxFollowUpDays,
  formatDate,
} from "common/utils";
import { StyledProcessContainer, StyledTitle } from "../styles";

const getStatus = (startingDate, followUpdays) => {
  const days = getDaysFromToday(startingDate);
  if (days > followUpdays) {
    return FOLLOW_UP_STATUS.incomplete;
  }
  if (days < followUpdays) {
    return FOLLOW_UP_STATUS.closed;
  }
  return FOLLOW_UP_STATUS.pending;
};

const getPendingTasksByProcess = (process, followUpDate) => (
  <Grid item xs={ 12 } md={ 4 }>
    <StyledProcessContainer>
      <StyledTitle>{ process?.name }</StyledTitle>
      {process?.process_detail?.evaluations?.map((evaluation) => (
        <div key={ evaluation.id }>
          {evaluation.team_members?.map((item) => (
            <TaskStatusCard
              key={ item.id }
              title={ item.full_name }
              subtitle={ followUpDate }
              link={ item.link }
              status={ process?.process_detail.status }
            />
          ))}
        </div>
      ))}
    </StyledProcessContainer>
  </Grid>
);

export const getFollowUpProcess = (followUpList, surveyProcess, startingDate, isValidToAnswer, isPending = false) => {
  if (isNotValid(surveyProcess)) return <NoDataMessage />;

  const processList = isPending ? surveyProcess?.filter((e) => (isEqual(e.type, SURVEY_PROCESS_TYPE.performance.key))) : surveyProcess;
  const processData = processList?.map((process) => {
    const processDetail = process?.process_detail;
    const evaluations = processDetail?.evaluations?.filter((evaluation) => !isEqual(evaluation.evaluation_type, EVALUATION_TYPE.MANAGER));
    const followUpDays = followUpList?.find((followUp) => isEqual(followUp.id, process?.follow_up_process_id))?.days || 0;
    const followUpDate = formatDate(sumDaysFromDate(startingDate, followUpDays, true));
    const evaluationLink = !isNotValid(evaluations) && (evaluations[0]?.link || null);
    const isComplete = isEqual(processDetail?.status, FOLLOW_UP_STATUS.complete);
    const followUpStatus = isComplete ? FOLLOW_UP_STATUS.complete : getStatus(startingDate, followUpDays);
    if (isPending) return getPendingTasksByProcess(process, followUpDate);
    const followUpStartDate = formatDate(processDetail?.follow_up_data?.date);
    const isValidDateToAnswer = isValidToAnswer && processDetail?.follow_up_data?.is_valid;

    if ((isComplete || evaluationLink)) {
      return (
        <TaskStatusCard
          key={ process.id }
          title={ process.name }
          subtitle={ followUpStartDate }
          link={ isValidDateToAnswer ? evaluationLink : null }
          status={ followUpStatus }
        />
      );
    }
    return "";
  });
  return processData;
};
