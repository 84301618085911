import PropTypes from "prop-types";

const BounceLoader = (props) => {
  const { length, align } = props;
  return (
    <div className={ `bounce-container ${align}` }>
      {[...Array(length).keys()].map((index) => {
        let classname = "";
        if (index === 0) {
          classname = "bounce1";
        } else if (index === 1) {
          classname = "bounce2";
        }
        return <div key={ `bounce-${index}` } className={ classname } />;
      })}
    </div>
  );
};

BounceLoader.propTypes = {
  length: PropTypes.number,
  align: PropTypes.string,
};

BounceLoader.defaultProps = {
  length: 3,
  align: "",
};
export default BounceLoader;
