import { useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import charts from "theme/charts";
import Modal from "components/Modal";
import CircleLoader from "components_refactor/CircleLoader";
// import PerformanceChart from "components/PerformanceChart";
import ModalCardInfo from "components/ModalCardInfo";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import NoDataMessage from "components_refactor/NoDataMessage";
import Header from "./components/Header";
import TopContent from "./components/TopContent";
import CategoriesResults from "./components/CategoriesResults";
import Answers from "./components/Answers";
import Comments from "./components/Comments";
import Recommendations from "./components/Recommendations";

const GoodLeaderModal = (props) => {
  const {
    t,
    lang,
    profileStates,
    goodLeaderStates,
    goodLeaderValues,
    surveyStates,
    // evolution,
    handleChange,
    // getAverageResult,
    handleRecommendationModal,
    onCloseModals,
    collaborator,
  } = props;

  const [viewSelected, setViewSelected] = useState(0);
  const { isModalOpen } = profileStates;
  const { isOpenRecommendationModal, answerSelected } = goodLeaderStates;
  // const { goodLeaderEvolution, isLoadingGoodLeaderEvolution } = evolution;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const handleSelection = (optionSelected) => {
    setViewSelected(optionSelected);
  };

  const initialRender = surveyStates.surveyResults === null
    || surveyStates.isLoadingSurveyResults
    || goodLeaderValues.data === null
    ? (
      <CircleLoader />
    ) : (
      <>
        <Header
          evaluation={ goodLeaderStates.evaluationSelected }
          evaluations={ goodLeaderStates.evaluationList }
          collaborator={ collaborator }
          handleChange={ handleChange }
          isMobile={ isMobile }
          showProfile={ goodLeaderValues.data?.results_by_evaluation_type === null }
        />
        {goodLeaderStates.evaluationList?.length === 0
          ? <NoDataMessage /> : (
            <>
              {goodLeaderValues.data?.results_by_evaluation_type !== null && (
                <TopContent
                  goodLeaderValues={ goodLeaderValues }
                  isMobile={ isMobile }
                  handleSelection={ handleSelection }
                />
              )}
              {viewSelected === 0 ? (
                <Grid item xs={ 12 }>
                  <CategoriesResults
                    values={ goodLeaderValues.data }
                    scale={ goodLeaderValues.scale }
                    handleModal={ handleRecommendationModal }
                    isMobile={ isMobile }
                  />
                </Grid>
              ) : (
                <Answers
                  values={ goodLeaderValues.answers }
                  scale={ goodLeaderValues.scale }
                  handleModal={ handleRecommendationModal }
                />
              )}
              {goodLeaderValues.comments?.length > 0 && (
                <Comments values={ goodLeaderValues.comments } />
              )}
              <ModalCardInfo
                isOpen={ isOpenRecommendationModal }
                onClose={ handleRecommendationModal }
                title={ answerSelected?.name }
              >
                <Recommendations t={ t } language={ lang } data={ answerSelected } />
              </ModalCardInfo>
            </>
          )}
      </>
    );

  return (
    <Modal
      isOpen={ isModalOpen.goodLeader }
      onClose={ () => onCloseModals() }
    >
      <StyledScrollBar maxHeight={ "620px" } padding={ "16px" } minWidth={ "1120px" }>
        {initialRender}
      </StyledScrollBar>
    </Modal>
  );
};

GoodLeaderModal.propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  onCloseModals: PropTypes.func.isRequired,
  profileStates: PropTypes.object.isRequired,
  goodLeaderStates: PropTypes.object.isRequired,
  goodLeaderValues: PropTypes.object.isRequired,
  surveyStates: PropTypes.object.isRequired,
  // evolution: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  // getAverageResult: PropTypes.func.isRequired,
  handleRecommendationModal: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
};

export default GoodLeaderModal;
