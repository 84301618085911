import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import clsx from "clsx";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/Button";
import { LANGUAGES_NAMES } from "common/constants";
import {
  useStyles,
  StyledContainer,
  StyledFormControl,
  StyledSelect,
} from "./styles";

const ChangeLanguageSelect = ({ isCustomIcon }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const [cookies, setCookie] = useCookies(["language"]);

  const getInitialLanguage = () => {
    if (cookies.language) {
      return cookies.language;
    }
    return "es";
  };

  const [language, setLanguage] = useState(getInitialLanguage);

  const [openSelect, setOpenSelect] = useState(false);

  const handleChange = (event) => {
    const newLang = event.target.value;
    setCookie("language", newLang);
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
  };

  const handleOpenClose = () => setOpenSelect(!openSelect);

  const isBlueTheme = isCustomIcon && classes.blueTheme;

  useEffect(() => {
    if (cookies.language && cookies.language !== language) {
      setLanguage(cookies.language);
    }
    // eslint-disable-next-line
  }, [cookies.language]);

  return (
    <StyledContainer data-testid={ "change-language-select" }>
      <Button onClick={ handleOpenClose }>
        <GTranslateIcon className={ clsx(classes.icon, isBlueTheme) } />
      </Button>
      <StyledFormControl>
        <StyledSelect
          custom={ isCustomIcon }
          open={ openSelect }
          onClose={ handleOpenClose }
          onOpen={ handleOpenClose }
          value={ language }
          onChange={ handleChange }
          inputProps={ {
            classes: {
              icon: clsx(classes.icon, isBlueTheme),
            },
          } }
        >
          <MenuItem value={ "es" }>{LANGUAGES_NAMES.spanish}</MenuItem>
          <MenuItem value={ "en" }>{LANGUAGES_NAMES.english}</MenuItem>
          <MenuItem value={ "pt" }>{LANGUAGES_NAMES.portuguese}</MenuItem>
        </StyledSelect>
      </StyledFormControl>
    </StyledContainer>
  );
};

ChangeLanguageSelect.propTypes = {
  isCustomIcon: PropTypes.bool,
};

ChangeLanguageSelect.defaultProps = {
  isCustomIcon: false,
};

export default ChangeLanguageSelect;
