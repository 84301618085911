import {
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SkeletonLoader from "components/SkeletonLoader";
import SelectWithImage from "components/SelectWithImage";
import NineBox from "components/NineBox";
import { isEmpty, isNull } from "common/helpers";
import { SKELETONS_NUMBER } from "common/constants";
import {
  isAdmin,
  getDefaultFormatOption, getEmployeeId, getSelectCollaboratorOptions,
} from "common/utils";
import { usePatternImageNineBox } from "hooks/usePatternImageNineBox";
import { SessionContext } from "modules/session/context";
import { getList as getAttritionCollaboratorChart } from "redux/actions/collaborators/attritionActions";
import { resetStateOne, getChildren } from "redux/actions/collaboratorActions";
import { getList as getResultScales } from "redux/actions/resultScalesActions";
import { getListByEmployee as getNineboxResultByEmployee } from "redux/actions/talentScore/talentScoreActions";
import Recommendations from "views/Account/components/LossRisk/components/Recommendations";
import { getRecommendations } from "views_refactor/Profile/functions/lossRisk";
import { getCircleByProfile, getDataNineBoxResult } from "views/MatrixNineBox/functions";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import DetailsByCollaborator from "./components/DetailsByCollaborator";

const Overview = (props) => {
  const {
    selectIdFromTable,
  } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const employeeId = user && getEmployeeId(user);
  const { t, i18n } = useTranslation(["talentDrain", "potential", "common"]);
  // If you don't choose to view the matrix from 'in progress',
  // then set the first child as the default.
  const [idSelected, setIdSelected] = useState(selectIdFromTable);
  const [nineBoxResultEmployee, setNineBoxResultEmployee] = useState();
  const [searchText, setSearchText] = useState("");

  const dispatch = useDispatch();

  const {
    list: collaboratorAttrition,
    loadingList: isLoadingCollaboratorAttrition,
  } = useSelector(({ collaboratorAttritionReducer }) => collaboratorAttritionReducer);

  const {
    childrenList,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    one: collaboratorData,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    list: resultScaleList,
    isLoadingList: isLoadingScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const { data: historical, isLoading: isLoadingData } = useQuery(
    ["historical", idSelected],
    () => getNineboxResultByEmployee(idSelected),
    { staleTime: Infinity },
    [idSelected],
  );

  const getChildrenData = useCallback(() => {
    const query = {
      q: {
        employee_id: employeeId,
        id_not_in: [employeeId],
      },
    };
    if (!isAdmin(user?.userCookies)) {
      query.q = { manager_id_special_in: [employeeId] };
    }
    query.q.active_in = [true];
    query.q.person_full_name_cont = searchText;
    dispatch(getChildren(query));
  }, [employeeId, user, searchText, dispatch]);

  useEffect(() => {
    getChildrenData();
    // eslint-disable-next-line
  }, [searchText]);

  const { nineBoxTypeList, isLoading } = useNineBoxTypes(t);

  const handleHistoricalNinebox = useCallback(async () => {
    if (!isEmpty(historical)) {
      const results = await getDataNineBoxResult(historical, resultScaleList, t, nineBoxTypeList);
      setNineBoxResultEmployee(results);
    } else {
      setNineBoxResultEmployee([]);
    }
  }, [historical, resultScaleList, t]);

  useEffect(() => {
    if (!isLoadingData && !isLoadingScaleList && !isLoading) {
      handleHistoricalNinebox(historical);
    }
    // eslint-disable-next-line
  }, [historical, isLoadingData, isLoadingScaleList]);

  useEffect(() => {
    if (!isNull(idSelected)) {
      dispatch(getAttritionCollaboratorChart(idSelected, { q: { employee_id_eq: employeeId } }));
    }
  }, [dispatch, idSelected, employeeId]);

  useEffect(() => {
    if (!isNull(selectIdFromTable)) {
      dispatch(resetStateOne());
    }
  }, [selectIdFromTable, dispatch]);

  useEffect(() => {
    if (isNull(resultScaleList) && !isLoadingScaleList) {
      dispatch(getResultScales());
    }
  }, [resultScaleList, isLoadingScaleList, dispatch]);

  const handleChange = (id) => {
    setIdSelected(id);
    dispatch(resetStateOne());
    setNineBoxResultEmployee([]);
  };

  const patternImage = usePatternImageNineBox(nineBoxResultEmployee);

  const getContent = isLoadingData || !nineBoxResultEmployee ? (
    <SkeletonLoader num={ SKELETONS_NUMBER.NINE } />
  ) : (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } md={ 7 }>
        <NineBox>
          {nineBoxResultEmployee && (
            <>
              {patternImage !== "" && patternImage}
              {getCircleByProfile(
                nineBoxResultEmployee,
                t,
              )}
            </>
          )}
        </NineBox>
      </Grid>
      <Grid item xs={ 12 } md={ 5 }>
        {nineBoxResultEmployee && (
          <DetailsByCollaborator
            collaboratorId={ idSelected }
            results={ nineBoxResultEmployee[0] }
            attritionStates={ {
              data: collaboratorAttrition,
              collaborator: collaboratorAttrition?.responseList[0],
              isLoading: isLoadingCollaboratorAttrition,
            } }
            recommendations={ !isEmpty(collaboratorAttrition?.responseList) ? (
              <Recommendations
                data={ getRecommendations(collaboratorAttrition?.responseList, t, i18n.language) }
                isLoading={ isLoadingCollaboratorAttrition }
              />
            ) : null }
          />
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid container data-testid={ "overview-view-component" }>
      <Grid item xs={ 12 } md={ 5 }>
        <SelectWithImage
          menuItems={ getSelectCollaboratorOptions(childrenList) }
          value={ idSelected }
          onChange={ handleChange }
          disabled={ isEmpty(childrenList) }
          setSearchText={ setSearchText }
          defaultValue={ collaboratorData && getDefaultFormatOption(collaboratorData) }
        />
      </Grid>
      { getContent }
    </Grid>
  );
};

Overview.propTypes = {
  selectIdFromTable: PropTypes.number.isRequired,
};

export default Overview;
