import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TodayOutlinedIcon from "@mui/icons-material/Today";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import Banner from "components_refactor/Banner";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components_refactor/NoDataMessage";
import {
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
  VARIANT,
} from "common/constants";
import { isEmpty } from "common/helpers";
import { formatDate } from "common/utils";
import { EVENT_STATE_COLORS } from "theme/palette";
import EvaluationsSection from "./components/EvaluationsSection";
import { getDescriptionBySurvey } from "./functions";
import {
  StyledCard, StyledColumn, StyledContainer, StyledEvaluationTitle, StyledGrid, StyledItem,
} from "./styles";

const CoverView = (props) => {
  const {
    t, summaryStates, evaluationStates,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const { evaluation, justOne, percentage } = summaryStates;
  const { isLoadingListByEmployee } = evaluationStates;

  const hasCompletedEvaluations = percentage === 100;

  const bannerRender = isMobile ? (
    <StyledEvaluationTitle>
      <img src={ evaluation?.processType?.icon } alt={ evaluation?.processType?.label || "" } />
      { evaluation?.name }
    </StyledEvaluationTitle>
  ) : (
    <Banner
      title={ evaluation?.name }
      icon={ evaluation?.processType?.icon }
    />
  );

  const evaluationDetails = (
    <StyledGrid container spacing={ 3 }>
      <Grid item xs={ 12 } sm={ justOne ? 12 : 4 }>
        <StyledColumn color={ hasCompletedEvaluations ? EVENT_STATE_COLORS.green : null } >
          <CheckCircleOutlineIcon />
          <StyledItem>
            {t(`surveys:state.${hasCompletedEvaluations ? "" : "in"}complete`)}
          </StyledItem>
        </StyledColumn>
      </Grid>
      <Grid item xs={ 12 } sm={ justOne ? 12 : 4 }>
        <StyledColumn color={ evaluation?.isFinished ? EVENT_STATE_COLORS.red : null }>
          <TodayOutlinedIcon />
          <StyledItem>
            <Typography variant={ VARIANT.bodyOne }>
              {`${t(`surveys:state.${evaluation?.isFinished ? "finished" : "finishDate"}`)}:`}
            </Typography>
            {formatDate(evaluation?.endDate)}
          </StyledItem>
        </StyledColumn>
      </Grid>
    </StyledGrid>
  );

  return (
    <StyledContainer>
      <Grid container spacing={ 3 }>
        {!isLoadingListByEmployee && isEmpty(evaluation) ? <NoDataMessage /> : (
          <>
            <Grid item xs={ 12 } >
              { isLoadingListByEmployee || !evaluation ? (
                <SkeletonLoader
                  num={ SKELETONS_NUMBER.ONE }
                  variant={ SKELETON_VARIANT.rectangular }
                  height={ 200 }
                  columnWidth={ 12 }
                  hasPadding={ false }
                />
              ) : bannerRender }
            </Grid>
            <Grid item xs={ 12 }>
              <StyledCard>
                { isLoadingListByEmployee || !evaluation ? (
                  <SkeletonLoader num={ SKELETONS_NUMBER.THREE } isInline={ false } />
                ) : (
                  <>
                    <Typography variant={ VARIANT.h5 }>
                      { getDescriptionBySurvey(t)[evaluation?.type] }
                    </Typography>
                    { evaluationDetails }
                    {!justOne && <Typography variant={ VARIANT.h5 }>{t("surveys:toEvaluate")}</Typography>}
                  </>
                )}
                <EvaluationsSection { ...props } />
              </StyledCard>
            </Grid>
          </>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default CoverView;
