import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tab from "components/Tab";
import { StyledTitle, StyledTabsContainer } from "styledComponents/Tabs";
import TabPanel from "components_refactor/TabPanel";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components_refactor/Filter";
import TalentManager from "views/TalentManager";
import {
  ORG_UNITS, DEFAULT_SORTS, DOWNLOAD_TYPE,
} from "common/constants";
import usePagination from "hooks/utils/usePagination";
import {
  StyledDashboardContainer,
} from "styledComponents/View";
import Matrix from "./components/Matrix";
import { StyledContainer } from "./styles";

const LeadershipView = (props) => {
  const { t, handleExternalQuery } = props;

  const [tabHandler, setTabHandler] = useState(0);

  const allTabs = [{ label: t("common:common.report") }, { label: t("common:common.survey") }];

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const { pagination, setPagination, handlePagination } = usePagination(1);
  const [filterQuery, setFilterQuery] = useState();
  const [searchFilter, setSearchFilter] = useState();
  const [selectHeatMap, setSelectHeatMap] = useState(ORG_UNITS.division);
  const [tableSort, setTableSort] = useState(DEFAULT_SORTS.score);

  const [values, setValues] = useState({
    evaluation: "",
    downloadHeatMap: DOWNLOAD_TYPE.none,
  });

  const prevSelectedHeatMapRef = useRef();
  useEffect(() => {
    prevSelectedHeatMapRef.current = selectHeatMap;
  });
  const prevSelectedHeatMap = prevSelectedHeatMapRef.current;

  const handleExternalLeadership = (query) => {
    setFilterQuery(query);
    handleExternalQuery(query);
  };

  const query = {
    q: {
      employee_active_in: [true],
      active_in: [true],
    },
  };

  useEffect(() => {
    if (!filterQuery) {
      handleExternalLeadership(query);
    }
  }, []);

  return (
    <StyledDashboardContainer>
      <ViewHeaderTitle title={ t("leadership:title") } />
      <StyledTabsContainer item xs={ 12 } spacing={ 1 }>
        <Tab
          tabs={ allTabs }
          onChange={ handleValueChanged }
          tabValue={ tabHandler }
        />
        <TabPanel value={ tabHandler } index={ 0 }>
          <StyledContainer container spacing={ 2 }>
            <Grid item xs={ 12 } >
              <Filter
                onApplyFilters={ handleExternalLeadership }
              />
            </Grid>
          </StyledContainer>
          <StyledTitle
            variant={ "h4" }
            fontWeight={ 700 }
          >
            {t("leadership:leadership_types_matrix")}
          </StyledTitle>
          <Matrix { ...props } />
        </TabPanel>
        <TabPanel value={ tabHandler } index={ 1 }>
          <TalentManager
            values={ values }
            selectHeatMap={ selectHeatMap }
            setValues={ setValues }
            prevSelectedHeatMap={ prevSelectedHeatMap }
            setSelectHeatMap={ setSelectHeatMap }
            setSearchFilter={ setSearchFilter }
            searchFilter={ searchFilter }
            tableSort={ tableSort }
            setPagination={ setPagination }
            pagination={ pagination }
            handlePagination={ handlePagination }
            setTableSort={ setTableSort }
          />
        </TabPanel>
      </StyledTabsContainer>
    </StyledDashboardContainer>
  );
};

LeadershipView.propTypes = {
  t: PropTypes.func.isRequired,
  handleExternalQuery: PropTypes.func.isRequired,
};

export default LeadershipView;
