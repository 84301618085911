import { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import {
  StyledCheckbox, StyledChip,
  StyledListItem, StyledIconButton,
  CustomPopper,
} from "./styles";

function filterTree(nodes, search) {
  if (!nodes) return [];
  const lowerSearch = search.toLowerCase();

  return nodes
    .map((node) => {
      const filteredChildren = node.children
        ? filterTree(node.children, search)
        : [];

      const matchNode = node.name.toLowerCase().includes(lowerSearch);

      if (matchNode || filteredChildren.length > 0) {
        return {
          ...node,
          children: filteredChildren,
        };
      }
      return null;
    })
    .filter(Boolean);
}

function flattenTree(nodes, expandedIds, selectedIds, level = 0) {
  let flat = [];
  nodes.forEach((node) => {
    const hasChildren = Array.isArray(node.children) && node.children.length > 0;
    const isExpanded = expandedIds.has(node.id);

    flat.push({
      ...node,
      level,
      hasChildren,
      isExpanded,
    });

    if (hasChildren && isExpanded) {
      flat = flat.concat(
        flattenTree(node.children, expandedIds, selectedIds, level + 1),
      );
    }
  });
  return flat;
}

const InputTagTree = ({
  label,
  data = [],
  value = [],
  onChange = () => {},
  placeholder,
  disabled = false,
}) => {
  const [expandedIds, setExpandedIds] = useState(new Set());
  const [inputValue, setInputValue] = useState("");

  const flattenedOptions = useMemo(() => {
    const filtered = inputValue ? filterTree(data, inputValue) : data;
    const selectedIds = new Set(value.map((v) => v.id));
    return flattenTree(filtered, expandedIds, selectedIds);
  }, [data, inputValue, expandedIds, value]);

  const toggleExpand = (id) => {
    setExpandedIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const handleSelectionChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      disabled={ disabled }
      options={ flattenedOptions }
      filterOptions={ (options) => options }
      getOptionLabel={ (option) => option.name || "" }
      isOptionEqualToValue={ (option, val) => option.id === val.id }
      value={ value }
      onChange={ handleSelectionChange }
      inputValue={ inputValue }
      onInputChange={ (event, newVal) => setInputValue(newVal) }
      PopperComponent={ CustomPopper }
      renderOption={ (props, option, { selected }) => (
        <li { ...props } key={ option.id } style={ { cursor: "pointer", padding: 0 } }>
          <StyledListItem
            level={ option.level }
            onClick={ (e) => {
              e.stopPropagation();
              const newSelection = selected
                ? value.filter((n) => n.id !== option.id)
                : [...value, option];
              handleSelectionChange(e, newSelection);
            } }
          >
            <StyledIconButton
              size={ "small" }
              onClick={ (e) => {
                e.stopPropagation();
                toggleExpand(option.id);
              } }
              disabled={ !option.hasChildren }
              active={ option.hasChildren }
            >
              {option.isExpanded ? <ExpandLess /> : <ExpandMore />}
            </StyledIconButton>
            <StyledCheckbox
              checked={ selected }
              tabIndex={ -1 }
              disableRipple
              size={ "small" }
            />
            {option.name}
          </StyledListItem>
        </li>
      ) }
      renderTags={ (tagValue, getTagProps) => tagValue.map((option, index) => (
        <StyledChip
          key={ option.id }
          label={ option.complete_path }
          { ...getTagProps({ index }) }
          onDelete={ (e) => {
            e.stopPropagation();
            handleSelectionChange(
              e,
              value.filter((n) => n.id !== option.id),
            );
          } }
        />
      )) }
      renderInput={ (params) => (
        <TextField
          { ...params }
          label={ label }
          placeholder={ placeholder }
          variant={ "outlined" }
          size={ "small" }
        />
      ) }
    />
  );
};

InputTagTree.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      children: PropTypes.array,
    }),
  ),
  value: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

InputTagTree.defaultProps = {
  label: "",
  data: [],
  value: [],
  onChange: () => {},
  placeholder: "",
  disabled: false,
};

export default InputTagTree;
