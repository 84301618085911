import PropTypes from "prop-types";
import ViewHeaderTitle from "components/ViewHeaderTitle";

const UncontrolledView = (props) => {
  const { header, view } = props;

  return (
    <div data-testid={ "general-administrator-uncontrolled-view" }>
      {header && header.title && (
        <ViewHeaderTitle
          title={ header?.title }
          subtitle={ header?.subtitle }
          breadcrumb={ header?.breadcrumb }
        />
      )}

      {view}
    </div>
  );
};

UncontrolledView.propTypes = {
  header: PropTypes.object,
  view: PropTypes.object,
};

export default UncontrolledView;
