import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Modal from "components/Modal";
import InputForm from "components/InputForm";
import AlertDialog from "components/AlertDialog";
import InputTag from "components/InputTag";
import SkeletonLoader from "components/SkeletonLoader";
import AutoCompleteController from "components_refactor/AutocompleteController";
import Alert from "components_refactor/Alert";
import {
  INPUT_RULES, INPUT_TYPE, OBJECT_KEYS, SIZE, SKELETONS_NUMBER,
} from "common/constants";
import { getErrorMessage } from "common/formValidators";
import { replacePath } from "common/utils";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import RolesModal from "./components/RolesModal";
import DynamicAttributesData from "./components/DynamicAttributesData";
import localRoutes from "../../../../functions/routes";

const EmployeeModal = (props) => {
  const {
    t,
    history,
    collaborator,
    employeeInfoStates,
    allRolesList,
    hookEmployeeForm,
    modalButtons,
    handleFunctions,
    loadings,
    otherFields,
    onEmployeeSubmit,
    employeeErrors,
    onAutocomplete,
    process,
  } = props;

  const {
    isModalOpen, inputTextValue, newCreation, isOpenDialog,
    inputTagsSelected, employeeRoles,
  } = employeeInfoStates;
  const {
    countries, positions, divisions,
  } = employeeInfoStates.optionsList;
  const {
    handleNew, handleDialog, handleDialogSubmit, handleRolesModal, handleTags,
  } = handleFunctions;
  const {
    cities, areas, levels, entities,
  } = otherFields;

  const { control, register } = hookEmployeeForm;
  const { isLoadingHierarchyLevels, isLoadingLegalEntities, isLoadingDynamicAttributes } = loadings;
  const { onAutocompleteChange, onChangeDivision } = onAutocomplete;

  const handleDivision = (divisionId) => {
    onChangeDivision(divisionId, hookEmployeeForm.setValue);
  };

  const initialRender = collaborator === null || isLoadingDynamicAttributes
    ? <SkeletonLoader num={ SKELETONS_NUMBER.EIGTH } />
    : (
      <>
        <Alert
          type={ "info" }
          title={ t("common:validations.job_position_edit.title") }
          message={ t("common:validations.job_position_edit.message") }
        />
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.text }
              label={ t("common:common.name") }
              control={ control }
              name={ "collaborator.person_attributes.full_name" } // TODO: do it for candidate as well
              rules={ INPUT_RULES.required }
              error={ getErrorMessage(employeeErrors, "collaborator.person_attributes.full_name", "full_name") }
              isRequired
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              id={ OBJECT_KEYS.position }
              options={ positions }
              type={ INPUT_TYPE.autocomplete }
              label={ t("common:common.position") }
              control={ control }
              name={ "collaborator.job_position_attributes.position_id" } // TODO: do it for candidate as well
              nameOfAttr={ OBJECT_KEYS.name }
              fieldValue={ OBJECT_KEYS.id }
              onChangeValue={ onAutocompleteChange() }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common:common.add_new_position"),
                prop: OBJECT_KEYS.position,
              } }
              rules={ INPUT_RULES.required }
              error={ getErrorMessage(employeeErrors, "collaborator.job_position_attributes.position_id", "position") }
              isRequired
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteController
              id={ "division" }
              options={ divisions }
              name={ "collaborator.job_position_attributes.division_id" }
              label={ t("common:common.division") }
              control={ control }
              onChangeValue={ handleDivision }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <AutoCompleteController
              id={ "area" }
              options={ areas }
              name={ "collaborator.job_position_attributes.area_id" }
              label={ t("common:common.area") }
              control={ control }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              id={ "level" }
              options={ levels }
              type={ INPUT_TYPE.autocomplete }
              label={ t("common:common.hierarchyLevel") }
              control={ control }
              onChangeValue={ onAutocompleteChange() }
              name={ "collaborator.job_position_attributes.hierarchy_level_id" }
              nameOfAttr={ OBJECT_KEYS.name }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common:common.add_new_level"),
                prop: OBJECT_KEYS.level,
              } }
              loading={ isLoadingHierarchyLevels }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              id={ "legalEntity" }
              options={ entities }
              type={ INPUT_TYPE.autocomplete }
              label={ t("common:common.legalEntity") }
              control={ control }
              onChangeValue={ onAutocompleteChange() }
              name={ "collaborator.legal_entity_id" }
              nameOfAttr={ OBJECT_KEYS.name }
              addNew={ {
                handleNew: handleDialogSubmit,
                text: t("common:common.add_new_legalEntity"),
                prop: OBJECT_KEYS.legalEntity,
              } }
              loading={ isLoadingLegalEntities }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              id={ "country" }
              options={ countries }
              type={ INPUT_TYPE.autocomplete }
              label={ t("common:common.country") }
              control={ control }
              name={ "collaborator.job_position_attributes.country_id" }
              onChangeValue={ onAutocompleteChange() }
              nameOfAttr={ OBJECT_KEYS.name }
              rules={ INPUT_RULES.required }
              error={ getErrorMessage(employeeErrors, "collaborator.job_position_attributes.country_id", "country") }
              isRequired
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              id={ OBJECT_KEYS.city }
              options={ cities }
              type={ INPUT_TYPE.autocomplete }
              label={ t("common:common.city") }
              control={ control }
              onChangeValue={ onAutocompleteChange() }
              name={ "collaborator.job_position_attributes.city_id" }
              nameOfAttr={ OBJECT_KEYS.name }
            />
          </Grid>
          {/* TODO: Validate when is candidate */}
          <Grid item xs={ 12 } md={ 6 }>
            <InputForm
              type={ INPUT_TYPE.email }
              label={ t("common:common.email") }
              control={ control }
              name={ "collaborator.email" }
              rules={ INPUT_RULES.required }
              error={ getErrorMessage(employeeErrors, "collaborator.email", "email") }
              isRequired
            />
          </Grid>
          <Grid xs={ 12 } item md={ 6 }>
            <InputTag
              label={ t("common:common.roles.main") }
              size={ SIZE.small }
              itemsSelected={ inputTagsSelected }
              name={ "collaborator.user_roles" }
              register={ register }
              onChange={ handleTags }
              data={ allRolesList }
              onAddButtonField={ handleRolesModal }
              disableClearable
              forcePopupIcon={ false }
              isRequired
            />
          </Grid>
        </Grid>
        <Grid container spacing={ 2 }>
          <DynamicAttributesData { ...props } />
        </Grid>
      </>
    );

  return (
    <Modal
      isOpen={ isModalOpen.employeeInfo }
      onClose={ () => replacePath(history, localRoutes.root) }
      title={ t("profile:editEmployee") }
      onSubmit={ hookEmployeeForm.handleSubmit(onEmployeeSubmit) }
      buttons={ modalButtons }
      disabled={ Object.entries(employeeErrors)?.length !== 0 }
      isLoading={ process.isLoadingProcess }
    >
      <div>
        <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "700px" }>
          { initialRender }
        </StyledScrollBar>
        <AlertDialog
          isOpen={ isOpenDialog }
          onClose={ () => handleDialog(false) }
          title={ `${t(`common:common.add_new_${newCreation}`)}: ${inputTextValue}` }
          message={ t("common:common.modal_messages.sure_text") }
          onSubmit={ () => handleNew(false) }
        />
        {collaborator
      && (
        <RolesModal
          isOpen={ isModalOpen.rolesInfo }
          onClose={ handleRolesModal }
          hasSelected={ employeeRoles.map((e) => ({
            name: e.value,
            managed_countries: e.countries?.map((country) => ({ id: country.id, name: country.value })),
          })) }
          collaborator={ collaborator }
          allRolesList={ allRolesList }
        />
      )}
      </div>
    </Modal>
  );
};

EmployeeModal.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  collaborator: PropTypes.object,
  employeeInfoStates: PropTypes.object.isRequired,
  hookEmployeeForm: PropTypes.object.isRequired,
  modalButtons: PropTypes.object.isRequired,
  handleFunctions: PropTypes.object.isRequired,
  loadings: PropTypes.object.isRequired,
  otherFields: PropTypes.object.isRequired,
  onAutocomplete: PropTypes.object.isRequired,
  onEmployeeSubmit: PropTypes.func.isRequired,
  employeeErrors: PropTypes.object,
  process: PropTypes.object,
  allRolesList: PropTypes.array,
};

EmployeeModal.defaultProps = {
  collaborator: null,
  employeeErrors: null,
  process: null,
  allRolesList: [],
};

export default EmployeeModal;
