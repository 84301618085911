import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import toNumber from "lodash/toNumber";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SkeletonLoader from "components/SkeletonLoader";
// import PerformanceChart from "components/PerformanceChart";
import ModalCardInfo from "components/ModalCardInfo";
import {
  VARIANT, SKELETONS_NUMBER, INDEX, LOCATION_PROPS, SURVEY_PROCESS_TYPE,
} from "common/constants";
import paths from "common/paths";
import { getPropertyByLocation } from "common/utils";
import {
  isNull, isEqual, isEmpty,
} from "common/helpers";
// import { getGoodLeaderEvolution } from "redux/actions/surveyProcessesActions";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { getGoodLeaderScaleWithColors } from "views/Account/functions";
import { getAnswers, getComments, getReducerToUse } from "../../functions/performance";
import Recommendations from "./components/Recommendations";
import Header from "./components/Header";
import ResultScale from "./components/ResultScale";
import Answers from "./components/Answers";
import Comments from "./components/Comments";
import GeneralResult from "./components/GeneralResult";
import { useStyles } from "../Performance/styles";
import { StyledGrid } from "./styles";

const GoodLeaderResult = (props) => {
  const {
    user, from, reducerToUse, searchBy,
  } = props;
  const { t, i18n } = useTranslation("performance", "engagement");
  const classes = useStyles();
  const [values, setValues] = useState({
    data: {},
    scale: [],
    answers: [],
    comments: [],
  });
  const [evaluationChange, setEvaluationChange] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [answerSelected, setAnswerSelected] = useState();
  // search process id by url
  const location = useLocation();
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processParam = locationSearch?.split(paths.goodLeader)[INDEX.one];
  const processId = processParam ? locationSearch?.split("=")[INDEX.two] : null;
  const dispatch = useDispatch();
  const {
    list: surveyResults,
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer);

  const {
    list: goodLeaderProcesses,
    isLoadinglist: isLoadinglistGoodLeaderProcesses,
  } = useSelector(({ performanceGoodLeaderReducer }) => performanceGoodLeaderReducer);

  // TODO: Uncommenting when the evolution comes by collaborator and not through their descendants
  // const {
  //   goodLeaderEvolution,
  //   isLoadingGoodLeaderEvolution,
  // } = useSelector(({ surveysReducer }) => surveysReducer);

  const getGeneralData = useCallback(() => {
    const userProfile = from
      ? getReducerToUse(searchBy, reducerToUse, user, from)
      : user;
    const employeeId = from ? userProfile?.id : userProfile?.employee?.id;
    // TODO: Uncommenting when the evolution comes by collaborator and not through their descendants
    // const query = {
    //   q: {
    //     employee_id_eq: employeeId,
    //   },
    // };
    if (employeeId) {
      dispatch(getIndividualSurveyResult(employeeId));
      // dispatch(getGoodLeaderEvolution(query));
    }
  }, [searchBy, reducerToUse, user, from, dispatch]);

  useEffect(() => {
    if (user) {
      getGeneralData();
    }
  }, [user, getGeneralData]);

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
    setEvaluationChange(true);
  };

  const getEvaluations = useCallback(() => {
    const evaluations = surveyResults?.filter(
      (survey) => isEqual(survey?.survey_process?.type, SURVEY_PROCESS_TYPE.goodLeader.key),
    )?.map((item) => ({
      value: item.survey_process.id,
      label: item.survey_process.name,
    }));
    return evaluations;
  }, [surveyResults]);

  const setData = useCallback(() => {
    if (
      isEmpty(values.data)
      || !isEqual(values.data?.survey_process?.id, values.evaluation)
    ) {
      const surveysProcessResult = surveyResults
        ?.filter((survey) => survey.survey_process)
        .find((result) => isEqual(result.survey_process.id, values.evaluation));

      setValues((prevValues) => ({
        ...prevValues,
        data: surveysProcessResult,
        scale: surveysProcessResult?.result_scale_indices && getGoodLeaderScaleWithColors(surveysProcessResult?.result_scale_indices),
        answers: getAnswers(surveysProcessResult),
        comments: getComments(surveysProcessResult),
      }));
      setEvaluationChange(false);
    }
  }, [values, surveyResults]);

  useEffect(() => {
    if (!isLoadingSurveyResults && evaluationChange) {
      setData();
    }
  }, [setData, evaluationChange, isLoadingSurveyResults]);

  useEffect(() => {
    const EVAL = getEvaluations();
    if (!isEmpty(EVAL) && !isNull(surveyResults)) {
      setValues((prevValues) => ({
        ...prevValues,
        evaluation: EVAL[INDEX.zero].value,
      }));
      setEvaluationChange(true);
    }
  }, [surveyResults, getEvaluations]);

  useEffect(() => {
    const EVAL = getEvaluations();
    if (!isEmpty(EVAL) && !isNull(surveyResults)) {
      setValues((prevValues) => ({
        ...prevValues,
        evaluation: toNumber(processId) || EVAL[EVAL.length - INDEX.one]?.value,
      }));
      setEvaluationChange(true);
    }
  }, [surveyResults, getEvaluations, processId, processParam]);

  const handleModal = (data) => {
    setAnswerSelected(data);
    setIsOpenModal(!isOpenModal);
  };

  return (
    <Card data-testid={ "good-leader-result" }>
      <CardContent className={ classes.content }>
        {isLoadinglistGoodLeaderProcesses || isLoadingSurveyResults ? (
          <SkeletonLoader num={ SKELETONS_NUMBER.SIX } />
        ) : (
          <>
            <Header
              evaluation={ values.evaluation }
              evaluations={ getEvaluations() }
              handleChange={ handleChange }
              goodLeaderProcess={ goodLeaderProcesses }
            />
            <Grid container>
              <Grid
                item
                xs={ 12 }
                md={ 9 }
                className={ classes.goodLeaderTextResults }
              >
                <Typography variant={ VARIANT.bodyOne }>
                  {t("leaderEvaluationText")}
                </Typography>
              </Grid>
              {values.scale && (
                <ResultScale
                  scale={ values.scale }
                  classes={ classes }
                />
              )}
              <StyledGrid item xs={ 12 }>
                <GeneralResult
                  data={ values?.data }
                  scale={ values?.scale }
                />
              </StyledGrid>
            </Grid>
            <Answers
              values={ values?.answers || [] }
              scale={ values?.scale }
              handleModal={ handleModal }
            />
            {/* <StyledChart container>
              <Grid item xs={ 12 }>
                <Typography variant={ VARIANT.h4 }>{t("dashboard.graphic_title")}</Typography>
              </Grid>
              <Grid item xs={ 12 }>
                <PerformanceChart
                  title={ t("dashboard.graphic_title") }
                  labels={ goodLeaderEvolution?.map((e) => e.name) }
                  data={ goodLeaderEvolution ? getAverageResult(goodLeaderEvolution) : [] }
                  isLoading={ isLoadingGoodLeaderEvolution }
                />
              </Grid>
            </StyledChart> */}
            <Comments values={ values?.comments } />
            <ModalCardInfo
              isOpen={ isOpenModal }
              onClose={ handleModal }
              title={ answerSelected?.name }
            >
              <Recommendations t={ t } language={ i18n?.language } data={ answerSelected } />
            </ModalCardInfo>
          </>
        )}
      </CardContent>
    </Card>
  );
};

GoodLeaderResult.propTypes = {
  user: PropTypes.object.isRequired,
  reducerToUse: PropTypes.object.isRequired,
  from: PropTypes.string,
  searchBy: PropTypes.string,
};

GoodLeaderResult.defaultProps = {
  from: null,
  searchBy: "",
};

export default GoodLeaderResult;
