import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { truncateString } from "common/utils";
import { StyledLink } from "./styles";

const adjustPosition = (value) => Math.max(10, Math.min(90, value));

const NineBoxAvatar = ({ person, size, hasAvatar }) => (
  <Tooltip
    arrow
    title={ (
      <>
        <p><strong>{person.full_name}</strong></p>
        <p>
          {`X: ${person.x}`}
        </p>
        <p>
          {`Y: ${person.y}`}
        </p>
      </>
    ) }
  >
    {hasAvatar ? (
      <Avatar
        alt={ person.full_name }
        src={ person.profile_img }
        sx={ {
          width: size,
          height: size,
          cursor: "pointer",
          position: "absolute",
          left: `${adjustPosition(person.quadrant_x)}%`,
          top: `${adjustPosition(100 - person.quadrant_y)}%`,
          transform: "translate(-50%, -50%)",
          zIndex: 10,
        } }
      />
    ) : (
      <Box
        sx={ {
          position: "absolute",
          left: `${adjustPosition(person.quadrant_x)}%`,
          top: `${adjustPosition(100 - person.quadrant_y)}%`,
          transform: "translate(-50%, -50%)",
          zIndex: 10,
          backgroundColor: "#5F5F5F",
          padding: "0 !important",
          borderRadius: "20px",
          color: "white",
          textAlign: "center",
          cursor: "pointer",
          minWidth: 100,
          minHeight: 14,
          fontSize: 10,
        } }
      >
        <StyledLink href={ "#" }>{truncateString(person.full_name, 14)}</StyledLink>
      </Box>
    )}
  </Tooltip>
);

NineBoxAvatar.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    full_name: PropTypes.string.isRequired,
    profile_img: PropTypes.string.isRequired,
    x: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    y: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    quadrant_x: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    quadrant_y: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  }).isRequired,
  size: PropTypes.number,
  hasAvatar: PropTypes.bool,
};

NineBoxAvatar.defaultProps = {
  size: 40,
  hasAvatar: true,
};

export default NineBoxAvatar;
