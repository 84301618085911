import NoDataMessage from "components_refactor/NoDataMessage";
import { isNull } from "common/helpers";

export const errorTable = (t) => (
  <NoDataMessage
    message={ `${t("common:common.api_responses.error.title")} \n ${t(
      "common:common.api_responses.error.contact_administrator",
    )}` }
    customStyles={ "noData" }
  />
);

export const noDataTable = () => <NoDataMessage customStyles={ "noData" } />;

export const isNewInputRow = (actions, parentId) => (
  (!isNull(actions?.newIdElement) && actions[`isNewRow_${parentId}`])
    || (isNull(actions?.newIdElement) && isNull(parentId) && actions?.disabled)
);
