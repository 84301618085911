import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import charts from "theme/charts";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import TalentSpiderChart from "components/TalentSpiderChart";
import Filter from "components/Filter";
import InputTextSearch from "components/InputTextSearch";
import DownloadExcelButton from "components/DownloadExcelButton";
import { getFormattedObject } from "components/TalentSpiderChart/functions";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { StyledDashboardContainer } from "styledComponents/View";
import {
  StyledContent,
  StyledSubtitleText,
  StyledSearchContainer,
  StyledDownLoad,
} from "./styles";
import TalentDrainChart from "./components/TalentDrainChart";
import AppliedFilters from "./components/AppliedFilters";
import { RISK_CATEGORY } from "./functions";
import { useFilters } from "./hooks/useFilters";
import { useDownload } from "./hooks/useDownload";

const TalentDrainView = (props) => {
  const { handleOpenModal } = props;
  const { t } = useTranslation("talentDrain");
  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down(charts.breakpoints.small),
  );

  const {
    list,
    isLoadingList,
    downloadList,
    isLoadingDownloadList,
  } = useSelector(({ attritionReducer }) => attritionReducer);

  const {
    defaultFilters,
    states,
    handleExternalFilter,
    handleDeleteOption,
    resetDispatched,
    filterName,
    handleExternalQuery,
    handleSearch,
    filterOptions,
  } = useFilters({ t, list });

  const { handleDownload } = useDownload({
    t,
    isLoadingDownloadList,
    downloadList,
    states,
  });

  return (
    <StyledDashboardContainer data-testid={ "talent-drain-view-component" }>
      <ViewHeaderTitle title={ t("title") } />
      <StyledContent>
        <Grid container>
          <Grid item xs={ 12 }>
            <Box pb={ 4 }>
              <Filter
                isButton={ isMobile }
                isLabel
                type={ FILTER_ACTIONS_TYPES.talentDrain }
                externalParams={ [
                  1,
                  null,
                  RISK_CATEGORY.high.key,
                  RISK_CATEGORY.medium.key,
                  RISK_CATEGORY.low.key,
                ] }
                externalHandler={ handleExternalFilter }
                defaultValues={ defaultFilters }
                dispatched={ { value: states.dispatched, reset: resetDispatched } }
                limitTag={ 1 }
                handleExternalQuery={ handleExternalQuery }
              />
            </Box>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={ 12 } md={ 3 }>
              <AppliedFilters
                filterOptions={ filterOptions }
                handleDelete={ handleDeleteOption }
                filterName={ filterName }
              />
            </Grid>
          </Hidden>
          <Grid item xs={ 12 } md={ 9 }>
            <TalentSpiderChart
              dataFormatted={ getFormattedObject(list?.averages) }
              filterNames={ filterName }
            />
          </Grid>
          <Hidden smDown>
            <Grid item xs={ 12 } md={ 3 }>
              <AppliedFilters
                filterOptions={ filterOptions }
                handleDelete={ handleDeleteOption }
                filterName={ filterName }
              />
            </Grid>
          </Hidden>
        </Grid>
      </StyledContent>
      <StyledContent>
        <Grid container>
          <Grid item xs={ 12 }><StyledSubtitleText>{ t("riskGroups") }</StyledSubtitleText></Grid>
          <Grid item xs={ 12 }>
            <StyledSearchContainer isMobile={ isMobile }>
              <InputTextSearch handleInput={ handleSearch } />
              <StyledDownLoad>
                <DownloadExcelButton
                  disabled={ isLoadingList || isLoadingDownloadList }
                  onClick={ () => handleDownload() }
                  isDownloadApart
                />
              </StyledDownLoad>
            </StyledSearchContainer>
            <TalentDrainChart
              query={ states.filterQuery }
              searchFilter={ states.searchFilter }
              handleOpenModal={ handleOpenModal }
            />
          </Grid>
        </Grid>
      </StyledContent>
    </StyledDashboardContainer>
  );
};

TalentDrainView.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
};

export default TalentDrainView;
