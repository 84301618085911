import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize={ "small" } />;
const checkedIcon = <CheckBoxIcon fontSize={ "small" } />;

const MultiSelectFilter = ({ label, options, onSelectionChange, disabled, resetFilters }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleSelectionChange = (event, newValue) => {
    setSelectedOptions(newValue);
  };

  useEffect(() => {
    const selectedIds = selectedOptions.map((option) => option.id);
    onSelectionChange(selectedIds);
  }, [selectedOptions]);

  useEffect(() => {
    if (resetFilters) {
      setSelectedOptions([]);
    }
  }, [resetFilters]);

  return (
    <Autocomplete
      multiple
      id={ "checkboxes-tags-demo" }
      disabled={ disabled }
      size={ "small" }
      limitTags={ 2 }
      options={ options }
      disableCloseOnSelect
      getOptionLabel={ (option) => option.name }
      isOptionEqualToValue={ (option, value) => option.id === value.id }
      renderOption={ (props, option, { selected }) => (
        <li { ...props }>
          <Checkbox
            icon={ icon }
            checkedIcon={ checkedIcon }
            checked={ selected }
          />
          {option.name}
        </li>
      ) }
      value={ selectedOptions }
      onChange={ handleSelectionChange }
      renderInput={ (params) => (
        <TextField { ...params } label={ label } />
      ) }
    />
  );
};

MultiSelectFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  resetFilters: PropTypes.bool,
};

MultiSelectFilter.defaultProps = {
  disabled: false,
  resetFilters: false,
};

export default MultiSelectFilter;
