import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import SkeletonLoader from "components/SkeletonLoader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

const TabPanel = (props) => {
  const {
    children, value, index, isLoading,
  } = props;

  const isActive = value === index;

  return (
    <div
      data-testid={ "tabPanelContainer" }
      role={ "tabpanel" }
      id={ `simple-tabpanel-${index}` }
      aria-labelledby={ `simple-tab-${index}` }
      style={ { display: isActive ? "block" : "none" } }
    >
      <Box>
        {isLoading ? (
          <Card data-testid={ "account-profile-tab" }>
            <Divider />
            <CardContent>
              <SkeletonLoader num={ 9 } />
            </CardContent>
          </Card>
        ) : (
          children
        )}
      </Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
  isLoading: PropTypes.bool,
};

TabPanel.defaultProps = {
  children: null,
  index: 0,
  value: 0,
  isLoading: false,
};

export default TabPanel;
