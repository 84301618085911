import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "components/Modal";
import AutoCompleteCreableController from "components/AutocompleteCreableController";
import { OBJECT_KEYS, VARIANT } from "common/constants";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import AcknowledgmentIcon from "assets/images/feedback/acknowledgment.svg";
import AcknowledgmentForm from "../AcknowledgmentForm";
import { ModalContent } from "./styles";

const AcknowledgmentFormModal = (props) => {
  const {
    t,
    collaborator,
    feedbackStates,
    hookFeedbackForm,
    collaboratorsList,
    handleInputText,
    onAcknowledgmentSubmit,
    onClose,
  } = props;
  const { isModalOpen, hasEmployee } = feedbackStates;
  const { control, handleSubmit } = hookFeedbackForm;

  const employeeRender = hasEmployee ? (
    <Typography variant={ VARIANT.h5 }>{collaborator?.name}</Typography>
  ) : (
    <AutoCompleteCreableController
      options={ collaboratorsList || [] }
      label={ t("feedback:select_collaborator") }
      control={ control }
      rules={ { required: true } }
      name={ "feedback_acknowledgement.feedback_attributes.employee_to_id" }
      nameOfAttr={ OBJECT_KEYS.fullname }
      isWithHelperText={ false }
      isRequired
      onInputTextChange={ handleInputText }
    />
  );

  return (
    <div data-testid={ "acknowledgement-modal-view" }>
      <Modal
        isOpen={ isModalOpen.addAcknowledgment }
        onClose={ onClose }
        title={ t("feedback:title_send_acknowledgment") }
        iconTitle={ AcknowledgmentIcon }
      >
        <ModalContent>
          <StyledScrollBar maxHeight={ "600px" } padding={ "10px" }>
            <form onSubmit={ handleSubmit(onAcknowledgmentSubmit) }>
              <Grid item xs={ 8 }>
                { employeeRender }
              </Grid>
              <Grid item xs={ 12 }>
                <AcknowledgmentForm { ...props } />
              </Grid>
            </form>
          </StyledScrollBar>
        </ModalContent>
      </Modal>

    </div>
  );
};

AcknowledgmentFormModal.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  feedbackStates: PropTypes.object.isRequired,
  hookFeedbackForm: PropTypes.object.isRequired,
  collaboratorsList: PropTypes.array,
  handleInputText: PropTypes.func.isRequired,
  showAlertErrorOrSuccess: PropTypes.func.isRequired,
  onAcknowledgmentSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

AcknowledgmentFormModal.defaultProps = {
  collaborator: null,
  collaboratorsList: null,
};

export default AcknowledgmentFormModal;
