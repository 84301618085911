import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { range } from "lodash";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import {
  BUTTON_STYLE_TYPES,
  COLORS_NAME,
  VARIANT,
} from "common/constants";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import {
  hasMoreEmployees,
  MAX_EMPLOYEES,
  MIN_WIDTH,
  getLinkFromTeamEvaluation,
  getAllTeamEmployees,
} from "views/Cover/functions";
import PeersModal from "../PeersModal";
import {
  StyledBox,
  StyledBoxButtons,
  StyledHead,
  StyledLinkContent,
  StyledList,
  StyledTitle,
} from "./styles";
import Employee from "../Employee";
import CustomLink from "../CustomLink";

const EvaluationCard = (props) => {
  const {
    t, data, size, showAll, setShowAll, processId, getEvaluationResults, canAddPeers,
  } = props;
  const [modal, setModal] = useState(false);
  const { handleSubmit } = useForm();

  const showMore = hasMoreEmployees(data);
  const isPeerEvaluation = data.type === EVALUATION_TYPE.PAIRS;
  const isTeamEvaluation = data.type === EVALUATION_TYPE.MANAGER;
  const isCollaboratorsEvaluation = data.type === EVALUATION_TYPE.COLLABORATORS;
  const isInternalClientEvaluation = data.type === EVALUATION_TYPE.INTERNAL_CLIENT;
  const allTeamEmployees = getAllTeamEmployees(isTeamEvaluation, data?.employees);
  const [selected, setSelected] = useState({
    employees: [],
    link: data.link,
  });
  const allEmployees = [...data.employees];
  if (showAll && isPeerEvaluation && canAddPeers) {
    allEmployees.push({
      id: null,
      name: t("surveys:addPeer"),
      profile_img_url: null,
      link: data.link,
      isPending: true,
      isPeerEvaluation,
    });
  }

  useEffect(() => {
    if ((isTeamEvaluation || isPeerEvaluation || isInternalClientEvaluation) && showAll) {
      const allTeam = getAllTeamEmployees((isTeamEvaluation || isPeerEvaluation || isInternalClientEvaluation), data?.employees);
      setSelected({
        ...selected,
        employees: allTeam,
      });
    }
    // eslint-disable-next-line
  }, [showAll]);

  useEffect(() => {
    if (isTeamEvaluation || isPeerEvaluation || isInternalClientEvaluation) {
      const link = getLinkFromTeamEvaluation(
        selected.employees,
        allTeamEmployees.length,
        allEmployees,
        data,
        processId,
      );
      setSelected({ ...selected, link });
    }
    // eslint-disable-next-line
  }, [selected.employees]);

  const handleSelected = (evaluationResultId) => {
    setSelected({
      employees: selected.employees.includes(evaluationResultId)
        ? selected.employees.filter((id) => id !== evaluationResultId)
        : [...selected.employees, evaluationResultId],
    });
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const getEmployees = (employees) => employees.slice(0, size).map((employee) => (
    <Employee
      key={ employee }
      employee={ employee }
      isPeerEvaluation={ isPeerEvaluation }
      isInternalClientEvaluation={ isInternalClientEvaluation }
      isTeamEvaluation={ isTeamEvaluation }
      handleModal={ handleModal }
      handleSelected={ handleSelected }
      canAddPeers={ canAddPeers }
      { ...props }
    />
  ));

  const employeesRender = data.type === EVALUATION_TYPE.PAIRS
    ? <Employee isPeerPreview allEmployees={ allEmployees } { ...props } canAddPeers={ canAddPeers }/>
    : getEmployees(allEmployees);

  const getShowAllView = () => range(Math.ceil(allEmployees.length / MAX_EMPLOYEES)).map((iter) => (
    <StyledList key={ iter } hasLink={ data.link } hasHeight showAll>
      { getEmployees(allEmployees.slice(
        iter * MAX_EMPLOYEES,
        (iter * MAX_EMPLOYEES) + MAX_EMPLOYEES,
      )) }
    </StyledList>
  ));

  let descriptionKey = "";

  if (!isCollaboratorsEvaluation && !isInternalClientEvaluation) {
    if (isPeerEvaluation) {
      descriptionKey = canAddPeers ? "Peers" : "PeersNoAdd";
    }
  }

  const evaluationText = t(`surveys:descriptionToEvaluate${descriptionKey}`);

  return (
    <StyledBox width={ `${size * MIN_WIDTH}%` }>
      <StyledTitle>{ `${data.label}${ showMore ? ` (${data.employees.length})` : ""}:` }</StyledTitle>
      {showAll && (
        <StyledHead inline={ isTeamEvaluation || isPeerEvaluation || isInternalClientEvaluation } >
          <Typography>
            {evaluationText}
          </Typography>
          <StyledBoxButtons>
            <Button
              onClick={ () => setShowAll(null) }
              variant={ VARIANT.outlined }
              color={ COLORS_NAME.primary }
              typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
            >
              {t("common:common.back")}
            </Button>
            {(isTeamEvaluation || isPeerEvaluation || isInternalClientEvaluation)
              && !data?.isCompleted
              && allEmployees.filter((employee) => employee.isPending).length > 1
              && (
                <Button
                  href={ selected.link }
                  variant={ VARIANT.outlined }
                  color={ COLORS_NAME.primary }
                  typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  disabled={ selected.employees.length === 0 }
                >
                  {t("surveys:start")}
                </Button>
              )}
          </StyledBoxButtons>
        </StyledHead>
      )}
      {showAll ? getShowAllView() : (
        <StyledList hasLink={ !data.isCompleted && data.link } showMore={ showMore }>
          { employeesRender }
        </StyledList>
      )}
      {!showAll && (
        <StyledLinkContent>
          <CustomLink
            showMore={ showMore }
            isPeerEvaluation={ isPeerEvaluation }
            isTeamEvaluation={ isTeamEvaluation }
            isInternalClientEvaluation={ isInternalClientEvaluation }
            handleModal={ handleModal }
            { ...props }
          />
        </StyledLinkContent>
      ) }
      { isPeerEvaluation && data.id
      && (
        <PeersModal
          isOpen={ modal }
          onClose={ handleModal }
          handleSubmit={ handleSubmit }
          evaluation={ data }
          processId={ processId }
          getEvaluationResults={ getEvaluationResults }
          canAddPeers={ canAddPeers }
          { ...props }
        />
      ) }
    </StyledBox>
  );
};

EvaluationCard.propTypes = {
  t: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  size: PropTypes.number.isRequired,
  processId: PropTypes.number.isRequired,
  getEvaluationResults: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
  setShowAll: PropTypes.func,
  canAddPeers: PropTypes.bool.isRequired,
};

EvaluationCard.defaultProps = {
  showAll: false,
  setShowAll: null,
};

export default EvaluationCard;
