import * as XLSX from "xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import PropTypes from "prop-types";
import { StyledButton } from "./styles";

const DownloadExcelButton = (props) => {
  const { t, filename } = props;
  const handleDownload = () => {
    const data = [
      {
        personal_id: "", name: "", x: "", y: "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: true });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.utils.sheet_add_aoa(worksheet, [["Personal ID", "Name", "X", "Y"]], {
      origin: "A1",
    });

    XLSX.writeFile(workbook, filename);
  };

  return (
    <StyledButton
      onClick={ handleDownload }
      startIcon={ <CloudDownloadIcon /> }
    >
      {t("ninebox:upload.steps.first.download")}
    </StyledButton>
  );
};

DownloadExcelButton.propTypes = {
  t: PropTypes.func.isRequired,
  filename: PropTypes.string,
};

DownloadExcelButton.defaultProps = {
  filename: "data.xlsx",
};

export default DownloadExcelButton;
