import { Doughnut, Chart } from "react-chartjs-2";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { CARD_RESULT } from "theme/palette";
import { getPercent } from "common/utils";
import { isNegative } from "common/helpers";
import {
  optionsDoughnut,
  roundCorners,
  textResult,
  getResult,
} from "./functions";
import { StyledGrid, StyledTitle, StyledCenter } from "./styles";

const DoughnutChart = (props) => {
  const {
    title,
    participation,
    color,
    hasSymbol,
    isHorizontal,
    size,
    isTextWithoutColor,
  } = props;

  roundCorners(Chart, isNegative(participation?.total) ? color : null);
  textResult(Chart, isTextWithoutColor);

  return (
    <StyledGrid container spacing={ 0 }>
      {!isHorizontal && title && (
        <Grid item xs={ 12 }><StyledCenter>{ title }</StyledCenter></Grid>
      )}
      <Grid item xs={ isHorizontal ? 8 : 12 }>
        <Doughnut
          data={ getResult(
            participation?.max ? participation.total : 0,
            participation?.max || 0,
            "",
            color,
          ) }
          options={ optionsDoughnut(
            participation?.max ? getPercent(
              (participation.total ?? 0) / (participation.max ?? 1),
            ) : 0,
            hasSymbol ? "%" : "",
            color,
          ) }
          width={ size }
          height={ size }
        />
      </Grid>
      {isHorizontal && title && (
        <Grid item xs={ 4 }>
          <StyledTitle>
            { title }
          </StyledTitle>
        </Grid>
      )}
    </StyledGrid>
  );
};

DoughnutChart.propTypes = {
  participation: PropTypes.shape({
    total: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  }).isRequired,
  title: PropTypes.string,
  color: PropTypes.string,
  hasSymbol: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  size: PropTypes.number,
  isTextWithoutColor: PropTypes.bool,
};

DoughnutChart.defaultProps = {
  title: null,
  color: CARD_RESULT.purpleCard,
  hasSymbol: false,
  isHorizontal: false,
  size: 80,
  isTextWithoutColor: false,
};

export default DoughnutChart;
