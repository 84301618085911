import PropTypes from "prop-types";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import ProfileImageDetailSimple from "components_refactor/ProfileImageDetailSimple";
import { ACTIONS } from "common/constants";

import {
  StyledContainerList,
  StyledContentFlex,
  StyledIconButton,
  StyledCloseIcon,
  StyledListItem,
} from "./styles";

const EmployeeGroup = (props) => {
  const {
    leadershipStates,
    handleEmployeeList,
    handleCircleAndReset,
  } = props;

  const {
    employeeGroup,
  } = leadershipStates;

  return (
    <StyledContainerList>
      <StyledContentFlex>
        <StyledIconButton
          aria-label={ ACTIONS.close }
          onClick={ handleEmployeeList }
        >
          <StyledCloseIcon />
        </StyledIconButton>
      </StyledContentFlex>
      <StyledScrollBar maxHeight={ "170px" }>
        {employeeGroup?.map((result) => (
          <StyledListItem
            key={ result.id }
            button
            onClick={ (e) => handleCircleAndReset(e, result) }
          >
            <ProfileImageDetailSimple
              collaborator={ result.employee }
            />
          </StyledListItem>
        ))}
      </StyledScrollBar>
    </StyledContainerList>
  );
};

EmployeeGroup.propTypes = {
  leadershipStates: PropTypes.object.isRequired,
  handleEmployeeList: PropTypes.func.isRequired,
  handleCircleAndReset: PropTypes.func.isRequired,
};

export default EmployeeGroup;
