import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import PropTypes from "prop-types";
import Autocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import InputText from "components/InputText";
import FormHelperText from "components_refactor/FormHelperText";
import LoaderInput from "components/LoaderInput";
import { NO_AUTOCOMPLETE } from "common/constants";
import { isEmpty } from "common/helpers";
import { StyledAdd, StyledIconRemove, StyledPaper } from "./styles";

const filter = createFilterOptions();

const AutoCompleteCreableController = (props) => {
  const { t } = useTranslation("common");
  const {
    id,
    label,
    disabled,
    fieldValue,
    name,
    options,
    isLoading,
    nameOfAttr,
    isMarginNormal,
    isRequired,
    isFreeSolo = false,
    customStyles,
    isWithHelperText = true,
    control,
    defaultValue = "",
    rules,
    onInputTextChange,
    onChangeValue,
    addNew,
    error,
    helperText,
    renderOption,
    size,
  } = props;
  const [inputValue, setInputValue] = useState("");

  const getOption = (option) => {
    if (typeof option === "object") {
      if (option?.key) {
        return String(option.key);
      }
      return String(option[nameOfAttr]);
    }

    const optionFind = options && options.find((opt) => opt.value === option);
    if (optionFind) {
      return optionFind[nameOfAttr];
    }

    return String(option);
  };

  const filterOptions = (options, params) => filter(options, params);

  const renderInput = (params) => (
    <>
      <InputText
        params={ { ...params } }
        error={ error }
        helperText={ helperText }
        label={ label }
        inputProps={ {
          ...params.inputProps,
          autoComplete: NO_AUTOCOMPLETE,
        } }
        InputProps={ {
          ...params.InputProps,
          endAdornment: (
            <StyledIconRemove>
              {isLoading ? (
                <LoaderInput />
              ) : null}
              {params.InputProps.endAdornment}
            </StyledIconRemove>
          ),
        } }
        isMarginNormal={ isMarginNormal }
        customStyles={ customStyles }
        size={ size }
      />
      {!disabled && isRequired && isWithHelperText && <FormHelperText />}
    </>
  );

  const fieldValueSelected = (data) => (fieldValue ? data?.[fieldValue] : data);

  function getOptionObj(option) {
    if (!option[fieldValue]) {
      option = options.find((op) => op[fieldValue] === option);
    }
    return option;
  }

  const getOptionSelected = (option, optValue) => option[fieldValue] === getOptionObj(optValue)?.[fieldValue];

  const handleInputTextChange = (text) => {
    onInputTextChange(text);
    setInputValue(text);
  };

  const addButton = ({ children, ...other }) => (
    <StyledPaper { ...other }>
      {children}
      {!isEmpty(inputValue) && addNew && (
        <StyledAdd onMouseDown={ () => addNew.handleNew(inputValue, addNew?.prop) }>
          {addNew.text}
        </StyledAdd>
      )}
    </StyledPaper>
  );

  const handleChange = (data) => {
    if (id) {
      onChangeValue(id, data);
    } else { onChangeValue(data); }
    return data;
  };

  return (
    <Controller
      name={ name }
      data-testid={ "auto-complete-creable-controller" }
      render={ ({ value, onChange }) => (
        <Autocomplete
          defaultValue={ defaultValue }
          value={ value }
          options={ options }
          onChange={ (_, data) => (onChange(onChangeValue ? handleChange(data) : fieldValueSelected(data))) }
          disabled={ disabled || isLoading }
          loadingText={ `${t("common.loading")}...` }
          filterOptions={ filterOptions }
          getOptionLabel={ getOption }
          renderInput={ renderInput }
          renderOption={ renderOption }
          selectOnFocus
          handleHomeEndKeys
          freeSolo={ isFreeSolo }
          isOptionEqualToValue={ getOptionSelected }
          inputValue={ inputValue }
          onInputChange={ (event, newInputValue) => {
            handleInputTextChange(newInputValue);
          } }
          PaperComponent={ addButton }
        />
      ) }
      control={ control }
      rules={ rules }
      defaultValue={ defaultValue }
    />
  );
};

AutoCompleteCreableController.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  nameOfAttr: PropTypes.string,
  disabled: PropTypes.bool,
  fieldValue: PropTypes.string,
  isLoading: PropTypes.bool,
  isMarginNormal: PropTypes.bool,
  isFreeSolo: PropTypes.bool,
  isRequired: PropTypes.bool,
  customStyles: PropTypes.string,
  isWithHelperText: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  rules: PropTypes.object,
  onInputTextChange: PropTypes.func,
  onChangeValue: PropTypes.func,
  addNew: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  renderOption: PropTypes.func,
  size: PropTypes.string,
};

AutoCompleteCreableController.defaultProps = {
  id: null,
  label: "",
  nameOfAttr: "",
  disabled: false,
  fieldValue: "",
  isLoading: false,
  isMarginNormal: false,
  isFreeSolo: false,
  isRequired: false,
  customStyles: "",
  isWithHelperText: false,
  defaultValue: "",
  rules: {},
  onInputTextChange: () => {},
  onChangeValue: null,
  addNew: null,
  error: false,
  helperText: "",
  renderOption: null,
  size: "",
};

export default AutoCompleteCreableController;
