import { useState, useEffect } from "react";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import ProfileImageDetail from "components_refactor/ProfileImageDetail";
import StarRating from "components/StarRating";
import { getCurrentLanguage, getPercent } from "common/utils";
import { StyledContent, StyledTitleWithSubtitle } from "styledComponents/Tables";
import { viewLeaderProfile } from "common/functions";
import { VARIANT } from "common/constants";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import {
  StyledLink,
  StyledNoResultContainer,
  StyledNoResult,
} from "views/TalentManager/styles";
import { getRows } from "../functions";

export const useDataTable = ({
  t, history, handleOpenModal, data,
}) => {
  const [rows, setRows] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(
    theme.breakpoints.down("sm"),
  );

  const language = getCurrentLanguage();

  const header = [
    /* "isParent" is added to indicate that there are columns that depend on it,
        based on this, the "+" is displayed to expand the child columns to the right. */
    {
      id: "name",
      label: t("tables:headers.name"),
      isParent: true,
      align: "left",
      customRender: (rowData) => (
        <ProfileImageDetail collaborator={ rowData.employee } isShortVersion />
      ),
    },
    /* child columns of "name", a "parent" is added
       to indicate to which column it relates. */
    {
      id: "manager",
      label: t("tables:headers.manager"),
      parent: "name",
    },
    {
      id: "location",
      label: t("tables:headers.location"),
      parent: "name",
      customRender: (rowData) => (
        <StyledTitleWithSubtitle>
          { rowData.employee?.country_name
              && (
                <Typography variant={ VARIANT.h6 }>
                  {rowData.employee.country_name}
                </Typography>
              )}
          { rowData.employee?.city_name
              && (
                <Typography variant={ VARIANT.bodyOne }>
                  <StyledContent mt={ 5 }>
                    <Location />
                    {rowData.employee.city_name}
                  </StyledContent>
                </Typography>
              )}
        </StyledTitleWithSubtitle>
      ),
    },
    {
      id: "position",
      label: t("tables:headers.position"),
      parent: "name",
    },
    // end of child columns of "name"
    {
      id: "good_leader_score",
      label: t("tables:headers.good_leader"),
      customRender: (rowData) => (
        <StyledLink
          onClick={ () => viewLeaderProfile(
            history,
            rowData.employee.id,
            rowData.survey_process_id,
          ) }
        >
          { getPercent(rowData.good_leader_score, true) }
        </StyledLink>
      ),
    },
    {
      id: "leadership_score",
      label: t("tables:headers.leadership_score"),
      isParent: true,
    },
    // child columns of "leadership_score"
    {
      id: "children_nps",
      label: `${t("engagement:nps")} ${t("tables:headers.team")}`,
      parent: "leadership_score",
    },
    {
      id: "children_performance",
      label: `${t("tables:headers.performance")} ${t("tables:headers.team")}`,
      parent: "leadership_score",
    },
    {
      id: "voluntary_turnover",
      label: `${t("nalaAnalytics:rotation")} ${t("tables:headers.team")}`,
      parent: "leadership_score",
    },
    // end of child columns of "leadership_score"
    {
      id: "leadership_types_matrix",
      label: t("leadership:leadership_types_matrix"),
    },
    {
      id: "performance",
      label: t("tables:headers.performance"),
      align: "left",
      customRender: (rowData) => {
        const performanceProcess = rowData.employee?.performance_results;
        return performanceProcess?.scale_index_count ? (
          <StarRating
            name={ rowData?.id }
            value={ performanceProcess?.position }
            isReadOnly
            maxRating={ performanceProcess?.scale_index_count }
            max={ performanceProcess?.scale_index_count }
            precision={ 1 }
            label={ performanceProcess?.result }
            hasNoFormattedValue
            tooltip={ performanceProcess?.score && performanceProcess?.scale_max_index ? {
              score: getPercent(performanceProcess.score, true, 2),
              maxScore: getPercent(performanceProcess.scale_max_index, true, 2),
            } : null }
          />
        ) : "";
      },
    },
    {
      id: "lossRisk",
      label: t("tables:headers.lossRisk"),
      customRender: (rowData) => (rowData.employee?.attrition_chart?.risk_category
        ? (
          <StyledLink
            onClick={ () => {
              handleOpenModal(
                history.location.pathname,
                rowData.employee.id,
              );
            } }
          >
            { t(`talentDrain:${rowData.employee.attrition_chart.risk_category}`) }
          </StyledLink>
        ) : (
          <StyledNoResultContainer>
            <StyledNoResult>{t("onboardingFollowUp:noResult")}</StyledNoResult>
          </StyledNoResultContainer>
        )),
    },
  ];

  useEffect(() => {
    if (data) {
      const newData = getRows(data, language, t);
      setRows(newData);
    }
    // eslint-disable-next-line
  }, [data]);

  return { header, rows, isMobile };
};
