import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ThumbsUpDown from "@mui/icons-material/ThumbsUpDown";
import StarsIcon from "@mui/icons-material/Stars";
import { SessionContext } from "modules/session/context";
import Modal from "components/Modal";
import { PATHS, ROLES } from "common/constants";
import { isAdmin, getEmployeeId, isValidRole } from "common/utils";
import { includesParentId } from "../../functions";
import useStyles from "./styles";

const EmployeeModal = (props) => {
  const {
    employee,
    isOpen,
    onOpenFeedback,
    onOpenAcknowledgement,
    onClose,
    hasAccessAcknowledgments,
  } = props;
  const { t } = useTranslation("common");
  const classes = useStyles();

  const {
    state: { user },
  } = useContext(SessionContext);

  const parentId = getEmployeeId(user);

  const optionButtons = (
    <div className={ classes.buttonsContainer }>
      { (isAdmin(user?.userCookies) || includesParentId(employee, parentId) || isValidRole(user?.userCookies, ROLES.TALENT_MANAGER)) && (
        <div className={ classes.button } onClick={ () => window.open(`${PATHS.profile}${PATHS.search.collaborators}${employee?.id}`, '_blank') }>
          <AccountCircleIcon />
          {t("common.view_profile")}
        </div>
      )}
      {employee?.id !== parentId && hasAccessAcknowledgments
    && (
      <>
        <div className={ clsx(classes.button, includesParentId(employee, parentId) ? classes.borderButton : "") } onClick={ () => onOpenFeedback() } >
          <ThumbsUpDown />
          <div>{t("common.send_feedback")}</div>
        </div>
        <div className={ clsx(classes.button, classes.borderButton) } onClick={ () => onOpenAcknowledgement() } >
          <StarsIcon />
          <div>{t("common.send_acknowledgment")}</div>
        </div>
      </>
    )}
    </div>
  );

  return (
    <div data-testid={ "orgchart-employee-modal-component" }>
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
        cs={ classes.customContainer }
      >
        <div className={ classes.content }>
          <Avatar
            alt={ employee?.name }
            src={ employee?.avatar }
            className={ classes.avatar }
          />
          <div className={ classes.descriptionContainer }>
            <div className={ classes.fullName }>{employee?.name}</div>
            <div className={ classes.descriptions }>{employee?.title}</div>
            <div className={ clsx(classes.descriptions, classes.descriptionDivider) }>{employee?.department}</div>
            <div className={ classes.descriptions }>{employee?.city}</div>
            <div className={ classes.descriptions }>
              { t("common:common.email") }
              {": "}
              {employee?.email}
            </div>
          </div>
        </div>
        {optionButtons}
      </Modal>
    </div>
  );
};

EmployeeModal.propTypes = {
  employee: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpenFeedback: PropTypes.func.isRequired,
  onOpenAcknowledgement: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  hasAccessAcknowledgments: PropTypes.bool,
};

EmployeeModal.defaultProps = {
  onClose: () => {},
  hasAccessAcknowledgments: false,
};

export default EmployeeModal;
