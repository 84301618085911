import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import DataNineBoxIcon from "assets/images/ninebox/bill.svg";
import { StyledImage, StyledList } from "./styles";

const List = ({ nineBoxData, t }) => {
  const totalEmployees = nineBoxData?.results?.length || 0;

  return (
    <Grid container alignItems={ "center" } justifyContent={ "center" } spacing={ 2 }>
      <StyledList item xs={ 12 }>
        <Typography variant={ "h4" }>
          <LightbulbIcon />
          {`${totalEmployees} ${t("ninebox:historical_total")}`}
        </Typography>
      </StyledList>
      <Grid item xs={ 12 } textAlign={ "center" }>
        <StyledImage src={ DataNineBoxIcon } alt={ "Ninebox" } />
      </Grid>
      <Grid item xs={ 12 } textAlign={ "center" }>
        <Typography variant={ "body1" }>
          {t("ninebox:select_info")}
        </Typography>
      </Grid>
    </Grid>
  );
};

List.propTypes = {
  t: PropTypes.func.isRequired,
  nineBoxData: PropTypes.object,
};

export default List;
