import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import { useForm, Controller } from "react-hook-form";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import Button from "components/Button";
import CloseIcon from "@mui/icons-material/Close";
import uploadImg from "assets/images/forms/subir-archivo.svg";
import DownloadExcelButton from "../DownloadExcelButton";
import CustomStepIcon from "../CustomStepIcon";
import DatePickerInput from "../DatePickerInput";
import { formatDateShort, getExcelData, getData } from "../../functions";
import {
  StyledRadio, StyledGrid, StyledTitle,
  StyledStepper, StyledStepName, StyledPreview,
  StyledButtonContainer,
} from "./styles";

const UploadDataModal = ({ open, onClose, onSubmit }) => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const date = formatDateShort(new Date(), locale);

  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      xAxisLabel: "Potential",
      yAxisLabel: "Performance",
      nineBoxName: `nine_box_${date}`,
      nineBoxDate: new Date().toISOString().split("T")[0],
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [excelData, setExcelData] = useState([]);
  const [selectedSource, setSelectedSource] = useState("xls");
  const [invalidEmployeesCount, setInvalidEmployeesCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);

  const steps = ["select-data-source", "upload-file", "preview-data"];

  const handleNext = () => {
    if (activeStep === 1 && (!selectedFile || excelData.length === 0)) {
      setErrorMessage(t("ninebox:upload.file_errors.general"));
      return;
    }
    setErrorMessage(null);
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const readExcel = (file) => {
    setExcelData([]);
    setErrorMessage(null);
    setInvalidEmployeesCount(0);

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      if (!workbook.SheetNames.length) {
        setErrorMessage(t("ninebox:upload.file_errors.workbook"));
        return;
      }
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      if (!json.length) {
        setErrorMessage(t("ninebox:upload.file_errors.empty"));
        return;
      }

      const { validData, invalidCount } = getData(json);
      if (validData.length === 0) {
        setErrorMessage(t("ninebox:upload.file_errors.invalid_data"));
        return;
      }
      setErrorMessage(null);
      setExcelData(validData);
      setInvalidEmployeesCount(invalidCount);
    };
    reader.readAsArrayBuffer(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.name.endsWith(".xls") && !file.name.endsWith(".xlsx")) {
      setErrorMessage(t("ninebox:upload.file_errors.invalid_file"));
      return;
    }
    setErrorMessage(null);
    setSelectedFile(null);
    setUploadProgress(0);

    setTimeout(() => {
      setSelectedFile(file);
      setUploadProgress(60);
      setTimeout(() => setUploadProgress(100), 1500);
      readExcel(file);
    }, 50);

    event.target.value = "";
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setExcelData([]);
    setInvalidEmployeesCount(0);
    setUploadProgress(0);
    setErrorMessage(null);
  };

  const handleCreate = handleSubmit((formData) => {
    const formattedData = getExcelData(excelData);
    onSubmit({ ...formData, results: formattedData });
    handleRemoveFile();
    setActiveStep(0);
    onClose();
  });

  return (
    <Modal open={ open } onClose={ onClose }>
      <Box
        sx={ {
          width: 750,
          minHeight: 500,
          backgroundColor: "white",
          padding: 4,
          borderRadius: 2,
          border: "none",
          boxShadow: 24,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        } }
      >
        <StyledStepper activeStep={ activeStep } sx={ { mb: 3 } }>
          {steps.map((label) => (
            <Step key={ label }>
              <StepLabel StepIconComponent={ CustomStepIcon } />
            </Step>
          ))}
        </StyledStepper>

        {activeStep === 0 && (
          <>
            <StyledStepName>
              {t("ninebox:upload.steps.first.title")}
            </StyledStepName>
            <Typography variant={ "body1" } sx={ { mb: 3 } }>
              {t("ninebox:upload.steps.first.subtitle")}
            </Typography>
            <StyledGrid container spacing={ 2 }>
              {["xls", "nala", "manual"].map((source) => (
                <Grid item xs={ 4 } key={ source }>
                  <StyledRadio
                    className={ selectedSource === source ? "active" : "" }
                    onClick={ () => source === "xls" && setSelectedSource(source) }
                  >
                    <FormControlLabel
                      value={ source }
                      control={ (
                        <Radio
                          checked={ selectedSource === source }
                          disabled={ source !== "xls" }
                        />
                      ) }
                      label={ (
                        <StyledTitle>
                          {t(`ninebox:upload.types.${source}.title`)}
                          <br />
                          <span>{t(`ninebox:upload.types.${source}.subtitle`)}</span>
                        </StyledTitle>
                      ) }
                      labelPlacement={ "bottom" }
                    />
                  </StyledRadio>
                </Grid>
              ))}
            </StyledGrid>
          </>
        )}

        {activeStep === 1 && (
          <>
            <StyledStepName>
              {t("ninebox:upload.steps.first.title")}
            </StyledStepName>
            <Typography variant={ "body1" }>
              {t("ninebox:upload.steps.first.subtitle")}
            </Typography>
            <DownloadExcelButton t={ t } />
            <Box display={ "flex" } pt={ 3 }>
              <Box pr={ 1 }>
                <img alt={ t("upload_document") } src={ uploadImg } />
              </Box>
              <Box flexGrow={ 1 }>
                <Typography variant={ "h6" }>
                  {t("bulkUpload:upload_document")}
                </Typography>
                <Typography variant={ "body2" }>
                  {t("bulkUpload:filesFormat")}
                </Typography>
              </Box>
            </Box>
            <Box sx={ { mb: 2, mt: 2 } }>
              <input
                id={ "upload-file" }
                type={ "file" }
                accept={ ".xls,.xlsx" }
                onChange={ handleFileChange }
                style={ { display: "none" } }
              />
              <label htmlFor={ "upload-file" }>
                <Button
                  variant={ "outlined" }
                  typeStyle={ "outlined" }
                  component={ "span" }
                  sx={ { textTransform: "none", width: "50%" } }
                >
                  {t("common:common.select_file")}
                </Button>
              </label>
            </Box>
          </>
        )}

        {activeStep === 1 && (
          <Box
            sx={ {
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: "40px",
            } }
          >
            {selectedFile && (
              <>
                <Typography variant={ "body2" }>{selectedFile.name}</Typography>
                <IconButton onClick={ handleRemoveFile }>
                  <CloseIcon />
                </IconButton>
              </>
            )}
          </Box>
        )}

        {activeStep === 1 && (
          <StyledPreview>
            <LinearProgress
              variant={ "determinate" }
              value={ uploadProgress }
              sx={ { mb: 1 } }
            />
            {invalidEmployeesCount > 0 && (
              <Typography
                variant={ "body1" }
                sx={ { color: "red", fontWeight: "bold", mb: 2 } }
              >
                {`${t("ninebox:upload.file_errors.invalid_employees")}: ${invalidEmployeesCount}`}
              </Typography>
            )}
            {errorMessage && (
              <Typography
                variant={ "body1" }
                sx={ { color: "red", fontWeight: "bold", mb: 2 } }
              >
                {errorMessage}
              </Typography>
            )}
          </StyledPreview>
        )}

        {activeStep === 2 && (
          <>
            <StyledStepName>
              {t("ninebox:upload.steps.last.title")}
            </StyledStepName>
            <Typography variant={ "body1" } sx={ { mb: 3 } }>
              {t("ninebox:upload.steps.last.subtitle")}
            </Typography>
            <StyledStepName>
              {`${t("ninebox:upload.steps.last.resume")}:`}
            </StyledStepName>
            <Box sx={ { mt: 2 } }>
              <Box sx={ { display: "flex", alignItems: "center", mb: 1 } }>
                <Typography
                  variant={ "body1" }
                  sx={ {
                    fontWeight: "bold",
                    mr: 1,
                    width: 200,
                    textAlign: "right",
                  } }
                >
                  {`${t("ninebox:upload.steps.last.data.employees")}:`}
                </Typography>
                <Typography variant={ "body1" }>
                  {excelData.length}
                </Typography>
              </Box>
              <Box sx={ { display: "flex", alignItems: "center", mb: 1 } }>
                <Typography
                  variant={ "body1" }
                  sx={ {
                    fontWeight: "bold",
                    mr: 1,
                    width: 200,
                    textAlign: "right",
                  } }
                >
                  {`${t("ninebox:upload.steps.last.data.x")}:`}
                </Typography>
                <Controller
                  name={ "xAxisLabel" }
                  control={ control }
                  defaultValue={ "Potential" }
                  onChange={ ([e]) => {
                    if (e.target.value.length <= 16) {
                      return e.target.value;
                    }
                    return control.getValues("xAxisLabel");
                  } }
                  as={ (
                    <TextField
                      variant={ "standard" }
                      size={ "small" }
                    />
                  ) }
                />
              </Box>
              <Box sx={ { display: "flex", alignItems: "center", mb: 1 } }>
                <Typography
                  variant={ "body1" }
                  sx={ {
                    fontWeight: "bold",
                    mr: 1,
                    width: 200,
                    textAlign: "right",
                  } }
                >
                  {`${t("ninebox:upload.steps.last.data.y")}:`}
                </Typography>
                <Controller
                  name={ "yAxisLabel" }
                  control={ control }
                  defaultValue={ "Performance" }
                  onChange={ ([e]) => {
                    if (e.target.value.length <= 16) {
                      return e.target.value;
                    }
                    return control.getValues("yAxisLabel");
                  } }
                  as={ (
                    <TextField
                      variant={ "standard" }
                      size={ "small" }
                    />
                  ) }
                />
              </Box>
              <Box sx={ { display: "flex", alignItems: "center", mb: 1 } }>
                <Typography
                  variant={ "body1" }
                  sx={ {
                    fontWeight: "bold",
                    mr: 1,
                    width: 200,
                    textAlign: "right",
                  } }
                >
                  {`${t("ninebox:upload.steps.last.data.name")}:`}
                </Typography>
                <Controller
                  name={ "nineBoxName" }
                  control={ control }
                  defaultValue={ `nine_box_${date}` }
                  onChange={ ([e]) => {
                    if (e.target.value.length <= 20) {
                      return e.target.value;
                    }
                    return control.getValues("nineBoxName");
                  } }
                  as={ (
                    <TextField
                      variant={ "standard" }
                      size={ "small" }
                    />
                  ) }
                />
              </Box>
              <Box sx={ { display: "flex", alignItems: "center", mb: 1 } }>
                <Typography
                  variant={ "body1" }
                  sx={ {
                    fontWeight: "bold",
                    mr: 1,
                    width: 200,
                    textAlign: "right",
                  } }
                >
                  {`${t("ninebox:upload.steps.last.data.date")}:`}
                </Typography>
                <Controller
                  name={ "nineBoxDate" }
                  control={ control }
                  defaultValue={ new Date().toISOString() }
                  as={ DatePickerInput }
                  onChangeName={ "onChange" }
                  valueName={ "value" }
                  onChange={ ([dateValue]) => dateValue }
                  label={ "" }
                  locale={ locale }
                  disablePortal={ false }
                />

              </Box>
            </Box>
          </>
        )}

        <StyledButtonContainer>
          <Button onClick={ onClose } typeStyle={ "cancel" }>
            {t("common:common.cancel")}
          </Button>
          <Box>
            {activeStep > 0 && (
              <Button
                onClick={ handleBack }
                variant={ "outlined" }
                sx={ { mr: 1 } }
              >
                {t("common:common.back")}
              </Button>
            )}
            <Button
              typeStyle={ "submit" }
              onClick={ activeStep === 2 ? handleCreate : handleNext }
            >
              {t(`common:common.${activeStep === 2 ? "create" : "next"}`)}
            </Button>
          </Box>
        </StyledButtonContainer>
      </Box>
    </Modal>
  );
};

UploadDataModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UploadDataModal;
