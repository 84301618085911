import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import {
  StyledContainer,
  StyledMenuItem,
  StyledListItemIcon,
} from "./styles";

const HorizontalMenu = ({ menuItems }) => (
  <StyledContainer columns={ { xs: 5, sm: 5 } }>
    {menuItems?.map((menuItem) => (
      <StyledMenuItem
        key={ menuItem?.title }
        onClick={ () => menuItem?.onClick && menuItem.onClick() }
        disabled={ menuItem?.disabled }
      >
        {menuItem?.icon && (
          <StyledListItemIcon>
            {menuItem.icon}
          </StyledListItemIcon>
        )}
        <Typography>
          {menuItem?.title}
        </Typography>
      </StyledMenuItem>
    ))}
  </StyledContainer>
);

HorizontalMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.node,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
    }),
  ),
};

HorizontalMenu.defaultProps = {
  menuItems: [],
};

export default HorizontalMenu;
