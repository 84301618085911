import styled from "styled-components";
import Button from "components/Button";
import theme from "theme";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`;

export const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    color: ${theme.palette.text.link};
    border-color: ${theme.palette.text.link};

    ${({ disabled }) => disabled
      && `
      color: rgba(0,0,0,0.26) !important;
      border: 1px solid rgba(0,0,0,0.12) !important;
      `}

    &.MuiButton-outlinedSizeSmall {
      padding: 2px 8px;
    }
  }
  @media (max-width: 600px) {
    &.MuiButtonBase-root {
      &.MuiButton-outlinedSizeSmall {
        width: 100%;
      }
    }
  }
`;
