import { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ViewModalComponent from "components/ViewModalComponent";
import { getCurrentLanguage } from "common/utils";
import { SessionContext } from "modules/session/context";
import useUserAbility from "hooks/useUserAbility";
import { useProfileReducerHook } from "./hooks/useProfileReducerHook";
import { useProfileHook } from "./hooks/useProfile.hook";
import { useFeedbackHook } from "./hooks/useFeedback.hook";
import { useGoalsHook } from "./hooks/useGoals.hook";
import { useMovementsHistoryHook } from "./hooks/useMovementsHistory.hook";
import { usePerformanceHook } from "./hooks/usePerformance.hook";
import { useSkillHook } from "./hooks/useSkill.hook";
import ProfileView from "./Profile.view";
import { useDocumentHook } from "./hooks/useDocument.hook";
import { useGoodLeaderHook } from "./hooks/useGoodLeader.hook";
import { useEmployeeInfoHook } from "./hooks/useEmployeeInfo.hook";
import { useManagerHook } from "./hooks/useManager.hook";
import { useSalaryInfoHook } from "./hooks/useSalaryInfo.hook";
import { useLossRiskHook } from "./hooks/useLossRisk.hook";

const ProfileController = (props) => {
  const {
    dispatch, location, history, t, language, handleOpenModal,
  } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  moment.locale(getCurrentLanguage());
  const ability = useUserAbility(user);

  const externalHooks = {
    dispatch,
    location,
    language,
    history,
    user,
    t,
    handleOpenModal,
    ability,
  };

  const allReducers = useProfileReducerHook(externalHooks);

  const profileStates = useProfileHook(externalHooks, {
    isOwnProfile: allReducers.isOwnProfile,
    isManager: allReducers.isManager,
    collaborator: allReducers.collaborator,
    searchCollaborator: allReducers.searchCollaborator,
    genderList: allReducers.genderList,
    isValidToShowNineBox: allReducers.isValidToShowNineBox,
    process: {
      processStates: allReducers.process,
      resetStateCollaborator: allReducers.resetStateCollaborator,
    },
    dispatchCollaborator: allReducers.dispatchCollaborator,
    dispatchResetCollaborator: allReducers.dispatchResetCollaborator,
    onCloseModals: allReducers.onCloseModals,
  });

  const movementsHistoryStates = useMovementsHistoryHook(
    externalHooks,
    {
      collaborator: allReducers.collaborator,
    },
  );

  const updateMovementFormValue = (name, value) => {
    movementsHistoryStates.hookMovementForm.setValue(name, value);
  };

  const employeeInfoStates = useEmployeeInfoHook(externalHooks, {
    collaborator: allReducers.collaborator,
    isAdmin: allReducers.isAdmin,
    process: {
      processStates: allReducers.process,
      resetStateCollaborator: allReducers.resetStateCollaborator,
    },
    dispatchCollaborator: allReducers.dispatchCollaborator,
    hookMovementForm: movementsHistoryStates.hookMovementForm,
    updateMovementFormValue,
  });

  const feedbackStates = useFeedbackHook(externalHooks, {
    isOwnProfile: allReducers.isOwnProfile,
    collaborator: allReducers.collaborator,
  });

  const goalsStates = useGoalsHook(
    { dispatch, ability, user },
    {
      collaborator: allReducers.collaborator,
      isOwnProfile: allReducers.isOwnProfile,
    },
  );

  const performanceStates = usePerformanceHook(externalHooks, {
    collaborator: allReducers.collaborator,
    isValidToShowAttrition: allReducers.isValidToShowAttrition,
    searchCollaborator: allReducers.searchCollaborator,
  });

  const skillStates = useSkillHook(externalHooks, {
    collaborator: allReducers.collaborator,
    isValidToShowAttrition: allReducers.isValidToShowAttrition,
    isValidToShowNineBox: allReducers.isValidToShowNineBox,
    searchCollaborator: allReducers.searchCollaborator,
    isOwnProfile: allReducers.isOwnProfile,
  });

  const documentsStates = useDocumentHook(externalHooks, {
    dispatchResetCollaborator: allReducers.dispatchResetCollaborator,
  });
  const goodLeaderStates = useGoodLeaderHook(externalHooks, {
    collaborator: allReducers.collaborator,
    searchCollaborator: allReducers.searchCollaborator,
  });

  const managerStates = useManagerHook(externalHooks, {
    collaborator: allReducers.collaborator,
  });

  const salaryInfoStates = useSalaryInfoHook(externalHooks, {
    collaborator: allReducers.collaborator,
    isAdmin: allReducers.isAdmin,
    isManager: allReducers.isManager,
    isNalaAdmin: allReducers.isNalaAdmin,
    process: {
      processStates: allReducers.process,
      resetStateCollaborator: allReducers.resetStateCollaborator,
    },
    dispatchCollaborator: allReducers.dispatchCollaborator,
  });

  const lossRiskStates = useLossRiskHook(externalHooks, {
    collaborator: allReducers.collaborator,
    isValidToShowAttrition: allReducers.isValidToShowAttrition,
  });

  if (!ability) {
    return "";
  }

  return (
    <ViewModalComponent viewComponent={ (
      <ProfileView
        ability={ ability }
        { ...props }
        { ...allReducers }
        { ...profileStates }
        { ...managerStates }
        { ...feedbackStates }
        { ...goalsStates }
        { ...movementsHistoryStates }
        { ...performanceStates }
        { ...skillStates }
        { ...documentsStates }
        { ...goodLeaderStates }
        { ...employeeInfoStates }
        { ...salaryInfoStates }
        { ...lossRiskStates }
        user={ user }
      />
    ) }
    />
  );
};

ProfileController.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func,
};

ProfileController.defaultProps = {
  handleOpenModal: () => {},
};

export default ProfileController;
