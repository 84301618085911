import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import palette, { STATE_COLORS } from "theme/palette";

export const StyledRadio = styled.div`
  padding-top: 10px;
  width: 100%;
  height: 160px;
  border: ${({ selected }) => (selected ? palette.border.radioSelected : palette.border.radio)};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;

  :hover {
    border: ${palette.border.radioSelected};
  }

  .Mui-checked {
    color: ${palette.text.link};
  }

  label {
    align-items: flex-start;
    .MuiButtonBase-root {
      padding: 0;
      margin-bottom: 10px;
    }
  }
`;

export const StyledGrid = styled(Grid)`
  padding-top: 20px;
  .active {
    border: ${palette.border.radioSelected};
  }
`;

export const StyledTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 6px;
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StyledFooter = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    padding: 10px 45px;
  }
`;

export const StyledStepper = styled(Stepper)`
  width: 40%;
  justify-self: anchor-center;
  .MuiSvgIcon-root {
    font-size: 38px;
    border: 1px solid ${STATE_COLORS.GREY};
    border-radius: 30px;
    color: ${palette.white};

    .MuiStepIcon-text {
      fill: ${STATE_COLORS.GREY};
    }

    &.Mui-completed, &.Mui-active {
      border: none;
      color: ${palette.text.link};
    }
  }
  .MuiStepIcon-root.MuiStepIcon-completed, .MuiStepIcon-root.MuiStepIcon-active {
    color: ${palette.text.link};
    border: none;
  }
  .Mui-completed .MuiStepIcon-text, .Mui-active .MuiStepIcon-text {
    fill: ${palette.white};
  }
  .MuiStepLabel-root {
    align-items: flex-start;
    cursor: pointer;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    text-align: left;
  }
  .MuiStepConnector-alternativeLabel{
    top: 18px;
    left: calc(-100% + 55px);
    right: calc(100% + 0px);
  }
`;

export const StyledStepName = styled.h5`
  color: ${palette.text.link};
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const StyledPreview = styled.div`
  .MuiLinearProgress-root {
    height: 5px;
  }
  .MuiLinearProgress-barColorPrimary {
    background: #19E2D6;
  }
  .MuiTableCell-root {
    padding: 10px;
  }
  .MuiTableCell-head {
    min-width: 150px;
    text-transform: capitalize;
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: 20px;
  left: 0;
  padding: 0 20px;
`;
