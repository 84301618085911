import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SkeletonLoader from "components/SkeletonLoader";
import {
  toast, MESSAGE_TYPES,
} from "components/Toast/functions";
import { SKELETONS_NUMBER } from "common/constants";
import { isNull, isUndefined } from "common/helpers";
import {
  getList as getTemplates,
  deleteItem as deleteTemplate,
  resetState as resetStateTemplate,
  resetStateProcess as resetStateProcessTemplate,
  getOne as getTemplate,
} from "redux/actions/template/templateActions";
import {
  getList as getSections,
} from "redux/actions/sections/sectionActions";
import RadioButtons from "./components/RadioButtons";
import { StyledTypography } from "./styles";

const Templates = (props) => {
  const { setDocs, setInfo, setSectionItems } = props;
  const { t } = useTranslation("templates");
  const [selectedItem, setSelectedItem] = useState();
  const { control } = useForm();

  const {
    list: listTemplates,
    isLoadingList: isLoadingTemplate,
    successProcessTemplate,
    one: oneTemplate,
    isLoadingOne,
  } = useSelector(({ templateReducer }) => templateReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTemplates());
    dispatch(getSections());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingOne) {
      setSelectedItem(oneTemplate);
    }
  }, [isLoadingOne, oneTemplate]);

  useEffect(() => {
    if (isUndefined(successProcessTemplate)) {
      const message = {
        title: t("actions.delete"),
        message: t("candidates:alertMessage.success"),
      };
      toast(MESSAGE_TYPES.success, message, t);
      dispatch(resetStateTemplate());
      dispatch(resetStateProcessTemplate());
      dispatch(getTemplates());
    }

    if (successProcessTemplate) {
      const message = {
        title: t("selected_data"),
        message: t("candidates:alertMessage.success"),
      };
      toast(MESSAGE_TYPES.success, message, t);
      dispatch(resetStateProcessTemplate());
      if (isNull(selectedItem)) {
        dispatch(resetStateTemplate());
        dispatch(getTemplates());
        dispatch(getTemplate(successProcessTemplate.id));
      }
    }
  }, [successProcessTemplate, selectedItem, dispatch, t]);

  const handleSelected = (item) => {
    if (isNull(item)) {
      setSelectedItem(null);
    } else {
      dispatch(getTemplate(item.id));
    }
  };

  useEffect(() => {
    if (oneTemplate) {
      setDocs(oneTemplate.onboarding_template_documents.map((item) => ({
        id: item.document_type_id,
        required: item.required,
      })));
      setInfo(oneTemplate.onboarding_template_infos.map((item) => ({
        id: item.info_type_id,
        required: item.required,
      })));
      setSectionItems(oneTemplate.onboarding_template_section_items.map((item) => ({
        id: item.section_item_id,
        required: item.required,
        order: item.order,
      })));
    }
  }, [oneTemplate, setDocs, setInfo, setSectionItems]);

  const handleDelete = () => {
    dispatch(deleteTemplate(selectedItem.id));
    setSelectedItem(null);
  };

  return (
    <Grid container spacing={ 3 } data-testid={ "templates-view" }>
      <Grid item xs={ 12 }>
        <StyledTypography>{t("description")}</StyledTypography>
        {isLoadingTemplate ? (<SkeletonLoader num={ SKELETONS_NUMBER.FOUR } />)
          : (
            <RadioButtons
              control={ control }
              controlName={ "templates.template_type_id" }
              radioName={ "templates" }
              options={ listTemplates }
              selected={ selectedItem }
              onClick={ handleSelected }
              deleteOption={ handleDelete }
              setDocs={ setDocs }
              setInfo={ setInfo }
              setSectionItems={ setSectionItems }
            />
          )}
      </Grid>
    </Grid>
  );
};

Templates.propTypes = {
  setDocs: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setSectionItems: PropTypes.func.isRequired,
};

export default Templates;
