import { useContext } from "react";
import { SessionContext } from "modules/session/context";
import NineBoxMatrixView from "./NineBoxMatrix.view";

const NineBoxMatrixController = (props) => {
  const { state: { user } } = useContext(SessionContext);

  return <NineBoxMatrixView user={ user } { ...props } />;
};

export default NineBoxMatrixController;
