import { useMemo } from "react";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import SkeletonLoader from "components/SkeletonLoader";
import { getPercent } from "common/utils";
import { LINEAL_GRAPHICS } from "theme/palette";
import { options } from "./functions/ChartOptions";
import { StyledChartContainer } from "./styles";

const PerformanceChart = ({
  labels, data, title, isLoading,
}) => {
  const chartData = useMemo(() => ({
    labels,
    datasets: [
      {
        label: title,
        backgroundColor: LINEAL_GRAPHICS.BG_COLOR,
        borderColor: LINEAL_GRAPHICS.LINE_COLOR,
        borderWidth: 2,
        data: data.map((item) => getPercent(item)),
        pointBackgroundColor: LINEAL_GRAPHICS.DOT_COLOR,
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  }), [labels, data, title]);

  return (
    <StyledChartContainer data-testid={ "performance-chart-component" }>
      {isLoading ? (
        <SkeletonLoader num={ 8 } />
      ) : (
        <Line data={ chartData } plugins={ [ChartDataLabels] } options={ options } />
      )}
    </StyledChartContainer>
  );
};

PerformanceChart.propTypes = {
  labels: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
};

PerformanceChart.defaultProps = {
  title: "Performance",
  isLoading: false,
};

export default PerformanceChart;
