import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import Button from "components/Button";
import { SIZE, VARIANT, BUTTON_STYLE_TYPES } from "common/constants";
import ViewHeaderTitle from "../ViewHeaderTitle";
import {
  useStyles, ModalView, Avatar, StyledIconButton, StyledHeaderIcon,
} from "./styles";

const Modal = (props) => {
  const {
    children,
    isOpen,
    onClose,
    iconTitle,
    customStyle,
    title,
    buttons,
    onSubmit,
    disabled,
    isLoading,
    borderIconColor,
    customHeader,
    maxWidth,
  } = props;
  const classes = useStyles({ maxWidth });
  const { t } = useTranslation("common");

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalView open={ isOpen } data-testid={ "modal-view" }>
      <div className={ clsx(classes.root, customStyle) }>
        <div className={ classes.titleContainerFlex }>
          {!customHeader && iconTitle && (
            <StyledHeaderIcon
              className={ classes.iconContainer }
              borderIconColor={ borderIconColor }
            >
              <Avatar src={ iconTitle } />
            </StyledHeaderIcon>
          )}
          <div className={ classes.titleFlexContainer }>
            {title && !customHeader && (
              <ViewHeaderTitle
                title={ title }
                customTitleStyle={ classes.titleStyle }
              />
            )}
            <StyledIconButton
              aria-label={ "close" }
              className={ classes.closeButton }
              onClick={ handleClose }
            >
              <CloseIcon fontSize={ SIZE.small } className={ classes.closeIcon } />
            </StyledIconButton>
          </div>
        </div>
        <div className={ classes.content }>{children}</div>
        {buttons && (
          <div className={ classes.buttonActionsContainer }>
            <Button
              onClick={ handleClose }
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
              disabled={ buttons?.isLoading }
              cs={ classes.buttonActions }
            >
              {buttons?.disagree ?? t("common.modal_messages.no_cancel")}
            </Button>
            <Button
              onClick={ onSubmit }
              variant={ VARIANT.contained }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              disabled={ buttons?.isLoading || disabled }
              cs={ classes.buttonActions }
              isLoading={ isLoading }
              autoFocus
            >
              {buttons?.agree ?? t("common.modal_messages.yes_confirm")}
            </Button>
          </div>
        )}
      </div>
    </ModalView>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  iconTitle: PropTypes.string,
  customStyle: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element,
  buttons: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  borderIconColor: PropTypes.string,
  customHeader: PropTypes.any,
  maxWidth: PropTypes.any,
};

Modal.defaultProps = {
  isOpen: false,
  iconTitle: null,
  customStyle: null,
  title: null,
  subtitle: null,
  children: null,
  buttons: null,
  onSubmit: null,
  disabled: false,
  isLoading: false,
  borderIconColor: null,
  onClose: null,
  customHeader: null,
  maxWidth: null,
};

export default Modal;
