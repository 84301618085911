import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SimpleAlert from "components/SimpleAlert";
import TablePagination from "components/TablePagination";
import InputCheckbox from "components/InputCheckbox";
import ContentByFormat from "components_refactor/ContentByFormat";
import {
  DIRECTION, VARIANT, INFO, INDEX,
} from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import charts from "theme/charts";
import { HEADER, getRowsDesktop } from "views/Preboarding/functions";
import {
  StyledGrid, StyledContent, StyledBox, StyledTypography,
} from "./styles";

const CompanyData = (props) => {
  const { data, setData } = props;
  const { t } = useTranslation("preboarding");
  const { control } = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const [selectedItem, setSelectedItem] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(false);

  useEffect(() => {
    if (data && !isEmpty(data) && !isFirstLoad) {
      setSelectedItem(data[INDEX.zero]);
    }
  }, [data, isFirstLoad]);

  const handleClick = (item) => {
    setSelectedItem(item);
  };

  const checkHandler = () => {
    const newStates = data.map((item) => {
      if (isEqual(item.id, selectedItem?.id)) {
        item.has_confirmed = !item.has_confirmed;
        setSelectedItem(item);
      }
      return item;
    });
    setData(newStates);
    setIsFirstLoad(true);
  };

  return (
    <StyledGrid container direction={ DIRECTION.row } spacing={ 4 }>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography variant={ VARIANT.h4 }>{ selectedItem?.name }</Typography>
        <StyledTypography variant={ VARIANT.bodyOne }>
          { selectedItem?.description }
        </StyledTypography>
        <ContentByFormat type={ selectedItem?.format } url={ selectedItem?.url } title={ selectedItem?.name } />
        {!selectedItem?.has_confirmed && selectedItem?.required && (
          <StyledContent>
            <SimpleAlert
              type={ INFO }
              message={ t("companyData.alert") }
            />
          </StyledContent>
        )}
        <InputCheckbox
          name={ `check-${selectedItem?.id}` }
          control={ control }
          label={ t("companyData.validation") }
          onClick={ () => checkHandler() }
          isDefaultChecked={ selectedItem?.has_confirmed }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <Typography variant={ VARIANT.h5 }>{ t("companyData.tableTitle") }</Typography>
        <StyledBox mt={ 2 }>
          <TablePagination
            header={ HEADER }
            list={ data }
            isLoading={ false }
            getRows={ {
              desktop: getRowsDesktop,
            } }
            onClickRow={ handleClick }
            withoutActions
            noStriped
            isDense={ !isMobile }
          />
        </StyledBox>
      </Grid>
    </StyledGrid>
  );
};

CompanyData.propTypes = {
  data: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired,
};

export default CompanyData;
