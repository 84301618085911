import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components_refactor/NoDataMessage";
import SimpleAccordion from "components/SimpleAccordion";
import InputEditor from "components/InputEditor";
import Button from "components/Button";
import SkeletonLoader from "components/SkeletonLoader";
import {
  handleMessages, HTTP_STATUS_RESPONSE, MESSAGE_TYPES, toast,
} from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES, BUTTON_TYPE, NINEBOX, SKELETONS_NUMBER, VARIANT,
} from "common/constants";
import { getKeyByValue, getEmployeeImage } from "common/utils";
import {
  isEmpty, isEmptyObject, isNotValid, isNull,
} from "common/helpers";
import {
  getOne as getCollaborator, update as updateCollaborator, resetStateProcess,
} from "redux/actions/collaboratorActions";
import Tips from "assets/images/potential/skills/tips.svg";
import ToDevelop from "assets/images/potential/skills/to_develop.svg";
import {
  AvatarProfile,
  EmployeeData,
  Title,
  History,
} from "views/Account/components/PotentialTab/components/NineBoxProfile/styles";
import AttritionProbabilityCard from "views_refactor/Profile/components/LossRiskInfo/components/AttritionProbabilityCard";
import {
  StyleTypography,
  StyledDetails,
  StyleList,
  StyledResult,
  StyledGrid,
} from "./styles";

const DetailsByCollaborator = (props) => {
  const {
    collaboratorId,
    results,
    attritionStates,
    recommendations,
    isLoading,
  } = props;
  const { t } = useTranslation(["developmentPlan", "potential"]);
  const [disabled, setIsDisabled] = useState(true);
  const [lastCollaboratorId, setLastCollaboratorId] = useState(null);
  const dispatch = useDispatch();
  const {
    one: collaboratorData,
    isLoadingOne: isLoadingCollaboratorData,
    successProcess: successCollaboratorProcess,
    isLoadingProcess: isLoadingCollaboratorProcess,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    control,
    handleSubmit,
    reset,
  } = useForm({
    "collaborator.expectation_attributes.description": "",
  });

  const validateInputEdit = (value) => setIsDisabled(isEmpty(value) || false);

  useEffect(() => {
    if (!isLoadingCollaboratorData && isNull(collaboratorData) && collaboratorId) {
      setLastCollaboratorId(collaboratorId);
      dispatch(getCollaborator(collaboratorId));
    }
  }, [isLoadingCollaboratorData, collaboratorData, collaboratorId, lastCollaboratorId, dispatch]);

  useEffect(() => {
    if (collaboratorData?.expectation) {
      reset({
        "collaborator.expectation_attributes.id": collaboratorData?.expectation?.id,
        "collaborator.expectation_attributes.description": collaboratorData?.expectation?.description,
      });
    }
  }, [collaboratorData, reset]);

  const getTipsByQuadrant = (quadrantIndex) => {
    const key = getKeyByValue(NINEBOX.quadrantIndex, quadrantIndex);
    return quadrantIndex >= 0 && (
      <StyleList>
        {t(`tipsByQuadrant.${key}`, { returnObjects: true }).map((item) => (
          <li key={ item }>{item}</li>
        ))}
      </StyleList>
    );
  };

  const onSubmit = (data) => {
    const { collaborator } = data;
    if (collaboratorData?.expectation?.id) {
      collaborator.expectation_attributes.id = collaboratorData?.expectation?.id;
    }
    dispatch(updateCollaborator(data, collaboratorId));
  };

  useEffect(() => {
    if (successCollaboratorProcess) {
      setIsDisabled(true);
      toast(
        MESSAGE_TYPES.success,
        handleMessages(MESSAGE_TYPES.success, HTTP_STATUS_RESPONSE.ok, t),
      );
      dispatch(resetStateProcess());
    }
  }, [successCollaboratorProcess, dispatch, t]);

  const details = [
    {
      canShow: !isEmptyObject(results),
      component: (
        <SimpleAccordion
          title={ t("tabsOverview.tipsForDevelopment") }
          src={ Tips }
          expandedPanel={ "tip" }
          isTitleImage
        >
          { !isEmptyObject(results) ? getTipsByQuadrant(results?.quadrantData[NINEBOX.quadrantData.quadrant]) : (
            <NoDataMessage
              message={ t("noTips") }
              isHappyFace
            />
          )}
        </SimpleAccordion>
      ),
    },
    {
      canShow: !isNull(collaboratorData),
      component: (
        <SimpleAccordion
          title={ t("tabsOverview.employeeExpectations") }
          src={ ToDevelop }
          expandedPanel={ "overview" }
          isTitleImage
        >
          <StyledGrid container>
            <form onSubmit={ handleSubmit(onSubmit) }>
              <Controller
                as={ (
                  <InputEditor
                    validateInputEdit={ validateInputEdit }
                    text={ collaboratorData?.expectation?.description }
                  />
                ) }
                name={ "collaborator.expectation_attributes.description" }
                control={ control }
              />
              <Button
                type={ BUTTON_TYPE.submit }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                disabled={ disabled }
                isLoading={ isLoadingCollaboratorProcess }
              >
                {t("common:common.save")}
              </Button>
            </form>
          </StyledGrid>
        </SimpleAccordion>
      ),
    },
    {
      canShow: !isNotValid(attritionStates.data) && !isNotValid(attritionStates.collaborator),
      component: (
        <StyledResult>
          <AttritionProbabilityCard t={ t } attritionStates={ attritionStates } hasBorder />
        </StyledResult>
      ),
    },
    {
      canShow: !isNull(recommendations),
      component: (
        <SimpleAccordion
          title={ t("tabsOverview.recommendations") }
          src={ ToDevelop }
          expandedPanel={ "recommendations" }
          isTitleImage
        >
          { recommendations }
        </SimpleAccordion>
      ),
    },
  ];

  const getResults = results?.quadrantData && !results.title && (
    <>
      <Typography variant={ VARIANT.h6 }>
        {`${t("ninebox:quadrant")}: ${results.quadrantData[NINEBOX.quadrantData.name]}`}
      </Typography>
      <StyleTypography>
        { results.quadrantData[NINEBOX.quadrantData.description] }
      </StyleTypography>
    </>
  );

  return (
    <Grid data-testid={ "details-by-collaborator-view-component" }>
      <EmployeeData>
        <AvatarProfile
          alt={ collaboratorData?.full_name }
          src={ getEmployeeImage(collaboratorData?.profile_img_url) }
        />
        <Title variant={ VARIANT.subtitle2 }>
          { collaboratorData?.full_name }
        </Title>
      </EmployeeData>
      <History>
        { isLoading ? (
          <SkeletonLoader num={ SKELETONS_NUMBER.TWO } isInline={ false } />
        ) : (
          <>
            <Typography variant={ VARIANT.h6 }>
              {results?.process?.name}
            </Typography>
            { getResults }
          </>
        )}
        <StyledDetails>
          { details.map((item) => item.canShow && item.component) }
        </StyledDetails>
      </History>
    </Grid>
  );
};

DetailsByCollaborator.propTypes = {
  collaboratorId: PropTypes.number.isRequired,
  results: PropTypes.object,
  attritionStates: PropTypes.object,
  recommendations: PropTypes.any,
  isLoading: PropTypes.bool,
};

DetailsByCollaborator.defaultProps = {
  results: {},
  attritionStates: {},
  recommendations: null,
  isLoading: false,
};

export default DetailsByCollaborator;
