import PropTypes from "prop-types";
import { StyledButton, StyledIconContainer } from "./styles";

const ButtonAddTable = (props) => {
  const {
    variant, onClick = () => {}, icon, disabled, text,
  } = props;

  const handleClick = () => onClick();

  return (
    <StyledButton
      data-testid={ "button-add-table" }
      variant={ variant }
      onClick={ handleClick }
      disabled={ disabled }
    >
      <StyledIconContainer>
        {icon}
        <span>{text}</span>
      </StyledIconContainer>
    </StyledButton>
  );
};

ButtonAddTable.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  text: PropTypes.string,
};

export default ButtonAddTable;
