import PropTypes from "prop-types";
import { StyledSectionContainer } from "./styles";

const SectionContainer = ({
  children, height, isFlex, padding,
}) => (
  <StyledSectionContainer height={ height } isFlex={ isFlex } padding={ padding }>
    {children}
  </StyledSectionContainer>
);

SectionContainer.propTypes = {
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  isFlex: PropTypes.bool,
  padding: PropTypes.string,
};

SectionContainer.defaultProps = {
  height: null,
  isFlex: false,
  padding: null,
};

export default SectionContainer;
