import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import includes from "lodash/includes";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import MenuPopup from "components/MenuPopup";
import ModalDialogContent from "components/Modal/components/ModalDialogContent";
import { ReactComponent as Units } from "assets/images/administrator/units.svg";
import { ReactComponent as Surveys } from "assets/images/administrator/surveys.svg";
import { ReactComponent as Cities } from "assets/images/administrator/city.svg";
import { ReactComponent as PositionsIcon } from "assets/images/administrator/positions.svg";
import { ReactComponent as ContractTypes } from "assets/images/administrator/contract_types.svg";
import { ReactComponent as AdditionalFields } from "assets/images/administrator/additional_fields.svg";
import { ReactComponent as Integrations } from "assets/images/administrator/integration.svg";
import { ReactComponent as Notifications } from "assets/images/administrator/notifications.svg";
// import { ReactComponent as Benefits } from "assets/images/administrator/benefits.svg";
// import { ReactComponent as Companies } from "assets/images/administrator/companies.svg";
// import { ReactComponent as FinishContractReasons } from "assets/images/administrator/finish_contract_reasons.svg";
// import { ReactComponent as CostCenter } from "assets/images/administrator/cost_center.svg";
// import { ReactComponent as Reorder } from "assets/images/administrator/reorder.svg";
import {
  formatDate,
  isAdminNala,
  getAllExceptSelected,
  getSlackUrlByCompany,
} from "common/utils";
import {
  OBJECT_KEYS,
  WIDTH,
  SIZE, MIN_VALUE,
  LOCAL_STORAGE_NAMES,
  ALIGN_ITEMS,
  ACTIONS,
  INDEX,
  TARGET,
  STATE,
} from "common/constants";
import { isEqual, isNotValid } from "common/helpers";
import SurveyProcess from "views/SurveyProcess";
import SurveyEvaluationsPreview from "views/SurveyEvaluationsPreview";
import { getProcessTypes } from "views/SurveyProcess/functions";
import {
  sendSurveyProcess, getList as getSurveyProcesses, getDataToDownload,
  loadDataResponse, updateState,
} from "redux/actions/surveyProcessesActions";
import {
  getList as getCitiesList,
  deleteItem as deleteCity,
  create as createCity,
  update as updateCity,
} from "redux/actions/common/cityActions";
import {
  getList as getPositions,
  create as createPosition,
  update as updatePosition,
  deleteItem as deletePosition,
} from "redux/actions/position/positionActions";
import {
  getList as getListOrgunits,
  create as createOrgUnits,
  update as updateOrgUnits,
  deleteItem as deleteOrgUnits,
} from "redux/actions/organizationUnits/orgUnitsActions";
import {
  getList as getTypeOfContract,
  deleteItem as deleteTypeOfContract,
  create as createTypeOfContract,
  update as updateTypeOfContract,
} from "redux/actions/typeOfContractActions";
import {
  getList as getDynamicAttribute,
  create as createDynamicAttribute,
  update as updateDynamicAttribute,
  deleteItem as deleteDynamicAttribute,
} from "redux/actions/common/dynamicAttributeActions";
import { getAsyncCollaboratorChildrens } from "redux/actions/collaboratorActions";
import { updateSlackUsers, getListSlackPaginated } from "redux/actions/integrations/slackActions";
import CardModule from "../components/CardModule";
import RelocateEmployee from "../components/RelocateEmployee";
import { getContractActions } from "./contractTypes";
import { getCityActions, getCountry } from "./cities";
import { buildNewOptionArray } from "./units";
import { getSurveyActions } from "./surveys";
import { getIntegrationActions } from "./integrations";

// TODO: remove position
export const MODULES_INFO = [
  {
    icon: <Units />,
    text: "units",
    path: "/administrator/units",
    disabled: false,
  },
  // FIXME: These options will be enabled when the services are ready
  {
    icon: <PositionsIcon />,
    text: "positions",
    path: "/administrator/positions",
    disabled: false,
  },
  // {
  //   icon: <Benefits />,
  //   text: "benefits",
  //   path: "/administrator/benefits",
  //   disabled: true,
  // },
  // {
  //   icon: <Companies />,
  //   text: "companies",
  //   path: "/administrator/companies",
  //   disabled: true,
  // },
  // {
  //   icon: <FinishContractReasons />,
  //   text: "finish_contract_reasons",
  //   path: "/administrator/finish-contract-reasons",
  //   disabled: true,
  // },
  // {
  //   icon: <CostCenter />,
  //   text: "cost_center",
  //   path: "/administrator/cost-center",
  //   disabled: true,
  // },
  {
    icon: <Surveys />,
    text: "surveys",
    path: "/administrator/surveys",
    new: true,
    simple: true,
    disabled: true,
  },
  {
    icon: <ContractTypes />,
    text: "contract-types",
    path: "/administrator/contract-types",
    disabled: false,
  },
  {
    icon: <Cities />,
    text: "cities",
    path: "/administrator/cities",
    disabled: false,
  },
  {
    icon: <AdditionalFields />,
    text: "additional-fields",
    path: "/administrator/additional-fields",
    disabled: false,
  },
  {
    icon: <Integrations />,
    text: "integrations",
    path: "/administrator/integrations",
    disabled: false,
  },
  {
    icon: <Notifications />,
    text: "notifications",
    path: "/administrator/notifications",
    disabled: false,
  }
];

export const getModules = (t, userCookies) => {
  let modules = [];
  // FIXME: remove disabled and isAdmin when available to all administrators
  const isAdmin = isAdminNala(userCookies);
  modules = MODULES_INFO.map((item, index) => [
    ((item.disabled && isAdmin) || !item.disabled) && (
      <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 } key={ `${item.name} - ${index}` }>
        <CardModule
          icon={ item.icon }
          text={ t(`modules.${item.text}.title`) }
          path={ item.path }
          disabled={ item.disabled && !isAdmin }
        />
      </Grid>
    ),
  ]);

  return modules;
};

export const MODULES_INDEX = {
  units: 0,
  positions: 1,
  surveys: 2,
  contractTypes: 3,
  cities: 4,
  additionalFields: 5,
  integrations: 6,
};

export const UNITS = MODULES_INFO[MODULES_INDEX.units].text;
export const CONTRACT_TYPES = MODULES_INFO[MODULES_INDEX.contractTypes].text;
export const CITIES = MODULES_INFO[MODULES_INDEX.cities].text;
export const POSITIONS = MODULES_INFO[MODULES_INDEX.positions].text;
export const ADDITIONAL_FIELDS = MODULES_INFO[MODULES_INDEX.additionalFields].text;

const SURVEYS = MODULES_INFO[MODULES_INDEX.surveys]?.text;

const INTEGRATIONS = MODULES_INFO[MODULES_INDEX.integrations].text;

export const getDataBreadcrumbs = (t, thisModule, last) => [
  {
    label: t("title"),
    path: "/administrator",
  },
  {
    label: t(`modules.${thisModule}.title`),
    path: last ? "./" : "",
  },
];

const getDeleteMessage = (t, thisModule, rowData) => {
  const mainMessage = `${t("common:common.modal_messages.delete_text")} ${t(`modules.${thisModule}.singular_title`)} ${
    rowData.name
  }?`;
  const collaboratorsMessage = `${rowData.employees_count} ${t(`modules.${thisModule}.collaborators`)} `;

  const deleteMessage = isNotValid(rowData.employees_count) || rowData.employees_count === MIN_VALUE
    ? mainMessage
    : `${collaboratorsMessage}${mainMessage}`;
  return deleteMessage;
};

const getCollaboratorsParamQuery = (module) => {
  let query;
  switch (module) {
  case UNITS:
    query = "job_position_organization_units_id_eq";
    break;
  case POSITIONS:
    query = "job_position_position_id_eq";
    break;
  default:
    break;
  }
  return query;
};

const getOptionsAction = async (dispatch, module, companyId) => {
  let options;
  switch (module) {
  case UNITS:
    options = await dispatch(getListOrgunits());
    break;
  case POSITIONS:
    options = await dispatch(getPositions());
    break;
  default:
    break;
  }
  return options;
};

const getRelocateKey = (module) => {
  let key;
  switch (module) {
  case UNITS:
    key = OBJECT_KEYS.unit;
    break;
  case POSITIONS:
    key = OBJECT_KEYS.position;
    break;
  default:
    break;
  }
  return key;
};

export const handleRowDelete = (functions, t, thisModule, rowData) => functions.viewModal(
  t("common:common.modal_messages.sure_question"),
  "",
  getDeleteMessage(t, thisModule, rowData),
  t("common:common.modal_messages.yes_confirm"),
  t("common:common.modal_messages.no_cancel"),
  () => {
    functions.handleDelete(thisModule, rowData.id);
  },
);

export const handleRowEdit = (functions, thisModule, rowData) => {
  functions.handleEdit(thisModule, rowData);
};

const handleMove = (functions, thisModule, rowData, t) => {
  functions.handleMoveModal(thisModule, rowData, t);
};

export const pushValidOptions = (
  rowData,
  ACTIONS_MENU,
  DELETE_ACTION_MENU,
  thisModule,
  t,
  functions,
) => {
  const isAvailableToDelete = rowData.employees_count === MIN_VALUE;
  switch (thisModule) {
  case UNITS:
    const moveAction = {
      title: t("table.actions.move"),
      icon: <SyncAltIcon fontSize={ SIZE.small } />,
      onClick: () => {
        handleMove(functions, thisModule, rowData, t);
      },
    };
    ACTIONS_MENU.push(moveAction);
    ACTIONS_MENU.push(DELETE_ACTION_MENU);
    break;
  case CONTRACT_TYPES:
    if (isAvailableToDelete) {
      ACTIONS_MENU.push(DELETE_ACTION_MENU);
    }
    break;
  default:
    ACTIONS_MENU.push(DELETE_ACTION_MENU);
    break;
  }
};

export const getTableHeader = (dispatch, t, thisModule, classes, functions, actionsOptions) => [
  // this first object will be modified when drag and drop functionalities are done
  // {
  //   id: "id",
  //   label: "",
  //   customRender: (rowData) => {
  //     return <Reorder />;
  //   },
  //   width: WIDTH.actions,
  //   dragable: true,
  // },
  {
    id: OBJECT_KEYS.name,
    label: t(`modules.${thisModule}.title`),
    collapseCell: { isActive: thisModule === UNITS },
    width: WIDTH.text,
  },
  {
    id: OBJECT_KEYS.createdAt,
    label: t("table.create_date"),
    width: WIDTH.date,
    align: ALIGN_ITEMS.center,
    customRender: (rowData) => formatDate(rowData.created_at),
  },
  {
    id: OBJECT_KEYS.employeesCount,
    label: t("table.collaborators"),
    width: WIDTH.text,
    align: ALIGN_ITEMS.center,
  },
  {
    id: OBJECT_KEYS.principalActions,
    label: t("table.actions.main"),
    customRender: (rowData) => {
      const DELETE_ACTION_MENU = {
        title: <div className={ classes.delete }>{t("table.actions.delete")}</div>,
        icon: <HighlightOffIcon fontSize={ SIZE.small } className={ classes.delete } />,
        onClick: async () => {
          let modalContent = {
            children: (
              <ModalDialogContent
                title={ t("common:common.modal_messages.sure_question") }
                text={ getDeleteMessage(t, thisModule, rowData) }
                onClick={ () => functions.handleDelete(thisModule, rowData.id) }
                handleClose={ () => functions.setIsOpen(false) }
              />
            ),
          };
          if (rowData.employees_count > MIN_VALUE) {
            const childrens = await dispatch(getAsyncCollaboratorChildrens(
              rowData.id,
              getCollaboratorsParamQuery(thisModule),
            ));
            if (!isEmpty(childrens)) {
              const { companyId } = actionsOptions;
              const { handleMoveMassive, openCloseModal } = functions;
              const action = await getOptionsAction(dispatch, thisModule, companyId);
              modalContent = {
                title: "",
                children: (
                  <RelocateEmployee
                    id={ rowData.id }
                    module={ thisModule }
                    relocateKey={ getRelocateKey(thisModule) }
                    title={ t(`modules.${thisModule}.relocate_title`) }
                    subtitle={ t(`modules.${thisModule}.relocate_subtitle`) }
                    classes={ classes }
                    options={ buildNewOptionArray(getAllExceptSelected(action, rowData.id)) }
                    collaborators={ childrens }
                    action={ (data) => handleMoveMassive(thisModule, data, rowData.id) }
                    modalHandler={ openCloseModal }
                  />
                ),
              };
            }
          }
          functions.handleModalContent(modalContent);
          functions.setIsOpen(true);
        },
      };

      const ACTIONS_MENU = [
        {
          title: t("table.actions.edit"),
          icon: <EditIcon fontSize={ SIZE.small } />,
          onClick: () => {
            handleRowEdit(functions, thisModule, rowData);
          },
        },
      ];

      pushValidOptions(rowData, ACTIONS_MENU, DELETE_ACTION_MENU, thisModule, t, functions);

      return <MenuPopup items={ ACTIONS_MENU } />;
    },
    width: WIDTH.actions,
  },
];

export const getActions = (dispatch, t, thisModule, classes, functions, actionsOptions) => (rowData) => {
  const DELETE_ACTION_MENU = {
    title: <div className={ classes.delete }>{t("table.actions.delete")}</div>,
    icon: <HighlightOffIcon fontSize={ SIZE.small } className={ classes.delete } />,
    onClick: async () => {
      let modalContent = {
        children: (
          <ModalDialogContent
            title={ t("common:common.modal_messages.sure_question") }
            text={ getDeleteMessage(t, thisModule, rowData) }
            onClick={ () => functions.handleDelete(thisModule, rowData.id) }
            handleClose={ () => functions.setIsOpen(false) }
          />
        ),
      };
      if (rowData.employees_count > MIN_VALUE) {
        const childrens = await dispatch(getAsyncCollaboratorChildrens(
          rowData.id,
          getCollaboratorsParamQuery(thisModule),
        ));
        if (!isEmpty(childrens)) {
          const { companyId } = actionsOptions;
          const { handleMoveMassive, openCloseModal } = functions;
          const action = await getOptionsAction(dispatch, thisModule, companyId);
          modalContent = {
            title: "",
            children: (
              <RelocateEmployee
                id={ rowData.id }
                module={ thisModule }
                relocateKey={ getRelocateKey(thisModule) }
                title={ t(`modules.${thisModule}.relocate_title`) }
                subtitle={ t(`modules.${thisModule}.relocate_subtitle`) }
                classes={ classes }
                options={ buildNewOptionArray(getAllExceptSelected(action, rowData.id)) }
                collaborators={ childrens }
                action={ (data) => handleMoveMassive(thisModule, data, rowData.id) }
                modalHandler={ openCloseModal }
              />
            ),
          };
        }
      }
      functions.handleModalContent(modalContent);
      functions.setIsOpen(true);
    },
  };

  const ACTIONS_MENU = [
    {
      title: t("table.actions.edit"),
      icon: <EditIcon fontSize={ SIZE.small } />,
      onClick: () => {
        handleRowEdit(functions, thisModule, rowData);
      },
    },
  ];

  pushValidOptions(rowData, ACTIONS_MENU, DELETE_ACTION_MENU, thisModule, t, functions);

  return <MenuPopup items={ ACTIONS_MENU } />;
};

export const getDispatch = (thisModule, dispatch, query) => {
  switch (thisModule) {
  case UNITS:
    dispatch(getListOrgunits(true));
    break;
  case SURVEYS:
    dispatch(getSurveyProcesses("", query));
    break;
  case CONTRACT_TYPES:
    dispatch(getTypeOfContract(query));
    break;
  case CITIES:
    dispatch(getCitiesList(false, query));
    break;
  case POSITIONS:
    dispatch(getPositions(query));
    break;
  case ADDITIONAL_FIELDS:
    dispatch(getDynamicAttribute(query));
    break;
  case INTEGRATIONS:
    dispatch(getListSlackPaginated());
    break;
  default:
    break;
  }
};

export const postDispatch = async (dispatch, thisModule, data, companyId) => {
  let createElement;
  switch (thisModule) {
  case UNITS:
    createElement = await dispatch(createOrgUnits(data));
    break;
  case CONTRACT_TYPES:
    createElement = await dispatch(createTypeOfContract(data));
    break;
  case CITIES:
    createElement = await dispatch(createCity(data));
    break;
  case POSITIONS:
    createElement = await dispatch(createPosition(data));
    break;
  case ADDITIONAL_FIELDS:
    createElement = await dispatch(createDynamicAttribute(data));
    break;
  default:
    break;
  }
  return createElement;
};

export const putDispatch = async (dispatch, thisModule, id, data, companyId) => {
  let updateElement;
  switch (thisModule) {
  case UNITS:
    updateElement = await dispatch(updateOrgUnits(id, data));
    break;
  case CONTRACT_TYPES:
    updateElement = await dispatch(updateTypeOfContract(id, data));
    break;
  case CITIES:
    updateElement = await dispatch(updateCity(id, data));
    break;
  case POSITIONS:
    updateElement = await dispatch(updatePosition(id, data));
    break;
  case ADDITIONAL_FIELDS:
    updateElement = await dispatch(updateDynamicAttribute(id, data));
    break;
  default:
    break;
  }
  return updateElement;
};

export const deleteDispatch = async (dispatch, thisModule, id, companyId, alternativeId, isMassive) => {
  let deleteElement;
  switch (thisModule) {
  case UNITS:
    deleteElement = await deleteOrgUnits(id, alternativeId);
    break;
  case CONTRACT_TYPES:
    deleteElement = await dispatch(deleteTypeOfContract(id));
    break;
  case CITIES:
    deleteElement = await dispatch(deleteCity(id, alternativeId));
    break;
  case POSITIONS:
    deleteElement = await deletePosition(id, alternativeId);
    break;
  case ADDITIONAL_FIELDS:
    deleteElement = await dispatch(deleteDynamicAttribute(id));
    break;
  default:
    break;
  }
  return deleteElement;
};

const getIntegrationData = (totalActiveUsers, totalConnectedUsers, t, companyId) => [
  {
    name: "Slack",
    description: t("modules.integrations.slack.description"),
    url: (
      <Link
        href={ getSlackUrlByCompany(companyId) }
        target={ TARGET.self }
      >
        <img
          alt={ "Add to Slack" }
          height={ "40" }
          width={ "139" }
          src={ "https://platform.slack-edge.com/img/add_to_slack.png" }
          srcSet={ "https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" }
        />
      </Link>),
    connectedUsers: `${totalConnectedUsers}/${totalActiveUsers}`,
    notConnectedUsers: totalActiveUsers - totalConnectedUsers,
  },
];

export const getReducerToUse = (thisModule, selector, companyId, t) => {
  let data = [];
  let isLoading = false;
  let total = 0;

  const {
    listTree: listOrgUnitsTree,
    isLoadingTree: isLoadingOrgUnitsTree,
  } = selector(({ orgUnitsReducer }) => orgUnitsReducer);

  const {
    list: listsSurveyProcess,
    listTotal: listTotalSurveyProcess,
    loadingList: isLoadingSurveyProcess,
  } = selector(({ surveysReducer }) => surveysReducer);

  const {
    list: listCities,
    listTotal: listTotalCities,
    isLoadingList: isLoadingCities,
  } = selector(({ cityReducer }) => cityReducer);

  const {
    list: listTypeOfContract,
    listTotal: listTotalTypeOfContract,
    isLoading: isLoadingTypeOfContract,
  } = selector(({ typeOfContractReducer }) => typeOfContractReducer);

  const {
    list: listPositions,
    listTotal: listTotalPositions,
    isLoadingList: isLoadingPositions,
  } = selector(({ positionReducer }) => positionReducer);

  const {
    list: listDynamicAttributes,
    listTotal: listTotalDynamicAttributes,
    isLoadingList: isLoadingDynamicAttributes,
  } = selector(({ dynamicAttributeReducer }) => dynamicAttributeReducer);

  const {
    listTotalSlackUsers: listTotalConnectedUsers,
    listTotalActiveUsers,
    isLoadingList: isLoadingSlack,
  } = selector(({ slackReducer }) => slackReducer);

  switch (thisModule) {
  case UNITS:
    data = listOrgUnitsTree;
    isLoading = isLoadingOrgUnitsTree;
    break;
  case SURVEYS:
    data = listsSurveyProcess;
    total = listTotalSurveyProcess;
    isLoading = isLoadingSurveyProcess;
    break;
  case CONTRACT_TYPES:
    data = listTypeOfContract;
    total = listTotalTypeOfContract;
    isLoading = isLoadingTypeOfContract;
    break;
  case CITIES:
    data = listCities;
    total = listTotalCities;
    isLoading = isLoadingCities;
    break;
  case POSITIONS:
    data = listPositions;
    total = listTotalPositions;
    isLoading = isLoadingPositions;
    break;
  case ADDITIONAL_FIELDS:
    data = listDynamicAttributes;
    total = listTotalDynamicAttributes;
    isLoading = isLoadingDynamicAttributes;
    break;
  case INTEGRATIONS:
    data = getIntegrationData(listTotalActiveUsers, listTotalConnectedUsers, t, companyId);
    isLoading = isLoadingSlack;
    break;
  default:
    break;
  }
  return {
    data,
    total,
    isLoading,
  };
};

export const pushChildren = (list, data, parentId) => {
  isNull(parentId) ? list.push(data) : list.children.push(data);
  return list;
};

const findNestedChildrens = (object, targetId) => {
  if (object.id === targetId) {
    return object;
  }
  if (object.children) {
    for (const item of object.children) {
      const check = findNestedChildrens(item, targetId);
      if (check) {
        return check;
      }
    }
  }
  return null;
};

const findChildrens = (list, parentId) => {
  let result = null;
  for (const object of list) {
    result = findNestedChildrens(object, parentId);
    if (result) {
      break;
    }
  }

  return result;
};

export const getFinalObject = (list, parentId) => {
  const moduleList = list;
  if (isNull(parentId)) {
    return moduleList;
  }
  return findChildrens(moduleList, parentId);
};

// FIXME: use org unit type
export const getParentId = (actions, item) => (!isNull(actions.newIdElement)
  ? actions.newIdElement
  : isEmpty(item?.children)
    ? item?.parent_id
    : item?.children[0]?.parent_id);

export const getUncontrollerRender = (thisModule, action) => {
  let render = null;

  switch (thisModule) {
  case SURVEYS:
    render = action === ACTIONS.preview ? <SurveyEvaluationsPreview /> : <SurveyProcess />;
    break;
  default:
    break;
  }

  return render;
};

export const loadDataDispatch = async (thisModule, dispatch, id) => {
  let response = null;
  switch (thisModule) {
  case SURVEYS:
    response = await dispatch(loadDataResponse(id));
    break;
  default:
    break;
  }
  return response;
};

export const downloadExcel = async (thisModule, dispatch, id, total) => {
  let dataToDownload;
  switch (thisModule) {
  case SURVEYS:
    dataToDownload = await dispatch(getDataToDownload(id, total));
    break;
  default:
    break;
  }
  return dataToDownload;
};

export const updateDispatch = async (thisModule, dispatch) => {
  let response = null;
  switch (thisModule) {
  case INTEGRATIONS:
    response = await dispatch(updateSlackUsers());
    break;
  default:
    break;
  }
  return response;
};

const headersByModule = {
  [UNITS]: [UNITS, OBJECT_KEYS.createdAt, OBJECT_KEYS.employeesCount],
  [POSITIONS]: [POSITIONS],
  [ADDITIONAL_FIELDS]: [OBJECT_KEYS.additionalFields],
  [CONTRACT_TYPES]: [OBJECT_KEYS.contractTypes, OBJECT_KEYS.employmentRelationshipOnly, OBJECT_KEYS.createdAt],
  [CITIES]: [CITIES, OBJECT_KEYS.country, OBJECT_KEYS.employeesCount],
  [SURVEYS]: [SURVEYS, OBJECT_KEYS.finishDate, OBJECT_KEYS.processType, OBJECT_KEYS.state],
  [INTEGRATIONS]: [OBJECT_KEYS.name, OBJECT_KEYS.description, OBJECT_KEYS.connectedUsers],
};

export const getTableHeaderByModule = (dispatch, t, thisModule, functions, classes, actionsOptions) => {
  let tableHeader = [];
  const header = thisModule in headersByModule ? [...headersByModule[thisModule], ...["actions"]] : [];
  const head = header.map((item) => ({
    id: item,
    label: t(`tables:headers.${item}`),
  }));
  switch (thisModule) {
  case SURVEYS:
    tableHeader = { head, actions: getSurveyActions(t, thisModule, functions) };
    break;
  case CONTRACT_TYPES:
    tableHeader = { head, actions: getContractActions(t, thisModule, functions, classes) };
    break;
  case CITIES:
    tableHeader = { head, actions: getCityActions(t, thisModule, functions, classes) };
    break;
  case UNITS:
    tableHeader = getTableHeader(dispatch, t, thisModule, classes, functions, actionsOptions);
    break;
  case INTEGRATIONS:
    tableHeader = { head, actions: getIntegrationActions(t, thisModule, functions) };
    break;
  default:
    tableHeader = { head, actions: getActions(dispatch, t, thisModule, classes, functions, actionsOptions) };
    break;
  }

  return tableHeader;
};

export const getModuleData = (thisModule) => MODULES_INFO.find((moduleInfo) => moduleInfo.text === thisModule);

export const getHeaderModule = (t, thisModule) => ({
  title: t(`modules.${thisModule}.title`),
  subtitle: t(`modules.${thisModule}.subtitle`),
  breadcrumb: getDataBreadcrumbs(t, thisModule),
});

export const getAllExceptDeleted = (list, id) => {
  const listAux = list;
  return listAux
    .map((item) => ({ ...item }))
    .filter((item) => {
      if (OBJECT_KEYS.children in item) {
        item.children = getAllExceptDeleted(item.children, id);
      }
      return item.id !== id;
    });
};

export const getListUpdated = (list, element) => {
  const listAux = list;
  return listAux
    .map((item) => ({ ...item }))
    .filter((item) => {
      if (OBJECT_KEYS.children in item) {
        item.children = getListUpdated(item.children, element);
      }

      if (item.id === element.id) {
        // all keys need it from all modules - here -
        item.name = element.name;
        item.employment_relationship = element.employment_relationship;
        item.country_id = element.country_id;
      }

      return item;
    });
};

export const sendEmailDispatch = async (
  thisModule,
  dispatch,
  id,
  isReminder = false,
) => {
  let sendElement;
  switch (thisModule) {
  case SURVEYS:
    sendElement = await dispatch(sendSurveyProcess(id, isReminder));
    break;

  default:
    break;
  }
  return sendElement;
};

export const getSubmitDataObject = (module, objData, editableRow, selectedParentId) => {
  let newData;
  switch (module) {
  case UNITS:
    newData = {
      name: objData[module].name,
      parent_id: editableRow ? editableRow.parent_id : selectedParentId,
    };
    break;
  case CONTRACT_TYPES:
    newData = {
      name: objData[module].name,
      employment_relationship_id: objData[module].employment_relationship,
    };
    break;
  case CITIES:
    newData = {
      name: objData[module].name,
      country_id: objData[module].country_id,
    };
    break;
  case POSITIONS:
    newData = {
      name: objData[module].name,
    };
    break;
  case ADDITIONAL_FIELDS:
    newData = {
      name: objData[module].name,
      create_attributes: false,
    };
    break;
  default:
    break;
  }
  return newData;
};

export const getDefaultValues = (module, editableRow) => {
  let defaultValues;
  switch (module) {
  case UNITS:
    defaultValues = {
      name: editableRow.name,
    };
    break;
  case CONTRACT_TYPES:
    defaultValues = {
      name: editableRow.name,
      employment_relationship: editableRow.employment_relationship?.id,
    };
    break;
  case CITIES:
    defaultValues = {
      name: editableRow.name,
      country_id: editableRow.country_id,
    };
    break;
  case POSITIONS:
    defaultValues = {
      name: editableRow.name,
    };
    break;
  case ADDITIONAL_FIELDS:
    defaultValues = {
      name: editableRow.name,
    };
    break;
  default:
    break;
  }
  return defaultValues;
};

export const validateOnAdd = (module) => {
  const validModules = [CONTRACT_TYPES, CITIES];
  const isOnAddValid = includes(validModules, module);
  if (isOnAddValid) {
    return true;
  }
  return false;
};

export const getPulseValidation = (module, control) => {
  let isValid;
  const controlToUse = control[module];
  switch (module) {
  case CONTRACT_TYPES:
    isValid = {
      name: isEmpty(controlToUse.name),
      employment_relationship: !isNumber(controlToUse.employment_relationship),
    };
    break;

  default:
    isValid = {
      name: isEmpty(controlToUse.name),
    };
    break;
  }
  return isValid;
};

export const removeOrGetFromLocalStorage = (module, isRemove) => {
  let itemName;
  switch (module) {
  case UNITS:
    itemName = "orgUnits";
    break;
  case CONTRACT_TYPES:
    itemName = "typeOfContract";
    break;
  case CITIES:
    itemName = "cities";
    break;
  case POSITIONS:
    itemName = "positions";
    break;
  default:
    break;
  }
  if (isRemove) {
    localStorage.removeItem(LOCAL_STORAGE_NAMES[itemName]);
  } else {
    return itemName;
  }
};

export const getAsyncDispatch = (dispatch, thisModule) => {
  switch (thisModule) {
  case UNITS:
    return dispatch(getListOrgunits());
  case CITIES:
    return dispatch(getCitiesList(true));
  default:
    break;
  }
};

export const getModalMoveTitle = (thisModule, t) => {
  switch (thisModule) {
  case UNITS:
    return t("modules.units.move");
  case CITIES:
    return t("modules.cities.move");
  default:
    break;
  }
};

export const getRowsDesktop = (data, t, history, extraParam, isMobile, modal, module) => data?.map((rowData) => {
  const content = [{
    id: rowData.id,
    content: rowData.name,
  }];

  // For units
  if (!isEmpty(modal.modalOptions) && isEqual(modal.modalOptions[INDEX.one], UNITS)) {
    content[INDEX.zero].collapseData = modal.modalOptions[INDEX.zero](rowData);
  }
  // For surveys
  if (isEqual(module, SURVEYS) || rowData[OBJECT_KEYS.date]) {
    content.push({
      content: formatDate(rowData.date),
    });
  }
  if (isEqual(module, SURVEYS) || rowData[OBJECT_KEYS.type]) {
    content.push({
      content: getProcessTypes(t).find((process) => isEqual(rowData.type, process.key))?.label,
    });
  }
  if (isEqual(module, SURVEYS) || rowData[OBJECT_KEYS.state]) {
    content.push({
      content: `${t(`surveys:survey_state.${rowData.state}`)}${rowData.follow_up_process_id ? ` (${t("surveys:form.fields.follow_up")})` : ""}`,
    });
  }
  // For cities
  if (isEqual(module, CITIES) || rowData[OBJECT_KEYS.country_id]) {
    content.push({
      content: getCountry(rowData[OBJECT_KEYS.country_id]).name,
    });
  }
  // For contracts
  if (isEqual(module, CONTRACT_TYPES) || rowData[OBJECT_KEYS.employmentRelationshipOnly]) {
    content.push({
      content: rowData[OBJECT_KEYS.employmentRelationshipOnly]?.name || "",
    });
  }
  // For units
  if (isEqual(module, UNITS) || OBJECT_KEYS.createdAt in rowData) {
    content.push({
      content: formatDate(rowData[OBJECT_KEYS.createdAt]) || "",
    });
  }
  if (isEqual(module, UNITS) || OBJECT_KEYS.employeesCount in rowData) {
    content.push({
      content: rowData[OBJECT_KEYS.employeesCount] || "",
    });
  }

  // For integrations
  if (isEqual(module, INTEGRATIONS) || rowData[OBJECT_KEYS.description]) {
    content.push({
      content: rowData[OBJECT_KEYS.description],
    });
  }
  // not connected users
  if (isEqual(module, INTEGRATIONS) || rowData[OBJECT_KEYS.connectedUsers]) {
    content.push({
      content: rowData[OBJECT_KEYS.connectedUsers],
    });
  }

  content.push({
    content: extraParam(rowData),
  });
  return content;
});

export const enableDispatch = async (
  thisModule,
  dispatch,
  id,
  processType,
) => {
  let enableElement;
  switch (thisModule) {
  case SURVEYS:
    enableElement = {};
    enableElement = await dispatch(updateState(id, STATE.launch, processType));
    break;

  default:
    break;
  }
  return enableElement;
};
