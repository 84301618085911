import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { StyledToggleButton, StyledToggleButtonGroup } from "./styles";

const LanguageSelector = (props) => {
  const { onLanguageChange, value } = props;
  const [language, setLanguage] = useState(value);

  const handleLanguage = (event, newLanguage) => {
    if (newLanguage !== null) {
      setLanguage(newLanguage);
      onLanguageChange(newLanguage);
    }
  };

  useEffect(() => {
    setLanguage(value);
  }, [value]);

  return (
    <StyledToggleButtonGroup
      value={ language }
      exclusive
      onChange={ handleLanguage }
      aria-label={ "language" }
    >
      <StyledToggleButton value={ "es" } aria-label={ "spanish" }>
        {"ES"}
      </StyledToggleButton>
      <StyledToggleButton value={ "en" } aria-label={ "english" }>
        {"EN"}
      </StyledToggleButton>
      <StyledToggleButton value={ "pt" } aria-label={ "portuguese" }>
        {"PT"}
      </StyledToggleButton>
    </StyledToggleButtonGroup>
  );
};

LanguageSelector.propTypes = {
  onLanguageChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

LanguageSelector.defaultProps = {
  value: "es",
};

export default LanguageSelector;
