import { getTooltipIcon, getTooltipTitle, getTooltipRecommendation } from "components/TalentSpiderChart/functions/dotTooltip";
import { getVerticesLabelIcon } from "components/TalentSpiderChart/functions";
import { CATEGORY_PROCESS } from "common/constants";
import { isEmpty, isNull } from "common/helpers";
import { getPercent } from "common/utils";
import { RISK_COLORS } from "theme/palette";

export const RISK_CATEGORY = {
  high: {
    key: CATEGORY_PROCESS.high,
    color: RISK_COLORS.RED,
  },
  medium: {
    key: CATEGORY_PROCESS.medium,
    color: RISK_COLORS.YELLOW,
  },
  low: {
    key: CATEGORY_PROCESS.low,
    color: RISK_COLORS.GREEN,
  },
};

export const TABS = (t) => [
  { label: t("talentDrain:map") },
  { label: t("talentDrain:recommendations") },
];

export const INDEX_TABS = {
  map: 0,
  recommendations: 1,
};

export const getRecommendations = (data, t, language) => {
  if (data && !isEmpty(data)) {
    const recommendations = data[0].attrition_axis_values
      .filter((item) => !isNull(item.value))
      .map((item) => {
        const { recommendation } = item;
        const title = recommendation?.id ? t(`talentDrain:chart.tooltipsContent.titles.${recommendation.tag}`) : getTooltipTitle(getPercent(item.value), t);
        const text = recommendation && recommendation.id
          ? recommendation[`recommendation_${language}`] || ""
          : "";
        return {
          icon: getVerticesLabelIcon(item.slug).icon,
          title: t(`talentDrain:chart.labels.${item.slug}`),
          percentage: getPercent(item.value, true),
          recommendation: {
            icon: getTooltipIcon(getPercent(item.value)),
            title,
            text,
          },
        };
      });
    return recommendations;
  }
};
