import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SimpleAccordion from "components/SimpleAccordion";
import NoDataMessage from "components_refactor/NoDataMessage";
import YoutubeCard from "components/YoutubeCard";
// import CardMedia from "components/CardMedia";
// import DefaultReading from "assets/images/potential/skills/reading.png";
import { VARIANT } from "common/constants";
// import { getExternalTitlePage } from "common/utils";
import { skillTypeKeys } from "../../functions";
import { getIcon, getTooltip, getContent } from "./functions";
import {
  Icon, Title, MainTitle, Col,
} from "./styledComponents";

// TODO: remove
const SkillCard = (props) => {
  const {
    title, icon, skills, tips,
  } = props;
  const { t } = useTranslation("potential");

  const getTipInformation = (currentTip, key) => {
    const videoList = skillTypeKeys.map((item) => item.value === currentTip && t(`tabPotential.skills.tips.${item.id}.${key}`, { returnObjects: true })).filter((item) => item)[0];
    return videoList;
  };

  // const getReadings = (readingIds) => Promise.all(readingIds?.map((reading) => getExternalTitlePage(reading)));

  const getTipsContent = () => tips.map((tip) => {
    const videoIds = getTipInformation(tip.name, "videos");
    // do not delete this
    // const readingIds = getTipInformation(tip.name, "reading");
    // const readingTitlePromise = getReadings(readingIds).then((data) => data);
    // const getReadingTitle = async () => {
    //   const readingTitle = await readingTitlePromise;
    //   console.log(readingTitle)
    // };
    // getReadingTitle()

    return (
      <Grid
        key={ tip.name }
        container
      >
        <Grid item xs={ 12 }>
          <MainTitle>
            <Icon src={ getIcon(tip.name) } alt={ "tips section icon" } />
            <Title variant={ VARIANT.h5 }>{tip.name}</Title>
          </MainTitle>

        </Grid>
        { videoIds?.map((video) => (
          <Grid key={ video } item xs={ 12 } sm={ 6 } md={ 4 } >
            <YoutubeCard
              videoId={ video }
              viewMoreLabel={ t("tabPotential.knowMore") }
            />
          </Grid>
        ))}
        {/* { getReadings(readingIds).then((data) => {
          console.log(data);
          return <div>card aquis</div>
          // // return (
          // //   <Grid key={ reading } item xs={ 12 } sm={ 6 } md={ 4 } >
          // //     <CardMedia image={ DefaultReading } title={ readingTitle } />
          // //   </Grid>
          // // );
        }) } */}
      </Grid>
    );
  });

  return (
    <Grid data-testid={ "skill-card-component" } container>
      <Grid item xs={ 12 } >
        {tips ? (
          <Col>
            <SimpleAccordion
              title={ title }
              src={ icon }
              expandedPanel={ `skill-${title}` }
              isTitleImage
            >
              <Typography variant={ VARIANT.subtitleTwo }>{t("tabPotential.skills.turnOnSubtitles")}</Typography>
              {isEmpty(tips) ? <NoDataMessage /> : getTipsContent()}
            </SimpleAccordion>
          </Col>
        ) : (
          <MainTitle>
            <Icon
              src={ icon }
              alt={ "skill section icon" }
            />
            <Title variant={ VARIANT.h5 }>
              {title}
            </Title>
          </MainTitle>
        )}

      </Grid>
      { !tips && isEmpty(skills) ? <NoDataMessage />
        : skills?.map((item) => (
          <Col key={ item.name } item xs={ 12 } sm={ 6 } md={ 4 } >
            <SimpleAccordion
              title={ item.name }
              src={ getIcon(item.name) }
              hasTitleTooltip={ getTooltip(item.name, t) }
              expandedPanel={ `skill-${item.name}` }
            >
              {getContent(item.name, item.result, t)}
            </SimpleAccordion>
          </Col>
        ))}
    </Grid>
  );
};

SkillCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  skills: PropTypes.array,
  tips: PropTypes.array,
};

SkillCard.defaultProps = {
  skills: null,
  tips: null,
};

export default SkillCard;
