import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import SkeletonLoader from "components/SkeletonLoader";
import SectionContainer from "components_refactor/SectionContainer";
import AlertDialog from "components/AlertDialog";
import {
  BUTTON_STYLE_TYPES, SKELETONS_NUMBER, VARIANT,
} from "common/constants";
import { replacePath } from "common/utils";
import constants from "styledComponents/constants";
import Timeline from "./components/Timeline";
import MovementModal from "./components/MovementModal";
import localRoutes from "../../functions/routes";
import { StyledTitle, StyledContent } from "./styles";

const MovementHistoryInfo = (props) => {
  const {
    t,
    history,
    isAdmin,
    movementsHistoryListState,
    hasAuthorization,
    handleDeleteMovementById,
    collaborator,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const initialRender = !movementsHistoryListState.list || movementsHistoryListState.isLoading ? (
    <SkeletonLoader num={ SKELETONS_NUMBER.ONE } isInline={ false } />
  ) : (
    <Timeline { ...props } />
  );

  const handleOpenAlert = (option) => {
    setIsOpen(option);
  };

  const deleteMovement = () => {
    const lastMovementIndex = movementsHistoryListState.list.length - 1;
    const lastMovement = movementsHistoryListState.list[lastMovementIndex];
    handleOpenAlert(false);
    handleDeleteMovementById(lastMovement.id);
  };

  const employeeFullName = collaborator?.full_name;

  const message = (
    <>
      <p>{t("profile:deleteLastMovementMessage", { employeeFullName })}</p>
      <br />
      <p><strong>{t("profile:deleteLastMovementQuestion")}</strong></p>
    </>
  );

  return (
    <SectionContainer>
      <Grid container>
        <Grid item xs={ 12 } md={ 4 }>
          <StyledTitle weight={ constants.fontConstants.bold } isTitle>
            {t("profile:movementsHistory")}
          </StyledTitle>
        </Grid>
        {isAdmin && hasAuthorization && (
          <Grid item xs={ 12 } md={ 8 }>
            <StyledContent>
              <Button
                onClick={ () => replacePath(history, localRoutes.addMovement) }
                variant={ VARIANT.contained }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              >
                { t("profile:movementOrPromotion") }
              </Button>
              {movementsHistoryListState.list.length > 1 && !movementsHistoryListState.isLoading
              && (
                <Button
                  onClick={ () => handleOpenAlert(true) }
                  variant={ VARIANT.outlined }
                  typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
                >
                  { t("profile:deleteLastMovement") }
                </Button>
              )}
            </StyledContent>
          </Grid>
        )}
        <Grid item xs={ 12 }>
          { initialRender }
        </Grid>
      </Grid>
      <MovementModal { ...props } />
      <AlertDialog
        isOpen={ isOpen }
        onClose={ () => handleOpenAlert(false) }
        title={ t("profile:deleteLastMovement") }
        message={ message }
        onSubmit={ deleteMovement }
        buttons={ {
          isLoading: false,
        } }
      />
    </SectionContainer>
  );
};

MovementHistoryInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  movementsHistoryListState: PropTypes.object.isRequired,
  hasAuthorization: PropTypes.bool.isRequired,
  handleDeleteMovementById: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
};

MovementHistoryInfo.defaultProps = {
  collaborator: null,
};

export default MovementHistoryInfo;
