import PropTypes from "prop-types";
import { isEmpty } from "common/helpers";
import Typography from "@mui/material/Typography";
import DefaultBreadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

const Breadcrumbs = ({ data }) => (
  <div data-testid={ "breadcrumbs" }>
    {data.length > 0 && (
      <DefaultBreadcrumbs aria-label={ "breadcrumb" }>
        {data.map((link) => (isEmpty(link.path) ? (
          <Typography color={ "textPrimary" } key={ link.label }>
            {link.label}
          </Typography>
        ) : (
          <Link color={ "inherit" } href={ link.path } key={ link.label } underline={ "hover" }>
            {link.label}
          </Link>
        )))}
      </DefaultBreadcrumbs>
    )}
  </div>
);

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ),
};

Breadcrumbs.defaultProps = {
  data: [],
};

export default Breadcrumbs;
