import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components_refactor/NoDataMessage";
import Accordion from "components/Accordion";
import { BORDER_ACCORDION_TYPE, VARIANT } from "common/constants";
import { isEmpty } from "common/helpers";
import { accordionData } from "../../../../functions/performance";
import { StyledAccordionContainer, StyledTypography } from "./styles";

const Answers = (props) => {
  const { values, scale, handleModal } = props;
  const { t } = useTranslation("performance");

  return (
    <div data-testid={ "good-leader-answers" }>
      <Grid container>
        <Grid item xs={ 12 } md={ 7 }>
          <StyledTypography variant={ VARIANT.bodyOne }>
            {t("leaderDescriptionQuestion")}
          </StyledTypography>
        </Grid>
      </Grid>
      {isEmpty(values) ? (
        <NoDataMessage />
      ) : (
        <StyledAccordionContainer>
          <Accordion
            type={ BORDER_ACCORDION_TYPE.rounded }
            data={ accordionData(t, values, scale, handleModal) }
            isDefaultIconStyle
            iconLeft
          />
        </StyledAccordionContainer>
      )}
    </div>
  );
};

Answers.propTypes = {
  handleModal: PropTypes.func.isRequired,
  values: PropTypes.array,
  scale: PropTypes.array,
};

Answers.defaultProps = {
  values: [],
  scale: [],
};

export default Answers;
