import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import isString from "lodash/isString";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AutoCompleteCreable from "components/AutocompleteCreable";
import SimpleTableCheckbox from "components/SimpleTableCheckbox";
import ManagerSelect from "components/commons/ManagerSelect";
import Button from "components/Button";
import { isEmpty, isEqual, isNull } from "common/helpers";
import {
  BUTTON_STYLE_TYPES, TYPES, OBJECT_KEYS, ROLES,
} from "common/constants";
import {
  getRelocatedCollaborators,
  removeFromRelocated,
  getAvailableCollaborators,
  isButtonDisabled,
  getManagerSelected,
  getAdditionalInfoRow,
} from "./functions";
import { useStyles } from "./styles";

const RelocateListForm = (props) => {
  const {
    employeeId,
    mainBoxTitle,
    findBy,
    options,
    headerMainTable,
    headerTable,
    dataTable,
    handleData,
    labels,
    setCollaboratorsToRelocate,
    additionalInfo,
    hasEmployees,
  } = props;
  const [values, setValues] = useState({
    [findBy]: null,
  });
  const [itemsSelected, setItemsSelected] = useState([]);
  const [itemsUnselected, setItemsUnselected] = useState([]);
  const [mainData, setMainData] = useState(dataTable); // NOTE: collaborators to relocate
  const [dataRelocated, setDataRelocated] = useState([]); //  NOTE: collaborators relocated
  const [relocatedCollaborators, setRelocatedColaborators] = useState([]);
  const [isUnselectAll, setIsUnselectAll] = useState(false);
  const [inputOptions, setInputOptions] = useState([]);

  const { t } = useTranslation("common");
  const { control } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();
  const employeeKey = hasEmployees ? OBJECT_KEYS.employeesIds : OBJECT_KEYS.collaboratorsIds;
  const isForManager = isEqual(findBy?.main, ROLES.MANAGER);

  useEffect(() => {
    setInputOptions(
      getAvailableCollaborators(
        isEmpty(inputOptions) ? options : inputOptions,
        mainData,
        true,
        employeeId,
      ),
    );
  }, [inputOptions, options, mainData, employeeId]);

  useEffect(() => {
    if (additionalInfo) {
      getAdditionalInfoRow(dispatch, additionalInfo, dataRelocated, employeeKey);
    }
  }, [additionalInfo, dataRelocated, dispatch, employeeKey]);

  const onAutocompleteChange = () => (prop, event, newValue) => {
    setValues({
      ...values,
      [prop]:
        isNull(newValue) || isString(newValue)
          ? newValue
          : newValue[findBy?.key],
    });
  };

  const handleManagerChange = (data) => {
    setValues({
      ...values,
      [findBy?.main]: data,
    });
  };

  const handleUnselectedAll = (isUnselected) => {
    setIsUnselectAll(isUnselected);
  };

  const handleItemsSelected = (items) => {
    setIsUnselectAll(false);
    setItemsSelected(items);
  };

  const handleItemsUnselected = (items) => {
    setIsUnselectAll(false);
    setItemsUnselected(items);
  };

  const handleClickMove = () => {
    setIsUnselectAll(true);
    const temporalObject = {
      id: isForManager ? values[findBy?.main]?.id : values[findBy?.main],
      [employeeKey]: itemsSelected,
    };
    const auxRelocated = relocatedCollaborators;
    auxRelocated.push(temporalObject);
    setRelocatedColaborators(auxRelocated);
    handleData(auxRelocated);
    setDataRelocated([
      ...dataRelocated,
      ...getRelocatedCollaborators(
        mainData,
        itemsSelected,
        isForManager
          ? values[findBy?.main]?.full_name
          : getManagerSelected(inputOptions, values[findBy?.main]).full_name,
      ),
    ]);

    const updatedRemovedData = getAvailableCollaborators(
      mainData,
      itemsSelected,
      false,
      employeeId,
    );

    setMainData(updatedRemovedData);
    setCollaboratorsToRelocate(updatedRemovedData);
  };

  const handleClickRemove = () => {
    setIsUnselectAll(true);
    const relocated = removeFromRelocated(
      relocatedCollaborators,
      itemsUnselected,
      employeeKey,
    );
    setRelocatedColaborators(relocated);

    handleData(relocated);

    const updatedMainData = [
      ...mainData,
      ...getRelocatedCollaborators(dataRelocated, itemsUnselected),
    ];
    setMainData(updatedMainData);

    setDataRelocated(
      getAvailableCollaborators(
        dataRelocated,
        itemsUnselected,
        false,
        employeeId,
      ),
    );
    setCollaboratorsToRelocate(updatedMainData);
  };

  return (
    <Grid
      container
      className={ classes.container }
      data-testid={ "relocate-list-form" }
    >
      <Grid item xs={ 12 } sm={ 6 } className={ classes.itemGrid }>
        <Typography className={ classes.tableTitle }>
          { `${mainBoxTitle} ${t("common:common.to_relocate")}` }
        </Typography>
        <Box>
          <SimpleTableCheckbox
            header={ headerMainTable }
            data={ mainData }
            handleItemsSelected={ handleItemsSelected }
            handleUnselectedAll={ handleUnselectedAll }
            isUnselectAll={ isUnselectAll }
            hasToolbar
            isMainTable
          />
        </Box>
      </Grid>
      <Grid item xs={ 12 } sm={ 6 } className={ classes.itemGrid }>
        <Typography className={ classes.tableTitle }>
          {`${mainBoxTitle} ${t("common:common.relocated")}`}
        </Typography>
        <Box>
          <SimpleTableCheckbox
            header={ headerTable }
            data={ dataRelocated }
            handleItemsSelected={ handleItemsUnselected }
            handleUnselectedAll={ handleUnselectedAll }
            isUnselectAll={ isUnselectAll }
            handleClickRemove={ handleClickRemove }
            hasToolbar
          />
        </Box>
      </Grid>
      <Grid item xs={ 8 } sm={ 4 } md={ 5 } className={ classes.itemGrid }>
        { isForManager ? (
          <ManagerSelect
            name={ findBy?.main }
            control={ control }
            value={ values[findBy?.main] }
            onChange={ handleManagerChange }
            employee={ { id: employeeId } }
          />
        ) : (
          <AutoCompleteCreable
            id={ findBy?.main }
            options={ inputOptions }
            label={ labels?.input }
            value={ values[findBy?.main] }
            onChange={ onAutocompleteChange() }
            name={ findBy?.main }
            nameOfAttr={ findBy?.label }
          />
        )}
      </Grid>
      <Grid item xs={ 4 } sm={ 2 } md={ 1 } className={ classes.itemGrid }>
        <Button
          variant={ BUTTON_STYLE_TYPES.OUTLINED }
          type={ TYPES.text }
          cs={ classes.button }
          disabled={ isButtonDisabled(itemsSelected, values, findBy) }
          onClick={ handleClickMove }
        >
          {labels?.button}
        </Button>
      </Grid>
    </Grid>
  );
};

RelocateListForm.propTypes = {
  employeeId: PropTypes.string,
  mainBoxTitle: PropTypes.string,
  findBy: PropTypes.object,
  options: PropTypes.array,
  headerMainTable: PropTypes.array,
  headerTable: PropTypes.array,
  dataTable: PropTypes.array,
  handleData: PropTypes.func,
  labels: PropTypes.object,
  setCollaboratorsToRelocate: PropTypes.func,
  additionalInfo: PropTypes.array,
  hasEmployees: PropTypes.bool,
};

export default RelocateListForm;
