import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import Grid from "@mui/material/Grid";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Button from "components/Button";
import SectionContainer from "components_refactor/SectionContainer";
import { formatDate, historyPush } from "common/utils";
import {
  BUTTON_STYLE_TYPES, COLORS_NAME, DEFAULT_MIN_DECIMAL, INPUT_TYPE, OBJECT_KEYS, VARIANT,
} from "common/constants";
import { ReactComponent as DollarIcon } from "assets/images/icons/dollar.svg";
import { ReactComponent as ClockIcon } from "assets/images/icons/clock.svg";
import { StyledNoData } from "views_refactor/Profile/styles";
import SalaryInfoDetail from "./components/SalaryInfoDetail";
import InfoEditButton from "./components/InfoEditButton";
import localRoutes from "../../functions/routes";
import { StyledContent } from "./styles";

const SalaryInfo = (props) => {
  const {
    t, history, collaborator, isAdmin, hasAuthorization, isValidToShowSalary, salaryInfoStates,
  } = props;

  return (
    <SectionContainer>
      <Grid container spacing={ 1 }>
        {isValidToShowSalary && (
          <Grid container>
            <SalaryInfoDetail
              icon={ <DollarIcon /> }
              iconText={ t("profile:salary") }
              detail={ collaborator?.salary ? (
                <NumberFormat
                  value={ collaborator?.salary?.gross_salary }
                  displayType={ INPUT_TYPE.text }
                  thousandSeparator
                  suffix={ ` ${salaryInfoStates.codeCurrency}` }
                  fixedDecimalScale
                  decimalScale={ DEFAULT_MIN_DECIMAL }
                />
              ) : <StyledNoData>{t("profile:noData")}</StyledNoData> }
              hasEditButton
            />
            {isAdmin && hasAuthorization && (
              <Grid item xs={ 2 }>
                <InfoEditButton { ...props } />
              </Grid>
            )}
          </Grid>
        )}
        <SalaryInfoDetail
          icon={ <ClockIcon /> }
          iconText={ t("profile:startingDate") }
          detail={ collaborator?.starting_date
            ? formatDate(collaborator?.starting_date)
            : <StyledNoData>{t("profile:noData")}</StyledNoData> }
        />
        <SalaryInfoDetail
          icon={ <FileCopyIcon /> }
          iconText={ t("profile:contractType") }
          detail={ collaborator?.job_position?.type_of_contract?.name
            || <StyledNoData>{t("profile:noData")}</StyledNoData> }
        />
        {isAdmin && hasAuthorization && (
          <Grid item xs={ 12 }>
            <StyledContent>
              <Button
                onClick={ () => historyPush(
                  history,
                  localRoutes.addMovement,
                  history?.location?.search,
                  { type: OBJECT_KEYS.salaryRaise },
                ) }
                variant={ VARIANT.contained }
                color={ COLORS_NAME.primary }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              >
                {t("account:movementsHistory.SalaryRaise")}
              </Button>
            </StyledContent>
          </Grid>
        )}
      </Grid>
    </SectionContainer>
  );
};

SalaryInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  collaborator: PropTypes.object,
  salaryInfoStates: PropTypes.object.isRequired,
  hasAuthorization: PropTypes.bool.isRequired,
  isValidToShowSalary: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

SalaryInfo.defaultProps = {
  collaborator: null,
  isAdmin: false,
  isValidToShowSalary: false,
};

export default SalaryInfo;
