import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components_refactor/NoDataMessage";
import { CARD_TYPE, PERSONALITY_EVALUATION, VARIANT } from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import EncouragementImage from "assets/images/potential/encouragement.svg";
import StressImage from "assets/images/potential/stress.svg";
import {
  StyledCardIcon,
  StyledCardTitle,
  StyledEvaluationCard,
  StyledCardText,
} from "../../styles";

const EvaluationCard = (props) => {
  const {
    t, info, type, archetype, index,
  } = props;

  const getBadgeTitle = () => (
    <StyledCardIcon>
      <img
        src={ isEqual(index, PERSONALITY_EVALUATION.energizes)
          ? EncouragementImage
          : StressImage }
        alt={ isEqual(index, PERSONALITY_EVALUATION.energizes)
          ? t("potential:chart.encouragement")
          : t("potential:chart.stress") }
        className={ isEqual(index, PERSONALITY_EVALUATION.energizes) ? "cardImage" : "" }
      />
      <StyledCardTitle variant={ VARIANT.h5 }>
        {isEqual(index, PERSONALITY_EVALUATION.energizes)
          ? t("potential:chart.encouragement")
          : t("potential:chart.stress")}
      </StyledCardTitle>
    </StyledCardIcon>
  );

  return (
    <StyledEvaluationCard
      container
      spacing={ 3 }
    >
      <Grid item xs={ 12 }>
        <div
          className={ isEqual(type, CARD_TYPE.evaluation) ? "cardTitleBordered" : "" }
        >
          {isEqual(type, CARD_TYPE.evaluation) ? (
            <StyledCardTitle variant={ VARIANT.h5 }>
              {`${t("potential:chart.archetype")}: ${archetype}`}
            </StyledCardTitle>
          ) : (
            getBadgeTitle()
          )}
        </div>
        {isEmpty(info) ? (
          <NoDataMessage customStyles={ "noDataCustom" } />
        ) : (
          <StyledCardText>{info}</StyledCardText>
        )}
      </Grid>
    </StyledEvaluationCard>
  );
};

EvaluationCard.propTypes = {
  t: PropTypes.func.isRequired,
  info: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  archetype: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default EvaluationCard;
