import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RelocateListForm from "components/RelocateListForm";
import Button from "components/Button";
import {
  MESSAGE_TYPES,
  toast,
} from "components/Toast/functions";
import { BUTTON_STYLE_TYPES, VARIANT } from "common/constants";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import {
  MAIN_RELOCATE_EMPLOYEE,
  RELOCATE_EMPLOYEE,
  findObjectBy,
  getRelocateAditionalInformation,
} from "../../functions/units";
import { useStyles } from "./styles";

const RelocateEmployee = (props) => {
  const {
    id,
    module,
    relocateKey,
    title,
    subtitle,
    collaborators,
    options,
    action,
    modalHandler,
  } = props;
  const { t } = useTranslation(["common", "collaborators", "administrator"]);
  const classes = useStyles();
  const [toRelocate, setToRelocate] = useState();
  const [disabled, setIsDisabled] = useState(true);

  const handleData = (data) => {
    setToRelocate(data);
  };

  const setCollaboratorsToRelocate = (data) => {
    setIsDisabled(!isEmpty(data));
  };

  const handleAction = () => {
    action(toRelocate);
    toast(MESSAGE_TYPES.info, { message: t(`administrator:modules.${module}.alert_message`) });
  };

  const closeModal = () => {
    modalHandler(false);
  };

  return (
    <StyledScrollBar maxHeight={ "600px" }>
      <Grid container data-testid={ "relocate-employee-org-unit-form" }>
        <Grid item xs={ 12 } className={ classes.itemGrid }>
          <Typography className={ classes.subtitleText }>{title}</Typography>
          <Typography variant={ VARIANT.subtitleTwo }>{subtitle}</Typography>
        </Grid>
        <Grid item xs={ 12 }>
          <RelocateListForm
            employeeId={ id }
            mainBoxTitle={ t("collaborators:title") }
            findBy={ findObjectBy(relocateKey) }
            options={ options || [] }
            headerMainTable={ MAIN_RELOCATE_EMPLOYEE(t, classes) }
            headerTable={ RELOCATE_EMPLOYEE(t, classes, relocateKey) }
            dataTable={ collaborators }
            handleData={ handleData }
            labels={ {
              button: t("common:common.move"),
              input: t(`administrator:modules.${module}.singular_title`),
            } }
            setCollaboratorsToRelocate={ setCollaboratorsToRelocate }
            additionalInfo={ getRelocateAditionalInformation(toRelocate, relocateKey) }
            hasEmployees
          />
        </Grid>
        <Grid item xs={ 12 }>
          <div className={ classes.actionButton }>
            <Button
              onClick={ closeModal }
              variant={ BUTTON_STYLE_TYPES.CONTAINED }
              typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
              cs={ classes.cancel }
            >
              {t("collaborators:buttons.cancel")}
            </Button>
            <Button
              variant={ BUTTON_STYLE_TYPES.CONTAINED }
              autoFocus
              type={ BUTTON_STYLE_TYPES.SUBMIT }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
              onClick={ handleAction }
              disabled={ disabled }
            >
              {t("common:common.relocateDelete")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </StyledScrollBar>
  );
};

RelocateEmployee.propTypes = {
  id: PropTypes.string,
  module: PropTypes.string,
  relocateKey: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  collaborators: PropTypes.array,
  options: PropTypes.array,
  action: PropTypes.func,
  modalHandler: PropTypes.func,
};

export default RelocateEmployee;
