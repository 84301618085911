import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "theme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Modal from "components/Modal";
import palette from "theme/palette";
import Typography from "@mui/material/Typography";

export const StyledGridContainer = styled(Grid)`
  margin-bottom: ${theme.spacing(2)}px;
  align-items: center;
  display: flex;
`;

export const StyledClassification = styled(Box)`
  display: flex;
  align-Items: center;
  max-width: 1000px;
  width: 80vw;
`;

export const StyledGridContentContainer = styled(Grid)`
  margin-top: 12px;
`;

export const StyledGridChartContainer = styled(StyledGridContentContainer)`
  background-color: ${palette.background.lightGrey};
`;

export const StyledGridProfileContainer = styled(StyledGridContentContainer)`
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 10px;
`;

export const StyledGridFilter = styled(Grid)`
  margin-top: 12px;
`;

export const StyledResult = styled.div`
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  h4 {
    font-weight: 700;
    font-size: 16px;
    color: ${palette.text.grayDark};
    padding-bottom: 10px;
  }
`;

export const StyledBoxResult = styled(StyledResult)`
  background-color: ${(props) => (props.active ? "#F3F6FF" : palette.background.lightGrey)};
`;

export const StyledGridResult = styled(Grid)`
  padding: 0;
  background-color: ${palette.background.lightGrey};
`;

export const StyledModal = styled(Modal)`
  padding: 0px !important;
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

export const StyledLabelTypography = styled(Typography)`
  margin-left: 10px;
  font-weight: 500;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  background: #C4C4C4;
  border-radius: 100px;
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  font-weight: 500;
  border: 1px solid;
  border-color: ${(props) => (props.active ? props.theme.palette.primaryApp : "transparent")} !important;
  color: ${(props) => (props.active ? props.theme.palette.primaryApp : "#4F4F4F")};
  background-color: ${(props) => (props.active ? props.theme.palette.background.paper : "transparent")};
  text-transform: none;
  padding: 8px 10px;
  border-radius: 100px !important;
  transition: padding 0.3s ease, margin 0.3s ease;
  width: 145px;
  height: 36px;
  &:hover {
    border-color: ${(props) => (props.active ? props.theme.palette.primaryApp : "transparent")};
    background-color: ${(props) => (props.active ? props.theme.palette.white : "transparent")};
  }
`;

export const StyledLinkContainer = styled.div`
  margin-left: auto;
  @media (max-width: 600px) {
    margin: auto;
    width: 100%;
  }
`;

export const StyledActionButton = styled.button`
  width: 100%;
  padding: 8px 16px;
  border: 1px solid blue;
  background: transparent;
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  transition: background 0.3s ease;

  &:hover {
    background: ${palette.background.lightGray};
  }

  @media (max-width: 600px) {
    width: 100%;
    text-align: center;
    padding: 12px;
  }
`;

export const StyledFullWidthLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;
