import {
  useState, useEffect, useCallback, createRef,
} from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import LeadershipMatrix from "components_refactor/LeadershipMatrix";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { getLeadershipTypeResults } from "redux/actions/leadershipTypeResults/leadershipTypeResultsActions";
import { getLeadershipTypes } from "redux/actions/leadershipTypes/leadershipTypesActions";
import { StyledTitle } from "styledComponents/Tabs";
import { TRANSITIONS } from "common/constants/ninebox";
import { PAGINATION } from "common/constants";
import { isNotValid, isNullOrUndefined } from "common/helpers";
import LeadershipResult from "../LeadershipResult";
import EmployeeGroup from "../EmployeeGroup";
import LeadershipTable from "../LeadershipTable";
import {
  getLeadershipTypeResult,
  getCircle,
  getCircleImage,
  getTooltipName,
  getPatternImageByProcess,
} from "../../functions";
import { StyledCard, StyledTable, StyledGlobalActions } from "./styles";

const Matrix = (props) => {
  const {
    t,
    leadershipStates,
    getQuadrantDetail,
    resetQuadrants,
    setLeadershipStates,
    handleCircleSelected,
    handleEmployeeList,
    handleCircleAndReset,
  } = props;

  const {
    leadershipResult,
    selectedQuadrant,
    query,
    anchorEl,
    selectedPanel,
  } = leadershipStates;

  const [currentPage, setCurrentPage] = useState();
  const [concatenatedLeadershipTypeResults, setConcatenatedLeadershipTypeResults] = useState([]);
  const [maxPage, setMaxPage] = useState(null);

  // TODO: Check the filter component because the current one generates
  // different queries when nothing is selected.
  let cleanedQuery = {};
  if (query) {
    cleanedQuery = {
      q: Object.fromEntries(
        Object.entries(query.q).filter(([, value]) => value !== null),
      ),
    };
  }

  const {
    data: { data: leadershipTypeResults, total } = {},
    isLoading: isLoadingCurrentLeadershipTypeResults,
    error,
    refetch, // This function is used to manually trigger the execution of a query.
  } = useQuery(["leadershipTypeResults", cleanedQuery, currentPage], () => getLeadershipTypeResults(cleanedQuery, currentPage), {
    staleTime: Infinity,
    enabled: false, // property is used to determine if the query should be executed or not
    retry: false,
  });

  const {
    data: leadershipTypes,
  } = useQuery("leadershipTypes", () => getLeadershipTypes(), {
    staleTime: Infinity,
  });

  const setResults = useCallback(async () => {
    resetQuadrants();
    // if it is the first load set empty
    if (currentPage === 1) {
      setLeadershipStates({ leadershipResult: [] });
    }
    if (!isNotValid(concatenatedLeadershipTypeResults)) {
      const results = await getLeadershipTypeResult(concatenatedLeadershipTypeResults);
      setTimeout(() => {
        setLeadershipStates({ leadershipResult: results });
      }, 200);
    }
  }, [
    concatenatedLeadershipTypeResults,
    resetQuadrants,
    setLeadershipStates,
    currentPage,
  ]);

  useEffect(() => {
    if (!isLoadingCurrentLeadershipTypeResults) {
      setResults();
    }
    // eslint-disable-next-line
  }, [
    leadershipTypeResults,
    isLoadingCurrentLeadershipTypeResults,
  ]);

  // Pagination
  const handleRefetch = () => {
    refetch();
    setTimeout(() => {
      setCurrentPage((prevPage) => prevPage + 1);
    }, 2000);
  };

  useEffect(() => {
    if (currentPage !== null && (currentPage === 1 || currentPage <= maxPage)) {
      handleRefetch();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setConcatenatedLeadershipTypeResults([]);
    setMaxPage(null);
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (!isLoadingCurrentLeadershipTypeResults && leadershipTypeResults) {
      const actualMaxPage = Math.ceil(total / PAGINATION.maxResultPerPage);
      if (maxPage === null && total) {
        setMaxPage(actualMaxPage);
      }
      setConcatenatedLeadershipTypeResults((prevScores) => {
        const existingEmployeeIds = new Set(prevScores.map((result) => result.employee.id));
        const filteredResults = leadershipTypeResults.filter((result) => !existingEmployeeIds.has(result.employee.id));
        return [...prevScores, ...filteredResults];
      });
      if (maxPage && (currentPage === maxPage || actualMaxPage !== maxPage)) {
        setCurrentPage(maxPage + 1);
      }
    }
    // eslint-disable-next-line
  }, [isLoadingCurrentLeadershipTypeResults, leadershipTypeResults]);

  useEffect(() => {
    if (error && error?.response?.status) {
      toast(MESSAGE_TYPES.error, {
        message: `${error.response.status} - ${t("common:common.api_responses.error.title")}`,
      });
    }
    // eslint-disable-next-line
  }, [error]);

  const circleRef = createRef();

  return (
    <>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 } md={ 8 }>
          <StyledCard>
            <LeadershipMatrix
              getQuadrantDetail={ getQuadrantDetail }
              quadrant={ selectedQuadrant }
            >
              {/* circle patterns */}
              {(!isLoadingCurrentLeadershipTypeResults || leadershipResult)
                && getPatternImageByProcess(leadershipResult)}
              {/* circle - matrix data */}
              {/* TODO: create circle component */}
              {(!isLoadingCurrentLeadershipTypeResults || leadershipResult) && leadershipTypes && getCircle(
                leadershipResult,
                handleEmployeeList,
                handleCircleSelected,
                circleRef,
                0, // current results
                leadershipTypes,
              )}
              {/* actions on matrix  */}
              {!isNullOrUndefined(selectedPanel) && (
                <StyledGlobalActions
                  onClick={ () => handleCircleSelected(selectedPanel) }
                >
                  {getCircleImage(
                    null,
                    1,
                    selectedPanel.x,
                    selectedPanel.y,
                    leadershipTypes,
                    `url(#person${selectedPanel.index})`,
                    circleRef,
                    true,
                  )}
                  {selectedPanel.employee.full_name
                  && getTooltipName(selectedPanel.employee.full_name, circleRef)}
                </StyledGlobalActions>
              )}
            </LeadershipMatrix>
          </StyledCard>
          <Popper
            id={ anchorEl && TRANSITIONS.popper }
            open={ anchorEl !== null }
            anchorEl={ anchorEl }
            transition
          >
            <Paper elevation={ 3 }>
              {/* employees with the same position */}
              <EmployeeGroup
                handleEmployeeList={ handleEmployeeList }
                handleCircleAndReset={ handleCircleAndReset }
                { ...props }
              />
            </Paper>
          </Popper>
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          {/* results by quadrant */}
          <StyledCard>
            <LeadershipResult { ...props } />
          </StyledCard>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={ 12 }>
          <StyledTable>
            <StyledTitle>
              {t("performance:managers")}
            </StyledTitle>
            <LeadershipTable { ...props } />
          </StyledTable>
        </Grid>
      </Grid>
    </>
  );
};

Matrix.propTypes = {
  t: PropTypes.func.isRequired,
  leadershipStates: PropTypes.object.isRequired,
  getQuadrantDetail: PropTypes.func.isRequired,
  handleSelectedQuadrant: PropTypes.func.isRequired,
  resetQuadrants: PropTypes.func.isRequired,
  setLeadershipStates: PropTypes.func.isRequired,
  handleCircleSelected: PropTypes.func.isRequired,
  handleEmployeeList: PropTypes.func.isRequired,
  handleCircleAndReset: PropTypes.func.isRequired,
};

export default Matrix;
