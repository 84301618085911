import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import {
  BUTTON_STYLE_TYPES, SIZE, KEY_PRESS, ADORMENT_POSITION,
} from "common/constants";
import { isUndefined } from "common/helpers";
import FormHelperText from "components_refactor/FormHelperText";
import {
  CustomAutocomplete, useStyles, StyledChip,
  StyledSearchText, StyledLoad, StyledCheckbox,
  CustomPopper,
} from "./styles";

const InputTag = (props) => {
  const {
    id,
    label,
    placeholder,
    size,
    itemsSelected,
    onChange,
    data,
    disabled,
    hasCheckbox,
    textAddDinamicTag,
    onAddDinamicTag,
    onInputTextChange = () => {},
    name,
    register,
    isRequired,
    cs,
    groupBy,
    limitTags,
    defaultValues,
    withoutTags,
    onAddButtonField,
    onClickTag,
    hasKeyValue,
    searchable,
    searchableText,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation("common");

  const icon = <CheckBoxOutlineBlankIcon fontSize={ SIZE.small } />;
  const checkedIcon = <CheckBoxIcon fontSize={ SIZE.small } />;

  const [value, setValue] = useState(itemsSelected || defaultValues);
  const [inputValue, setInputValue] = useState("");
  const [tagSelected, setTagSelected] = useState("");

  const handleChange = (items) => {
    onChange(items);
    if (!withoutTags) {
      setValue(items);
      setTagSelected("");
    }
  };

  const handleInputTextChange = (text) => {
    if (hasKeyValue) {
      onInputTextChange(text, id);
    } else {
      onInputTextChange(text);
    }
    setInputValue(text);
  };

  useEffect(() => {
    setValue(itemsSelected || defaultValues);
  }, [itemsSelected, defaultValues]);

  const handleDinamicTag = () => {
    onAddDinamicTag();
  };

  const addButton = ({ children, ...other }) => (
    <Paper { ...other }>
      {searchable && data && !isEmpty(data) && <StyledSearchText>{ searchableText }</StyledSearchText>}
      {isUndefined(data) && (
        <StyledLoad>
          <CircularProgress size={ 40 } />
        </StyledLoad>
      )}
      {children}
      {!isEmpty(inputValue) && onAddDinamicTag && (
        <span role={ "button" } tabIndex={ "0" } onMouseDown={ handleDinamicTag } className={ classes.addNew }>
          {textAddDinamicTag}
        </span>
      )}
      {data && isEmpty(data) && (
        <p className={ classes.empty }>
          {" "}
          {t("common.no_records")}
        </p>
      )}
    </Paper>
  );

  const handleOnKeyUp = () => {
    setInputValue("");
    onAddDinamicTag();
  };

  const getInputEnterValidation = (e) => (e.key === KEY_PRESS.enter && !isEmpty(inputValue) && onAddDinamicTag
    ? handleOnKeyUp()
    : {});

  const getOptions = () => {
    if (groupBy?.filterBy === groupBy?.compareWith) {
      return data;
    }
    const sortedData = data?.sort((a, b) => {
      const aLabel = a.label || "";
      const bLabel = b.label || "";
      return -1 * bLabel.localeCompare(aLabel);
    });
    return sortedData || [];
  };

  const getGroupBy = () => ((groupBy?.filterBy === groupBy?.compareWith) ? (option) => option?.country : null);

  const clickTag = (option) => {
    if (!isUndefined(onClickTag)) {
      setTagSelected(option.value);
      onClickTag(option);
    }
  };

  return (
    <CustomAutocomplete
      data-testid={ "input-tags" }
      limitTags={ limitTags }
      multiple
      id={ id }
      options={ data ? getOptions() : [] }
      value={ value }
      onChange={ (e, newval) => {
        handleChange(newval);
      } }
      inputValue={ inputValue }
      onInputChange={ (event, newInputValue) => {
        handleInputTextChange(newInputValue);
      } }
      disableCloseOnSelect
      size={ size }
      noOptionsText={ "" }
      className={ cs }
      disabled={ disabled }
      getOptionLabel={ (option) => option.label }
      isOptionEqualToValue={ (option, value) => option.label === value.label }
      groupBy={ getGroupBy() }
      renderOption={ (localProps, option, { selected }) => (hasCheckbox ? (
        <li {...localProps} style={ { padding: "0 10px" } }>
          <StyledCheckbox
            icon={ icon }
            checkedIcon={ checkedIcon }
            checked={ selected }
          />
          {option.label}
        </li>
      ) : (
        <li {...localProps}>{option.label}</li>
      )) }
      renderInput={ (params) => (
        <>
          <TextField
            { ...params }
            variant={ BUTTON_STYLE_TYPES.OUTLINED }
            label={ label }
            placeholder={ placeholder }
            inputRef={ register }
            name={ name }
            onKeyUp={ (e) => getInputEnterValidation(e) }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {onAddButtonField && !disabled && (
                    <InputAdornment position={ ADORMENT_POSITION.end }>
                      <IconButton
                        onClick={ () => onAddButtonField() }
                        edge={ ADORMENT_POSITION.end }
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            } }
          />
          {!disabled && isRequired && <FormHelperText />}
        </>
      ) }
      PaperComponent={ addButton }
      PopperComponent={ CustomPopper }
      renderTags={ (values, getTagProps) => values?.map((option, index) => (
        <StyledChip
          { ...getTagProps({ index }) }
          key={ option.label }
          label={ option.label }
          clickable={ !isUndefined(onClickTag) }
          active={ option.value === tagSelected ? "true" : "false" }
          onClick={ () => clickTag(option) }
        />
      )) }
    />
  );
};

InputTag.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  itemsSelected: PropTypes.array,
  onChange: PropTypes.func,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  hasCheckbox: PropTypes.bool,
  isRequired: PropTypes.bool,
  cs: PropTypes.string,
  limitTags: PropTypes.number,
  defaultValues: PropTypes.array,
  withoutTags: PropTypes.bool,
  onAddButtonField: PropTypes.func,
  onClickTag: PropTypes.func,
  hasKeyValue: PropTypes.bool,
  searchable: PropTypes.bool,
  searchableText: PropTypes.string,
  onInputTextChange: PropTypes.func,
  onAddDinamicTag: PropTypes.func,
  textAddDinamicTag: PropTypes.string,
  groupBy: PropTypes.object || PropTypes.bool || PropTypes.array || PropTypes.string,
};

export default InputTag;
