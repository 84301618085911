import PropTypes from "prop-types";

const CustomStepIcon = ({ active, completed, icon }) => (
  <div
    style={ {
      width: 36,
      height: 36,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: active || completed ? "#2930FF" : "#E5E7EB",
      color: active || completed ? "#FFF" : "#6B7280",
      fontWeight: "bold",
      fontSize: "16px",
    } }
  >
    {icon}
  </div>
);

CustomStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};

export default CustomStepIcon;
