import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import DownloadExcelButton from "components/DownloadExcelButton";
import { SessionContext } from "modules/session/context";
import {
  BUTTON_STYLE_TYPES, LOCAL_STORAGE_NAMES, ROLES, COUNTRIES,
} from "common/constants";
import {
  getItemFromLocalStorage,
  mainDownloadExcel,
  isValidRole,
  getEmployeeCompanyId,
  isColombianAdmin,
} from "common/utils";
import { getList as getDynamicAttribute } from "redux/actions/common/dynamicAttributeActions";
import { getCandidatesToDownload } from "redux/actions/candidateActions";
import OptionsModal from "../OptionsModal";
import { getDataExcelCandidates } from "./functions";
import { StyledButtons } from "./styles";

const CandidatesToolbar = () => {
  const { t } = useTranslation("candidates");
  const dispatch = useDispatch();
  const {
    state: { user },
  } = useContext(SessionContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const {
    list: dynamicAttributesList,
    isLoadingList: isLoadingDynamicAttributes,
  } = useSelector(({ dynamicAttributeReducer }) => dynamicAttributeReducer);

  const {
    downloadList,
    isLoadingDownloadList,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const isTalentManager = isValidRole(user?.userCookies, ROLES.TALENT_MANAGER);
  const colombianAdmin = isColombianAdmin(user?.userCookies);
  const companyId = getEmployeeCompanyId(user);

  const downloadCandidates = () => {
    const country = colombianAdmin ? COUNTRIES.colombia : "";
    const currencies = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies);

    const dataToExcel = getDataExcelCandidates(
      downloadList,
      dynamicAttributesList,
      companyId,
      currencies,
      t,
      country,
      isTalentManager,
    );

    mainDownloadExcel(dataToExcel, t("title"));
  };

  useEffect(() => {
    dispatch(getDynamicAttribute());
    dispatch(getCandidatesToDownload());
  }, [dispatch]);

  return (
    <div data-testid={ "candidates-toolbar" }>
      <StyledButtons>
        <DownloadExcelButton
          disabled={ isLoadingDownloadList || isLoadingDynamicAttributes }
          onClick={ () => downloadCandidates() }
        />
        <Button
          onClick={ handleOpenClose }
          typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
        >
          { t("createCandidate") }
        </Button>
      </StyledButtons>
      <OptionsModal isOpen={ isOpen } onClose={ handleOpenClose } />
    </div>
  );
};

export default CandidatesToolbar;
