import qs from "qs";
import {
  LOCAL_STORAGE_NAMES,
  OBJECT_KEYS,
  PAGINATION,
  PARAMS_SERIALIZER_OPTIONS,
  SURVEY_PROCESS_TYPE,
} from "common/constants";
import { cleanQuery, getItemFromLocalStorage, getUserId } from "common/utils";
import { isNull } from "common/helpers";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,

  RESET_STATE_ENGAGEMENT_EVOLUTION,
  GET_LIST_ENGAGEMENT_EVOLUTION,
  GET_LIST_ENGAGEMENT_EVOLUTION_LOADING,
  GET_LIST_ENGAGEMENT_EVOLUTION_ERROR,

  RESET_STATE_ENGAGEMENT_COMMENTS,
  GET_LIST_ENGAGEMENT_COMMENTS,
  GET_LIST_ENGAGEMENT_COMMENTS_LOADING,
  GET_LIST_ENGAGEMENT_COMMENTS_ERROR,

  RESET_STATE_ENGAGEMENT_NPS,
  GET_LIST_ENGAGEMENT_NPS,
  GET_LIST_ENGAGEMENT_NPS_LOADING,
  GET_LIST_ENGAGEMENT_NPS_ERROR,

  RESET_STATE_ENGAGEMENT_SATISFACTION,
  GET_LIST_ENGAGEMENT_SATISFACTION,
  GET_LIST_ENGAGEMENT_SATISFACTION_LOADING,
  GET_LIST_ENGAGEMENT_SATISFACTION_ERROR,

  RESET_STATE_ENGAGEMENT_OVERALL_STATISTICS,
  GET_LIST_ENGAGEMENT_OVERALL_STATISTICS,
  GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_LOADING,
  GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_ERROR,

  RESET_STATE_ENGAGEMENT_PARTICIPATION,
  GET_LIST_ENGAGEMENT_PARTICIPATION,
  GET_LIST_ENGAGEMENT_PARTICIPATION_LOADING,
  GET_LIST_ENGAGEMENT_PARTICIPATION_ERROR,

  RESET_STATE_ENGAGEMENT_TOPIC,
  GET_LIST_ENGAGEMENT_TOPIC,
  GET_LIST_ENGAGEMENT_TOPIC_LOADING,
  GET_LIST_ENGAGEMENT_TOPIC_ERROR,

  RESET_STATE_COLLABORATORS_BY_PROCESS,
  GET_LIST_COLLABORATORS_BY_PROCESS,
  GET_LIST_COLLABORATORS_BY_PROCESS_LOADING,
  GET_LIST_COLLABORATORS_BY_PROCESS_ERROR,
} from "../actionTypes/engagement";

const URL = {
  main: "/engagement_processes",
  process: "/survey_processes",
  evolution: "evolution",
  comments: "comments_by_department",
  nps: "nps_by_department",
  satisfaction: "satisfaction_by_department",
  satisfactionByTopic: "satisfaction_by_topic",
  overallStatistics: "overall_statistics",
  participation: "participation",
  collaborators: "collaborators",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const getList = (query = {}, light = false) => async (dispatch, getState) => {
  const { list } = getState().engagementProcessReducer;

  if (!list?.length) {
    dispatch({
      type: GET_LIST_LOADING,
    });

    const modifiedQuery = light ? { ...query, light: true } : query;

    try {
      const response = await configAxios.get(URL.main, {
        params: modifiedQuery,
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      dispatch({
        type: GET_LIST,
        payload: response.data.engagement_processes,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

// evolution
export const resetStateEngagementEvolution = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_EVOLUTION,
  });
};

export const getEngagementEvolution = (query = null) => async (dispatch, getState) => {
  const { engagementEvolution } = getState().engagementProcessReducer;
  const lastQuery = query && { q: cleanQuery(query.q) };

  if (!engagementEvolution || query) {
    dispatch({
      type: GET_LIST_ENGAGEMENT_EVOLUTION_LOADING,
    });
    try {
      const response = await configAxios.get(`${URL.main}/${URL.evolution}`, {
        params: {
          ...lastQuery,
        },
        paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
      });
      let data = response.data.evolution;
      data = data.filter((evolution) => !isNull(evolution));
      data.forEach((item, index) => {
        item.disabled = false;
        item.index = index;
      });

      dispatch({
        type: GET_LIST_ENGAGEMENT_EVOLUTION,
        payload: data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_EVOLUTION_ERROR);
    }
  }
};

// comments by department

export const resetStateEngagementComments = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_COMMENTS,
  });
};

export const getCommentsByDepartment = (id, query = null) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const lastQuery = query && { q: cleanQuery(query.q) };

  dispatch({
    type: GET_LIST_ENGAGEMENT_COMMENTS_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${id}/${URL.comments}`, {
      params: {
        ...lastQuery,
        [OBJECT_KEYS.userId]: userId,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_ENGAGEMENT_COMMENTS,
      payload: response.data.departments,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_COMMENTS_ERROR);
  }
};

// nps by department

export const resetStateEngagementNps = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_NPS,
  });
};

export const getNpsByDepartment = (id) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));

  dispatch({
    type: GET_LIST_ENGAGEMENT_NPS_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${id}/${URL.nps}`, {
      params: {
        [OBJECT_KEYS.userId]: userId,
      },
    });
    dispatch({
      type: GET_LIST_ENGAGEMENT_NPS,
      payload: response.data.departments,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_NPS_ERROR);
  }
};

// satisfaction by department

export const resetStateEngagementSatisfaction = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_SATISFACTION,
  });
};

export const getSatisfactionByDepartment = (id, query = null) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const lastQuery = query && { q: cleanQuery(query.q) };

  dispatch({
    type: GET_LIST_ENGAGEMENT_SATISFACTION_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${id}/${URL.satisfaction}`, {
      params: {
        ...lastQuery,
        [OBJECT_KEYS.userId]: userId,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_ENGAGEMENT_SATISFACTION,
      payload: response.data.departments,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_SATISFACTION_ERROR);
  }
};

export const resetStateOverallStatistics = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_OVERALL_STATISTICS,
  });
};

export const getOverallStatistics = (id, query = null) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const lastQuery = query && { q: cleanQuery(query.q) };

  dispatch({
    type: GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${id}/${URL.overallStatistics}`, {
      params: {
        ...lastQuery,
        [OBJECT_KEYS.userId]: userId,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_ENGAGEMENT_OVERALL_STATISTICS,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_OVERALL_STATISTICS_ERROR);
  }
};

export const resetStateParticipation = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_PARTICIPATION,
  });
};

export const getParticipationByProcess = (id, query = null) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const lastQuery = query && { q: cleanQuery(query.q) };

  dispatch({
    type: GET_LIST_ENGAGEMENT_PARTICIPATION_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.process}/${id}/${URL.participation}`, {
      params: {
        ...lastQuery,
        [OBJECT_KEYS.userId]: userId,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_ENGAGEMENT_PARTICIPATION,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_PARTICIPATION_ERROR);
  }
};

export const resetStateEngagementTopic = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_ENGAGEMENT_TOPIC,
  });
};

export const getSatisfactionByTopic = (id, query = null) => async (dispatch) => {
  const userId = getUserId(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.user));
  const lastQuery = query && { q: cleanQuery(query.q) };

  dispatch({
    type: GET_LIST_ENGAGEMENT_TOPIC_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.main}/${id}/${URL.satisfactionByTopic}`, {
      params: {
        ...lastQuery,
        [OBJECT_KEYS.userId]: userId,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_ENGAGEMENT_TOPIC,
      payload: response.data,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ENGAGEMENT_TOPIC_ERROR);
  }
};

export const getCollaboratorsByProcess = (id, query = null) => async (dispatch) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const pagination = { size: PAGINATION.maxPerPage, number: PAGINATION.next };
  dispatch({
    type: GET_LIST_COLLABORATORS_BY_PROCESS_LOADING,
  });
  try {
    const response = await configAxios.get(`${URL.process}/${id}/${URL.collaborators}`, {
      params: {
        ...lastQuery,
        page: pagination,
      },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    dispatch({
      type: GET_LIST_COLLABORATORS_BY_PROCESS,
      payload: response.data.collaborators,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_COLLABORATORS_BY_PROCESS_ERROR);
  }
};

export const resetStateCollaboratorsByProcess = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_COLLABORATORS_BY_PROCESS,
  });
};

// News
const path = {
  main: "/engagement_processes",
  process: "/survey_processes",
  evolution: "evolution",
  comments: "comments_by_department",
  nps: "nps_by_department",
  satisfaction: "satisfaction_by_department",
  satisfactionByTopic: "satisfaction_by_topic",
  overallStatistics: "overall_statistics",
  participation: "participation",
  collaborators: "collaborators",
  generalSatisfaction: "overall_satisfaction_statistics",
  generalNps: "overall_nps_statistics",
  departments: "departments",
  topics: "topics",
  satisfactionForDepartment: "satisfaction_for_department",
  satisfactionForTopic: "satisfaction_for_topic",
  commentsForDepartment: "comments_for_department",
};

// overall_satisfaction_statistics
export const getOverallParticipationStatistics = async (id, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.generalSatisfaction}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};

// overall_nps_statistics
export const getOverallNpsStatistics = async (id, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${URL.main}/${id}/${path.generalNps}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};

// participation
export const getParticipation = async (id, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.participation}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};

// topics
export const getTopics = async (id, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.topics}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data?.topics;
};

// departments
export const getDepartments = async (id, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.departments}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data?.departments;
};

// satisfaction_for_department
export const getSatisfactionForDepartment = async (id, departmentId, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.satisfactionForDepartment}`, {
    params: {
      department_id: departmentId,
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};

// comments_for_department
export const getCommentsForDepartment = async (id, departmentId, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.commentsForDepartment}`, {
    params: {
      department_id: departmentId,
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};

// satisfaction_for_topic
export const getSatisfactionForTopic = async (id, topicId, query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${path.main}/${id}/${path.satisfactionForTopic}`, {
    params: {
      topic_id: topicId,
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};
