import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SimpleAccordion from "components/SimpleAccordion";
import NoDataMessage from "components_refactor/NoDataMessage";
import YoutubeCard from "components/YoutubeCard";
import {
  VARIANT, INDEX,
} from "common/constants";
import { isEqual } from "common/helpers";
import { createResultToSkillLevelMap, findDataInScale } from "common/utils";
import { skillTypeKeys } from "views_refactor/Profile/functions/skills";
import { getIcon, getTooltip, getContent } from "./functions";
import {
  StyledIcon, StyledTitle, StyledMainTitle, StyledCol,
} from "./styles";

const SkillCard = (props) => {
  const {
    title, icon, skills, tips, resultScale,
  } = props;
  const { t } = useTranslation("potential"); // TODO: delete this when you are able to test this (rn undefined)

  const resultToSkillLevelMap = createResultToSkillLevelMap(resultScale);

  const getTipInformation = (currentTip, key) => {
    const videoList = skillTypeKeys
      .map(
        (item) => isEqual(item.value, currentTip)
            && t(`tabPotential.skills.tips.${item.id}.${key}`, {
              returnObjects: true,
            }),
      )
      .filter((item) => item)[INDEX.zero];
    return videoList;
  };

  const getTipsContent = () => tips.map((tip) => {
    const videoIds = getTipInformation(tip?.tag, "videos");
    return (
      <Grid
        key={ tip.name }
        container
      >
        <Grid item xs={ 12 }>
          <StyledMainTitle>
            {tip?.tag && <StyledIcon src={ getIcon(tip.tag) } alt={ "tips section icon" } /> }
            <StyledTitle variant={ VARIANT.h5 }>{ tip.name }</StyledTitle>
          </StyledMainTitle>

        </Grid>
        { videoIds?.map((video) => (
          <Grid key={ video } item xs={ 12 } sm={ 6 } md={ 4 } >
            <YoutubeCard
              videoId={ video }
              viewMoreLabel={ t("tabPotential.knowMore") }
            />
          </Grid>
        ))}
      </Grid>
    );
  });

  return (
    <Grid container>
      <Grid item xs={ 12 } >
        {tips ? (
          <StyledCol>
            <SimpleAccordion
              title={ title }
              src={ icon }
              expandedPanel={ `skill-${title}` }
              isTitleImage
            >
              {!isEmpty(tips) && <Typography variant={ VARIANT.subtitleTwo }>{t("tabPotential.skills.turnOnSubtitles")}</Typography>}
              {isEmpty(tips) ? <NoDataMessage customStyles={ "noDataCustom" } /> : getTipsContent()}
            </SimpleAccordion>
          </StyledCol>
        ) : (
          <StyledMainTitle>
            <StyledIcon
              src={ icon }
              alt={ "skill section icon" }
            />
            <StyledTitle variant={ VARIANT.h5 }>
              {title}
            </StyledTitle>
          </StyledMainTitle>
        )}

      </Grid>
      { !tips && isEmpty(skills) ? <NoDataMessage customStyles={ "noDataCustom" } />
        : skills?.map((item) => {
          const scaleIndex = findDataInScale(
            resultScale?.result_scale_indices,
            item?.score,
            false,
          );
          const ratingData = {
            name: item.name,
            value: scaleIndex?.position,
            maxRating: resultScale?.result_scale_indices?.length,
          };
          return (
            <StyledCol key={ item.name } item xs={ 12 } sm={ 6 } md={ 4 } >
              <SimpleAccordion
                title={ item.name }
                src={ getIcon(item?.tag) }
                rating={ ratingData }
                hasTitleTooltip={ getTooltip(item?.tag, t) }
                expandedPanel={ `skill-${item.name}` }
              >
                {getContent(item?.tag, item.result, t, resultToSkillLevelMap)}
              </SimpleAccordion>
            </StyledCol>
          );
        })}
    </Grid>
  );
};

SkillCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  resultScale: PropTypes.object.isRequired,
  skills: PropTypes.array,
  tips: PropTypes.array,
};

SkillCard.defaultProps = {
  skills: null,
  tips: null,
};

export default SkillCard;
