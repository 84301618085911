import { AbilityBuilder } from "@casl/ability";
import { MODULES, MODULE_ACTIONS } from "common/constants";
import { getUserRoles } from "common/utils";
import { ROLE_MODULES } from "../functions";

export const createOrganizationChartAbilities = (user) => {
  const { can, rules } = new AbilityBuilder();
  const userRoles = getUserRoles(user?.userCookies);
  const organizationChartModule = ROLE_MODULES.find(
    (moduleItem) => moduleItem.slug === MODULES.organizationChart,
  );

  if (organizationChartModule) {
    const { slug, roles } = organizationChartModule;

    if (roles.some((role) => userRoles.includes(role))) {
      // Module access
      // It is already validated in the routes, but looking for a way to use this
      can(MODULE_ACTIONS.view, slug);
    }
  }

  return rules;
};
