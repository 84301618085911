import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { BUTTON_STYLE_TYPES, VARIANT } from "common/constants";
import Loader from "components/Loader";
import Button from "../Button";
import { StyledDialog, StyledDialogLoader } from "./styles";

const AlertModal = (props) => {
  const {
    title,
    text,
    textDisagree,
    textAgree,
    onClick,
    open,
    isLoading,
    handleClose,
    disableBackdropClick,
    disableEscapeKeyDown,
    disabledIcon,
    isLoader,
  } = props;

  const { t } = useTranslation("candidates");

  return (
    <div data-testid={ "alert-modal" }>
      {isLoader ? (
        <StyledDialogLoader
          disableBackdropClick={ disableBackdropClick }
          disableEscapeKeyDown={ disableEscapeKeyDown }
          open={ open }
          onClose={ handleClose }
          aria-labelledby={ "alert-dialog-title" }
          aria-describedby={ "alert-dialog-description" }
        >
          <Loader size={ 60 } />
        </StyledDialogLoader>
      ) : (
        <StyledDialog
          disableBackdropClick={ disableBackdropClick }
          disableEscapeKeyDown={ disableEscapeKeyDown }
          open={ open }
          onClose={ handleClose }
          aria-labelledby={ "alert-dialog-title" }
          aria-describedby={ "alert-dialog-description" }
        >
          {!disabledIcon && (
            <IconButton
              aria-label={ "close" }
              onClick={ handleClose }
              disabled={ isLoading }
            >
              <CloseIcon />
            </IconButton>
          )}
          <DialogTitle id={ "alert-dialog-title" }>
            {title}
          </DialogTitle>
          <DialogContent>
            {text && (
              <DialogContentText id={ "alert-dialog-description" }>
                {text}
              </DialogContentText>
            )}
            {props.children}
          </DialogContent>
          {onClick && (
            <DialogActions>
              <>
                <Button
                  onClick={ handleClose }
                  variant={ VARIANT.contained }
                  typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
                  disabled={ isLoading }
                >
                  {textDisagree || t("alertMessage.default_btn_cancel")}
                </Button>
                <Button
                  onClick={ onClick }
                  variant={ VARIANT.contained }
                  typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                  isLoading={ isLoading }
                  autoFocus
                >
                  {textAgree || t("alertMessage.default_btn_accept")}
                </Button>
              </>
            </DialogActions>
          )}
        </StyledDialog>
      )}
    </div>
  );
};
AlertModal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  textDisagree: PropTypes.string,
  textAgree: PropTypes.string,
  onClick: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  disabledIcon: PropTypes.bool,
  isLoader: PropTypes.bool,
};

export default AlertModal;
