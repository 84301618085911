import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputText from "components/InputText";
import { isEmpty, orderByAsc } from "common/helpers";
import { VARIANT, OBJECT_KEYS } from "common/constants";
import { StyledGrid } from "./styles";

const AdditionalData = (props) => {
  const {
    data, handleChange, answers, disabled,
  } = props;
  const { t } = useTranslation("common");

  return (
    <StyledGrid container spacing={ 3 } data-testid={ "additional-data" }>
      {orderByAsc(data, OBJECT_KEYS.id)?.map((item, index) => (
        <Grid item xs={ 12 } sm={ 6 } key={ index }>
          <InputText
            id={ item.id }
            label={ item.name }
            name={ item.name }
            variant={ VARIANT.outlined }
            onChange={ handleChange }
            value={ answers && answers[item.id] }
            fullWidth
            params={ {
              error: item.required && answers && isEmpty(answers[item.id]),
              helperText: item.required ? t("common.fieldRequired") : "",
            } }
            disabled={ disabled }
          />
        </Grid>
      ))}
    </StyledGrid>
  );
};

AdditionalData.propTypes = {
  data: PropTypes.array,
  handleChange: PropTypes.func,
  answers: PropTypes.object,
  disabled: PropTypes.bool,
};

AdditionalData.defaultProps = {
  data: [],
  handleChange: () => {},
  answers: {},
  disabled: false,
};

export default AdditionalData;
