import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import FeedbackIcon from "assets/images/feedback/feedback.svg";
import Modal from "components/Modal";
import InputTextController from "components/InputTextController";
import Button from "components/Button";
import {
  BUTTON_STYLE_TYPES,
  BUTTON_TYPE,
  FORM_MODE,
  INPUT_RULES,
  INPUT_TYPE,
  STATE,
} from "common/constants";
import { update as updateCandidate } from "redux/actions/candidateActions";
import { useStyles } from "./styles";

export default function CandidateHireFormModal(props) {
  const {
    candidateId,
    isOpen,
    onClose,
  } = props;
  const { t } = useTranslation(["common", "candidates"]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isLoadingProcess,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    reValidateMode: FORM_MODE.onChange,
    mode: FORM_MODE.onChange,
  });

  const onSubmit = (formData) => {
    if (formData) {
      formData.candidate.state_transition = STATE.HIRE;
      dispatch(updateCandidate(formData, candidateId));
    }
  };

  return (
    <div data-testid={ "feedback-modal-view" }>
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
        title={ t("candidates:mark_as_hired_modal.title") }
        iconTitle={ FeedbackIcon }
      >
        <Grid className={ classes.root }>
          <form onSubmit={ handleSubmit(onSubmit) }>
            <Grid item xs={ 8 }>
              <InputTextController
                type={ INPUT_TYPE.text }
                label={ t("common:common.corporative_email") }
                placeholder={ t("common:common.email") }
                control={ control }
                rules={ INPUT_RULES.isValidEmail }
                required
                name={ "candidate.email" }
              />
            </Grid>
            <Grid item xs={ 12 } className={ classes.btnSubmit }>
              <Button
                type={ BUTTON_TYPE.submit }
                typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
                disabled={ !isValid }
                isLoading={ isLoadingProcess }
              >
                {t("common:common.save")}
              </Button>
            </Grid>
          </form>
        </Grid>
      </Modal>
    </div>
  );
}

CandidateHireFormModal.propTypes = {
  candidateId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
