import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleVerticalStepper from "components/SimpleVerticalStepper";
import NoDataMessage from "components_refactor/NoDataMessage";
import { SKELETONS_NUMBER } from "common/constants";
import { ReactComponent as Clock } from "assets/images/salary/clock.svg";
import {
  stepContentTitle,
  extraStep,
  translation,
  getStepIcon,
} from "../../../../functions/salary";

const MovementstHistory = (props) => {
  const { data, isLoading } = props;
  const { t } = useTranslation("account");

  return (
    <Box mt={4} data-testid={"salary-tab-movements"}>
      <Card>
        <CardHeader title={t("movementsHistory.title")} />
        <CardContent>
          {isLoading && (
            <SkeletonLoader num={SKELETONS_NUMBER.EIGTH} />
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {isEmpty(data) ? (
                <NoDataMessage />
              ) : (
                <SimpleVerticalStepper
                  data={data}
                  getStepIcon={getStepIcon}
                  stepContentTitle={stepContentTitle(t)}
                  extraStep={extraStep(<Clock />, "")}
                  mainTranslate={translation(t, "movementsHistory")}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

MovementstHistory.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default MovementstHistory;
