import { getPercent } from "common/utils";

/**
 * Retrieves all unique result labels from `result_scale_indices`, sorted by `position` descending.
 */
export const getAllResultsLabels = (indices) => indices
  .slice()
  .sort((a, b) => a.top - b.top)
  .map((item) => item.result);

/**
 * Converts process data into a structure suitable for Chart.js.
 * Ensures values are scaled to base 100 (multiplying by 100).
 * Also sorts the results in descending order of `position`.
 */
export const transformProcessData = (processes, indices) => {
  const result = {};
  const expectedIndices = indices.length;
  const sortedIndices = indices.slice().sort((a, b) => a.top - b.top);

  processes.forEach((process) => {
    const processIndices = Object.keys(process.results_by_scale_indices).length;

    if (processIndices !== expectedIndices) {
      result[process.id] = { invalid: true };
    } else {
      result[process.id] = {};
      sortedIndices.forEach((scale, index) => {
        const value = process.results_by_scale_indices[index + 1] || 0;
        result[process.id][scale.result] = getPercent(value);
      });
    }
  });

  return result;
};

// Predefined color palette
const predefinedColors = [
  "#5257FF", "#A3A6FF", "#F2994A", "#D0EEF2", "#EFD756",
  "#EFE1FF", "#D7E9C4", "#CEA3FF", "#3BE9DF", "#00059E",
];

/**
 * Assigns a unique color from `predefinedColors` to each process.
 * If there are more processes than colors, it cycles through the colors.
 */
export const getProcessColor = (() => {
  const assignedColors = {};
  let validProcessCount = 0;

  return (id, isInvalid = false) => {
    if (isInvalid) {
      return "#B0B0B0";
    }

    if (!assignedColors[id]) {
      assignedColors[id] = predefinedColors[validProcessCount % predefinedColors.length];
      validProcessCount += 1;
    }

    return assignedColors[id];
  };
})();

export const hexToRgba = (hex, alpha = 1) => {
  let newColor = hex.replace("#", "");
  if (newColor.length === 3) {
    newColor = newColor.split("").map((color) => color + color).join("");
  }
  const r = parseInt(newColor.slice(0, 2), 16);
  const g = parseInt(newColor.slice(2, 4), 16);
  const b = parseInt(newColor.slice(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
