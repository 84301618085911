import { useTranslation } from "react-i18next";
import NoDataMessage from "components_refactor/NoDataMessage";
import {
  Container,
} from "./styles";

// TODO: remove file
const NineBoxProfile = () => {
  const { t } = useTranslation(["potential", "common", "performance"]);

  return (
    <Container data-testid={ "nine-box-profile-view" }>
      <NoDataMessage
        message={ t("ninebox:historical_empty") }
      />
    </Container>
  );
};

export default NineBoxProfile;
