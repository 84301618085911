import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TabPanel from "components_refactor/TabPanel";
import Tab from "components/Tab";
import SectionContainer from "components_refactor/SectionContainer";
import NoDataMessage from "components_refactor/NoDataMessage";
import Button from "components/Button";
import { ReactComponent as EnvelopIcon } from "assets/images/icons/envelop.svg";
import { ReactComponent as LinkedinIcon } from "assets/images/icons/linkedin.svg";
import emptyData from "assets/images/successions/succession_empty.svg";
import { useInternalClientsByEmployee, useDeleteInternalClient } from "hooks/useInternalClient";
import { isEmpty, isNotValid } from "common/helpers";
import { TARGET } from "common/constants";
import { replacePath, truncateName } from "common/utils";
import constants from "styledComponents/constants";
import { StyledDeleteChip } from "styledComponents/Chip";
import { StyledNoData } from "views_refactor/Profile/styles";
import InfoEditButton from "./components/InfoEditButton";
import RolesWithTooltips from "./components/RolesWithTooltips";
import InternalClientModal from "./components/InternalClientModal";
import localRoutes from "../../functions/routes";
import { getOrganizationUnitName } from "../../functions/profile";
import { StyledGridInfo } from "../../styles";
import {
  StyledInfoContainer,
  StyledTitle,
  StyleRolesContainer,
  StyledLink,
  StyledTabsContainer,
  StyledEmptyContainer,
  StyledIconButton,
} from "./styles";

const EmployeeInfo = (props) => {
  const {
    t, collaborator, history, isAdmin,
    hasAuthorization, user, isOwnProfile,
  } = props;

  const hasInternalClient = user?.company?.internal_client;

  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (newValue) => {
    setActiveTab(newValue);
  };

  const orgUnits = getOrganizationUnitName(collaborator?.job_position?.organization_units);
  const orgUnitsName = [orgUnits.division?.name, orgUnits.area?.name, orgUnits.subarea?.name];
  const formattedOrgNames = orgUnitsName.slice(1).filter((name) => name).join(" | ");

  const completedDocumentsLength = collaborator?.requested_documents
    ? collaborator.requested_documents.filter((doc) => doc.file_url || doc.url).length
    : 0;

  const tabs = [{ label: t("common:common.location") }];
  if (hasInternalClient) {
    tabs.push({ label: t("common:common.internal_clients") });
  }

  const [modal, setModal] = useState(false);

  const handleAddModal = () => {
    setModal(!modal);
  };

  const {
    internalClients,
    isLoading: isLoadingInternalClients,
  } = useInternalClientsByEmployee(collaborator?.id, t, hasInternalClient);

  const { mutate: deleteInternalClient } = useDeleteInternalClient(t, collaborator?.id);

  const handleDeleteClient = (id) => {
    deleteInternalClient(id);
  };

  const isValidToConfigInternalClients = (isAdmin && hasAuthorization)
  || isOwnProfile || collaborator?.is_descendant;

  const internalClientComponent = internalClients?.map((employee) => (
    <Tooltip key={ employee.internal_client_id } title={ employee.internal_client_full_name } arrow>
      <StyledDeleteChip
        label={ truncateName(employee.internal_client_full_name, 20) }
        variant={ "outlined" }
        color={ "primary" }
        size={ "small" }
        onDelete={ isValidToConfigInternalClients
          ? () => handleDeleteClient(employee.id) : undefined }
        width={ "80px" }
      />
    </Tooltip>
  ));

  return (
    <SectionContainer padding={ "0px" } isFlex>
      <StyledTabsContainer>
        <Tab
          tabs={ tabs }
          onChange={ handleChangeTab }
          tabValue={ activeTab }
        />

        <TabPanel value={ activeTab } index={ 0 }>
          <StyledGridInfo container spacing={ 2 }>
            {isAdmin && hasAuthorization && (
              <Grid item xs={ 12 }>
                <InfoEditButton { ...props } />
              </Grid>
            )}
            <Grid item xs={ 12 } md={ 6 }>
              <StyledInfoContainer>
                <StyledTitle weight={ constants.fontConstants.bold }>
                  {t("profile:unit")}
                </StyledTitle>
                <p>
                  {orgUnitsName[0] || (
                    <StyledNoData>{t("profile:noData")}</StyledNoData>
                  )}
                </p>
              </StyledInfoContainer>
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <StyledInfoContainer>
                <StyledTitle weight={ constants.fontConstants.bold }>
                  {t(`profile:${collaborator?.job_position?.city ? "city" : "country"}`)}
                </StyledTitle>
                <p>
                  {collaborator?.job_position?.city?.name
                  || collaborator?.job_position?.country?.name || (
                    <StyledNoData>{t("profile:noData")}</StyledNoData>
                  )}
                </p>
              </StyledInfoContainer>
            </Grid>
            {orgUnitsName.filter((name) => name !== undefined).length > 1 && (
              <Grid item xs={ 12 }>
                <StyledInfoContainer>
                  <StyledTitle weight={ constants.fontConstants.bold }>
                    {t("profile:orgUnit")}
                  </StyledTitle>
                  <StyleRolesContainer>
                    {isNotValid(collaborator?.job_position)
                  || isEmpty(collaborator?.job_position?.organization_units)
                      ? (
                        <StyledNoData>{t("profile:noData")}</StyledNoData>
                      ) : (
                        formattedOrgNames
                      )}
                  </StyleRolesContainer>
                </StyledInfoContainer>
              </Grid>
            )}
            <Grid item xs={ 6 }>
              <StyledInfoContainer>
                <StyledTitle weight={ constants.fontConstants.bold }>
                  {t("profile:role")}
                </StyledTitle>
                <RolesWithTooltips collaborator={ collaborator } t={ t } />
              </StyledInfoContainer>
            </Grid>
            <Grid item xs={ 6 }>
              <StyledInfoContainer>
                <StyledTitle weight={ constants.fontConstants.bold }>
                  {t("profile:documents")}
                </StyledTitle>
                <StyledLink
                  onClick={ () => replacePath(history, localRoutes.documents) }
                >
                  {`(${completedDocumentsLength}) ${t("profile:filesUploaded")}`}
                </StyledLink>
              </StyledInfoContainer>
            </Grid>
            <Grid item xs={ 12 }>
              <StyledInfoContainer color={ "link" }>
                {collaborator?.email && (
                  <div className={ "imageTextContainer" }>
                    <EnvelopIcon />
                    <a href={ `mailto:${collaborator.email}` }>{collaborator.email}</a>
                  </div>
                )}
                {collaborator?.person?.linkedin_profile && (
                  <div className={ "imageTextContainer" }>
                    <LinkedinIcon />
                    <Link href={ collaborator?.person?.linkedin_profile } target={ TARGET.blank }>
                      {t("profile:seeLinkedinProfile")}
                    </Link>
                  </div>
                )}
              </StyledInfoContainer>
            </Grid>
          </StyledGridInfo>
        </TabPanel>
        {hasInternalClient && (
          <TabPanel value={ activeTab } index={ 1 }>
            <StyledGridInfo container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <StyledInfoContainer minHeight={ "300px" }>
                  {!isLoadingInternalClients && internalClients?.length > 0 ? (
                    <>
                      {internalClientComponent}
                      {isValidToConfigInternalClients
                      && (
                        <Tooltip title={ t("common:common.add") }>
                          <StyledIconButton onClick={ () => handleAddModal() }>
                            <AddCircleIcon />
                          </StyledIconButton>
                        </Tooltip>
                      )}
                    </>
                  ) : (
                    <StyledEmptyContainer>
                      <NoDataMessage
                        iconImage={ emptyData }
                        message={ t("common:internal_clients.card.empty.title") }
                      />
                      {isValidToConfigInternalClients
                      && (
                        <Button
                          type={ "text" }
                          onClick={ () => handleAddModal() }
                        >
                          {t("common:internal_clients.card.empty.action")}
                        </Button>
                      )}
                    </StyledEmptyContainer>
                  )}
                </StyledInfoContainer>
                <InternalClientModal
                  isOpen={ modal }
                  onClose={ handleAddModal }
                  collaborator={ collaborator }
                />
              </Grid>
            </StyledGridInfo>
          </TabPanel>
        )}
      </StyledTabsContainer>
    </SectionContainer>
  );
};

EmployeeInfo.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  hasAuthorization: PropTypes.bool.isRequired,
  history: PropTypes.object,
  isAdmin: PropTypes.bool,
  user: PropTypes.object,
  isOwnProfile: PropTypes.bool,
};

EmployeeInfo.defaultProps = {
  collaborator: null,
  history: null,
  isAdmin: false,
  user: {
    company: {
      internal_client: false,
    },
  },
  isOwnProfile: false,
};

export default EmployeeInfo;
