import { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { getSuccessionByCollaboratorsLight as getCollaborators, getCollaborator } from "redux/actions/collaboratorActions";
import { getList as getOrgUnitsList } from "redux/actions/common/orgUnitActions";
import { getList as getResultScales } from "redux/actions/resultScalesActions";
import { NINEBOX, SKELETONS_NUMBER, ROWS_RANGE_PER_PAGE_LIGHT } from "common/constants";
import { formatDate } from "common/utils";
import { getDataNineBoxQuadrant } from "views/MatrixNineBox/functions";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import { ReactComponent as UserIcon } from "assets/images/icons/user.svg";
import noSuccessionList from "assets/images/successions/succession_empty.svg";
import SkeletonLoader from "components/SkeletonLoader";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import SimpleCustomAccordion from "components/SimpleCustomAccordion";
import TableGrid from "components/TableGrid";
import NoDataMessage from "components_refactor/NoDataMessage";
import Button from "components/Button";
import { ReactComponent as CriticalPosition } from "assets/images/icons/critic.svg";
import { getCurrentTalentScoreByEmployee } from "redux/actions/talentScore/talentScoreActions";
import { getSuccessionPlansByPosition } from "redux/actions/position/positionActions";
import { generateEstimatedTimeByLanguage } from "views/Successions/functions";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import SuccessionForm from "./components/SuccessionForm";
import CollaboratorInfo from "./components/CollaboratorInfo";
import SuccessionInfo from "./components/SuccessionInfo";
import {
  StyledInfo,
  StyledHeaderIcon,
  StyledContainer,
  StyledInfoContainer,
  StyledContainerInfo,
  StyledGridContainer,
  StyledEmptyContainer,
  StyledContainerAccordion,
} from "./styles";

const SuccessionsByPosition = (props) => {
  const { employeeInfo } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(["common", "successions"]);
  const { language } = i18n;

  const [collaboratorsSelected, setCollaboratorsSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [successionPlans, setSuccessionPlans] = useState([]);
  const [addCollaborators, setAddCollaborators] = useState(false);

  const {
    collaboratorSuccessionList,
    isLoadingCollaboratorSuccession,
  } = useSelector(({ collaboratorReducer }) => collaboratorReducer);

  const {
    list: resultScaleList,
    isLoading: isLoadingScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const { nineBoxTypeList, isLoading: isLoadingType } = useNineBoxTypes(t);

  const getNineboxResult = async (selectedEmployee) => {
    let nineboxResult = null;
    let quadrant = null;
    const currentTalentScore = await getCurrentTalentScoreByEmployee(selectedEmployee);
    if (currentTalentScore?.performance && currentTalentScore?.potential) {
      const results = getDataNineBoxQuadrant(currentTalentScore, resultScaleList, t, nineBoxTypeList);
      if (results.length > 0) {
        quadrant = results[0].quadrantData[NINEBOX.quadrantData.quadrant];
      }
      const performanceDate = new Date(currentTalentScore.performance.score_updated_at);
      const potentialDate = new Date(currentTalentScore.potential.score_updated_at);
      const latestDate = performanceDate > potentialDate ? performanceDate : potentialDate;
      nineboxResult = {
        nineboxQuadrant: {
          quadrant,
          updatedAt: formatDate(latestDate),
        },
      };
    }
    return nineboxResult;
  };

  const getDataByEmployee = useCallback(async () => {
    if (collaboratorSuccessionList.length > 0) {
      try {
        const collaboratorsDataPromises = collaboratorSuccessionList.map(async (
          selectedEmployee,
        ) => {
          const employeeData = await dispatch(getCollaborator(selectedEmployee.id));
          const nineboxResult = await getNineboxResult(selectedEmployee.id);
          return {
            ...employeeData,
            results: nineboxResult,
          };
        });

        const collaboratorsData = await Promise.all(collaboratorsDataPromises);

        setCollaboratorsSelected(collaboratorsData);
      } catch (error) {
        setCollaboratorsSelected([]);
      }
    }
    setIsLoading(false);
  }, [collaboratorSuccessionList, dispatch]);

  useEffect(() => {
    if (collaboratorSuccessionList && !isLoadingType) {
      getDataByEmployee();
    }
    // eslint-disable-next-line
  }, [collaboratorSuccessionList]);

  const getSuccessionPlans = useCallback(async (query, positionId) => {
    if (employeeInfo.successorsCount > 0) {
      const originalQuery = query;
      const newQuery = {
        ...originalQuery,
        q: {
          ...originalQuery.q,
        },
      };
      delete newQuery.q.position_id_eq;
      try {
        const successions = await getSuccessionPlansByPosition(positionId, newQuery);
        setSuccessionPlans(successions);
      } catch (error) {
        setSuccessionPlans([]);
      }
    }
  });

  useEffect(() => {
    if (resultScaleList !== null && !isLoadingScaleList) {
      if (employeeInfo) {
        const originalQuery = employeeInfo.query;
        const newQuery = {
          ...originalQuery,
          q: {
            ...originalQuery.q,
          },
        };
        delete newQuery.q.s;
        newQuery.q.position_id_eq = employeeInfo.positionId;
        newQuery.q.organization_unit_id_eq = employeeInfo.orgUnitId;
        newQuery.q.active_in = [true];
        dispatch(getCollaborators(newQuery));
        getSuccessionPlans(newQuery, employeeInfo.positionId);
      }
    }
    // eslint-disable-next-line
  }, [resultScaleList]);

  useEffect(() => {
    dispatch(getOrgUnitsList());
    dispatch(getResultScales());
    // eslint-disable-next-line
  }, []);

  const estimatedTime = generateEstimatedTimeByLanguage(language);

  const successorsCountComponent = (
    <StyledContainer>
      <h4>{t("administrator:modules.positions.successions.title")}</h4>
      <StyledInfoContainer>
        <h5>{successionPlans?.length || 0}</h5>
        <UserIcon />
      </StyledInfoContainer>
    </StyledContainer>
  );

  const collaboratorsCountComponent = (
    <StyledContainer>
      <h4>{t("administrator:modules.positions.successions.actives")}</h4>
      <StyledInfoContainer>
        <h5>{collaboratorsSelected?.length || 0}</h5>
        <UserIcon />
      </StyledInfoContainer>
    </StyledContainer>
  );

  const handleDeleteSuccession = (successionPlanId) => {
    setSuccessionPlans((currentPlans) => currentPlans.filter(
      (plan) => plan.id !== successionPlanId,
    ));
  };

  const successorsContent = employeeInfo.successorsCount === 0 ? (
    <StyledEmptyContainer>
      <NoDataMessage
        iconImage={ noSuccessionList }
        message={ t("administrator:modules.positions.successions.empty") }
      />
      <Button
        type={ "text" }
        onClick={ () => setAddCollaborators(true) }
      >
        {t("successions:actions.add")}
      </Button>
    </StyledEmptyContainer>
  ) : successionPlans?.length !== 0
    && (
      <>
        {successionPlans.map((succession) => (
          <SuccessionInfo
            key={ succession.id }
            succession={ succession }
            t={ t }
            estimatedTime={ estimatedTime }
            handleDeleteSuccession={ handleDeleteSuccession }
          />
        ))}
        <StyledEmptyContainer>
          <Button
            type={ "text" }
            onClick={ () => setAddCollaborators(true) }
          >
            {t("successions:actions.add")}
          </Button>
        </StyledEmptyContainer>
      </>
    );

  const handleSuccessionList = (hasChange = false) => {
    setAddCollaborators(false);
    if (hasChange) {
      const newQuery = {
        q: {
          active_in: [true],
        },
      };
      getSuccessionPlans(newQuery, employeeInfo.positionId);
    }
  };

  const getCollaboratorData = (collaborator) => (
    <StyledContainerInfo>
      <CollaboratorInfo
        collaborator={ collaborator }
        t={ t }
        estimatedTime={ estimatedTime }
      />
    </StyledContainerInfo>
  );

  const header = [
    {
      id: "full_name",
      label: t("tables:headers.name"),
      customRender: (rowData) => getCollaboratorData(rowData),
    },
  ];

  return (
    <Grid container spacing={ 2 }>
      <StyledGridContainer item xs={ 12 }>
        <StyledInfo>
          <StyledHeaderIcon>
            <SuccessionIcon />
          </StyledHeaderIcon>
          <ViewHeaderTitle
            title={ employeeInfo.positionName }
            subtitle={ employeeInfo.orgUnitName }
            icon={ employeeInfo.isCriticalPosition ? <CriticalPosition fontSize={ "small" } /> : "" }
          />
        </StyledInfo>
      </StyledGridContainer>
      {(isLoading || isLoadingCollaboratorSuccession)
        ? <SkeletonLoader num={ SKELETONS_NUMBER.SIX } />
        : (
          <Grid item xs={ 12 }>
            {addCollaborators ? (
              <SuccessionForm
                orgUnitId={ employeeInfo.orgUnitId }
                positionId={ employeeInfo.positionId }
                handleSuccessionList={ handleSuccessionList }
              />
            )
              : (
                <>
                  {collaboratorsSelected.length !== 0 && (
                    <StyledContainerAccordion>
                      <SimpleCustomAccordion
                        header={ collaboratorsCountComponent }
                        content={ (
                          <TableGrid
                            rows={ collaboratorsSelected }
                            id={ "collaborator-table-grid" }
                            header={ header }
                            paginationOptions={ {
                              maxPerPage: ROWS_RANGE_PER_PAGE_LIGHT[0],
                              rowsRange: ROWS_RANGE_PER_PAGE_LIGHT,
                            } }
                            isLoading={ isLoadingCollaboratorSuccession }
                            searchBy={ "full_name" }
                          />
                        ) }
                        isActive={ employeeInfo?.successorsCount !== 0 }
                      />
                    </StyledContainerAccordion>
                  )}
                  {employeeInfo.successorsCount === null ? ""
                    : (
                      <SimpleCustomAccordion
                        header={ successorsCountComponent }
                        content={ successorsContent }
                        isActive={ employeeInfo.successorsCount === 0 }
                      />
                    )}
                </>
              )}
          </Grid>
        )}
    </Grid>
  );
};

SuccessionsByPosition.propTypes = {
  employeeInfo: PropTypes.object.isRequired,
};

export default SuccessionsByPosition;
