import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import InputText from "components/InputTextController";
import SelectController from "components/SelectController";
import RadioButtonController from "components/RadioButtonController";
import { COMPONENT, VARIANT } from "common/constants";
import { getLabelAnswer, getLabelPlacement, getLabelClassName } from "components/Question/functions";
import QuestionActions from "../QuestionActions";
import EvaluationScaleForm from "../../EvaluationScaleForm";
import EvaluationScaleEditForm from "../../EvaluationScaleEditForm";
import { getFormattedScalesList } from "../../../functions";
import {
  StyledContainer,
  StyledContent,
  StyledFormControl,
  StyledRadioGroup,
  StyledQuestionContainer,
  StyledSelectContainer,
  StyledEvaluationScaleForm,
} from "./styles";

const Question = (props) => {
  const {
    questionId,
    question,
    scale,
    scales,
    formLanguage,
    control,
    t,
    watch,
    open,
    setValue,
    onDelete,
    handleAddNewQuestion,
    handleDuplicateQuestion,
    isNew,
  } = props;

  const [scaleValue, setScaleValue] = useState(null);
  const [showActions, setShowActions] = useState(false);
  const [isCustomFormVisible, setIsCustomFormVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [lastSelectedScale, setLastSelectedScale] = useState(scale?.id);

  const selectWatch = watch(`scale_${questionId}`);

  useEffect(() => {
    if (scale) {
      setScaleValue(scale);
    }
  }, [scale]);

  // Handle changes in scale selection
  useEffect(() => {
    if (selectWatch && selectWatch !== "custom") {
      setLastSelectedScale(selectWatch);
    }
  }, [selectWatch]);

  // Function to render the answers based on the selected scale
  const getAnswers = () => {
    if (scaleValue !== null) {
      const actualScale = scales.find((item) => item.id === scaleValue.id);
      return actualScale && actualScale?.indices?.map((item) => {
        const firstIdScale = actualScale.indices[0].id;
        const lastIdScale = actualScale.indices[actualScale.indices.length - 1].id;
        const label = getLabelAnswer(item.id, item[`answer_${formLanguage}`], actualScale, firstIdScale, lastIdScale);
        const labelPlacementType = getLabelPlacement(actualScale);
        const labelClassName = getLabelClassName(labelPlacementType, label);
        return (
          <RadioButtonController
            label={ label }
            value={ item[`answer_${formLanguage}`] }
            key={ item.id }
            labelPlacement={ labelPlacementType }
            className={ labelClassName }
            disabled
          />
        );
      });
    }
    return [];
  };

  // Handle scale form visibility
  useEffect(() => {
    if (selectWatch) {
      setIsEditFormVisible(false);
      if (selectWatch === "custom") {
        setIsCustomFormVisible(true);
      } else {
        setIsCustomFormVisible(false);
        const newScale = scales.find((item) => item.id === selectWatch);
        setScaleValue(newScale);
      }
    }
  }, [selectWatch]);

  // Cancel adding/editing a custom scale
  const handleCancelSectionClick = () => {
    setIsCustomFormVisible(false);
    setValue(`scale_${questionId}`, lastSelectedScale);
    const newScale = scales.find((item) => item.id === lastSelectedScale);
    setScaleValue(newScale);
  };

  // Cancel editing a scale
  const handleCancelEditClick = () => {
    setIsEditFormVisible(false);
    setValue(`scale_${questionId}`, lastSelectedScale);
    const newScale = scales.find((item) => item.id === lastSelectedScale);
    setScaleValue(newScale);
  };

  // Add a new scale to the list of scales
  const handleCreateScale = (newScale) => {
    scales.push(newScale);
    setValue(`scale_${questionId}`, newScale.id);
    setScaleValue(newScale);
  };

  // Update an existing scale
  const handleEditScale = (updatedScale) => {
    const scaleIndex = scales.findIndex((scaleData) => scaleData.id === updatedScale.id);
    if (scaleIndex !== -1) {
      scales[scaleIndex] = updatedScale;
    }
    setValue(`scale_${questionId}`, updatedScale.id);
    setScaleValue(updatedScale);
  };

  // Open the form to edit an existing scale
  const handleEditEvaluationScale = () => {
    setIsCustomFormVisible(false);
    setIsEditFormVisible(true);
  };

  return (
    <StyledContainer
      onMouseEnter={ () => setShowActions(true) }
      onMouseLeave={ () => setShowActions(false) }
      open={ open }
      new={ isNew }
      data-testid={ `question_${questionId}` }
    >
      <StyledContent>
        <StyledQuestionContainer open={ open }>
          <InputText
            name={ `question_${formLanguage}_${questionId}` }
            defaultValue={ question }
            control={ control }
            variant={ VARIANT.outlined }
            rules={ {
              required: true,
            } }
            multiline={ 2 }
            placeholder={ t("planning:templates.sections.question") }
          />
        </StyledQuestionContainer>
        {!open && (
          <StyledSelectContainer>
            <SelectController
              name={ `scale_${questionId}` }
              label={ t("planning:answerType") }
              control={ control }
              menuItems={ getFormattedScalesList(scales, formLanguage) }
              sx={ { width: "100%" } }
              defaultValue={ scale?.id || "" }
            />
          </StyledSelectContainer>
        )}
      </StyledContent>
      {isCustomFormVisible
      && (
        <StyledEvaluationScaleForm>
          <EvaluationScaleForm
            { ...props }
            handleCancelSectionClick={ handleCancelSectionClick }
            handleCreateScale={ handleCreateScale }
          />
        </StyledEvaluationScaleForm>
      )}
      {scale !== null
        && (
          <StyledFormControl
            component={ COMPONENT.fieldset }
            alignItems={ "center" }
          >
            <StyledRadioGroup
              aria-label={ question }
              name={ question }
              defaultValue={ "" }
              display={ "inline" }
            >
              { getAnswers() }
            </StyledRadioGroup>
          </StyledFormControl>
        )}
      {isEditFormVisible
        && (
          <StyledEvaluationScaleForm>
            <EvaluationScaleEditForm
              { ...props }
              handleCancelSectionClick={ handleCancelEditClick }
              handleEditScale={ handleEditScale }
              scale={ scale }
            />
          </StyledEvaluationScaleForm>
        )}
      {showActions && !isCustomFormVisible && !isEditFormVisible && (
        <QuestionActions
          onAdd={ handleAddNewQuestion }
          onDuplicate={ handleDuplicateQuestion }
          onDelete={ onDelete }
          editScale={ scale ? handleEditEvaluationScale : null }
          t={ t }
        />
      )}
    </StyledContainer>
  );
};

Question.propTypes = {
  questionId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  question: PropTypes.string.isRequired,
  formLanguage: PropTypes.string.isRequired,
  scale: PropTypes.object,
  scales: PropTypes.arrayOf(PropTypes.object).isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func,
  t: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  handleAddNewQuestion: PropTypes.func,
  handleDuplicateQuestion: PropTypes.func,
  onDelete: PropTypes.func,
  isNew: PropTypes.bool,
};

Question.defaultProps = {
  scale: null,
  watch: null,
  open: false,
  handleAddNewQuestion: () => {},
  handleDuplicateQuestion: () => {},
  onDelete: () => {},
  isNew: false,
};

export default Question;
