import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import InputText from "components/InputText";

const AutoCompleteController = ({
  options,
  name,
  label,
  control,
  defaultValue,
  onChangeValue,
  disabled,
}) => {
  const getOptionLabel = (option) => option?.label || "";

  return (
    <Controller
      name={ name }
      control={ control }
      defaultValue={ defaultValue }
      render={ ({
        onChange, onBlur, value, ref,
      }) => (
        <Autocomplete
          disabled={ disabled }
          options={ options }
          getOptionLabel={ getOptionLabel }
          onChange={ (_, newValue) => {
            // Handle the selection of a new value
            const selectedValue = newValue ? newValue.value : null;
            onChange(selectedValue);
            if (onChangeValue) onChangeValue(selectedValue);
          } }
          onBlur={ onBlur }
          // Set the value based on the current form value
          value={ options.find((option) => option.value === value) || null }
          renderInput={ (params) => <InputText { ...params } label={ label } ref={ ref } /> }
        />
      ) }
    />
  );
};

AutoCompleteController.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
control: PropTypes.object.isRequired,
  defaultValue: PropTypes.string,
  onChangeValue: PropTypes.func,
  disabled: PropTypes.bool,
};

AutoCompleteController.defaultProps = {
  defaultValue: "",
  onChangeValue: null,
  disabled: false,
};

export default AutoCompleteController;
